<template>
    <div :id="idx" class="relative w-full inline-flex justify-center">
        <button @click="show=!show" class="bg-white relative w-full px-5 h-9 hover:bg-red-100 hover:border-gray-300" :disabled="isDisabled">
            <span v-if="!text" class="text-gray-400 italic">{{label}}</span>
            <div v-if="text">
                <span class="text-red-500">{{text}}</span>
            </div>
            <a v-if="text" @click.prevent="onClear" href="#" class="absolute right-0 -bottom-px  h-9 hover:text-red-500 flex items-center p-2"><i class="icon-cross3" tabindex="-1"></i></a>
        </button>
        <div v-show="show" class="absolute z-20 animated faster fadeIn bg-white border w-60 bottom-11 rounded-sm mb-0.5 shadow-lg">
            <div class="py-1 px-3 border-b rounded-t-sm bg-gray-100 text-gray-500">apply discount</div>
            <div class="p-3">
                <div class="mb-3">
                    <label for="">Discount Type</label>
                    <div ref="dropdown" class="mt-1 relative">
                        <button @click="showDiscount = !showDiscount" class="px-3 h-8 w-full border rounded-sm bg-white flex items-center justify-between" :class="{'rounded-b-none': showDiscount}"> 
                            <span>{{discountText}}</span>
                            <span><i class="icon-arrow-down22"></i></span>
                        </button>
                        <ul v-show="showDiscount" class="absolute bg-white w-full border rounded-b-sm left-0 -mt-px top-8 z-10 shadow-lg pb-1 pt-2">
                            <li v-for="(discount, i) in selectDiscount" :key="i" @click="onSelectDiscount(discount)" class="py-1.5 px-3 text-left hover:bg-gray-100" :class="{'bg-blue-500 text-blue-50 hover:bg-blue-500': discount.value == discountType}">{{discount.text}}</li>
                        </ul>
                    </div>
                </div>
                <div class="mb-3">
                    <label for=""></label>
                    <div class="flex">
                        <span :class="{'border-red-500': err}" class="h-8 flex items-center justify-center w-8 bg-gray-100 border rounded-l-sm px-3 text-gray-400">{{iconType}}</span>
                        <autonumeric ref="amount" v-model="amount" @keydown.native="err=''" type="text" :class="{'border-red-500': err}" class="border w-full h-8 outline-none px-3 rounded-tl-none rounded-bl-none border-l-0" :options="{modifyValueOnWheel : false, maximumValue: this.maximumValue, minimumValue: 0}" :placeholder="'Amount'"></autonumeric>
                    </div>
                </div>
                <div class="mb-1">
                    <button @click="onApply" class="w-full bg-white border rounded-sm px-5 h-8 hover:bg-gray-100 hover:border-gray-300 text-cyan-500">Apply</button>
                </div>
            </div>
            <div class="w-full flex justify-center relative">
                <div class="absolute -bottom-2 h-3.5 w-3.5 border border-l-0 border-t-0 transform rotate-45 bg-white "></div>
            </div>
        </div>
    </div>
</template>
<script>
import Autonumeric from '../components/Autonumeric.vue'
export default {
    components: {Autonumeric},
    props: ['value','symbol', 'total', 'label', 'idx', 'isDisabled'],
    data () {
        return {
            show: false,
            discountType: 'Percentage',
            discountText: 'Percentage (%)',
            amount: 0,
            err: '',
            text: '',
            discount: {
                discountType: '',
                amount: '',
                value: 0,
            },
            showDiscount: false,
            maximumValue: 100
        }
    },
    mounted () {
        if(this.value.discountType == 'Fixed') {
            this.discountText = 'Fixed (Rp)'
            this.maximumValue = 100000000
        } else {
            this.maximumValue = 100
        }
        this.$nextTick (() => {
            this.discountType = this.value.discountType || 'Percentage'
            this.amount = this.value.amount
            if(this.discountType == 'Percentage' && this.amount) {
                this.text = this.amount+'%'
            }
            if(this.discountType == 'Fixed' && this.amount) {
                this.text = Intl.NumberFormat().format(this.amount)
            }
        })
        document.addEventListener('click', this.closMenu)
        document.addEventListener('click', this.closeDropdown)
    },
    destroyed () {
        document.removeEventListener('click', this.closMenu)
        document.removeEventListener('click', this.closeDropdown)
    },
    computed: {
        iconType () {
            let icn = ''
            if(this.discountType == 'Percentage') {
                icn = '%'
            } else {
                icn = this.symbol
            }
            return icn
        },
    selectDiscount () {
        let data = [
            {text: 'Percentage (%)', value: 'Percentage'},
            {text: `Fixed (${this.symbol})`, value: 'Fixed'},
        ]
        return data
    }
    },
    methods: {
        closMenu ($event) {
            if(!$event || !this.$el.contains($event.target)) {
                this.show = false
            }
        },
        closeDropdown (e) {
            if(!e || !this.$refs.dropdown.contains(e.target)) {
                this.showDiscount = false
            }
        },
        onSelectDiscount (d) {
            this.discountType = d.value
            this.discountText = d.text
            this.showDiscount = false
            this.amount = ''
            this.$nextTick(() => {
                if(d.value == 'Fixed') {
                    this.maximumValue = 100000000
                } else {
                    this.maximumValue = 100
                }
                this.$refs.amount.$el.focus()
            })
        },
        onApply () {
            if(!this.amount) {
                this.err = true
            }
            if(this.amount) {
                if(this.discountType == 'Percentage') {
                    this.text = this.amount+'%'
                } else {
                    this.text = Intl.NumberFormat().format(this.amount)
                }
                this.show = false
                this.discount.discountType = this.discountType
                this.discount.amount = this.amount
                this.onCalulate()
            }
        },
        onClear () {
            this.text = ''
            this.amount = 0
            this.discountType = 'Percentage'
            this.discountText = 'Percentage (%)'
            this.discount = {
                discountType: '',
                amount: '',
                value: 0
            }
            this.$emit('input',this.discount)
        },
        onCalulate () {
            if(this.text) {
                if(this.discountType == 'Percentage') {
                    const dsc = this.total * this.amount / 100
                    this.discount.value = dsc
                    this.$emit('input', this.discount)
                } 
                if(this.discountType == 'Fixed') {
                    this.discount.value = this.amount
                    this.$emit('input', this.discount)
                }
            }
        }
    },
    watch: {
        value (val) {
            this.discountType = val.discountType || 'Percentage'
            this.amount = val.amount
            if(this.discountType == 'Percentage' && this.amount) {
                this.maximumValue = 100
                this.text = this.amount+'%'
                this.discount.amount = this.amount
                this.discount.discountType = this.discountType
            }
            if(this.discountType == 'Fixed' && this.amount) {
                this.discountText = 'Fixed (Rp)'
                this.maximumValue = 100000000
                this.discount.amount = this.amount
                this.discount.discountType = this.discountType
                this.text = Intl.NumberFormat().format(this.amount)
            }
        },
        total () {
            this.onCalulate()
        },
        show () {
            if(this.value.discountType == 'Fixed') {
                this.discountText = 'Fixed (Rp)'
                this.maximumValue = 100000000
            } else {
                this.maximumValue = 100
                this.discountText = 'Percentage (%)'
            }
            this.$nextTick (() => {
                this.discountType = this.value.discountType || 'Percentage'
                this.amount = this.value.amount
                if(this.discountType == 'Percentage' && this.amount) {
                    this.text = this.amount+'%'
                }
                if(this.discountType == 'Fixed' && this.amount) {
                    this.text = Intl.NumberFormat().format(this.amount)
                }
            })
        }
    },
}
</script>