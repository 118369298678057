<template>
    <div>
        <div class="flex items-center justify-between mb-5">
            <div class="flex items-center space-x-5">
                <div class="font-semibold leading-none text-lg text-gray-600">DETAIL TRANSFER</div>
                <a href="#" class="hover:text-orange-500 flex items-center"><i class="icon-download7" style="font-size: 18px"></i></a>
            </div>
            <div v-if="isStatus">
                <button @click="onComplete" :disabled="isDisabled" class="border bg-white h-9 rounded-sm px-5 border-orange-500 text-orange-500 font-medium hover:bg-orange-500 hover:text-orange-50 mr-2">Complete</button>
                <button @click="onEdit" class="border bg-white h-9 rounded-sm px-5 border-orange-500 text-orange-500 font-medium hover:bg-orange-500 hover:text-orange-50">
                    <i class="icon-pencil mr-2"></i>
                    Edit
                </button>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-5 mb-5">
            <div class="col-span-2 bg-white border rounded-sm">
                <div class="grid grid-cols-2 divide-x">
                    <div class="p-5 flex items-center">
                        <div class="mr-3">
                            <i class="icon-file-spreadsheet icon-3x text-gray-300"></i>
                        </div>
                        <div>
                            <div v-if="isLoading" class="ph-item">
                                <div class="bg-gray-200 h-6 w-44 mb-1.5"></div>
                            </div>
                            <div v-if="!isLoading" class="text-orange-500 font-medium text-xl">{{transfers.no}}</div>
                            <div class="text-gray-400">STOCK TRANSFER #</div>
                        </div>
                    </div>
                    <div class="p-5 flex items-center">
                        <div class="mr-3">
                            <i class="icon-calendar3 icon-3x text-gray-300"></i>
                        </div>
                        <div>
                            <div v-if="isLoading" class="ph-item">
                                <div class="bg-gray-200 h-6 w-48 mb-1.5"></div>
                            </div>
                            <div v-if="!isLoading" class="text-orange-500 font-medium text-xl">{{getDate(transfers.createdAt)}}</div>
                            <div class="text-gray-400">DATE TRANSFER</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-blue-500 rounded-sm">
                <div class="p-5 flex items-center">
                    <div class="mr-3">
                        <i class="icon-info22 icon-3x text-blue-300"></i>
                    </div>
                    <div>
                        <div v-if="isLoading" class="ph-item mb-1.5">
                            <div class="bg-gray-200 h-6 w-28"></div>
                        </div>
                        <div v-if="!isLoading" class="text-blue-50 font-medium text-xl">{{this.status}}</div>
                        <div class="text-blue-100">STATUS</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bg-white border border-b-0 grid grid-cols-4 p-5 rounded-t-sm">
            <div class="border-r">
                <label for="">From Loacation (Warehouse)</label>
                <div v-if="isLoading" class="ph-item mt-1">
                    <div class="bg-gray-200 h-5 w-44"></div>
                </div>
                <div v-if="!isLoading" class="text-base font-medium">{{transfers.fromWarehouseId.name}}</div>
            </div>
            <div class="border-r px-5">
                <label for="">To Loacation (Warehouse)</label>
                <div v-if="isLoading" class="ph-item mt-1">
                    <div class="bg-gray-200 h-5 w-44"></div>
                </div>
                <div v-if="!isLoading" class="text-base font-medium">{{transfers.toWarehouseId.name}}</div>
            </div>
            <div class="border-r px-5">
                <label for="">Remarks</label>
                <div v-if="isLoading" class="ph-item mt-px">
                    <div class="bg-gray-200 h-4 w-44"></div>
                </div>
                <div v-if="!isLoading" class="">{{transfers.remarks}}</div>
            </div>
            <div class="px-5">
                <label for="">Created By</label>
                <div v-if="isLoading" class="ph-item mt-px">
                    <div class="bg-gray-200 h-4 w-44"></div>
                </div>
                <div v-if="!isLoading" class="">{{transfers.userId.name}}</div>
            </div>
        </div>

        <table class="w-full">
            <thead>
                <tr class="bg-orange-500">
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-center" style="width: 4%">#</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 38%">ITEM</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 38%">FROM SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 38%">TO SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-right">QTY</th>
                </tr>
            </thead>
            <tbody v-if="isLoading" class="bg-white divide-y">
                <tr v-for="i in 20" :key="i">
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-4 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-4 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-4 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-4 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-4 bg-gray-200"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="!isLoading" class="bg-white divide-y border border-t-0">
                <tr v-for="(item, i) in transfers.items" :key="i">
                    <td class="py-3 px-5">{{i+1}}</td>
                    <td class="py-3 px-5">
                        <div class="flex items-center">
                            <div class="mr-3">
                                <img :src="imgURL+'/public/img/products/200/'+item.productId.images[0]" width="60" alt="">
                            </div>
                            <div>
                                <div class="font-medium">{{item.productId.name}}</div>
                                <div class="text-gray-500">{{item.productId.sku}}</div>
                            </div>
                        </div>
                    </td>
                    <td class="py-3 px-5">{{item.fromSectionId.name}}</td>
                    <td class="py-3 px-5">{{item.toSectionId.name}}</td>
                    <td class="py-3 px-5 text-right">{{item.qty}}</td>
                </tr>
            </tbody>
        </table>
        <alert ref="alert"></alert>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../../moment'
import alert from '../../../components/alert.vue'
import Swal from 'sweetalert2/dist/sweetalert2'
export default {
    components: {alert},
    data () {
        return {
            isDisabled: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            isLoading: true,
            transfers: [],
            status: 'New',
            checkQty: []
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        isStatus () {
            let status;
            if(this.status == 'New') {
                status = true
            } else {
                status = false
            }
            return status
        }
    },
    methods: {
        async getData () {
            this.isLoading = true
            const res = await axios.get('/transfers/detail/'+this.$route.params.id)
            this.isLoading = false
            this.transfers = res.data
            this.status = res.data.status
            const items = res.data.items
            if(res.data.status == 'New') {
                for await (let item of items) {
                    const inv = await axios.get('/transfers/inventory/qty', {
                        params: {
                            warehouseId: this.transfers.fromWarehouseId._id,
                            productId: item.productId._id,
                            sectionId: item.fromSectionId._id
                        }
                    })
                    this.checkQty.push(inv.data.qty)
                }
            }
        },
        onComplete () {
            this.isDisabled = true
            axios.put('/transfers/inventory/'+this.$route.params.id)
            .then(res => {
                this.status = res.data.status
                this.$refs.alert.open('success', 'Stock adjustment has been successfully completed')
                this.isDisabled = false
            })
            .catch(err => {
                this.isDisabled = false
                const product = `${err.response.data.productId.name} [${err.response.data.productId.sku}]`
                Swal.fire({
                    title: 'Error Stock',
                    text: product+" out of stock, please check again",
                    type: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonClass:'bg-blue-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-blue-600',
                })
            })
        },
        checkStock () {
            for(let i = 0; i < this.transfers.items.length; i++) {
                if(this.checkQty[i] < this.transfers.items[i].qty) {
                    return false
                }
            }
            return true
        },  
        onEdit () {
            this.$router.push('/stock-transfers/edit/'+this.$route.params.id)
        },
        getDate (d) {
            return moment(d)
        }
    }
}
</script>