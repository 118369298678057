<template>
    <div class="p-5">
        <div class="bg-white border rounded-sm">
            <div class="relative flex items-center border-b h-14 px-5">
                    <router-link @click.prevent="tabsMenu($event,'/settings')" to="/settings" class="inline-flex px-5 h-full items-center transition duration-200 hover:text-red-500" exact>Information</router-link>
                    <router-link @click.prevent="tabsMenu($event,'/settings/shippings')" to="/settings/shippings" class="inline-flex px-5 h-full items-center transition duration-200 hover:text-red-500">Shippings</router-link>
                    <router-link @click.prevent="tabsMenu($event,'/settings/bank-accounts')" to="/settings/bank-accounts" class="inline-flex px-5 h-full items-center transition duration-200 hover:text-red-500">Bank Accounts</router-link>
                    <router-link @click.prevent="tabsMenu($event, '/settings/user-management')" to="/settings/user-management" class="inline-flex px-5 h-full items-center transition duration-200 hover:text-red-500">User Management</router-link>
                    <router-link @click.prevent="tabsMenu($event, '/settings/general-setup')" to="/settings/general-setup" class="inline-flex px-5 h-full items-center transition duration-200 hover:text-red-500">General Setup</router-link>
                    <div ref="active" class="absolute transform duration-300 bg-red-500 -bottom-px" style="height: 3px"></div>
            </div>
            <div>
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        if(this.$route.path == '/settings') {
            this.$refs.active.style.width='108px'
        }
        if(this.$route.path == '/settings/shippings') {
            this.$refs.active.style.width='96px'
            this.$refs.active.style.transform="translateX(109px)"
        }
        if(this.$route.path == '/settings/bank-accounts') {
            this.$refs.active.style.width='128px'
            this.$refs.active.style.transform="translateX(204px)"
        }
        if(this.$route.path == '/settings/user-management') {
            this.$refs.active.style.width='147px'
            this.$refs.active.style.transform="translateX(332px)"
        }
        if(this.$route.path == '/settings/general-setup') {
            this.$refs.active.style.width='120px'
            this.$refs.active.style.transform="translateX(479px)"
        }
    },
    methods: {
        tabsMenu (e, route) {
            this.$router.push(route)
            let x = e.target.offsetLeft - 20
            this.$refs.active.style.width = e.target.clientWidth +"px"
            this.$refs.active.style.transform="translateX("+x+"px)"
        }
    },
    watch: {
        $route(to) {
            if(to.fullPath == '/settings') {
                this.$refs.active.style.width='108px'
                this.$refs.active.style.transform="translateX(0px)"
            }
            if(to.fullPath == '/settings/shippings') {
                this.$refs.active.style.width='96px'
                this.$refs.active.style.transform="translateX(109px)"
            }
            if(to.fullPath == '/settings/bank-accounts') {
                this.$refs.active.style.width='128px'
                this.$refs.active.style.transform="translateX(204px)"
            }
            if(to.fullPath == '/settings/user-management') {
                this.$refs.active.style.width='147px'
                this.$refs.active.style.transform="translateX(332px)"
            }
            if(to.fullPath == '/settings/general-setup') {
                this.$refs.active.style.width='120px'
                this.$refs.active.style.transform="translateX(479px)"
            }
        }
    }
}
</script>

<style scoped>
.active {
    @apply text-red-500 font-semibold;
}
</style>