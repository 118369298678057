<template>
	<label class="switch">
		<input :checked="value" @change="onChange" type="checkbox">
		<span class="slider round"></span>
	</label>
</template>
<script>
export default {
    props:["value"],
    methods: {
        onChange (e) {
            this.$emit('input', e.target.checked)
        }
    }

}
</script>
<style>
.switch {
	position: relative;
	display: inline-block;
	width: 2.25rem;
	height: 1.255rem;
}

.switch input { 
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
    display: block;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 100px;
}

.slider:before {
	position: absolute;
    display: block;
	content: "";
	height: 1.125rem;
	width: 1.125rem;
	top: 1px;
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
	-webkit-transition: .2s;
	transition: .2s;
}

input:checked + .slider {
	background-color: #64bd62;
}

input:checked + .slider:before {
	-webkit-transform: translateX(17px);
	-ms-transform: translateX(17px);
	transform: translateX(17px);
}
</style>