<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div v-show="show" class=" animated zoomIn faster flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        show: Boolean
    }
}
</script>