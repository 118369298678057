<template>
    <div v-if="product" class="p-5 border border-t-0">
        <div class="py-3 border-b">
            <ul class="flex items-center space-x-5">
                <li v-for="(image, i) in product.images" :key="i" class="border w-48">
                    <img :src="imgURL+'/public/img/products/700/'+image" alt="">
                </li>
            </ul>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRODUCT NAME</div>
            <div class="col-span-9 text-teal-500">{{product.name}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">BRAND</div>
            <div class="col-span-9 text-teal-500" v-if="product.barandId">{{product.brandId.name}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">CATEGORY</div>
            <div class="col-span-9 text-teal-500">
                <ul class="flex divide-x">
                    <li v-for="(category, i) in product.categoriesId" :key="i" class="pr-2">{{category.name}}</li>
                </ul>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">CONDITION</div>
            <div class="col-span-9 text-teal-500">{{product.condition}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-top">
            <div class="col-span-3 font-semibold">DESCRIPTION</div>
            <div v-html="product.description" class="col-span-9 text-teal-500"></div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRODUCT VIDEOS</div>
            <div class="col-span-9 text-teal-500">
               <template v-if="videos.length > 0">
                    <div v-for="(video, i) in videos" :key="i" class="border rounded-sm flex items-center p-3 mb-5">
                        <img :src="video.thumbnails.default.url" width="70" alt="">
                        <div class="ml-2 mr-3 overflow-x-hidden">
                            <h6 class="font-semibold truncate">{{video.title}}</h6>
                            <div class="text-gray-400 truncate">{{video.description}}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRODUCT SKU</div>
            <div class="col-span-9 text-teal-500">{{product.sku}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">SERIAL NUMBER</div>
            <div class="col-span-9 text-teal-500">{{product.isSerialNumber}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRODUCT PRICE</div>
            <div class="col-span-9">
                <div class="grid grid-cols-3 gap-5">
                    <div v-if="user" class="col-span-1">
                        <div v-if="user.role == 'Admin'">
                            <div>Purchaase Price</div>
                            <div class="text-teal-500">{{product.currencySymbol}}{{Intl.NumberFormat('en-US').format(product.purchasePrice)}}</div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div>Selling Price</div>
                        <div class="text-teal-500">{{Intl.NumberFormat('en-US').format(product.sellingPrice)}}</div>
                    </div>
                    <div class="col-span-1">
                        <div>Nett Price</div>
                        <div class="text-teal-500">{{Intl.NumberFormat('en-US').format(product.netPrice)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">MEASUREMENTS</div>
            <div class="col-span-9">
                <div class="grid grid-cols-4 gap-5">
                    <div class="col-span-1">
                        <div>Width</div>
                        <div class="text-teal-500">{{product.measurements.width.value}} {{product.measurements.width.unit}}</div>
                    </div>
                    <div class="col-span-1">
                        <div>Height</div>
                        <div class="text-teal-500">{{product.measurements.height.value}} {{product.measurements.height.unit}}</div>
                    </div>
                    <div class="col-span-1">
                        <div>Dept</div>
                        <div class="text-teal-500">{{product.measurements.depth.value}} {{product.measurements.depth.unit}}</div>
                    </div>
                    <div class="col-span-1">
                        <div>Weight</div>
                        <div class="text-teal-500">{{product.measurements.weight.value}} {{product.measurements.weight.unit}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">INTERNAL NOTE</div>
            <div class="col-span-9 text-teal-500">{{product.notes.internal}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">EXTERNAL NOTE</div>
            <div class="col-span-9 text-teal-500">{{product.notes.external}}</div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">FILE ATTCHMENTS</div>
            <div class="col-span-9 text-teal-500">
                <div v-for="(file ,i) in attachments" :key="i" class="flex items-center mb-3 px-5 py-2 rounded-sm border">
                    <div v-if="new RegExp('pdf').test(file.name)">
                        <i class="icon-file-pdf icon-2x text-red-600"></i>
                    </div>
                    <div v-if="new RegExp('docx').test(file.name)">
                        <i class="icon-file-word icon-2x text-blue-800"></i>
                    </div>
                    <div v-if="new RegExp('xlsx').test(file.name)">
                        <i class="icon-file-excel icon-2x text-green-800"></i>
                    </div>
                    <div class="ml-3">
                        <div class="font-semibold">{{file.name}}</div>
                        <div class="text-gray-400">{{file.size / 1000}} KB</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRODUCT ACCESSORIES</div>
            <div class="col-span-9 text-teal-500">
                <div v-for="(accessories, i) in product.accessories" :key="i" class="flex mb-2 p-1">
                    <div class="border rounded-sm mr-3">
                        <img :src="imgURL+'/public/img/products/200/'+accessories.images[0]" alt="" width="50">
                    </div>
                    <div>
                        <div>{{accessories.name}}</div>
                        <div>{{accessories.sku}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 border-b py-3 items-center">
            <div class="col-span-3 font-semibold">PRE ORDER</div>
            <div v-if="product.preorder.isActive" class="col-span-9 text-teal-500">{{product.preorder.duration}} {{product.preorder.timeUnit}}</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
export default {
    props: ['id'],
    data () {
        return {
            product: '',
            imgURL: process.env.VUE_APP_ROOT_API,
            videos: [],
            attachments: []
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        getData () {
            const getYoutube = async (url) => {
                const youtubeData = await axios.post('/products/youtube', {id: url})
                return youtubeData
            }
            const getFileFromUrl = async (url, name) => {
                const data = await fetch(url)
                const blob = await data.blob()
                blob.name = name[2]
                blob.lastModified = new Date();
                return blob
            }
            axios.get('/products/info/'+this.id)
            .then(res => {
                this.product = res.data
                if(this.product.videos.length > 0) {
                    for(let video of this.product.videos) {
                        getYoutube(video.url)
                        .then(res => {
                            this.videos.push(res.data.snippet)
                        })
                    }
                }
                for(let file of this.product.attachments) {
                    let name = file.split('/')
                    getFileFromUrl(this.imgURL+'/'+file, name)
                    .then(res => {
                        this.attachments.push(res)
                    })
                }
            })
        }
    },
} 
</script>