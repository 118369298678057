<template>
    <div>
        <div class="grid grid-cols-3 gap-5 mb-5">
            <div class="col-span-2 bg-white border rounded-sm">
                <div class="grid grid-cols-2 divide-x">
                    <div class="p-5 flex items-center">
                        <div class="mr-3">
                            <i class="icon-file-spreadsheet icon-3x text-gray-300"></i>
                        </div>
                        <div>
                            <div v-if="isLoading" class="ph-item">
                                <div class="bg-gray-200 h-6 w-44 mb-1.5"></div>
                            </div>
                            <div v-if="!isLoading" class="text-orange-500 font-medium text-xl">{{transfers.no}}</div>
                            <div class="text-gray-400">STOCK ADJUSTMENT #</div>
                        </div>
                    </div>
                    <div class="p-5 flex items-center">
                        <div class="mr-3">
                            <i class="icon-calendar3 icon-3x text-gray-300"></i>
                        </div>
                        <div>
                            <div v-if="isLoading" class="ph-item">
                                <div class="bg-gray-200 h-6 w-48 mb-1.5"></div>
                            </div>
                            <div v-if="!isLoading" class="text-orange-500 font-medium text-xl">{{getDate(transfers.createdAt)}}</div>
                            <div class="text-gray-400">DATE ADJUSTMENT</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-blue-500 rounded-sm">
                <div class="p-5 flex items-center">
                    <div class="mr-3">
                        <i class="icon-info22 icon-3x text-blue-300"></i>
                    </div>
                    <div>
                        <div v-if="isLoading" class="ph-item mb-1.5">
                            <div class="bg-gray-200 h-6 w-28"></div>
                        </div>
                        <div v-if="!isLoading" class="text-blue-50 font-medium text-xl">{{transfers.status}}</div>
                        <div class="text-blue-100">STATUS</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white border border-b-0 grid grid-cols-3 p-5 rounded-t-sm">
            <div class="border-r">
                <label for="">From Loacation (Warehouse)</label>
                <div v-if="isLoading" class="ph-item mt-1">
                    <div class="bg-gray-200 h-5 w-44"></div>
                </div>
                <div v-if="!isLoading" class="text-base font-medium">{{transfers.fromWarehouseId.name}}</div>
            </div>
            <div class="border-r px-5">
                <label for="">To Loacation (Warehouse)</label>
                <div v-if="isLoading" class="ph-item mt-1">
                    <div class="bg-gray-200 h-5 w-44"></div>
                </div>
                <div v-if="!isLoading" class="text-base font-medium">{{transfers.toWarehouseId.name}}</div>
            </div>
            <div class="px-5">
                <label for="">Remarks</label>
                <div v-if="isLoading" class="ph-item mt-px">
                    <div class="bg-gray-200 h-4 w-44"></div>
                </div>
                <div v-if="!isLoading" class="mt-1">
                    <input v-model="form.remarks" type="text" class="form-control">
                </div>
            </div>
        </div>
        <table class="w-full">
            <thead>
                <tr class="bg-orange-500">
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-center" style="width: 3%">#</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 28%">ITEM</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 27%">FROM SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 27%">TO SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-center">QTY</th>
                </tr>
            </thead>
            <tbody v-if="!isLoading" class="bg-white border divide-y">
                <tr v-for="(trf, i) in form.transfers" :key="trf.idx">
                    <td class="py-3 px-5">{{i+1}}</td>
                    <td class="py-3 px-5">
                        <autosearch :label="trf.name" :err="err[i].productId" :ref="'autosearch'+i" v-model="trf.productId" :text="true" @input="onSelectProduct($event,i)" class="border-t-0" :url="'/transfers/product/'+transfers.fromWarehouseId._id" :params="productIds" :placeholder="'Search product by name or sku'">
                            <template #default="item">
                                <div class="flex items-center">
                                    <img :src="imgURL+'/public/img/products/200/'+item.item.images[0]" width="50" alt="">
                                    <div class="ml-3">
                                        <h6 class="font-medium">{{item.item.name}}</h6>
                                        <div class="text-gray-400">{{item.item.sku}}</div>
                                    </div>
                                </div>
                            </template>
                        </autosearch>
                    </td>
                    <td class="py-3 px-5">
                        <div v-if="trf.productId" class="slt" :class="{'err': err[i].fromSectionId}">
                            <select-2 :ref="'sectionFrom'+i" v-model="trf.fromSectionId" :options="fromSection[i].section" @input="onSelectFromSection(i)" class="form-control" :placeholder="'Please select from section'">
                                <option value=""></option>
                            </select-2>
                        </div>
                    </td>
                    <td class="py-3 px-5">
                        <div v-if="trf.productId" class="slt" :class="{'err': err[i].toSectionId}">
                            <select-2 :ref="'sectionTo'+i" v-model="trf.toSectionId" :options="toSection" @input="onSelectToSection(i)" class="form-control" :placeholder="'Please select to section'">
                                <option value=""></option>
                            </select-2>
                        </div>
                    </td>
                    <td class="py-3 px-5">
                        <div class="flex items-center">
                            <div v-if="trf.productId" class="mr-3">
                                <autonumeric @input="onQty($event, i)" @keydown.native="err[i].qty = ''" @keydown.native.enter.prevent="onEnterQty(i)" :ref="'qty'+i" v-model="trf.qty" :options="['integer', {modifyValueOnWheel : false}]"  class="form-control border-t-0 border-l-0 border-r-0 text-right" :class="{'border-red-500': err[i].qty}"  :placeholder="'quantity'">

                                </autonumeric>
                            </div>
                            <div class="ml-auto">
                                <a @click.prevent="onDeleteItem(i)" href="#" class="text-red-400 hover:text-red-500"><i class="icon-cancel-circle2"></i></a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="p-5 border border-t-0 flex justify-between rounded-b-sm bg-white">
            <div>
                <button :disabled="form.transfers.length < 1"  :class="[form.transfers.length < 1 ? 'bg-blue-400 cursor-not-allowed h-9 px-10 text-blue-50 rounded-sm':'bg-blue-500 h-9 px-10 text-blue-50 rounded-sm']"  @click="onAddItem">Add Item</button>
            </div>
            <div>
                <button @click="onSave" class="h-9 px-5 bg-orange-500 text-orange-50 rounded-sm" :disabled="isDisabled">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../../moment'
import select2 from '../../../components/Select2.vue'
import autosearch from '../../../components/autoSearch.vue'
import Autonumeric from '../../../components/Autonumeric.vue'
import Swal from 'sweetalert2/dist/sweetalert2'
export default {
    components: {select2, autosearch, Autonumeric},
    data () {
        return {
            isDisabled: false,
            isLoading: true,
            imgURL: process.env.VUE_APP_ROOT_API,
            transfers: '',
            fromSection: [],
            toSection: [],
            form: {
                remarks: '',
                transfers: []
            },
            err: []
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        productIds () {
            let id = []
            for (let i = 0; i < this.form.transfers.length; i++) {
                id.push(this.form.transfers[i].productId)
            }
            return id
        },
        items () {
            let item = []
            for (let i = 0; i < this.form.transfers.length; i++) {
                const el = this.form.transfers[i];
                item.push({productId: el.productId, fromSectionId: el.fromSectionId, toSectionId: el.toSectionId, qty: el.qty})
            }
            return item
        }
    },
    methods: {
        async getData () {
            this.isLoading = true
            const result = await axios.get('/transfers/edit/'+this.$route.params.id)
            const items = result.data.items
            this.transfers = result.data
            this.form.remarks = result.data.remarks
            this.toSection = result.data.toWarehouseId.sections.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                return obj
            })
            for await (let item of items) {
                await axios.get('/transfers/inventory', {
                    params: {
                        productId: item.productId._id,
                        warehouseId: result.data.fromWarehouseId._id
                    }
                })
                .then(res => {
                    const section = res.data.map(obj => {
                        obj.id = obj.sectionId._id
                        obj.text = this.transfers.fromWarehouseId.name +' - '+ obj.sectionId.name+' / '+ obj.qty
                        return obj
                    })
                    const sectionId = items.filter(obj => {
                        return obj.productId._id == item.productId._id
                    }).map(obj => {
                        return obj.fromSectionId._id
                    })
                    if(section.length > sectionId.length) {
                        let a = section.filter(obj => {
                           return obj.id == item.fromSectionId._id
                        })
                        let b = section.filter(obj => !sectionId.includes(obj.id))
                        this.fromSection.push({section: a.concat(b)})
                    } else {
                        let s = section.filter(obj => {
                           return obj.id == item.fromSectionId._id
                        })
                        this.fromSection.push({section: s})
                    }
                })
            }
            this.$nextTick(() => {
                for(let i = 0; i < items.length; i++) {
                    this.form.transfers.push({idx: items[i].idx, productId: items[i].productId._id, name: items[i].productId.name, fromSectionId: items[i].fromSectionId._id, toSectionId: items[i].toSectionId._id, qty: items[i].qty})
                    this.err.push({productId: '', fromSectionId: '', toSectionId: '', qty: ''})
                }
                this.isLoading = false
            })
        },
        onSelectProduct (e, i) {
            this.err[i].productId = false
            if(e) {
                this.form.transfers[i].fromSectionId = ''
                this.form.transfers[i].toSectionId = ''
                this.form.transfers[i].qty = ''
                axios.get('transfers/inventory', {
                    params: {
                        productId: e,
                        warehouseId: this.transfers.fromWarehouseId._id,
                    }
                })
                .then(res => {
                    const section = res.data.map(obj => {
                        obj.id = obj.sectionId._id
                        obj.text = this.transfers.fromWarehouseId.name +' - '+ obj.sectionId.name+' / '+ obj.qty
                        return obj
                    })
                    const sectionId = this.form.transfers.filter(obj => {
                        return obj.productId === e
                    }).map(obj => {
                        return obj.fromSectionId
                    })
                    this.fromSection[i].section = section.filter(obj => !sectionId.includes(obj.id))
                    this.$nextTick(() => {
                        this.$refs['sectionFrom'+i][0].open()
                    })
                })
            }
        },
        onSelectFromSection (i) {
            this.err[i].fromSectionId = false
            this.$refs['sectionTo'+i][0].open()
        },
        onSelectToSection (i) {
            this.err[i].toSectionId = false
            this.$refs['qty'+i][0].$el.focus()
        },
        onQty (e, i) {
            if(e) {
                const sectionId = this.form.transfers[i].fromSectionId
                const stock = this.fromSection[i].section.find(obj => obj.id == sectionId)
                if(e > stock.qty) {
                    Swal.fire({
                        title: 'Error Stock',
                        text: "This product is out of stock",
                        type: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonClass:'bg-orange-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-orange-600',
                    })
                }
            }
        },
        onEnterQty (i) {
            let id = i+1
            const sectionId = this.form.transfers[i].fromSectionId
            const stock = this.fromSection[i].section.find(obj => obj.id == sectionId)
            const qty = this.form.transfers[i].qty
            if(qty > stock.qty) {
                Swal.fire({
                    title: 'Error Stock',
                    text: "This product is out of stock",
                    type: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonClass:'bg-orange-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-orange-600',
                })
            } else {
                if(this.form.transfers.length == id) {
                    this.onAddItem()
                }
                this.$nextTick(() => {
                    this.$refs['autosearch'+id][0].onFocus()
                })
            }
        },
        onAddItem () {
            const idx = Date.now()
            this.form.transfers.push({idx: idx, productId: '', fromSectionId: '', toSectionId: '', qty: ''})
            this.err.push({productId: '', fromSectionId: '', toSectionId: '', qty: ''})
            this.fromSection.push({section: ''})
            this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight)
            })
        },
        onDeleteItem (i) {
            this.form.transfers.splice(i, 1)
            this.err.splice(i, 1)
            this.fromSection.splice(i, 1)
        },
        onSave () {
            this.isDisabled = true
            const items = this.form.transfers
            for (let i = 0; i < items.length; i++) {
                if(!items[i].productId) {
                    this.err[i].productId = true
                }
                if(!items[i].fromSectionId) {
                    this.err[i].fromSectionId = true
                }
                if(!items[i].toSectionId) {
                    this.err[i].toSectionId = true
                }
                if(!items[i].qty) {
                    this.err[i].qty = true
                }
                if(this.fromSection[i].section) {
                    const section = this.fromSection[i].section.find(obj => obj.id == items[i].fromSectionId)
                    if(section && items[i].qty > section.qty) {
                        this.err[i].qty = true
                    }
                }
            }
            if(this.isEmpty(this.err)) {
                axios.put('/transfers/update/'+this.$route.params.id,{
                    remarks: this.form.remarks,
                    items: this.form.transfers
                })
                .then(res => {
                    this.isDisabled = false
                    const transferId = res.data._id
                    this.$router.push('/stock-transfers/detail/'+transferId)
                })
            } else {
                this.isDisabled = false
            }
        },
        isEmpty (object) {
            if(object.length < 1) {
                return false
            }
            for (let i of object) {
                if(i.productId || i.fromSectionId || i.toSectionId || i.qty) {
                    window.scrollTo(0,100)
                    return false
                }
            }
            return true
        },
        getDate (d) {
            return moment(d)
        }
    },
}
</script>