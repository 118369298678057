<script>
import { mapMutations } from 'vuex';
export default {
    methods: {
        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            spliceBreadcrumb: 'splice',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty',
            setButtonText: 'text',
            setDiscard: 'discard',
            setDiscardText: 'discardText',
            setDisabled: 'disabled',
            setAction: 'action',
            setPages: 'pages'
        })
    }
}
</script>