<template>
    <div class="bg-white">
        <div class="border px-5 p-3 rounded-t-sm"><i class="icon-info22 mr-2"></i>NEW STOCK TRANSFER</div>
        <div class="p-5 border-l border-r">
            <div class="grid grid-cols-3 gap-5">
                <div :class="{'err': emptyFromWarehouseId}">
                    <div class="mb-2">
                        <label for="">FROM LOCATION (WAREHOUSE)<span class="text-red-500"> *</span></label>
                    </div>
                    <select-2 v-model="form.fromWarehouseId" :options="warehouseList" @input="onSelectFromWarehouse($event)" class="form-control" :placeholder="'Please select warehouse'">
                        <option value=""></option>
                    </select-2>
                </div>
                <div :class="{'err': emptyToWarehouseId}">
                    <div class="mb-2">
                        <label for="">TO LOCATION (WAREHOUSE)<span class="text-red-500"> *</span></label>
                    </div>
                    <select-2 v-model="form.toWarehouseId" ref="warehouseto" :options="warehouseList" @input="onSelectToWarehouse($event)" class="form-control" :placeholder="'Please select warehouse'">
                        <option value=""></option>
                    </select-2>
                </div>
                <div>
                    <label for="">REMARKS</label>
                    <input v-model="form.remarks" ref="remarks" type="text" class="form-control mt-2" placeholder="Remarks">
                </div>
            </div>
        </div>
        <table class="w-full">
            <thead>
                <tr class="bg-orange-500">
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-center" style="width: 3%">#</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 28%">ITEM</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 27%">FROM SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5" style="width: 27%">TO SECTION</th>
                    <th class="uppercase font-medium text-orange-50 py-3.5 px-5 text-center">QTY</th>
                </tr>
            </thead>
            <tbody class="border divide-y">
                <tr v-for="(item, i) in form.transfers" :key="item.idx">
                    <td class="py-3 px-5">{{i+1}}</td>
                    <td class="py-3 px-5">
                        <autosearch :err="err[i].productId" :ref="'autosearch'+i" v-model="item.productId" @input="onSelectProduct($event, i)" :text="true" :url="'/transfers/product/'+warehouseFromId" :params="productIds" :placeholder="'Search product by name or sku'">
                            <template #default="item">
                                <div class="flex items-center">
                                    <img :src="imgURL+'/public/img/products/200/'+item.item.images[0]" width="50" alt="">
                                    <div class="ml-3">
                                        <h6 class="font-medium">{{item.item.name}}</h6>
                                        <div class="text-gray-400">{{item.item.sku}}</div>
                                    </div>
                                </div>
                            </template>
                        </autosearch>
                    </td>
                    <td class="py-3 px-5">
                        <div v-if="item.productId" class="slt" :class="{'err': err[i].fromSectionId}">
                            <select-2 :ref="'sectionFrom'+i" v-model="item.fromSectionId" :options="sectionFrom[i].section" @input="onSelectFromSection(i)" class="form-control slt" :placeholder="'Please select section'">
                                <option value=""></option>
                            </select-2>
                        </div>
                    </td>
                    <td class="py-3 px-5">
                        <div v-if="item.productId" class="slt" :class="{'err': err[i].toSectionId}">
                            <select-2 :ref="'sectionTo'+i" v-model="item.toSectionId" :options="sectionToList" @input="onSelectToSection(i)" class="form-control slt" :placeholder="'Please select section'">
                                <option value=""></option>
                            </select-2>
                        </div>
                    </td>
                    <td class="py-3 px-5">
                        <div class="flex items-center">
                            <div v-if="item.productId" class="mr-3">
                                <autonumeric @input="onQty($event, i)" @keydown.native="err[i].qty = ''" @keydown.native.enter.prevent="onEnterQty(i)" :ref="'qty'+i" v-model="item.qty" :options="['integer', {modifyValueOnWheel : false}]"  class="form-control border-t-0 border-l-0 border-r-0 text-right" :class="{'border-red-500': err[i].qty}"  :placeholder="'quantity'">

                                </autonumeric>
                            </div>
                            <div class="ml-auto">
                                <a @click.prevent="onDeleteItem(i)" href="#" class="text-red-400 hover:text-red-500"><i class="icon-cancel-circle2"></i></a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="p-5 border border-t-0 flex justify-between rounded-b-sm">
            <div>
                <button :disabled="form.transfers.length < 1"  :class="[form.transfers.length < 1 ? 'bg-blue-400 cursor-not-allowed h-9 px-10 text-blue-50 rounded-sm':'bg-blue-500 h-9 px-10 text-blue-50 rounded-sm']"  @click="onAddItem">Add Item</button>
            </div>
            <div>
                <button @click="onSave" class="h-9 px-5 bg-orange-500 text-orange-50 rounded-sm" :disabled="isDisabled">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import select2 from '../../../components/Select2.vue'
import autosearch from '../../../components/autoSearch.vue'
import Autonumeric from '../../../components/Autonumeric.vue'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2'
export default {
    components: {select2, autosearch, Autonumeric},
    data () {
        return {
            isDisabled: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            warehouses: [],
            warehouseFromName: '',
            warehouseFromId: '',
            warehouseToName: '',
            sectionFrom: [],
            sectionTo: [],
            form: {
                fromWarehouseId: '',
                toWarehouseId: '',
                remarks: '',
                transfers: [],
            },
            err: [],
            emptyFromWarehouseId: false,
            emptyToWarehouseId: false
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        warehouseList () {
            return this.warehouses.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                return obj
            })
        },
        sectionToList () {
            return this.sectionTo.map(obj => {
                obj.id = obj._id
                obj.text = this.warehouseToName +' - '+obj.name
                return obj
            })
        },
        productIds () {
            let id = []
            for (let i = 0; i < this.form.transfers.length; i++) {
                id.push(this.form.transfers[i].productId)
            }
            return id
        },
    },
    methods: {
        getData () {
            axios.get('/transfers/create')
            .then(res => {
                this.warehouses = res.data
            })
        },
        onSelectFromWarehouse (e) {
            this.emptyFromWarehouseId = false
            const wrs = this.warehouses.find(el => el._id == e)
            this.warehouseFromName = wrs.name
            this.warehouseFromId = wrs._id
            this.form.transfers = []
            if(!this.form.toWarehouseId) {
                this.$refs.warehouseto.open()
            }
            this.$nextTick(() => {
                if(this.form.transfers.length < 1 && this.form.toWarehouseId) {
                    this.onAddItem()
                }
            })
        },
        onSelectToWarehouse (e) {
            this.emptyToWarehouseId = false
            const wrs = this.warehouses.find(el => el._id == e)
            this.warehouseToName = wrs.name
            this.sectionTo = wrs.sections
            this.$refs.remarks.focus()
            if(this.form.transfers.length < 1 && this.form.fromWarehouseId) {
                this.onAddItem()
            }
        },
        onSelectProduct (e, i) {
            this.err[i].productId = false
            if(e) {
                this.form.transfers[i].fromSectionId = ''
                this.form.transfers[i].toSectionId = ''
                this.form.transfers[i].qty = ''
                axios.get('transfers/inventory', {
                    params: {
                        productId: e,
                        warehouseId: this.form.fromWarehouseId,
                    }
                })
                .then(res => {
                    const section = res.data.map(obj => {
                        obj.id = obj.sectionId._id
                        obj.text = this.warehouseFromName +' - '+ obj.sectionId.name+' / '+ obj.qty
                        return obj
                    })
                    const sectionId = this.form.transfers.filter(obj => {
                        return obj.productId === e
                    }).map(obj => {
                        return obj.fromSectionId
                    })
                    this.sectionFrom[i].section = section.filter(obj => !sectionId.includes(obj.id))
                    this.$nextTick(() => {
                        this.$refs['sectionFrom'+i][0].open()
                    })
                })
            }
        },
        onSelectFromSection (i) {
            this.err[i].fromSectionId = false
            this.$refs['sectionTo'+i][0].open()
        },
        onSelectToSection (i) {
            this.err[i].toSectionId = false
            this.$refs['qty'+i][0].$el.focus()
        },
        onQty (e, i) {
            if(e) {
                const sectionId = this.form.transfers[i].fromSectionId
                const stock = this.sectionFrom[i].section.find(obj => obj.id == sectionId)
                if(e > stock.qty) {
                    Swal.fire({
                        title: 'Error Stock',
                        text: "This product is out of stock",
                        type: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonClass:'bg-orange-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-orange-600',
                    })
                }
            }
        },
        onEnterQty (i) {
            let id = i+1
            const sectionId = this.form.transfers[i].fromSectionId
            const stock = this.sectionFrom[i].section.find(obj => obj.id == sectionId)
            const qty = this.form.transfers[i].qty
            if(qty > stock.qty) {
                Swal.fire({
                    title: 'Error Stock',
                    text: "This product is out of stock",
                    type: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonClass:'bg-orange-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-orange-600',
                })
            } else {
                if(this.form.transfers.length == id) {
                    this.onAddItem()
                }
                this.$nextTick(() => {
                    this.$refs['autosearch'+id][0].onFocus()
                })
            }
        },
        onAddItem () {
            const idx = Date.now()
            this.form.transfers.push({idx: idx, productId: '', fromSectionId: '', toSectionId: '', qty: ''})
            this.err.push({productId: '', fromSectionId: '', toSectionId: '', qty: ''})
            this.sectionFrom.push({section: ''})
            this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight)
            })
        },
        onDeleteItem (i) {
            this.form.transfers.splice(i, 1)
            this.err.splice(i, 1)
            this.sectionFrom.splice(i, 1)
        },
        onSave () {
            this.isDisabled = true
            const items = this.form.transfers
            for (let i = 0; i < items.length; i++) {
                if(!items[i].productId) {
                    this.err[i].productId = true
                }
                if(!items[i].fromSectionId) {
                    this.err[i].fromSectionId = true
                }
                if(!items[i].toSectionId) {
                    this.err[i].toSectionId = true
                }
                if(!items[i].qty) {
                    this.err[i].qty = true
                }
                
            }
            if(!this.form.fromWarehouseId) {
                this.emptyFromWarehouseId = true
            }
            if(!this.form.toWarehouseId) {
                this.emptyToWarehouseId = true
            }
            if(this.isEmpty(this.err) && this.form.fromWarehouseId && this.form.toWarehouseId) {
                axios.post('/transfers/create', this.form)
                .then(res => {
                    this.isDisabled = false
                    const transferId = res.data._id
                    this.$router.push('/stock-transfers/detail/'+transferId)
                })
            } else {
                this.isDisabled = false
            }
        },
        isEmpty (object) {
            if(object.length < 1) {
                return false
            }
            for (let i of object) {
                if(i.productId || i.fromSectionId || i.toSectionId || i.qty) {
                    window.scrollTo(0,100)
                    return false
                }
            }
            return true
        }
    }
}
</script>
