<template>
    <div>
        <modal :show="show">
            <div class="bg-white p-5 border rounded-sm w-2/4">
                <div class="text-center mb-5 text-base font-semibold">Add {{form.type}}</div>
                <form @submit.prevent="onSubmit">
                    <div class="mb-5">
                        <div class="mb-2"><label for="">TITLE <span class="text-red-500">*</span></label></div>
                        <input ref="title" v-model="form.title" @keydown="err.title=''" :class="{'border-red-500' : err.title}" type="text" class="form-control" placeholder="title">
                    </div>
                    <div class="mb-5">
                        <textarea v-model="form.details" class="w-full px-3 border rounded-sm py-2 focus:outline-none" rows="10" placeholder="enter task/note details..."></textarea>
                    </div>
                    <div class="flex items-center h-10 justify-between mb-5">
                        <div class="flex items-center">
                            <div class="form-radio relative w-28"> 
                                <input v-model="form.type" value="Note" name="type" id="note" type="radio" class="ml-5">
                                <label for="note" class="border pl-10 h-9 rounded-sm w-28 absolute"></label>
                                <span class="ml-3">Note</span>
                            </div>
                            <div class="form-radio relative w-28 ml-3"> 
                                <input v-model="form.type" value="Task" name="type" id="task" type="radio" class="ml-5">
                                <label for="task" class="border pl-10 h-9 rounded-sm w-28 absolute"></label>
                                <span class="ml-3">Task</span>
                            </div>
                        </div>
                        <div v-show="form.type == 'Task'" class="flex">
                            <div>
                                <date-picker v-model="form.dueDate" :masks="masks" :attributes="attrs" class="mt-2">
                                    <template v-slot="{inputValue, inputEvents}">
                                        <div class="flex relative items-center">
                                            <span class="h-9 flex items-center justify-center w-10 bg-teal-500 border border-teal-500 rounded-l-sm px-5 text-teal-50"><i class="icon-calendar3"></i></span>
                                            <input :value="inputValue" v-on="inputEvents" @blur="err.dueDate=''" :class="{'border-red-500': err.dueDate}" type="text" class="form-control pl-5 rounded-tl-none rounded-bl-none border-l-0" placeholder="Due date">
                                            <div class="absolute h-3 w-3 bg-teal-500 border-teal-500 border-r border-t left-9 transform rotate-45"></div>
                                        </div>
                                    </template>
                                </date-picker>
                            </div>
                            <div class="relative flex flex-col w-52 ml-1">
                                <select-2 ref="assignee" v-model="form.assignee" :options="users" class="form-control"  :placeholder="'Select user'">
                                    <option value=""></option>
                                </select-2>
                                <div class="absolute top-2 right-7">
                                    <a tabindex="-1" v-show="form.assignee" @click.prevent="onDeleteAssignee" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button @click="onClose" type="button" class="h-9 rounded-sm border px-5 mr-2">Cancel</button>
                        <button :disabled="isDisabled" class="h-9 rounded-sm px-5 bg-teal-500 text-teal-50">
                            <div v-if="isDisabled" class="absolute">...</div>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
import modal from '../components/Modal.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Select2 from '../components/Select2.vue'
import axios from 'axios'
export default {
    props: ['show', 'documentId','documentName', 'task'],
    components: {modal, DatePicker, Select2},
    data () {
        return {
            mode: 'new',
            taskId: '',
            isDisabled: false,
            users: [],
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date()
                }
            ],
            masks: {
                input: 'DD/MM/YYYY'
            },
            form: {
                document: '',
                documentId: '',
                documentName: '',
                route: '',
                type: 'Note',
                title: '',
                details: '',
                dueDate: '',
            },
            err: {
                title: '',
                dueDate: ''
            }  
        }
    },
    mounted () {
      this.form.route = this.$route.path
      this.getData()
    },
    methods: {
        getData () {
            axios.get('/tasks/create')
            .then(res => {
                this.users = res.data.users
            })
        },
        onClose () {
            this.mode = 'new'
            this.form.title = ''
            this.form.details = ''
            this.form.dueDate = ''
            this.err.title = ''
            this.err.dueDate = ''
            delete this.form.assignee
            this.form.type = 'Note'
            this.$emit('closeModal')
        },
        onSubmit () {
            if(!this.form.title) {
                this.err.title = true
            } 
            if(this.form.title) {
                if(this.mode == 'new') {
                    this.onSave()
                }
                if(this.mode == 'edit') {
                    this.onUpdate()
                }
            }
        },
        onSave () {
            this.isDisabled = true
            axios.post('/tasks/insert', this.form)
            .then(() => {
                this.$emit('getTasks')
                this.onClose()
                this.isDisabled = false
            })
        },
        onUpdate () {
            this.isDisabled = true
            axios.put('/tasks/edit/'+this.taskId, this.form)
            .then(() => {
                this.$emit('getTasks')
                this.onClose()
                this.isDisabled = false
            })
        },
        onDeleteAssignee () {
            this.form.assignee = ''
            this.$nextTick(() => {
                this.$refs.assignee.clear()
                delete this.form.assignee
            })
        }
    },
    watch: {
        documentId (val) {
            this.form.documentId = val
        },
        documentName (val) {
            this.form.documentName = val
        },
        task (val) {
            this.mode = 'edit'
            this.taskId = val._id
            this.form.title = val.title
            this.form.details = val.details
            this.form.dueDate = val.dueDate
            if(val.assignee) {
                this.form.assignee = val.assignee._id
            }
            this.form.type = val.type
        },
        show (val) {
            if(val) {
                this.$nextTick(() => {
                    this.$refs.title.focus()
                })
            }
        }
    }
}
</script>