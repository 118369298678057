<template>
    <div>
        <div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': sales.status == 'Quotation'}">QUOTATION</button></li>
                    <li><button :class="{'active': sales.status == 'Quotation Sent'}">QUOTATION SENT</button></li>
                    <li><button :class="{'active': sales.status == 'Sales Order'}">SALES ORDER</button></li>
                    <li v-if="sales.status == 'Cancelled'"><button :class="{'active': sales.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="sales.status == 'Quotation'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button @click="onConfirm" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CONFIRM</button>
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">SEND BY EMAIL</button>
            </div>
            <div v-if="sales.status == 'Quotation Sent'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SEND BY EMAIL</button>
                <button @click="onConfirm" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CONFIRM</button>
            </div>
            <div v-if="sales.status == 'Sales Order' && sales.invoiceStatus != 'Fully Invoiced'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SEND BY EMAIL</button>
                <button @click="modalCreateInvoice = true" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CREATE INVOICE</button>
            </div>
            <div v-if="sales.status == 'Cancelled'">
                <button @click="onSetToQuotation" class="h-7 border border-transparent px-2 text-teal-600 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SET TO QUOTATION</button>
            </div>
        </div>

        <div class="p-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                        <div @click="onTab('history')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='history'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">HISTORY LOG <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{activities.length}}</span></div>
                    </div>
                    <div class="flex divide-x">
                        <div @click="onDelivery" v-show="delivery.length > 0" class="py-2 px-5 border-l flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-truck text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-3">
                                <div>{{delivery.length}}</div>
                                <div>Delivery</div>
                            </div>
                        </div>
                        <div @click="onInvoice" v-show="invoices.length > 0" class="py-2 px-5 flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-calculator text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-2">
                                <div class="text-right">{{invoices.length}}</div>
                                <div>Invoices</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                <!-- LOADER -->
                <div v-if="isLoading">
                    <loader></loader>
                </div>
                <div v-if="!isLoading" class="grid grid-cols-10 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-6 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{sales.salesNo}}</div>
                                <div class="text-gray-400 text-xs">SALES ORDER #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{getDate(sales.createdAt)}}</div>
                                <div class="text-gray-400 text-xs">DATE CREATED ({{sales.user.name}})</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="" class="font-medium">CUSTOMER</label>
                            <div class="grid grid-cols-2">
                                <div v-if="sales.billTo">
                                    <label for="" class="font-semibold">Bill To</label>
                                    <div class="text-base font-medium text-blue-500">{{sales.billTo.displayName}}</div>
                                    <div v-if="sales.billTo.address">
                                        <div>{{sales.billTo.address.street}}</div>
                                        <div>{{sales.billTo.address.street2}}</div>
                                        <div>{{sales.billTo.address.subdistrictName}} {{sales.billTo.address.cityName}} {{sales.billTo.address.zip}}</div>
                                        <div>{{sales.billTo.address.provinceName}}</div>
                                    </div>
                                </div>
                                <div v-if="sales.shipTo">
                                    <label for="" class="font-semibold">Ship To</label>
                                    <div class="text-base font-medium text-blue-500">{{sales.shipTo.displayName}}</div>
                                    <div v-if="sales.shipTo.address">
                                        <div>{{sales.shipTo.address.street}}</div>
                                        <div>{{sales.shipTo.address.street2}}</div>
                                        <div>{{sales.shipTo.address.subdistrictName}} {{sales.shipTo.address.cityName}} {{sales.shipTo.address.zip}}</div>
                                        <div>{{sales.shipTo.address.provinceName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="" class="font-medium">CUSTOMER PO</label>
                            <div v-if="sales.customerPO">{{sales.customerPO}}</div>
                            <div v-else>--</div>
                        </div>
                        <div v-if="sales.remarks" class="mb-3">
                            <div class="flex items-center justify-between mb-1">
                                <div>
                                    <label for="" class="font-semibold">REMARKS</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="sales.remarks.urgent" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Urgent</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="sales.remarks.forYourReview" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">For Your Review</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="sales.remarks.replyAsap" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Reply ASAP</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="sales.remarks.pleaseComment" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Please Comment</label>
                                </div>
                            </div>
                            <div v-if="sales.remarks.comment">{{sales.remarks.comment}}</div>
                            <div v-else>--</div>
                        </div>
                        <div v-if="sales.tags">
                            <label for="" class="font-semibold">TAGS</label>
                            <div v-if="sales.tags.length > 0" class="flex items-center space-x-0.5">
                                <div v-for="tag in sales.tags" :key="tag" class="bg-gray-500 text-gray-50 text-xs px-1 rounded-sm py-px">{{tag}}</div>
                            </div>
                            <div v-else>--</div>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">ESTIMATED DELIVERY TIME</label>
                                <div>{{sales.estimatedDeliveryTime}}</div>
                            </div>
                            <div class="col-span-1">
                                <label class="font-medium" for="">DATE VALIDATY</label>
                                <div>{{getDate(sales.dateValidaty)}}</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="" class="font-medium">TERM OF PAYMENT</label>
                            <div v-if="sales.paymentTerm">{{sales.paymentTerm.code}}</div>
                            <div v-else>--</div>
                        </div>
                        <div v-if="sales.additionalCharges">
                            <label for="" class="font-semibold">ADDITIONAL CHARGES</label>
                            <table v-if="sales.additionalCharges.length > 0" class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr v-for="(adc, i) in sales.additionalCharges" :key="i">
                                    <td class="py-2 pl-3">{{adc.name}}</td>
                                    <td v-if="adc.isFixed" class="py-2 pr-3 text-right">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(adc.unitPrice)}}</td>
                                    <td v-else class="py-2 pr-3 text-right">{{adc.unitPrice}}%</td>
                                </tr>
                            </table>
                            <table v-else class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr>
                                    <td class="py-2 pl-3">--</td>
                                    <td class="py-2 pr-3 text-right">--</td>
                                </tr>
                            </table>
                            
                        </div>
                    </div>
                </div>
                <table class="w-full table-fixed bg-white">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  sticky z-20 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 bg-blue-500" style="width: 40%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">UNIT PRICE</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">QTY</th>
                            <th v-show="sales.status == 'Sales Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">DELIVERED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                            <th v-show="sales.status == 'Sales Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">INVOICED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 11%; top: 93px">DISCOUNT</th>
                            <th class="py-2.5 pl-2 pr-5 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody class="border border-b-0">
                        <tr v-for="(item, i) in sales.items" :key="i" class="border-b">
                            <td class="pl-5 h-9 border-r">{{i+1}}</td>
                            <td class="px-2 h-9 truncate">{{item.name}}</td>
                            <td class="px-2 h-9 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                            <td class="px-2 h-9 text-right">{{item.qty}}</td>
                            <td v-show="sales.status == 'Sales Order'" class="px-2 h-9 text-right">{{item.delivered}}</td>
                            <td class="h-9 px-1 text-center">
                                <a href="#" :class="[item.qty > item.stock ? 'text-red-500':'text-green-500']"><i class="icon-chart"></i></a>
                            </td>
                            <td v-show="sales.status == 'Sales Order'" class="px-2 h-9 text-right">{{item.invoiced}}</td>
                            <td v-if="item.discount.amount" class="h-9 px-2 text-right text-red-500">
                                <div v-if="item.discount.discountType == 'Fixed'">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.discount.amount)}}</div>
                                <div v-else>{{item.discount.amount}}%</div>
                            </td>
                            <td v-else class="h-9 px-2 text-right">--</td>
                            <td class="h-9 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                        </tr>
                        <!-- DOWN PAYMENT -->
                        <tr v-for="dp in sales.downPayments" :key="dp._id" class="border-b">
                            <td class="h-9 border-r pl-5 pr-2"></td>
                            <td class="px-2">{{dp.name}}</td> 
                            <td class="px-2 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(dp.amount)}}</td> 
                            <td class="px-2 text-right">0</td> 
                            <td class="px-2 text-right">0</td> 
                            <td class="px-2 text-right"></td> 
                            <td class="px-2 text-right">0</td> 
                            <td class="px-2 text-right">--</td> 
                            <td class="pl-2 pr-5 text-right">0</td> 
                        </tr>
                        <!-- TOTOAL -->
                        <tr>
                            <td v-if="sales.status == 'Sales Order'" colspan="7"></td>
                            <td v-else colspan="5"></td>
                            <td class="py-1 px-2 text-right">Subtotal</td>
                            <td class="py-1 pl-2 pr-5 text-right font-medium">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sales.total)}}</td>
                        </tr>
                        <!-- DISCOUNT -->
                        <tr v-if="sales.discount.value" class="font-medium text-red-500">
                            <td v-if="sales.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">Discount on total price</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">Discount on total price</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sales.discount.value)}}</td>
                        </tr>
                        <!-- TAX -->
                        <tr v-if="sales.tax.value" class="font-medium text-blue-500">
                            <td v-if="sales.status == 'Sales Order'" colspan="8" class="px-2 text-right">
                                {{sales.tax.code}} ({{sales.tax.amount}}%)
                            </td>
                            <td v-else colspan="6" class="px-2 text-right">
                                {{sales.tax.code}} ({{sales.tax.amount}}%)
                            </td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sales.tax.value)}}</td>
                        </tr>
                         <!-- ADDITIONAL CHARGES -->
                        <tr v-if="sales.totalAdditionalCharges" class="font-medium text-green-600">
                            <td v-if="sales.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">ADDITIONAL CHARGERS</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">ADDITIONAL CHARGERS	</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sales.totalAdditionalCharges)}}</td>
                        </tr>
                        <!-- SHIPPING -->
                        <tr v-if="sales.shipping" class="font-medium">
                            <td v-if="sales.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sales.shipping.shipmentCost)}}</td>
                        </tr>
                        <!-- TOTAL -->
                        <tr class="bg-orange-100">
                            <td></td>
                            <td></td>
                            <td v-if="sales.status == 'Sales Order'" colspan="5"></td>
                            <td v-else colspan="3" class="py-1 pl-10 pr-2.5"></td>
                            <td class="text-right text-base font-semibold h-9 px-2">TOTAL</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(sales.grandTotal)}}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="sales.offerConditions" class="p-5 bg-white border rounded-b-sm border-t-0">
                    <div class="text-base font-medium mb-3">KONDISI PENAWARAN</div>
                    <table class="w-full">
                        <tr v-for="(condition, i) in sales.offerConditions" :key="i">
                            <td class="pl-5 py-1">{{condition.name}} :</td>
                            <td class="pl-5 py-1">{{condition.description}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="sales.salesNo"></task-manager>   
            </div>
            <!-- HISTORY -->
            <div v-show="tab == 'history'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <history :activities="activities"></history>
            </div>
        </div>

        <!-- MODAL CREATE INVOICE -->
        <modal :show="modalCreateInvoice">
            <form @submit.prevent="onSubmitInvoice" class="w-2/4 bg-white border rounded-sm">
                <div class="flex items-center justify-between px-5 py-3 border-b">
                    <div class="font-medium">Create Invoices</div>
                </div>
                <div class="p-5">
                    <div class="text-gray-400 mb-5">Invoices will be created in draft so that you can review them before validation.</div>
                    <div class="flex space-x-5 mb-5 justify-center">
                        <div class="form-radio">
                            <input v-model="formInvoice.type" :value="'Regular'" type="radio" id="regular" name="radioinline" checked>
                            <label for="regular">Regular invoice</label>
                        </div>
                        <div class="form-radio">
                            <input v-model="formInvoice.type" :value="'Percentage'" type="radio" id="percent" name="radioinline">
                            <label for="percent">Down payment (percentage)</label>
                        </div>
                        <div class="form-radio">
                            <input v-model="formInvoice.type" :value="'Fixed'" type="radio" id="fixed" name="radioinline">
                            <label for="fixed">Down payment (fixed amount)</label>
                        </div>
                    </div>
                    <div v-show="formInvoice.type == 'Percentage'" class="px-16">
                        <autonumeric v-model="formInvoice.percentage" class="form-control" :options="['integer',{suffixText: '%', modifyValueOnWheel : false, maximumValue: 100}]">

                        </autonumeric>
                    </div>
                    <div v-show="formInvoice.type == 'Fixed'" class="px-16">
                        <autonumeric v-model="formInvoice.fixed" class="form-control" :options="['integer',{currencySymbol:'Rp ', modifyValueOnWheel : false}]">

                        </autonumeric>
                    </div>
                </div>
                <div class="text-right px-5 py-2 border-t">
                    <button @click="modalCreateInvoice = false" type="button" class="h-9 rounded-sm px-5 mr-2 border border-transparent hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                    <button type="submit" class="h-9 rounded-sm px-5 mr-2 border border-transparent hover:border-gray-200 hover:bg-gray-100" :disabled="isDisabled">CREATE INVOICE</button>
                    <button type="submit" class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50 hover:bg-teal-600" :disabled="isDisabled">CREATE INVOICE AND VIEW</button>
                </div>
            </form>
        </modal>

        <!-- MODAL CANCEL ORDER -->
        <modal :show="modalCancelSalesOrder">
            <div class="w-2/5 bg-white border rounded-sm">
                <div class="px-5 py-3 border-b flex items-center justify-between">
                    <div class="font-semibold">Cancel Sales Order</div>
                </div>
                <div class="p-5">
                    Some products have already been delivered. Returns can be created from the Delivery Orders.
                </div>
                <div class="text-right px-5 py-2 border-t">
                    <button @click="modalCancelSalesOrder = false" class="h-9 rounded-sm px-5 mr-2 border border-transparent hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                    <button class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50 hover:bg-teal-600">CONFIRM</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import stepper from '../../../components/stepper.vue'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import TaskManager from '../../../components/taskManager.vue'
import History from '../../../components/history.vue'
import {EventBus} from '../../../event-bus'
import {moment} from '../../../moment'
import Modal from '../../../components/Modal.vue'
import Autonumeric from '../../../components/Autonumeric.vue'
import Loader from '../../../components/loader.vue'
export default {
    components: {stepper, TaskManager, History, Modal, Autonumeric, Loader},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isDisabled: false,
            modalCancelSalesOrder: false,
            modalCreateInvoice: false,
            isLoading: false,
            tab: 'overview',
            delivery: [],
            invoices: [],
            sales: {
                paymentTerm: '',
                paymentTermId: '',
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                },
                user: ''
            },
            task: '',
            activities: [],
            formInvoice : {
                type: 'Regular',
                percentage: '',
                fixed: ''
            },
            pages: '',
            params: ''
        }
    },
    mounted () {
        this.setButtonText('EDIT')
        this.setAction(true)
        this.setDiscard(true)
        this.setDiscardText('CREATE')
        EventBus.$on('onClick', this.onEdit)
        EventBus.$on('onCancel', this.newQuotation)
        EventBus.$on('onDuplicate', this.onDuplicate)
        EventBus.$on('onPreviousPage', this.previousPage)
        EventBus.$on('onNextPage', this.nextPage)
        let a = localStorage.getItem('salesOrder')
        let params = JSON.parse(a)
        this.getData(true, params)
        this.params = params
    },
    destroyed () {
        EventBus.$off('onClick', this.onEdit)
        EventBus.$off('onCancel', this.newQuotation)
        EventBus.$off('onDuplicate', this.onDuplicate)
        EventBus.$off('onPreviousPage', this.previousPage)
        EventBus.$off('onNextPage', this.nextPage)
    },
    computed: {
        downPayments () {
            let data = [{name: 'Down Payment', date: new Date(), amount: '', total: '', status: 'Nothing'}]
            if(this.formInvoice.type == 'Percentage') {
                let val = this.formInvoice.percentage * this.sales.grandTotal / 100
                data[0].amount = Math.round(val)
                data[0].name = `Down payment of ${this.formInvoice.percentage}%`
                data[0].total = -data[0].amount
            }
            if(this.formInvoice.type == 'Fixed') {
                data[0].amount = this.formInvoice.fixed
                data[0].total = -data[0].amount
            }
            return data
        }
    },
    methods: {
        onTab (i) {
            this.tab = i
        },
        onDuplicate () {
            let id = this.$route.params.id
            axios.post('/quotations/duplicate/'+id)
            .then(res => {
                id = res.data._id
                this.setBreadcrumbs([
                    {
                        text: 'Quotation',
                        path: '/quotations'
                    },
                    {
                        text: res.data.quotationNo
                    }
                ])
                this.$router.push('/quotations/edit/'+ id)
            })
        },
        getData (v, params) {
            this.isLoading = true
            axios.get('/sales/detail/'+this.$route.params.id, {
                params: params
            })
            .then(res => {
                this.isLoading = false
                this.sales = res.data.sales
                if(!res.data.sales.paymentTerm) {
                    this.sales.paymentTerm = ''
                }
                this.activities = res.data.activities
                this.delivery = res.data.delivery
                this.invoices = res.data.invoices
                this.pages = res.data.pages
                this.setPages(this.pages)
                if(v) {
                    this.pushBreadcrumb({
                        text: res.data.sales.salesNo
                    }) 
                }
            })
            .catch(err => {
                this.isLoading = false
                console.log(err);
            })
        },
        onEdit () {
            this.$router.push('/sales/edit/'+ this.$route.params.id)
        },
        onCancelled () {
            for (let i=0; i < this.delivery.length; i++) {
                const el = this.delivery[i]
                if(el.status == 'Delivery' || el.status == 'Done') {
                    this.modalCancelSalesOrder = true
                    return
                }
            }
            axios.put('/quotations/cancel/'+this.$route.params.id)
            .then((res) => {
                this.setBreadcrumbs([
                    {text: 'Quotations', path: '/quotations'}
                ])
                this.$router.push('/quotations/detail/'+res.data._id)
            })
        },
        onSetToQuotation () {
            axios.put('/quotations/settoquotation/'+this.$route.params.id)
            .then(() => {
                this.getData(false)
            })
        },
        onConfirm () {
            axios.put('/quotations/confirm/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        newQuotation () {
            this.$router.push('/quotations/new')
        },
        getDate (d) {
            return moment(d)
        },
        onDelivery () {
            if(this.delivery.length > 1) {
                this.$router.push({path: '/delivery-orders', query: {salesId: this.sales._id}})
            } else {
                this.$router.push({path: '/delivery-orders/detail/'+this.delivery[0]._id})
            }
        },
        onInvoice () {
            if(this.invoices.length > 1) {
                this.$router.push({path: '/invoices', query: {salesId: this.sales._id}})
            } else {
                this.$router.push({path: '/invoices/detail/'+this.invoices[0]._id})
            }
        },
        onSubmitInvoice () {
            this.isDisabled = true
            this.sales.type = this.formInvoice.type
            if(this.formInvoice.type != 'Regular') {
                this.sales.downPayments = this.downPayments
            }
            if(this.sales.downPayments.length > 0) {
                let downPayments = this.sales.downPayments
                for(let i=0; i < downPayments.length; i++) {
                    this.sales.grandTotal += downPayments[i].total
                } 
            }
            axios.post('/invoices/create', this.sales)
            .then(() => {
                this.isDisabled = false
                this.getData()
                this.modalCreateInvoice = false
            })
        },
        previousPage() {
            let currPage = this.pages.currPage
            if(currPage > 1) {
                currPage -=1
                this.pages.currPage = currPage
                this.pages.currPage = currPage
                axios.get('/sales/page', {
                    params: {
                        page: currPage-1,
                        search: this.params.search,
                        filter: this.params.filter,
                    }
                })
                .then(res => {
                    this.popBreadcrumb()
                    this.$router.push('/sales/detail/'+res.data._id)
                })   
            }
        },
        nextPage() {
            let totalItems = this.pages.totalItems
            let currPage = this.pages.currPage
            if(currPage < totalItems) {
                currPage +=1
                this.pages.currPage = currPage
                axios.get('/sales/page', {
                    params: {
                        page: currPage-1,
                        search: this.params.search,
                        filter: this.params.filter,
                    }
                })
                .then(res => {
                    this.popBreadcrumb()
                    this.$router.push('/sales/detail/'+res.data._id)
                })
            }
        }
    },
    watch: {
        modalCreateInvoice (val) {
            if(val) {
                this.formInvoice.type = 'Regular'
            }
        },
        'formInvoice.type': function () {
            this.formInvoice.percentage = 0
            this.formInvoice.fixed = 0
        }
    },
    beforeRouteLeave (to, from, next) {
        if(to.name != 'Sales edit' && to.name !='Quotation Detail' && to.name != 'Quotation Edit') {
            this.replaceBreadcrumb({
                find: this.sales.quotationNo,
                replace: {text: this.sales.salesNo, path: this.$route.fullPath}
            })
        }
        if(to.name != 'Sales' && to.name != 'Invoices' && to.name != 'Invoice Detail' && to.name != 'Delivery Orders' && to.name !='Delivery Detail') {
            localStorage.removeItem('salesOrder')
        }
        this.setAction(false)
        this.setPages(false)
        next()
    }
}
</script>