<template>
    <div>
        <form @submit.prevent="onSubmit">
            <div class="grid grid-cols-12 border rounded-sm bg-white">
                <!-- INFO -->
                <div class="col-span-7">
                    <div class="border-b px-5 p-3"><i class="icon-info22 mr-2"></i>INFO</div>
                    <div class="p-5">
                        <div class="mb-5">
                            <label for="">COMPANY NAME<span class="text-red-500"> *</span></label>
                            <input v-model="form.name" type="text" @keydown="error.name=''" :class="{'border-red-500': error.name}" class="form-control mt-2" placeholder="Company name">
                            <span class="text-red-500">{{error.name}}</span>
                        </div>
                        <div class="mb-5">
                            <label for="">SUPPLIER CODE</label>
                            <input v-model="form.code" type="text" class="form-control mt-2" placeholder="Supplier code">
                        </div>
                        <div class="mb-5">
                            <label for="">WEBSITE</label>
                            <input v-model="form.website" type="text" class="form-control mt-2" placeholder="http://company.com">
                        </div>
                        <div class="mb-5">
                            <label for="">INTERNAL REMARKS</label>
                            <textarea v-model="form.internalRemasks" class="w-full border focus:outline-none rounded-sm mt-2 p-3" rows="2"></textarea>
                        </div>
                        <div class="mb-5">
                            <label for="">EKSTERNAL REMARKS</label>
                            <textarea v-model="form.externalRemasks" class="w-full border focus:outline-none rounded-sm mt-2 p-3" rows="2"></textarea>
                        </div>
                        <div class="mb-5">
                            <label for="">TAGS</label>
                            <tags-input v-model="form.tags" class="mt-2"></tags-input>
                        </div>
                    </div>
                </div>
                <!-- /end info -->

                <!-- ADDRESS & CONTATCS -->
                <div class="col-span-5 bg-gray-300 rounded-br-sm">
                    <div class="grid grid-cols-2 items-center border-b border-gray-200 h-11 rounded-tr-sm bg-gray-600">
                        <div class="relative col-span-1 text-center h-full">
                            <a @click.prevent="tabs = 'a'" href="#" :class="tabs=='a'?'text-orange-400':'text-gray-300'" class="group inline-flex h-full items-center transition duration-200 hover:text-orange-400 uppercase leading-none">ADDRESSES<span :class="{'bg-orange-400 text-white': tabs == 'a'}" class="bg-gray-400 rounded-sm px-2 py-1 ml-2 text-gray-300 leading-none transition duration-200 group-hover:bg-orange-400 group-hover:text-white">{{this.form.addressLists.length}}</span></a>
                            <div v-show="tabs == 'a'" class="absolute w-full bg-orange-400 -bottom-px" style="height: 3px"></div>
                        </div>
                        <div class="relative col-span-1 text-center h-full">
                            <a @click.prevent="tabs = 'b'" href="#" :class="tabs=='b'?'text-orange-400':'text-gray-300'" class="group inline-flex h-full items-center transition duration-200 hover:text-orange-400 uppercase leading-none">CONTACTS<span :class="{'bg-orange-400 text-white': tabs == 'b'}" class="bg-gray-400 rounded-sm px-2 py-1 ml-2 transition duration-200 group-hover:bg-orange-400 group-hover:text-white text-gray-300 leading-none">{{this.form.contactLists.length}}</span></a>
                            <div v-show="tabs == 'b'" class="absolute w-full bg-orange-400 -bottom-px" style="height: 3px"></div>
                        </div>
                    </div>
                    <!-- TAB ADDRESS -->
                    <div v-show="tabs=='a'" class="p-5 animated fadeIn">
                        <div v-if="!isAddAddress">
                            <div v-if="form.addressLists.length > 0">
                                <ul class="divide-y divide-gray-400 divide-dashed">
                                    <li v-for="(address, i) in form.addressLists" :key="i" class="flex items-center justify-between py-3">
                                        <div class="mr-5">
                                            <div v-if="address.type == 'Shipping'" class="font-semibold flex items-center"><i class="icon-truck mr-1"></i>Shipping</div>
                                            <div v-else class="font-semibold flex items-center"><i class="icon-coin-dollar mr-1"></i>Billing</div>
                                            <div>{{address.address}}</div>
                                            <div>{{address.city}}, {{address.state}},{{address.country}}, {{address.postalCode}}</div>
                                        </div>
                                        <div>
                                            <ul class="flex space-x-3">
                                                <li>
                                                    <a @click.prevent="onSetDefaultAddress(i)" href="#" :class="{'text-green-500': address.isDefault}" class="hover:text-green-500"><i class="icon-checkmark"></i></a>
                                                </li>
                                                <li>
                                                    <a @click.prevent="onEditAddress(i)" href="#" class="hover:text-blue-500"><i class="icon-pencil"></i></a>
                                                </li>
                                                <li>
                                                    <a @click.prevent="onDeleteAddress(i)" href="#" class="hover:text-red-500"><i class="icon-trash"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div class="text-right mt-5">
                                    <button @click="isAddAddress = true" type="button" class="bg-blue-500 h-9 hover:bg-blue-600 px-5 rounded-sm text-white focus:outline-none">+ Add address </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.addressLists.length == 0 || isAddAddress" class="animated fadeIn">
                            <form @submit.prevent="onSubmitAddress">
                                <div class="mb-5">
                                    <label for="">TYPE</label>
                                    <div class="flex justify-between mt-1">
                                        <select-2 v-model="formAddress.type"  class="form-control">
                                            <option value="Shipping">Shipping</option>
                                            <option value="Billing">Billing</option>
                                        </select-2>
                                        <button v-if="formAddress.type == 'Shipping'" @click="onModalCopyAddress('Billing')" type="button" class="bg-black text-white h-9 px-2 rounded-sm ml-2 w-64"><i class="icon-copy3 mr-2"></i>Copy from billing address</button>
                                        <button v-if="formAddress.type == 'Billing'" @click="onModalCopyAddress('Shipping')" type="button" class="bg-black text-white h-9 px-2 rounded-sm ml-2 w-64"><i class="icon-copy3 mr-2"></i>Copy from shipping address</button>
                                    </div>
                                </div>
                                <div class="mb-5">
                                    <label for="">ADRRESS</label>
                                    <textarea @keydown.tab="$refs.country.open()" v-model="formAddress.address" @keydown="formAddressError.address=''" class="p-3 border rounded-sm focus:outline-none w-full mt-1" rows="2"></textarea>
                                    <span class="text-red-500">{{formAddressError.address}}</span>
                                </div>
                                <div class="mb-5 grid grid-cols-2 gap-2">
                                    <div class="col-span-1">
                                        <div class="mb-1">
                                            <label for="">COUNTRY</label>
                                        </div>
                                        <select-2 ref="country" v-model="formAddress.country" :options="countries" @input="$refs.state.focus()" class="form-control" :placeholder="'Select province'">
                                            <option value=""></option>
                                        </select-2>
                                    </div>
                                    <div class="col-span-1">
                                        <label for="">STATE</label>
                                        <input ref="state" v-model="formAddress.state" type="text" class="form-control mt-1">
                                    </div>
                                </div>
                                <div class="mb-5 grid grid-cols-2 gap-2">
                                    <div class="col-span-1">
                                        <label for="">CITY / TOWN</label>
                                        <input v-model="formAddress.city" type="text" class="form-control mt-1">
                                    </div>
                                    <div class="col-span-1">
                                        <label for="">POSTAL CODE</label>
                                        <input v-model="formAddress.postalCode" type="text" class="form-control mt-1">
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button @click="onCancelAddress" type="button" class="h-9 px-5 border rounded-sm bg-gray-50 mr-2 focus:outline-none">Cancel</button>
                                    <button v-if="formAddressMode=='new'" class="h-9 px-5 text-white bg-teal-500 rounded-sm focus:outline-none hover:bg-teal-600">Add</button>
                                    <button v-else class="h-9 px-5 text-white bg-teal-500 rounded-sm focus:outline-none hover:bg-teal-600">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /end tab address -->

                    <!-- TAB CONTACTS -->
                    <div v-show="tabs=='b'" class="p-5 animated fadeIn">
                        <div v-if="!isAddContact">
                            <div v-if="form.contactLists.length > 0">
                                <ul class="divide-y divide-gray-400 divide-dashed">
                                    <li v-for="(contact, i) in form.contactLists" :key="i" class="p-3 flex justify-between">
                                        <div class="space-y-1">
                                            <div class="flex items-center"><i class="icon-user mr-3"></i>{{contact.designation}} {{contact.name}}</div>
                                            <div class="flex items-center"><i class="icon-envelop2 mr-3"></i>{{contact.email}}</div>
                                            <div class="flex items-center"><i class="icon-mobile mr-3"></i>{{contact.mobile}}</div>
                                            <div class="flex items-center"><i class="icon-office mr-3"></i>{{contact.office}}</div>
                                        </div>
                                        <div>
                                            <ul class="flex space-x-3">
                                                <li>
                                                    <a @click.prevent="onSetDefaultContact(i)" href="#" :class="{'text-green-500': contact.isDefault}" class="hover:text-green-500"><i class="icon-checkmark"></i></a>
                                                </li>
                                                <li>
                                                    <a @click.prevent="onEditContact(i)" href="#" class="hover:text-blue-500"><i class="icon-pencil"></i></a>
                                                </li>
                                                <li>
                                                    <a @click.prevent="onDeleteContact(i)" href="#" class="hover:text-red-500"><i class="icon-trash"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div class="text-right">
                                    <button @click.prevent="onAddContact" type="button" class="h-9 bg-blue-500 px-5 rounded-sm text-blue-50 hover:bg-blue-600">+ Add Contact</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.contactLists.length == 0 || isAddContact" class="animated fadeIn">
                            <form @submit.prevent="onSubmitContact" action="">
                                <div class="mb-5">
                                    <label for="">DESIGNATION</label>
                                    <input v-model="formContact.designation" type="text" class="form-control mt-1" placeholder="Designation">
                                </div>
                                <div class="mb-5">
                                    <label for="">NAME</label>
                                    <input v-model="formContact.name" type="text" class="form-control mt-1" placeholder="full name">
                                </div>
                                <div class="mb-5">
                                    <label for="">EMAIL</label>
                                    <input v-model="formContact.email" type="text" class="form-control mt-1" placeholder="email address">
                                </div>
                                <div class="grid grid-cols-2 gap-2 mb-5">
                                    <div class="col-span-1">
                                        <label for="">MOBILE</label>
                                        <input v-model="formContact.mobile" type="text" class="form-control mt-1" placeholder="mobile">
                                    </div>
                                    <div class="col-span-1">
                                        <label for="">OFFICE</label>
                                        <input v-model="formContact.office" type="text" class="form-control mt-1" placeholder="office">
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="button" @click.prevent="isAddContact = false" class="bg-white border rounded-sm h-9 px-5 hover:bg-gray-50">Cancel</button>
                                    <button v-if="formContactMode == 'new'" class="bg-teal-500 text-teal-50 ml-2 h-9 px-5 rounded-sm hover:bg-teal-600">Add</button>
                                    <button v-else class="bg-teal-500 text-teal-50 ml-2 h-9 px-5 rounded-sm hover:bg-teal-600">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /end tab contatcs -->
                    <!-- OPTIONS -->
                    <div class="px-5 p-3 bg-gray-600 text-gray-100"><i class="icon-cog2 mr-2"></i>OPTIONS</div>
                    <div class="p-5">
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">DEFAULT CURENNCY</label>
                                </div>
                                <div class="relative">
                                    <select-2 v-model="form.defaultCurrencyId" :options="currencyLists" class="form-control" :placeholder="'Please select'">
                                        <option value=""></option>
                                    </select-2>
                                    <div class="absolute top-2 right-7">
                                        <a v-show="form.defaultCurrencyId" @click.prevent="form.defaultCurrencyId=''" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">DEFAULT PAYMENT TERM</label>
                                </div>
                                <div class="relative">
                                    <select-2 v-model="form.defaultPaymentTermId" :options="paymentTermLists" class="form-control" :placeholder="'Please select'">
                                        <option value=""></option>
                                    </select-2>
                                    <div class="absolute top-2 right-7">
                                        <a v-show="form.defaultPaymentTermId" @click.prevent="form.defaultPaymentTermId=''" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">DEFAULT SHIPMENT TERMS</label>
                                </div>
                                <div class="relative">
                                    <select-2 v-model="form.defaultShipmentTermId" :options="shipmentTermLists" :sort="'asc'" class="form-control" :placeholder="'Please select'">
                                        <option value=""></option>
                                    </select-2>
                                    <div class="absolute top-2 right-7">
                                        <a v-show="form.defaultShipmentTermId" @click.prevent="form.defaultShipmentTermId=''" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">DEFAULT SHIPMENT METHOD</label>
                                </div>
                                <div class="relative">
                                    <select-2 v-model="form.defaultShipmentMethodId" :options="shipmentMethodLists" :sort="'asc'" class="form-control" :placeholder="'Please select'">
                                        <option value=""></option>
                                    </select-2>
                                    <div class="absolute top-2 right-7">
                                        <a v-show="form.defaultShipmentMethodId" @click.prevent="form.defaultShipmentMethodId=''" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">DEFAULT TAX</label>
                                </div>
                                <div class="relative">
                                    <select-2 v-model="form.defaultTaxId" :options="taxLists" :sort="'asc'" class="form-control" :placeholder="'Please select'">
                                        <option value=""></option>
                                    </select-2>
                                    <div class="absolute top-2 right-7">
                                        <a v-show="form.defaultTaxId" @click.prevent="form.defaultTaxId=''" href="#" class="text-gray-500 hover:text-red-500"><i class="icon-cross3"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /end options -->
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="$router.push('/suppliers')" type="button" class="h-9 px-5 border hover:bg-gray-50 bg-white mr-2 text-gray-500 rounded-sm">Cancel</button>
                <button class="h-9 px-5 hover:bg-teal-600 bg-teal-500 text-teal-50 rounded-sm" :disabled="isDisabled">Save</button>
            </div>
        </form>

         <!-- Modal Copy Address -->
        <modal :show="modalCopyAddress">
            <div class="bg-white w-2/5 p-5 rounded-sm relative">
                <div class="text-lg text-center mb-5">SELECT SHIPPING ADDRES TO COPY</div>
                <ul class="border divide-y max-h-96 overflow-y-scroll">
                    <li v-for="(address, i) in addressCopyLists" :key="i" @click="onCopyAddress(i)" class="p-3 cursor-pointer transition duration-300 hover:text-blue-500">
                        <div v-if="address.type == 'Shipping'" class="font-semibold flex items-center"><i class="icon-truck mr-1"></i>Shipping</div>
                        <div v-else class="font-semibold flex items-center"><i class="icon-coin-dollar mr-1"></i>Billing</div>
                        <div>{{address.address}}</div>
                        <div>{{address.city}}, {{address.state}}, {{address.country}}, {{address.postalCode}}</div>
                    </li>
                </ul>
                <div class="text-right mt-5">
                    <button @click="modalCopyAddress = false" class="h-9 px-3 bg-white hover:bg-gray-50 border">close</button>
                </div>
            </div>
        </modal>
        <!-- /end modal copy address -->
        
    </div>
</template>

<script>
import TagsInput from '../../components/TagsInput.vue'
import Select2 from '../../components/Select2.vue'
import Country from '../../countries.json'
import Swal from 'sweetalert2/dist/sweetalert2'
import Modal from '../../components/Modal.vue'
import axios from 'axios'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
export default {
    components: {
        TagsInput, Select2, Modal
    },
    mixins: [BreadcrumbsManager],
    data () {
        return {
            tabs: 'a',
            isDisabled: false,
            currencies: [],
            paymentTerms: [],
            shipmentTerms: [],
            shipmentMethods: [],
            taxs: [],
            modalCopyAddress: false,
            isAddAddress: false,
            formAddressMode: 'new',
            addressCopyLists: '',
            formAddress: {
                type: 'Shipping',
                address: '',
                country: '',
                state: '',
                city: '',
                postalCode: '',
                isDefault: false
            },
            formAddressError: {
                address: ''
            },
            isAddContact: false,
            formContactMode: 'new',
            formContact: {

            },
            form: {
                name: '',
                code: '',
                website: '',
                internalRemarks: '',
                externalRemarks: '',
                tags: [],
                defaultCurrencyId: '',
                defaultPaymentTermId: '',
                defaultShipmentTermId: '',
                defaultShipmentMethodId: '',
                defaultTaxId: '',
                addressLists: [],
                contactLists: []
            },
            error: {
                name: ''
            }
        }
    },
    mounted () {
        this.setButtonText('SAVE')
        this.getData()
    },
    computed: {
        countries () {
            return Country.map(obj => {
                obj.id = obj.name
                obj.text = obj.name
                return obj
            })
        },
        currencyLists () {
            return this.currencies.map(obj => {
                obj.id = obj._id
                obj.text = obj.code
                return obj
            })
        },
        paymentTermLists () {
            return this.paymentTerms.map(obj => {
                obj.id = obj._id
                obj.text = obj.code
                return obj
            })
        },
        shipmentTermLists () {
            return this.shipmentTerms.map(obj => {
                obj.id = obj._id
                obj.text = obj.code
                return obj
            })
        },
        shipmentMethodLists () {
            return this.shipmentMethods.map(obj => {
                obj.id = obj._id
                obj.text = obj.code
                return obj
            })
        },
        taxLists () {
            return this.taxs.map(obj => {
                obj.id = obj._id
                obj.text = obj.code
                return obj
            })
        },
        formSubmit () {
            for(let fr in this.form) {
                if(this.form[fr] === '') {
                    delete this.form[fr]
                }
            }
            return this.form
        }
    },
    methods: {
        getData () {
            axios.get('/suppliers/create')
            .then(res => {
                this.currencies = res.data.currencies
                this.shipmentTerms = res.data.shipmentTerms
                this.shipmentMethods = res.data.shipmentMethods
                this.paymentTerms = res.data.paymentTerms
                this.taxs = res.data.taxs
            })
        },
        // SUBMIT 
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Company name is required'
                window.scrollTo(0,0)
            }
            if(this.form.name) {
                this.isDisabled = true
                axios.post('/suppliers/new', this.formSubmit)
                .then(() => {
                    this.isDisabled = false
                    this.$router.push('/suppliers')
                })
            }
        },
        // CONCTACT FUCNTION
        onSetDefaultContact (i) {
            this.form.contactLists.map((obj, index )=> {
                if(index == i) {
                    obj.isDefault = true
                } else {
                    obj.isDefault = false
                }
                return obj
            })
        },
        onEditContact (i) {
            this.formContactIndex = i
            this.formContactMode = 'edit'
            this.isAddContact = true
            this.formContact.designation = this.form.contactLists[i].designation
            this.formContact.name = this.form.contactLists[i].name
            this.formContact.email = this.form.contactLists[i].email
            this.formContact.mobile = this.form.contactLists[i].mobile
            this.formContact.office = this.form.contactLists[i].office
            this.formContact.isDefault = this.form.contactLists[i].isDefault
        },
        onDeleteContact (i) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return 
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    app.form.contactLists.splice(i, 1)
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Conatact has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onAddContact () {
            this.formContactMode = 'new'
            this.isAddContact = true
            window.scrollTo(0,0)
        },
        onSubmitContact () {
            if(this.formContactMode == 'new') {
                if(this.form.contactLists.length == 0) {
                    this.formContact.isDefault = true
                    this.form.contactLists.push(this.formContact)
                } else {
                    this.form.contactLists.push(this.formContact)
                }
            } else {
                const i = this.formContactIndex
                this.form.contactLists.splice(i, 1, this.formContact)
            }
            this.isAddContact = false
            this.formContact = {
                designation: '',
                name: '',
                email: '',
                mobile: '',
                office: '',
                isDefault: false
            }
        },
        // ADDRESS FUNCTION
        onSubmitAddress () {
            if(!this.formAddress.address) {
                this.formAddressError.address = 'Address is required'
            }
            if(this.formAddress.address) {
                if(this.formAddressMode == 'new') {
                    if(this.form.addressLists.length == 0) {
                        this.formAddress.isDefault = true
                    }
                    this.form.addressLists.push(this.formAddress)
                } else {
                    const i = this.formAddress.index
                    this.form.addressLists[i].type = this.formAddress.type
                    this.form.addressLists[i].address = this.formAddress.address
                    this.form.addressLists[i].country = this.formAddress.country
                    this.form.addressLists[i].state = this.formAddress.state
                    this.form.addressLists[i].city = this.formAddress.city
                    this.form.addressLists[i].postalCode = this.formAddress.postalCode
                }
                this.isAddAddress = false
                this.formAddressMode = 'new'
                this.formAddress = {
                    type: 'Shipping',
                    address: '',
                    country: '',
                    state: '',
                    city: '',
                    postalCode: '',
                    isDefault: false
                }
            }
        },
        onSetDefaultAddress (i) {
            this.form.addressLists.map((obj, index) => {
                if(index == i) {
                    obj.isDefault = true
                } else {
                    obj.isDefault = false
                }
                return obj
            })
        },
        onEditAddress (i) {
            this.isAddAddress = true
            this.formAddressMode = 'edit'
            this.formAddress = this.form.addressLists[i]
            this.formAddress.index = i
        },
        onDeleteAddress (i) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return 
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    app.form.addressLists.splice(i, 1)
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Address has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onCancelAddress () {
            this.isAddAddress = false
            this.formAddressMode = 'new'
            this.formAddress = {
                type: 'Shipping',
                address: '',
                country: '',
                state: '',
                city: '',
                postalCode: '',
                isDefault: false
            }
        },
        onModalCopyAddress (val) {
            this.modalCopyAddress = true
            if(val == 'Shipping') {
                this.modalCopyAddressTitle = 'Select shipping address to copy'
                this.addressCopyLists = this.form.addressLists.filter(obj => obj.type == val)
            }
            if(val == 'Billing') {
                this.modalCopyAddressTitle = 'Select billing address to copy'
                this.addressCopyLists = this.form.addressLists.filter(obj => obj.type == val)
            }
        },
        onCopyAddress (i) {
            const address = this.addressCopyLists[i]
            let type = address.type == 'Billing' ? 'Shipping':'Billing'
            this.formAddress = {
                type: type,
                address: address.address,
                country: address.country,
                state: address.state,
                city: address.city,
                postalCode: address.postalCode,
                isDefault: false
            }
            this.modalCopyAddress = false
        }
    }
}
</script>