<template>
    <div class="border relative bg-white rounded-sm pt-0.5 pb-0" @click="onFocus" style="padding-left:46px">
        <div class="bg-gray-100 inline-flex items-center absolute h-full top-0 left-0 px-3">
            <i class="icon-price-tags"></i>
        </div>
        <span v-for="(tg, i) in tags" :key="i" :class="duplicate && duplicateText == tags[i] ? 'bg-green-400 text-white':'bg-gray-200'" class="hover:bg-red-400 hover:text-red-50 inline-flex items-center px-2 rounded-sm mr-0.5 mb-0.5" style="padding-top: 5px; padding-bottom: 5px">
            <span class="mr-2 cursor-default">{{tg}}</span>
            <a @click.prevent="removeTag(i)" href="#" class="flex items-center" :class="{'pointer-events-none': isDisabled}">
                <i class="icon-cross3"></i>
            </a>
        </span>
        <input :disabled="isDisabled" v-model="tag" @keydown="onAddTags" type="text" class="focus:outline-none pl-1 mb-0.5" placeholder="Add tag" ref="newtag" style="padding-top: 5px; padding-bottom: 5px">
    </div>
</template>

<script>
export default {
    props: {
        value: Array,
        isDisabled: Boolean
    },
    data () {
        return {
            tag: '',
            tags: this.value,
            duplicate: false,
            duplicateText: ''
        }
    },
    methods: {
        onFocus () {
            this.$refs.newtag.focus()
        },
        onAddTags(e) {
            if(e.keyCode === 13 || e.keyCode === 9 || e.keyCode === 188) {
                e.preventDefault()
                this.duplicate = this.tags.includes(this.tag)
                if(this.tags.includes(this.tag)) {
                    this.duplicateText = this.tag
                    this.tag = ''
                }
                if(this.tag) {
                    this.tags.push(this.tag)
                    this.tag = ''
                    this.duplicateText = ''
                    this.$refs.newtag.focus()
                }
            }
            if(e.keyCode === 8) {
                if(!this.tag) {
                    this.tags.splice(this.tags.length-1,1)
                }
            }
        },
        removeTag(i) {
            this.tags.splice(i,1)
        }
    },
    watch: {
        value (val) {
            this.tags = val
        }
    }
}
</script> 