<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search sales order or customer name">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filteres.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-white">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-400 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filteres" :key="i">{{filter}}
                                    <span v-show="filteres.length > 1 && filteres.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 block">
                    <button @click="showFilter = !showFilter" type="button" class="bg-white border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-50">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-7">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-white border mt-0.5 w-auto">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-100">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 pl-1 pr-3">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>
        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-green-600 border border-green-600">
                        <th class="py-2 pl-5 pr-2 text-green-100" style="width: 15%">SALES ORDER #</th>
                        <th class="py-2 px-2 text-green-100" style="width: 29%">CUSTOMER</th>
                        <th class="py-2 px-2 text-green-100 text-right" style="width: 14%">TOTAL</th>
                        <th class="py-2 px-2 text-green-100" style="width: 12%">INVOICE STATUS</th>
                        <th class="py-2 px-2 text-green-100" style="width: 11%">DATE CREATED</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="bg-white">
                    <tr v-for="i in 20" :key="i" class="border">
                        <td class="py-2.5 pl-5 pr-2 ph-item"><div class="h-4 w-28 bg-gray-200"></div></td>
                        <td class="py-2.5 px-2 ph-item"><div class="h-4 w-full bg-gray-200"></div></td>
                        <td class="py-2.5 px-2 ph-item"><div class="h-4 w-full bg-gray-200"></div></td>
                        <td class="py-2.5 px-2 ph-item"> <div class="h-4 w-full bg-gray-200"></div></td>
                        <td class="py-2.5 pl-2 pr-5 ph-item"><div class="h-4 w-full bg-gray-200"></div></td>
                    </tr>
                </tbody>
                <tbody v-if="!isLoading" class="bg-white">
                    <tr v-for="(sale, i) in sales" :key="i" class="border">
                        <td class="pl-5 pr-2 px-5">
                            <router-link @click.native="onDetail" :to="'/sales/detail/'+sale._id" class="text-blue-500">{{sale.salesNo}}</router-link>
                        </td>
                        <td class="py-2 px-2">
                            <div v-if="sale.customerId" class="turncate">{{sale.customer}}</div>
                        </td>
                        <td class="py-2 px-2 text-right">
                            {{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(sale.grandTotal)}}
                        </td>
                        <td class="py-2 px-2">
                            <div v-if="sale.invoiceStatus == 'To Invoice'" class="text-xs font-medium bg-blue-400 text-blue-50 inline-block rounded-sm px-1 py-px">{{sale.invoiceStatus}}</div>
                            <div v-if="sale.invoiceStatus == 'Fully Invoiced'" class="text-xs font-medium bg-green-600 text-green-50 inline-block rounded-sm px-1 py-px">{{sale.invoiceStatus}}</div>
                            <div v-if="sale.invoiceStatus == 'Nothing to Invoice'" class="text-xs font-medium bg-gray-400 text-gray-50 inline-block rounded-sm px-1 py-px">{{sale.invoiceStatus}}</div>
                        </td>
                        <td class="py-2 pl-2 pr-5">{{getDate(sale.salesCreated)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5 text-right">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import paginate from '../../../components/paginate.vue'
import debounce from 'lodash.debounce'
import { moment } from '../../../moment'
import {EventBus} from '../../../event-bus'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
export default {
    components: {paginate},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isLoading: false,
            search: '',
            showFilter: false,
            filterStatus: [
                {status: false, text: 'To Invoice'},
                {status: false, text: 'Nothing to Invoice'},
            ],
            sales: [],
            pages: '',
            searcData: '',
            page: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.setDisabled(false)
        this.setButtonText('CREATE')
        this.setDiscard(false)
        this.setBreadcrumbs([
            {text: 'Sales Orders'}
        ])
        document.addEventListener('click', this.closeFilter)
        EventBus.$on('onClick', this.newQuotation)
        this.getData()
    },
    destroyed () {
        this.setButtonText()
        EventBus.$off('onClick', this.newQuotation)
        document.removeEventListener('click', this.closeFilter)
    },
    computed: {
        filteres () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },
    methods: {
        getData (e) {
            this.page = e
            let a = localStorage.getItem('salesOrder')
            this.isLoading = true
            window.scrollTo(0, 0)
            let params = JSON.parse(a)
            if(params && !e) {
                this.search = params.search
                let index = this.filterStatus.findIndex(obj => {
                    return obj.text == params.filter
                })
                if(index > -1) {
                    this.filterStatus[index].status = true
                }
                axios.get('/sales', {
                    params: params
                })
                .then(res => {
                    this.isLoading = false
                    this.sales = res.data.data
                    this.pages = res.data.pages
                })
            } else {
                axios.get('/sales', {
                    params: {
                        page: e,
                        search: this.search,
                        filter: this.filteres
                    }
                })
                .then(res => {
                    this.isLoading = false
                    this.sales = res.data.data
                    this.pages = res.data.pages
                })
            }
        },
        onDetail () {
            let obj = {
                page: this.page,
                search: this.search,
                filter: this.filteres
            }
            localStorage.setItem('salesOrder',JSON.stringify(obj))
        },
        getDate (d) {
            return moment(d)
        },
        newQuotation () {
            this.$router.push('/quotations/new')
        },
        clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        }
    },
    watch: {
        search () {
            let a = localStorage.getItem('salesOrder')
            if(!a) {
                this.searchData()
            }
            localStorage.removeItem('salesOrder')
        },
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                    localStorage.removeItem('salesOrder')
                })
            },  
            deep: true
        }
    },
    beforeRouteLeave (to, from , next) {
        if(to.name == 'Sales detail') {
            this.replaceBreadcrumb({
                find: 'Sales Orders',
                replace: {text: 'Sales Orders', path: this.$route.fullPath}
            })
        } else {
            localStorage.removeItem('salesOrder')
        }
        next()
    }
}
</script>