<template>
    <div class="m-5">
        <form @submit.prevent="onSubmit">
            <div class="grid grid-cols-2 gap-5">
                <!-- Company Logo -->
                <div class="col-span-1">
                    <legend class="border-b pb-3 pt-2 font-semibold mb-5"><i class="icon-image2 mr-3"></i>Company Logo</legend>
                    <div class="relative w-48 h-48">
                        <div class="border-2 border-dashed rounded-sm w-full h-full">
                            <div v-if="!logo" class="flex w-full h-full items-center justify-center">
                                <div class="text-gray-300">
                                    Upload Company Logo
                                </div>
                            </div>
                            <img :src="logo" alt="">
                        </div>
                        <div class="absolute flex items-center justify-center bg-black top-0 bottom-0 left-0 right-0 w-full transition duration-200 rounded-sm opacity-0 bg-opacity-0 hover:bg-opacity-80 hover:opacity-100">
                            <a @click.prevent="modal=true" href="#" class="bg-transparent border-2 rounded-full px-2 py-1 text-white hover:text-gray-500 hover:bg-white hover:border-white">Change</a>
                        </div>
                    </div>
                </div>
                <!-- /end company Logo -->

                <!-- General Details -->
                <div class="col-span-1">
                    <legend class="border-b pb-3 pt-2 font-semibold mb-5"><i class="icon-info22 mr-3"></i>General details</legend>
                    <div class="grid grid-cols-6 mb-5">
                        <div class="col-span-2 mt-2">
                            <label for="">COMPANY NAME <span class="text-red-500">*</span></label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.name" @keydown="formError.name=''" :class="{'border-red-500': formError.name}" type="text" class="w-full px-3 h-9 border-b focus:outline-none focus:border-teal-500 focus:bg-gray-50" placeholder="Company name">
                            <div class="text-red-500 mt-1">{{formError.name}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-6 items-center mb-5">
                        <div class="col-span-2">
                            <label for="">TAGLINE</label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.tagline" type="text" class="w-full px-3 h-9 border-b focus:outline-none focus:border-teal-500 focus:bg-gray-50" placeholder="Tagline">
                        </div>
                    </div>
                    <div class="grid grid-cols-6 mb-5">
                        <div class="col-span-2 mt-2">
                            <label for="">DESCRIPTION</label>
                        </div>
                        <div class="col-span-4">
                            <textarea v-model="form.description" rows="4" class="w-full px-3 py-2 border focus:outline-none" placeholder="description"></textarea>
                        </div>
                    </div>
                </div>
                <!-- /end general details -->
            </div>
            <legend class="border-b pb-3 font-semibold mb-5"><i class="icon-address-book mr-3"></i>Contact Information</legend>
            <div class="grid grid-cols-2 mb-5 gap-5" style="min-height: 100px">
                <div class="col-span-1">
                    <div class="grid grid-cols-6 mb-5" style="min-height:40px;">
                        <div class="col-span-2">
                            COMPANY ADDRESS
                        </div>
                        <div class="col-span-4">
                            <a v-show="!form.companyAddress.address" @click.prevent="openModalAddress('Company Address','new')" href="#" class="text-blue-500">+COMPANY ADDRESS</a>
                            <div v-show="form.companyAddress.address" class="flex items-center justify-between">
                                <div>{{form.companyAddress.address}}, {{form.companyAddress.subdistrict.name}}, {{form.companyAddress.city.name}}, {{form.companyAddress.province.name}}, {{form.companyAddress.country}} {{form.companyAddress.postal_code}}</div>
                                <div class="ml-4 mr-7">
                                    <a @click="openModalAddress('Company Address', 'edit')" href="#" class="hover:text-blue-500"><i class="icon-pencil"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-6 mb-5" style="min-height:40px;">
                        <div class="col-span-2">
                            SHIPPING ADDRESS
                        </div>
                        <div class="col-span-4">
                            <a v-show="!form.shippingAddress.address" @click.prevent="openModalAddress('Shipping Address','new')" href="#" class="text-blue-500">+SHIPPING ADDRESS</a>
                            <div v-show="form.shippingAddress.address" class="flex items-center justify-between">
                                <div>{{form.shippingAddress.address}}, {{form.shippingAddress.subdistrict.name}}, {{form.shippingAddress.city.name}}, {{form.shippingAddress.province.name}}, {{form.shippingAddress.country}} {{form.shippingAddress.postal_code}}</div>
                                <div class="ml-4 mr-7">
                                    <a @click="openModalAddress('Shipping Address', 'edit')" href="#" class="hover:text-blue-500"><i class="icon-pencil"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-6">
                        <div class="col-span-2">
                            BILLING ADDRESS
                        </div>
                        <div class="col-span-4">
                            <a v-show="!form.billingAddress.address" @click.prevent="openModalAddress('Billing Address','new')" href="#" class="text-blue-500">+BILLING ADDRESS</a>
                            <div v-show="form.billingAddress.address" class="flex items-center justify-between">
                                <div>{{form.billingAddress.address}}, {{form.billingAddress.subdistrict.name}}, {{form.billingAddress.city.name}}, {{form.billingAddress.province.name}}, {{form.billingAddress.country}} {{form.billingAddress.postal_code}}</div>
                                <div class="ml-4 mr-7">
                                    <a @click="openModalAddress('Billing Address', 'edit')" href="#" class="hover:text-blue-500"><i class="icon-pencil"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-1">
                    <div class="grid grid-cols-6 items-center mb-5">
                        <div class="col-span-2">
                            <label for="">PHONE</label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.phone" type="text" class="h-9 px-3 w-full border-b focus:outline-none focus:bg-gray-50 focus:border-teal-500" placeholder="Phone">
                        </div>
                    </div>
                    <div class="grid grid-cols-6 items-center mb-5">
                        <div class="col-span-2">
                            <label for="">FAX</label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.fax" type="text" class="h-9 px-3 w-full border-b focus:outline-none focus:bg-gray-50 focus:border-teal-500" placeholder="Fax">
                        </div>
                    </div>
                    <div class="grid grid-cols-6 items-center mb-5">
                        <div class="col-span-2">
                            <label for="">EMAIL</label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.email" type="text" class="h-9 px-3 w-full border-b focus:outline-none focus:bg-gray-50 focus:border-teal-500" placeholder="Email">
                        </div>
                    </div>
                    <div class="grid grid-cols-6 items-center">
                        <div class="col-span-2">
                            <label for="">WEBSITE</label>
                        </div>
                        <div class="col-span-4">
                            <input v-model="form.website" type="text" class="h-9 px-3 w-full border-b focus:outline-none focus:bg-gray-50 focus:border-teal-500" placeholder="Website (example: http://myweb.com)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="submit" class="bg-blue-500 px-5 text-blue-50 h-9 rounded-sm">Save</button>
            </div>
        </form>

        <!-- Modal upload logo -->
        <modal :show="modal">
            <div class="w-1/2 bg-white border rounded-sm">
                <div class="flex p-5 items-center">
                    <div class="text-lg">Company Logo</div>
                </div>
                <div v-show="image">
                    <div ref="croppie"></div>
                </div>
                <div class="pb-5 flex justify-center">
                    <div class="relative text-white flex items-center bg-teal-600 h-9 w-auto rounded-sm">
                        <i class="icon-file-plus mx-3"></i>
                        <div class="mr-3">Browse file image</div>
                        <input ref="imageInput" @change="onFileChange" type="file" class="absolute border w-full h-9 opacity-0 cursor-pointer" accept=".jpg, .png">
                    </div>
                </div>
                <div class="text-right py-3 px-5 bg-gray-100">
                    <button type="button" @click="closeModal" class="bg-red-500 h-9 text-white px-5 rounded-sm focus:outline-none mr-2 hover:bg-red-600">Close</button>
                    <button type="button" @click="uploadImage" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!image ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!image">Save</button>
                </div>
            </div>
        </modal>
        <!-- /end modal upload log -->

        <!-- Modal address -->
        <modal :show="modalAddress">
            <div class="w-3/5 bg-white border rounded-sm p-5">
                <div class="text-center text-lg font-semibold mb-5 relative">
                    {{modalTitleAddress}}
                    <div class="absolute top-0 right-0">
                        <a @click="modalAddress = false" href="#" class="text-red-500 hover:text-red-600 text-2xl">×</a>
                    </div>
                </div>
                <form @submit.prevent="onSubmitModalAddress">
                    <div class="mb-5">
                        <label for="">ADDRESS</label>
                        <input ref="address" @keydown.tab="$refs.province.open()" v-model="formModalAddress.address"  type="text" class="h-9 mt-2 px-3 w-full border-b focus:outline-none focus:border-teal-500 focus:bg-gray-50" placeholder="Address">
                        <div v-if="formModalAddressError.address" class="text-red-500 mt-1">{{formModalAddressError.address}}</div>
                    </div>
                    <div class="mb-5">
                        <div class="grid grid-cols-3 gap-5">
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">PROVINCE</label>
                                </div>
                                <div class="flex flex-col">
                                    <select-2 ref="province" v-model="formModalAddress.province.id" @input="getCities(formModalAddress.province.id)" :options="provinces" :placeholder="'Select Province'">
                                        <option value=""></option>
                                    </select-2>
                                    <div v-if="formModalAddressError.province.id" class="text-red-500 mt-1">{{formModalAddressError.province.id}}</div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">CITY</label>
                                </div>
                                <div class="flex flex-col">
                                    <select-2 ref="cities" v-model="formModalAddress.city.id" :options="cities" @input="getSubdistricts(formModalAddress.city.id)" :placeholder="'Select Regency'">
                                        <option value=""></option>
                                    </select-2>
                                    <div v-if="formModalAddressError.city.id" class="text-red-500 mt-1">{{formModalAddressError.city.id}}</div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="mb-2">
                                    <label for="">SUBDISTRICT</label>
                                </div>
                                <div class="flex flex-col">
                                    <select-2 ref="subdistricts" v-model="formModalAddress.subdistrict.id" @input="$refs.countries.open()" :options="subdistricts" :placeholder="'Select District'">
                                        <option value=""></option>
                                    </select-2>
                                    <div v-if="formModalAddressError.subdistrict.id" class="text-red-500 mt-1">{{formModalAddressError.subdistrict.id}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-5 mb-5">
                        <div class="col-span-1">
                            <div class="mb-2">
                                <label for="">COUNTRY</label>
                            </div>
                            <div class="flex flex-col">
                                <select-2 ref="countries" v-model="formModalAddress.country" :options="countries" :placeholder="'Select Country'">
                                    <option value=""></option>
                                </select-2>
                                <div v-if="formModalAddressError.country" class="text-red-500 mt-1">{{formModalAddressError.country}}</div>
                            </div>
                        </div>
                        <div class="col-span-1">
                            <label for="">POSTAL CODE</label>
                            <input v-model="formModalAddress.postal_code" type="text" class="mt-2 px-3 h-9 border-b focus:outline-none focus:border-teal-500 focus:bg-gray-50 w-full" placeholder="Postal Code">
                            <div v-if="formModalAddressError.postal_code" class="text-red-500 mt-1">{{formModalAddressError.postal_code}}</div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="text-gray-400 text-xs">*Remember to click "Save" on bottom right corner to save company address</div>
                        <button type="submit" class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50 hover:bg-teal-600">Done</button>
                    </div>
                </form>
            </div>
        </modal>
        <alert ref="alert"></alert>
    </div>
</template>

<script>
import Select2 from '../../components/Select2.vue'
import axios from 'axios'
import Modal from '../../components/Modal.vue'
import Croppie from 'croppie'
import 'croppie/croppie.css'
import Countries from '../../countries.json'
import Alert  from '../../components/alert.vue'
import {mapActions} from 'vuex'
export default {
    components: {
        Modal,
        Select2,
        Alert
    },
    data () {
        return {
            alert: false,
            logo: false,
            modal: false,
            modalAddress: false,
            modalTitleAddress: '',
            modalAddresMode: '',
            image: '',
            croppie: '',
            countries: [],
            provinces: [],
            cities: [],
            subdistricts: [],
            formModalAddress: {
                address: '',
                province: {
                    id: '',
                    name: '',
                },
                city: {
                    id: '',
                    name: ''
                },
                subdistrict: {
                    id: '',
                    name: ''
                },
                country: '',
                postal_code: ''
            },
            formModalAddressError: {
                address: '',
                 province: {
                    id: '',
                    name: '',
                },
                city: {
                    id: '',
                    name: ''
                },
                subdistrict: {
                    id: '',
                    name: ''
                },
                country: '',
                postal_code: ''
            },
            form: {
                name: '',
                tagline: '',
                description: '',
                phone: '',
                email: '',
                website: '',
                companyAddress: {
                    address: '',
                    province: {
                        id: '',
                        name: '',
                    },
                    city: {
                        id: '',
                        name: ''
                    },
                    subdistrict: {
                        id: '',
                        name: ''
                    },
                    country: '',
                    postal_code: ''
                },
                shippingAddress: {
                    address: '',
                    province: {
                        id: '',
                        name: '',
                    },
                    city: {
                        id: '',
                        name: ''
                    },
                    subdistrict: {
                        id: '',
                        name: ''
                    },
                    country: '',
                    postal_code: ''
                },
                billingAddress: {
                    address: '',
                    province: {
                        id: '',
                        name: '',
                    },
                    city: {
                        id: '',
                        name: ''
                    },
                    subdistrict: {
                        id: '',
                        name: ''
                    },
                    country: '',
                    postal_code: ''
                }
            },
            formError: {
                name: '',
            }
        }
    },
    mounted () {
        for (let i = 0; i < Countries.length; i++) {
            this.countries.push({id: Countries[i].name, text: Countries[i].name})
        }
        this.getData()
        this.getProvince()
        this.initCroppie()
        this.checkImageExists()
    },
    methods: {
        ...mapActions({
            company: 'auth/company'
        }),
        getData () {
            axios.get('/setting/company')
            .then(res => {
                if(res.data) {
                    this.form = res.data
                }
            })
        },
        checkImageExists () {
            const img = new Image()
            const ulrImage = process.env.VUE_APP_ROOT_API+'/public/img/company-logo.png?'+Date.now()
            img.src = ulrImage
            if(img.complete) {
                this.logo = ulrImage
            } else {
                img.onload = () => {
                    this.logo = ulrImage
                }
                img.onerror = () => {
                    this.logo = ''
                }
            }
        },
        onSubmit () {
            if(!this.form.name) {
                this.formError.name = 'Company name is required'
            }
            if(this.form.name) {
               axios.post('/setting/company', this.form)
               .then(res => {
                    this.form = res.data
                    this.$refs.alert.open('success', 'Company setting has been successfully updated')
                    this.company()
               })
            }
        },
        openModalAddress(title, mode) {
            this.modalAddresMode = mode
            this.modalAddress = true
            this.modalTitleAddress = title
            if(mode == 'new') {
                this.formModalAddress = {
                    address: '',
                    province: {
                        id: '',
                        name: '',
                    },
                    city: {
                        id: '',
                        name: ''
                    },
                    subdistrict: {
                        id: '',
                        name: ''
                    },
                    country: '',
                    postal_code: ''
                }
                this.formModalAddressError = {
                    address: '',
                    province: {
                        id: '',
                        name: '',
                    },
                    city: {
                        id: '',
                        name: ''
                    },
                    subdistrict: {
                        id: '',
                        name: ''
                    },
                    country: '',
                    postal_code: ''
                }
            }
            if(mode == 'edit') {
                if(this.modalTitleAddress == 'Company Address') {
                    this.updateModalAddress(this.form.companyAddress)
                }
                if(this.modalTitleAddress == 'Shipping Address') {
                   this.updateModalAddress(this.form.shippingAddress)
                }
                if(this.modalTitleAddress == 'Billing Address') {
                    this.updateModalAddress(this.form.billingAddress)
                }
            }
            this.$nextTick(() => {
                this.$refs.address.focus()
            })
        },
        async updateModalAddress (form) {
                let provinceId = form.province.id
                let cityId = form.city.id
                this.cities = []
                this.subdistricts = []
                await axios.get('/indonesia/cities/'+provinceId)
                .then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        this.cities.push({id: res.data[i]._id, text: res.data[i].name})
                    }
                })
                await axios.get('/indonesia/subdistricts/'+cityId)
                .then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        this.subdistricts.push({id: res.data[i]._id, text: res.data[i].name})
                    }
                })
                this.$refs.cities.clear()
                this.$refs.subdistricts.clear()
                this.$nextTick(() => {
                    this.formModalAddress = form
                })
            },
        onSubmitModalAddress () {
            if(!this.formModalAddress.address) {
                this.formModalAddressError.address = 'Addres is required'
            }
            if(!this.formModalAddress.province.id) {
                this.formModalAddressError.province.id = 'Province is rquired'
            }
            if(!this.formModalAddress.city.id) {
                this.formModalAddressError.city.id = 'City is required'
            }
            if(!this.formModalAddress.subdistrict.id) {
                this.formModalAddressError.subdistrict.id = 'Subdistrict is required'
            }
            if(!this.formModalAddress.country) {
                this.formModalAddressError.country = 'Country is required'
            }
            if(!this.formModalAddress.postal_code) {
                this.formModalAddressError.postal_code = 'Postal code is required'
            }
            if(
                this.formModalAddress.address &&
                this.formModalAddress.province.id &&
                this.formModalAddress.city.id && 
                this.formModalAddress.subdistrict.id && 
                this.formModalAddress.country && 
                this.formModalAddress.postal_code) {
                    this.modalAddress = false
                    if(this.modalTitleAddress == 'Company Address') {
                        this.form.companyAddress = this.formModalAddress
                    }
                    if(this.modalTitleAddress == 'Billing Address') {
                        this.form.billingAddress = this.formModalAddress
                    }
                    if(this.modalTitleAddress == 'Shipping Address') {
                        this.form.shippingAddress = this.formModalAddress
                    }
                    this.$nextTick(() => {
                        this.formModalAddress = {
                            address: '',
                            province: {
                                id: '',
                                name: '',
                            },
                            city: {
                                id: '',
                                name: ''
                            },
                            subdistrict: {
                                id: '',
                                name: ''
                            },
                            country: '',
                            postal_code: ''
                        }
                    })
                }
        },
        getProvince () {
            axios.get('/indonesia/provinces')
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    this.provinces.push({id: res.data[i]._id, text: res.data[i].name})
                }
            })
        },
        getCities (provinceId) {
            this.formModalAddress.city.id = ''
            this.formModalAddress.subdistrict.id = ''
            this.cities = []
            this.subdistricts = []
            axios.get('/indonesia/cities/'+provinceId)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    this.cities.push({id: res.data[i]._id, text: res.data[i].name})
                }
                this.$nextTick(() => {
                    this.$refs.cities.open()
                })
            })
        },
        getSubdistricts (citiesId) {
            this.subdistricts = []
            axios.get('/indonesia/subdistricts/'+citiesId)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    this.subdistricts.push({id: res.data[i]._id, text: res.data[i].name})
                }
                this.$nextTick(() => {
                    this.$refs.subdistricts.open()
                })
            })
        },
        initCroppie () {
            let el = this.$refs.croppie
            let opt = {
                viewport: {
                    width: 250,
                    height: 250,
                },
                boundary: {
                    width: 300,
                    height: 300
                },
            }
            this.croppie = new Croppie(el, opt)
        },
        onFileChange (e) {
            const file = e.target.files[0]
            if(file) {
                this.image = URL.createObjectURL(file)
            }
            this.croppie.bind(this.image)
        },
        uploadImage() {
            this.uploadLoading = true
            this.croppie.result('blob', {format: 'png'})
            .then(output => {
                const formData = new FormData();
                formData.append('image', output)
                axios.post('/setting/upload-company-logo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.uploadLoading = false
                    this.logo = process.env.VUE_APP_ROOT_API+'/'+res.data.img +'?'+ Date.now()
                    this.modal = false
                })
            })
        },
        closeModal () {
            this.$refs.imageInput.value = ''
            this.modal = false
            setTimeout(()=> {
                this.image = ''
            }, 100)
        }
    },
    watch: {
        'formModalAddress.address': function (val) {
            if(val) {
                this.formModalAddressError.address = ''
            }
        },
        'formModalAddress.province.id': function (val) {
            if(val) {
                this.formModalAddressError.province.id = ''
                let prov = ''
                prov = this.provinces.find(pro => pro.id == val)
                this.formModalAddress.province.name = prov.text
            }
        },
        'formModalAddress.city.id': function (val) {
            if(val) {
                this.formModalAddressError.city.id = ''
                let cit = ''
                cit = this.cities.find(ct => ct.id == val)
                this.formModalAddress.city.name = cit.text
            }
        },
        'formModalAddress.subdistrict.id': function (val) {
            if(val) {
                this.formModalAddressError.subdistrict.id = ''
                let subd = ''
                subd = this.subdistricts.find(sub => sub.id == val)
                this.formModalAddress.subdistrict.name = subd.text
            }
        },
        'formModalAddress.country': function (val) {
            if(val) {
                this.formModalAddressError.country = ''
            }
        },
        'formModalAddress.postal_code': function (val) {
            if(val) {
                this.formModalAddressError.postal_code = ''
            }
        }
    }
}
</script>