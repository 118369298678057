<template>
    <div>
        <table class="w-full relative border border-b-0 bg-white">
            <thead class="border border-blue-500">
                <tr class="bg-blue-500 border-b">
                    <th class="py-2.5 pl-5 pr-2  sticky z-20 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                    <th class="py-2.5 px-2 sticky z-20 text-blue-50 bg-blue-500" style="width: 43%; top: 93px">ITEM</th>
                    <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 12%; top: 93px">UNIT PRICE</th>
                    <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 7%; top: 93px">QTY</th>
                    <th v-show="status" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 7%; top: 93px">RECEIVED</th>
                    <th class="py-2.5 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                    <th v-show="status" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 7%; top: 93px">BILLED</th>
                    <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-center bg-blue-500" style="width: 11%; top: 93px">DISCOUNT</th>
                    <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 13%; top: 93px">TOTAL</th>
                    <th class="py-2.5 pl-2 pr-5 sticky z-20 text-blue-50 bg-blue-500" style="widt: 2%; top: 93px"></th>
                </tr>
            </thead>
            <tbody ref="tbody" class="divide-y border-b">
                <tr v-for="(item, i) in products" :key="item.idx" @click="onEdit(i)" @mouseenter="item.hover = true" @mouseleave="item.hover = false" class="group cursor-pointer" :class="[{'hover:bg-white': item.edited},{'hover:bg-gray-100': item.hover}]">
                    <td :ref="'td'+i" class="pl-5 h-9 border-r relative" :class="{'bg-gray-100': item.edited}">
                        <div v-show="item.edited" :ref="'tick'+i" class="absolute -left-px -top-px w-2 bg-teal-500"></div>
                        {{i+1}}
                    </td>
                    <td class="h-9 border-r border-transparent" :class="{'border-gray-200': item.edited}">
                        <auto-search :isDisabled="item.status" :ref="'autosearch'+i" v-model="item.productId" @change="onSelectProduct($event, i)" :hover="item.hover && !item.edited" :params="productIds" :text="true" :label="item.name" :url="'/quotations/product'" :placeholder="'Search product by name or sku'">
                            <template #default="item">
                                <div class="flex items-center">
                                    <img :src="imgURL+'/public/img/products/200/'+item.item.images[0]" width="50" alt="">
                                    <div class="ml-3">
                                        <h6 class="font-medium">{{item.item.name}}</h6>
                                        <div class="text-gray-400">{{item.item.sku}}</div>
                                    </div>
                                </div>
                            </template>
                        </auto-search>
                    </td>
                    <td class="h-9 border-r border-transparent text-right" :class="{'border-gray-200': item.edited}">
                        <autonumeric 
                            :disabled="isDisabled"
                            :ref="'unitPrice'+i" 
                            v-model="item.unitPrice" 
                            @keydown.native.enter="onEnterUnitPrice(i)"
                            :options="{currencySymbol: currencySymbol,modifyValueOnWheel : false}" 
                            class="h-9 px-2 w-full text-right outline-none cursor-pointer" 
                            :class="[{'group-hover:bg-white': item.edited},{'group-hover:bg-gray-100':item.hover}]" 
                            :placeholder="'unit price'">
                        </autonumeric>
                    </td>
                    <td class="h-9 border-r border-transparent text-right" :class="{'border-gray-200': item.edited}">
                        <popover-stock :ref="'stock'+i" :productId="item.productId">
                            <autonumeric
                                :disabled ="isDisabled"
                                :ref="'qty'+i"
                                v-model="item.qty" 
                                @focus.native="$refs['stock'+i][0].onFocus()"  
                                @blur.native="$refs['stock'+i][0].onFocusOut()" 
                                @keydown.native.enter="onEnterQty(i)"
                                :options="['integer', {modifyValueOnWheel : false}]" 
                                class="h-9 px-2 w-full outline-none text-right cursor-pointer" 
                                :class="[{'group-hover:bg-white': item.edited}, {'group-hover:bg-gray-100': item.hover}]"
                                :placeholder="'qty'">
                            </autonumeric>
                        </popover-stock>
                    </td>
                    <td v-show="status" class="h-9 px-2 border-r border-transparent text-right" :class="{'border-gray-200': item.edited}">
                        {{item.delivered}}
                    </td>
                    <td class="h-9 px-1 border-r border-transparent text-center" :class="{'border-gray-200': item.edited}">
                        <a href="#"><i class="icon-chart"></i></a>
                    </td>
                    <td v-show="status" class="h-9 px-2 border-r border-transparent text-right" :class="{'border-gray-200': item.edited}">
                        {{item.invoiced}}
                    </td>
                    <td class="h-9 border-r border-transparent text-center" :class="{'border-gray-200': item.edited}">
                        <vue-discount v-model="item.discount" :isDisabled="isDisabled" :total="item.total" :idx="item.idx" :symbol="currencySymbol" :label="'Discount'"></vue-discount>
                    </td>
                    <td class="px-2 h-9 border-r border-transparent text-right" :class="{'border-gray-200': item.edited}">
                        {{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}
                    </td>
                    <td class="pl-2 pr-3 h-9 text-right">
                        <a @click.prevent.stop="onDeleteItem(i)" href="#" class="text-red-400 hover:text-red-500" :class="{'pointer-events-none': isDisabled || status}"><i class="icon-cancel-circle2"></i></a>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td></td>
                    <td colspan="2" rowspan="2" class="text-top align-top ">
                        <button :disabled="isDisabled" ref="addItem" @click="onAddItem" class="px-2 h-9 text-teal-500 hover:text-teal-600">+ Add Item</button>
                    </td>
                    <td class="px-2 h-7 text-right ">
                        {{totalQty}}
                    </td>
                    <td v-show="status"></td>
                    <td v-show="status"></td>
                    <td></td>
                    <td class="px-2 h-7 text-right">
                        Subtotal
                    </td>
                    <td class="px-2 h-7 font-medium text-right">
                        {{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(subTotal)}}
                    </td>
                </tr>
                <slot></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
import autoSearch from '../components/autoSearch.vue'
import Autonumeric from '../components/Autonumeric.vue'
import VueDiscount from '../components/discount.vue'
import popoverStock from '../components/popoverStock.vue'
export default {
    props: ['value', 'isDisabled', 'status', 'currencySymbol'],
    components: { autoSearch, Autonumeric, VueDiscount, popoverStock },
    data () {
        return {
            hover: false,
            imgURL: process.env.VUE_APP_ROOT_API,
        }
    },
    mounted () {
        window.addEventListener('click', this.onCloseEdit)
    },
    destroyed () {
        window.removeEventListener('click', this.onCloseEdit)
    },
    computed: {
        productIds () {
            let id = []
            for (let i = 0; i < this.value.length; i++) {
                id.push(this.value[i].productId)
            }
            return id
        },
        products () {
            return this.value.map(obj => {
                obj.total = obj.unitPrice * obj.qty
                obj.subTotal = obj.total - obj.discount.value
                return obj
            })
        },
        totalQty () {
            let total = 0
            for (let i = 0; i < this.value.length; i++) {
                total += this.value[i].qty
            }
            return total
        },
        subTotal () {
            let total = 0
            for (let i = 0; i < this.value.length; i++) {
                total += this.value[i].subTotal
            }
            return total
        },
    },
    methods: {
        onAddItem () {
            for (const item of this.value) {
                item.edited = false
            }
            this.clearItem()
            this.value.push({idx: Date.now(), productId: '', name: '', isSerialNumber: false, unitPrice: '', stock: '', qty: '', delivered: 0, invoiced: 0, total: 0, discount: {discountType: '', amount: '', value: ''}, subTotal: '', hover: false, edited: false})
            this.$nextTick(() => {
                let index = this.value.length -1
                this.$refs['autosearch'+index][0].onFocus()
                this.$nextTick(() => {
                    this.value[index].edited = true
                })
                let h = this.$refs['td'+index][0].clientHeight +2
                this.$refs['tick'+index][0].style.height = h+'px'
            })
        },
        onDeleteItem (i) {
            this.value.splice(i, 1)
        },
        onSelectProduct (e, i) {
            this.value[i].name = e.name
            this.value[i].isSerialNumber = e.isSerialNumber
            this.value[i].unitPrice = e.purchasePrice
            this.value[i].qty = 1
            this.value[i].stock = e.stock
            this.$nextTick(() => {
                this.$refs['unitPrice'+i][0].$el.focus()
                this.$refs['unitPrice'+i][0].$el.select()
            })
        },
        onEnterUnitPrice (i) {
            this.$refs['qty'+i][0].$el.focus()
        },  
        onEnterQty (i) {
            let index = i+1
            if(this.value[i].qty) {
                for (const item of this.value) {
                    item.edited = false
                    item.hover = false
                }
                if(this.value.length == index) {
                    this.onAddItem()
                } else {
                    this.value[index].edited = true
                    this.$refs['autosearch'+index][0].onFocus()
                }
            }
        },
        onEdit (i) {
            for (const item of this.value) {
                item.edited = false
            }
            this.value[i].edited = true
            let h = this.$refs['td'+i][0].clientHeight +2
            this.$refs['tick'+i][0].style.height = h+'px'
            this.clearItem()
        },
        clearItem () {
            let itemLength = this.value.length
            for (let i = 0; i < itemLength; i++) {
                let reverseIndex = (itemLength-1) - i
                if(this.value[reverseIndex].productId == '' && this.value.length > 1 && this.value[reverseIndex].edited == false) {
                    this.value.splice(reverseIndex, 1)
                }
            }
        },
        onCloseEdit (e) {
            if(!e || !this.$refs.tbody.contains(e.target) && this.$refs.addItem != e.target) {
                for (const item of this.value) {
                    item.edited = false
                }
                this.clearItem()
            }
        },
    },
}
</script>