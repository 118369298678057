<template>
    <div class="fixed flex flex-wrap justify-between px-5 items-stretch bg-white z-40 inset-x-0">
		<div class="-ml-5 mr-5 bg-black text-gray-50 flex items-center border-b border-gray-800">
			<div class="ml-5 py-2.5 w-60">
				<div class="flex items-center">
					<a href="" class="iniline-block">
						<img src="@/assets/logo_dutapersada.png" class="block h-4" alt="">
					</a>
					<a href="" class="ml-auto h-6 w-6 text-white border-white flex items-center justify-center border-2 rounded-full mr-5">
						<i class="icon-plus3"></i>
					</a>
				</div>
			</div>
		</div>
		<div class="flex flex-grow items-center px-5 border-b border-gray-800 -mx-5 text-red-50" style="background-color: #262c34">
			<ul class="flex felx-row">
				<li class="border-r border-gray-700">
					<dropdown>
						<a @click.prevent="getActivities" href="#" class="flex items-center h-12 w-40">
							<div class="mr-3">
								RECENT ACTIVITY
							</div>
							<div v-if="activityNotif > 0" class="h-5 min-w-min w-5 p-1 rounded-full bg-red-500 flex items-center justify-center text-red-50 top-0 -right-2.5 text-xs font-medium" style="font-size: 11px;">{{activityNotif}}</div>
						</a>
						<template #dropdown>
							<ul v-if="isLoading" class="max-h-96 overflow-y-scroll text-gray-500">
								<li v-for="p in 7" :key="p" class="border-b flex items-center px-5 py-3 ph-item">
									<div class="mr-3 h-9 w-9 bg-gray-200 rounded-full">

									</div>
									<div class=" w-64">
										<div class="h-3 mb-1 bg-gray-200"></div>
										<div class="h-3 w-52 mb-1 bg-gray-200"></div>
									</div>
								</li>
							</ul>
							<ul v-if="!isLoading" class=" max-h-96 overflow-y-scroll text-gray-500">
								<li v-for="(activity, i) in activities" :key="i" class="border-b flex items-center px-5 py-3">
									<!-- for created -->
									<div v-if="activity.event == 'insert'"  class="flex items-center">
										<div class="mr-3 h-9 w-9 bg-green-500 flex items-center justify-center rounded-full">
											<i class="icon-plus22 text-green-50" style="font-size: 20px"></i>
										</div>
										<div>
											<div class="flex items-center mb-1">
												<div class="mr-1">{{activity.document}}</div>
												<router-link @click.native="onSetBreadcrumb(activity, '/qotations')" v-if="activity.document == 'Quotations'" :to="'/quotations/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<router-link @click.native="onSetBreadcrumb(activity, '/sales')" v-if="activity.document == 'Sales Orders'" :to="'/sales/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<router-link @click.native="onSetBreadcrumb(activity, '/delivery-orders')" v-if="activity.document == 'Delivery Orders'" :to="'/delivery-orders/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<div class="ml-1">is created</div>
											</div>
											<div class="text-xs">{{activity.userId.name}} - <span>{{getDate(activity.createdAt)}}</span></div>
										</div>
									</div>

									<!-- for edited -->
									<div v-if="activity.event == 'update'" class="flex items-center">
										<div class="mr-3 h-9 w-9 bg-blue-500 flex items-center justify-center rounded-full">
											<i class="icon-pencil4 text-green-50"></i>
										</div>
										<div>
											<div class="flex items-center mb-1">
												<div class="mr-1">{{activity.document}}</div>
												<router-link @click.native="onSetBreadcrumb(activity, '/quotations')"  v-if="activity.document == 'Quotations'" :to="'/quotations/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<router-link @click.native="onSetBreadcrumb(activity, '/sales')"  v-if="activity.document == 'Sales Orders'" :to="'/sales/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<div class="ml-1">is edited</div>
											</div>
											<div class="text-xs">{{activity.userId.name}} - <span>{{getDate(activity.createdAt)}}</span></div>
										</div>
									</div>

									<!-- for cancelled -->
									<div v-if="activity.event == 'cancelled'" class="flex items-center">
										<div class="mr-3 h-9 w-9 bg-red-500 flex items-center justify-center rounded-full">
											<i class="icon-cross3 text-green-50" style="font-size: 20px"></i>
										</div>
										<div>
											<div class="flex items-center mb-1">
												<div class="mr-1">{{activity.document}}</div>
												<router-link @click.native="onSetBreadcrumb(activity, '/quotations')" v-if="activity.document == 'Quotations'" :to="'/quotations/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<router-link @click.native="onSetBreadcrumb(activity, '/sales')" v-if="activity.document == 'Sales Orders'" :to="'/sales/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<div class="ml-1">is cancelled</div>
											</div>
											<div class="text-xs">{{activity.userId.name}} - <span>{{getDate(activity.createdAt)}}</span></div>
										</div>
									</div>

									<!-- for set to qoutation -->
									<div v-if="activity.event == 'settoquotation'" class="flex items-center">
										<div class="mr-3 h-9 w-9 bg-yellow-500 flex items-center justify-center rounded-full">
											<i class="icon-reset text-green-50"></i>
										</div>
										<div>
											<div class="flex items-center mb-1">
												<div class="mr-1">{{activity.document}}</div>
												<router-link @click.native="onSetBreadcrumb(activity, '/quotations')" v-if="activity.document == 'Quotations'" :to="'/quotations/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<router-link @click.native="onSetBreadcrumb(activity, '/sales')" v-if="activity.document == 'Sales Orders'" :to="'/sales/detail/'+activity.documentId" class="text-teal-500">{{activity.documentName}}</router-link>
												<div class="ml-1">set to quotation</div>
											</div>
											<div class="text-xs">{{activity.userId.name}} - <span>{{getDate(activity.createdAt)}}</span></div>
										</div>
									</div>
								</li>
							</ul>
						</template>
					</dropdown>
				</li>
			</ul>
			<ul class="flex felx-row items-center ml-auto">
				<li>
					<a href="#" class="flex items-center h-12 px-4 hover:bg-gray-700 hover:bg-opacity-50">
						<i class="icon-bell2"></i>
					</a>
				</li>
				<li>
					<a href="#" class="reltive flex items-center h-12 px-4 hover:bg-gray-700 hover:bg-opacity-50">
						<i class="icon-bubbles9"></i>
					</a>
				</li>
				<li class="border-l border-gray-700 py-3 mx-2"></li>
				<li v-if="user">
					<a v-if="user.role == 'Admin'" @click.prevent="setting" href="#" class="flex items-center h-12 px-4 hover:bg-gray-700 hover:bg-opacity-50">
						<i class="icon-cog3 mr-2"></i> Settings
					</a>
				</li>
				<li class="relative">
					<dropdown :right="true">
						<a href="#" class="relative px-4 flex items-center h-12 user hover:bg-gray-700 hover:bg-opacity-50">
							<div class="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center">
								<div v-if="user">{{user.name[0]}}</div>
							</div>
						</a>
						<template #dropdown>
							<div class="w-40">
								<router-link to="/my-account" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-user mr-4"></i>My Account</router-link>
								<a @click="signOut" href="#" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-switch2 mr-4"></i>Logout</a>
							</div>
						</template>
					</dropdown>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Dropdown from '@/components/DropdownMenu.vue'
import { mapActions, mapGetters } from 'vuex'
import Pusher from 'pusher-js'
import axios from 'axios'
import {moment} from '../moment'
import BreadcrumbsManager from '../mixins/breadcrumbsManager.vue'
export default {
	components: {Dropdown},
	mixins: [BreadcrumbsManager],
	data () {
		return {
			isLoading: false,
			activityNotif: '',
			activities: [],
		}
	},
	created () {
		this.subscribe()
		
	},
	mounted () {
		this.getNotificationActivity()
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),
	},
	methods: {
		...mapActions({
			logout: 'auth/logout'
		}),
		onSetBreadcrumb (val, path) {
			this.setBreadcrumbs([
				{text: val.document, path: path}
			])
		},
		subscribe () {
			let pusher = new Pusher('9ed5b6068ed8653fce89', {cluster: 'ap1'})
			let activity = pusher.subscribe('broadcast')
			activity.bind('activity', () => {
				this.getNotificationActivity()
			})
		},
		getNotificationActivity () {
			axios.get('/notifications/unread', {
				params: {
					type: 'activity'
				}
			})
			.then(res => {
				this.activityNotif = res.data
			})
		},
		getActivities () {
			this.isLoading = true
			axios.get('/activities/recent')
			.then(res => {
				this.isLoading = false
				this.activities = res.data
				if(this.activityNotif > 0) {
					axios.delete('/notifications/delete', {
						params: {
							type: 'activity'
						}
					})
					.then(() => {
						this.getNotificationActivity()
					})
				}
			})
		},
		setting () {
			this.$router.push('/settings')
			this.setBreadcrumbs([
				{text: 'Settings'}
			])
			this.setButtonText('')
			this.setDiscard(false)
		},
		getDate (d) {
			return moment(d)
		},
		async signOut () {
			this.isLoading = true
			const token = localStorage.getItem('refreshToken')
			await this.logout(token)
			.then(() => {
				this.$router.push('/login')
				this.isLoading = false
			})
		}
	},
}
</script>