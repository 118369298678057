<template>
    <transition name="modal" mode="out-in">
        <div v-show="show" class="fixed inset-0 z-40 overflow-y-auto">
            <div class="flex justify-center">
                <div class="fixed inset-0 bg-black bg-opacity-30 transition-opacity" aria-hidden="true"></div>
                <transition name="slide" >
                    <div v-show="show" class="transform flex justify-center my-8 w-full">
                        <div class="w-3/5 rounded overflow-hidden shadow-2xl">
                            <div class="form-feedback form-feedback-left">
                                <input ref="search" v-model="search" type="text" class="h-12 w-full focus:outline-none pl-12 border-b text-xl" placeholder="search product name or SKU">
                                <div class="form-control-feedback">
                                    <span>
                                        <i class="icon-search4 text-gray-400" style="font-size: 25px"></i>
                                    </span>
                                </div>
                            </div>
                            <div v-if="search" class="bg-white">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-2">
                                        <ul>
                                            <li class="bg-teal-500 p-2 text-white">
                                                ini untuk index hasil pencarian
                                            </li>
                                            <li class="p-2">
                                                hasi berikutnya
                                            </li>
                                            <li class="p-2">
                                                hasi berikutnya
                                            </li>
                                            <li class="p-2">
                                                hasi berikutnya
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-span-4 p-3">
                                        ini untuk detail hasil pencarian
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    data () {
        return {
            show: false,
            search: ''
        }
    },
    mounted () {
        document.addEventListener('keydown', this.closeModal)
    },
    destroyed () {
        document.removeEventListener('keydown', this.closeModal)
    },
    methods: {
        showModal () {
            this.show = true
        },
        closeModal (e) {
            if(e.keyCode === 27) {
                if(this.search) {
                    this.search = ''
                } else {
                    this.show = false
                }
            }
        }
    },
    watch: {
        show (val) {
            if(val) {
                this.$nextTick(() => {
                    this.$refs.search.focus()
                })
            }
        }
    }
}
</script>