<template>
    <div class="bg-white border rounded-sm">
        <ul class="relative flex items-center border-b px-5">
            <li>
                <a href="#" @click.prevent="tabsMenu($event, 'overview')" :class="{'font-medium text-red-500': tab == 'overview'}" class="inline-block transition-all duration-300 p-4 border-b-2 border-transparent hover:border-gray-200 hover:text-red-500">OVERVIEW</a>
            </li>
            <li>
                <a href="#" @click.prevent="tabsMenu($event, 'info')" :class="{'font-medium text-red-500': tab == 'info'}" class="inline-block transition-all duration-300 p-4 border-b-2 border-transparent hover:border-gray-200 hover:text-red-500">INFO</a>
            </li>
            <li>
                <a href="#" @click.prevent="tabsMenu($event, 'purchase orders')" :class="{'font-medium text-red-500': tab == 'sales order'}" class="inline-block transition-all duration-300 p-4 border-b-2 border-transparent hover:border-gray-200 hover:text-red-500">PURCHASE ORDERS</a>
            </li>
            <li>
                <a href="#" @click.prevent="tabsMenu($event, 'task')" :class="{'font-medium text-red-500': tab == 'task'}" class="inline-block transition-all duration-300 p-4 border-b-2 border-transparent hover:border-gray-200 hover:text-red-500">TASK & NOTE<span class="ml-2.5 px-1 py-px rounded-sm text-gray-50 bg-gray-600">{{tasks.length}}</span></a>
            </li>
            <li class="ml-auto">
                <button @click="onEdit" class="bg-teal-500 px-3 h-9 rounded-sm text-teal-50 hover:bg-teal-600"><i class="icon-pencil mr-2"></i>EDIT SUPPLIER</button>
            </li>
            <li ref="active" class="absolute transform duration-300 line-active border-red-500 w-6 bottom-0"></li>
        </ul>
        <div class="p-5">
            <!-- OVERVIEW -->
            <div v-show="tab == 'overview'">
                <div class="animated fadeIn">
                    <div class="grid grid-cols-9 divide-x">
                        <div class="col-span-3 pr-5">
                            <div class="mb-5">
                                <div>Supplier Name</div>
                                <div class="text-lg font-medium">{{overview.name}}</div>
                            </div>
                            <div class="mb-5">
                                <div>Code</div>
                                <div class="text-lg font-medium">{{overview.code}}</div>
                            </div>
                            <div class="mb-5 text-gray-500">
                                <div class="text-lg font-light mb-3">Contact</div>
                                <div class="ml-5">
                                    <table class="w-full">
                                        <tbody class="divide-y border-b">
                                            <tr>
                                                <td class="p-2 w-20">Office</td>
                                                <td v-if="contact.office" class="p-0">{{contact.office}}</td>
                                                <td v-else class="p-0">-</td>
                                            </tr>
                                            <tr>
                                                <td class="p-2 w-16">Mobile</td>
                                                <td v-if="contact.mobile" class="p-0">{{contact.mobile}}</td>
                                                <td v-else class="p-0">-</td>
                                            </tr>
                                            <tr>
                                                <td class="p-2 w-16">Email</td>
                                                <td v-if="contact.email" class="p-0">{{contact.email}}</td>
                                                <td v-else class="p-0">-</td>
                                            </tr>
                                            <tr>
                                                <td class="p-2 w-16">Website</td>
                                                <td v-if="overview.website" class="p-0">{{overview.website}}</td>
                                                <td v-else class="p-0">-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mb-5 text-gray-500">
                                <div class="text-lg font-light mb-3">Address</div>
                                <div class="ml-5">
                                    <ul class="divide-y">
                                        <li v-for="(address, i) in overview.addressLists" :key="i" class="py-2">
                                            <div v-if="address.type == 'Shipping'" class="font-medium flex items-center"><i class="icon-truck mr-2"></i>{{address.type}}</div>
                                            <div v-else class="font-medium flex items-center"><i class="icon-coin-dollar mr-2"></i>{{address.type}}</div>
                                            <div>{{address.address}}</div>
                                            <div>{{address.city}}, {{address.state}}, {{address.country}}, {{address.postalCode}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mb-5 text-gray-500">
                                <div class="text-lg font-light mb-3">Internal Remarks</div>
                                <div class="ml-5">
                                    {{overview.internalRemarks}}
                                </div>
                            </div>
                            <div class="mb-5 text-gray-500">
                                <div class="text-lg font-light mb-3">External Remarks</div>
                                <div class="ml-5">
                                    {{overview.externalRemarks}}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-6 pl-5">
                            <div class="grid grid-cols-3 gap-5 mb-5">
                                <div class="rounded-sm px-5 py-3 bg-green-500 flex items-center">
                                    <div class="text-green-200">
                                        <i class="icon-file-pdf icon-3x"></i>
                                    </div>
                                    <div class="text-right ml-auto">
                                        <div class="text-green-50 text-2xl font-medium">{{countPurchases}}</div>
                                        <div class="text-green-200">0</div>
                                        <div class="text-green-200">PURCHASE ORDERS</div>
                                    </div>
                                </div>
                                <div class="border rounded-sm"></div>
                                <div class="border rounded-sm"></div>
                            </div>
                            <div>
                                <h3 class="text-base font-light">Recent Activities</h3>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <!-- /end overview -->

            <!-- INFO -->
            <div v-show="tab == 'info'">
                <div class="animated fadeIn">
                    <div class="grid grid-cols-12 gap-5">
                        <div class="col-span-7">
                            <div class="grid grid-cols-12 border-b py-3 items-center">
                                <div class="col-span-4 font-semibold">COMPANY NAME</div>
                                <div class="col-span-8 text-lg text-teal-500">{{overview.name}}</div>
                            </div>
                            <div class="grid grid-cols-12 border-b py-3 items-center">
                                <div class="col-span-4 font-semibold">SUPPLIER CODE</div>
                                <div v-if="overview.code" class="col-span-8 text-lg text-teal-500">{{overview.code}}</div>
                                <div v-else class="text-lg text-teal-500">--</div>
                            </div>
                            <div class="grid grid-cols-12 border-b py-3 items-center">
                                <div class="col-span-4 font-semibold">WEBSITE</div>
                                <div v-if="overview.website" class="col-span-8 text-lg text-teal-500">{{overview.website}}</div>
                                <div v-else class="text-lg text-teal-500">--</div>
                            </div>
                            <div class="grid grid-cols-12 border-b py-3 items-center">
                                <div class="col-span-4 font-semibold">INTERNAL REMARKS</div>
                                <div v-if="overview.internalRemarks" class="col-span-8 text-lg text-teal-500">{{overview.internalRemarks}}</div>
                                <div v-else class="text-lg text-teal-500">--</div>
                            </div>
                            <div class="grid grid-cols-12 border-b py-3 items-center">
                                <div class="col-span-4 font-semibold">EXTERNAL REMARKS</div>
                                <div v-if="overview.externalRemarks" class="col-span-8 text-lg text-teal-500">{{overview.externalRemarks}}</div>
                                <div v-else class="text-lg text-teal-500">--</div>
                            </div>
                            <div class="grid grid-cols-12 border-b py-3">
                                <div class="col-span-4 font-semibold">TAGS</div>
                                <div v-if="overview.tags" class="col-span-8">
                                    <div v-if="overview.tags.length > 0">
                                        <div class="bg-teal-500 px-2 py-0.5 rounded-sm text-gray-50 inline mr-2" v-for="(tag, i) in overview.tags" :key="i">{{tag}}</div>
                                    </div>
                                    <div v-else class="col-span-8">
                                        <div class="text-lg text-teal-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-5 py-3 bg-gray-300 mt-5 font-semibold">OPTIONS</div>
                            <div class="grid grid-cols-2 gap-5 py-3 border-b">
                                <div class="col-sapan-1">
                                    <div class="font-semibold">DEFAULT CURRENCY</div>
                                    <div v-if="overview.defaultCurrencyId" class="text-lg text-teal-500">{{overview.defaultCurrencyId.code}}</div>
                                    <div v-else class="text-lg text-teal-500">--</div>
                                </div>
                                <div class="col-sapan-1">
                                    <div class="font-semibold">DEFAULT PAYMENT TERM</div>
                                    <div v-if="overview.defaultPaymentTermId" class="text-lg text-teal-500">{{overview.defaultPaymentTermId.code}}</div>
                                    <div v-else class="text-lg text-teal-500">--</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5 py-3 border-b">
                                <div class="col-sapan-1">
                                    <div class="font-semibold">DEFAULT SHIPMENT TERM</div>
                                    <div v-if="overview.defaultShipmentTermId" class="text-lg text-teal-500">{{overview.defaultShipmentTermId.code}}</div>
                                    <div v-else class="text-lg text-teal-500">--</div>
                                </div>
                                <div class="col-sapan-1">
                                    <div class="font-semibold">DEFAULT SHIPMENT METHOD</div>
                                    <div v-if="overview.defaultShipmentMethodId" class="text-lg text-teal-500">{{overview.defaultShipmentMethodId.code}}</div>
                                    <div v-else class="text-lg text-teal-500">--</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5 py-3 border-b">
                                <div class="col-sapan-1">
                                    <div class="font-semibold">DEFAULT TAX</div>
                                    <div v-if="overview.defaultTaxId" class="text-lg text-teal-500">{{overview.defaultTaxId.code}}</div>
                                    <div v-else class="text-lg text-teal-500">--</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-5 bg-white h-full flex flex-col">
                            <div class="grid grid-cols-2 items-center border-b border-gray-200 h-11 rounded-sm bg-gray-600">
                                <div class="relative col-span-1 text-center h-full">
                                    <a @click.prevent="tabs = 'a'" href="#" :class="tabs=='a'?'text-orange-400':'text-gray-300'" class="group inline-flex h-full items-center transition duration-200 hover:text-orange-400 uppercase leading-none">ADDRESSES<span :class="{'bg-orange-400 text-white': tabs == 'a'}" class="bg-gray-400 rounded-sm px-2 py-1 ml-2 text-gray-300 leading-none transition duration-200 group-hover:bg-orange-400 group-hover:text-white">{{countAddress}}</span></a>
                                    <div v-show="tabs == 'a'" class="absolute w-full bg-orange-400 -bottom-px" style="height: 3px"></div>
                                </div>
                                <div class="relative col-span-1 text-center h-full">
                                    <a @click.prevent="tabs = 'b'" href="#" :class="tabs=='b'?'text-orange-400':'text-gray-300'" class="group inline-flex h-full items-center transition duration-200 hover:text-orange-400 uppercase leading-none">CONTACTS<span :class="{'bg-orange-400 text-white': tabs == 'b'}" class="bg-gray-400 rounded-sm px-2 py-1 ml-2 transition duration-200 group-hover:bg-orange-400 group-hover:text-white text-gray-300 leading-none">{{countContact}}</span></a>
                                    <div v-show="tabs == 'b'" class="absolute w-full bg-orange-400 -bottom-px" style="height: 3px"></div>
                                </div>
                            </div>
                            <div class="border h-full px-5 pb-5 overflow-hidden">
                                <div v-show="tabs == 'a'" class="animated fadeIn faster">
                                    <ul>
                                        <li v-for="(address, i) in overview.addressLists" :key="i" class="border-b border-dashed py-3">
                                            <div class="flex items-center font-semibold"><i class="icon-truck mr-2"></i>{{address.type}}</div>
                                            <div>{{address.address}}</div>
                                            <div><span v-if="address.city">{{address.city}},</span> {{address.state}} {{address.country}}, {{address.postalCode}}</div>
                                        </li>
                                    </ul>
                                </div>
                                <div v-show="tabs == 'b'" class="animated fadeIn faster">
                                    <ul>
                                        <li v-for="(contact, i) in overview.contactLists" :key="i" class="border-b border-dashed py-3">
                                            <div class="flex items-center mb-1"><i class="icon-user mr-3"></i>{{contact.name}}</div>
                                            <div class="flex items-center mb-1"><i class="icon-envelop2 mr-3"></i>{{contact.email}}</div>
                                            <div class="flex items-center mb-1"><i class="icon-mobile mr-3"></i>{{contact.mobile}}</div>
                                            <div class="flex items-center mb-1"><i class="icon-office mr-3"></i>{{contact.office}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /end info -->

            <!-- SALES ORDERs -->
            <div v-show="tab == 'purchase orders'">
                <div class="animated fadeIn">
                    <table class="w-full">
                        <thead class="border-b">
                            <tr>
                                <th class="px-5 py-3">PURCHASE ORDER #</th>
                                <th class="px-5 py-3">STATUS</th>
                                <th class="px-5 py-3">RECEIVE</th>
                                <th class="px-5 py-3">PAYMENT</th>
                                <th class="px-5 py-3">REMARKS</th>
                                <th class="px-5 py-3 text-right">DATE CREATED</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                           <tr v-for="(prc, i) in purchases" :key="i">
                                <td class="py-3 px-5">
                                    <router-link :to="'/purchases/detail/'+prc._id" class="text-cyan-600 flex items-center hover:text-cyan-700"><div :class="[prc.status=='New'? 'bg-green-500':'bg-gray-200']" class="h-3 w-3 rounded-full mr-3"></div> {{prc.no}}</router-link>
                                </td>
                                <td class="py-3 px-5">
                                    {{prc.status}}
                                </td>
                                <td class="py-3 px-5">
                                    <div v-if="prc.receiveStatus == 'In Progress'" class="px-1.5 py-px text-sm w-max rounded-full bg-gray-500 text-gray-50">{{prc.receiveStatus}}</div>
                                    <div v-if="prc.receiveStatus == 'Issued'" class="px-1.5 py-px w-max text-sm text-orange-50 rounded-full bg-orange-500">{{prc.receiveStatus}}</div>
                                    <div v-if="prc.receiveStatus == 'Delivered'" class="px-1.5 py-px w-max text-sm text-cyan-50 rounded-full bg-cyan-600">{{prc.receiveStatus}}</div>
                                </td>
                                <td class="py-3 px-5">
                                    <div class="flex items-center">
                                        <div v-if="prc.paymentStatus == 'In Progress'" class="px-1.5 py-px w-max text-sm text-gray-50 rounded-full bg-gray-500">{{prc.paymentStatus}}</div>
                                        <div v-if="prc.paymentStatus == 'Partial Paid'" class="px-1.5 py-px w-max text-sm rounded-full text-blue-50 bg-blue-800">{{prc.paymentStatus}}</div>
                                        <div v-if="prc.paymentStatus == 'Paid'" class="px-1.5 py-px w-max text-sm rounded-full text-cyan-50 bg-cyan-500">{{prc.paymentStatus}}</div>
                                    </div>
                                </td>
                                 <td class="py-3 px-5">
                                    {{prc.remarks}}
                                </td>
                                <td class="py-3 px-5 text-right">{{getDate(prc.createdAt)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex justify-end mt-5">
                        <paginate :pages="pages" @get-page="getData($event)"></paginate>
                    </div>
                </div>
            </div>
            <!-- /end sales orders -->

            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'">
                <div class="animated fadeIn">
                    <div class="flex items-center justify-between mb-5">
                        <div>
                            search
                        </div>
                        <div>
                            <button @click="modalTask=true" class="bg-violet-500 px-5 h-9 rounded-sm text-violet-50">ADD TASKS & NOTE</button>
                        </div>
                    </div>
                    <table class="w-full">
                        <thead>
                            <tr class="border-b">
                               <th class="py-3 px-5" style="width: 5%">TYPE</th>
                                <th class="py-3 px-5" style="width: 15%">TITLE</th>
                                <th class="py-3 px-5" style="width: 15%">DESCRIPTION</th>
                                <th class="py-3 px-5" style="width: 10%">STATUS</th>
                                <th class="py-3 px-5" style="width: 15%">DUE DATE</th>
                                <th class="py-3 px-5" style="width: 15%">ASSIGNEE</th>
                                <th class="py-3 px-5" style="width: 25%">DATE CREATED</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                            <tr v-for="(task, i) in tasks" :key="i">
                                <td class="py-3 px-5">
                                    <div v-if="task.type == 'Note'" class="px-1.5 py-px text-xs w-max rounded-full bg-blue-500 text-gray-50">{{task.type}}</div>
                                    <div v-if="task.type == 'Task'" class="px-1.5 py-px text-xs w-max rounded-full bg-cyan-500 text-gray-50">{{task.type}}</div>
                                </td>
                                <td class="py-3 px-5">
                                    <div>{{task.title}}</div>
                                </td>
                                <td class="py-3 px-5">{{task.details}}</td>
                                <td class="py-3 px-5">
                                    <div v-if="task.status == 'In Progress'" class="px-1.5 py-px text-xs w-max rounded-full bg-yellow-500 text-gray-50">{{task.status}}</div>
                                    <div v-if="task.status == 'Completed'" class="px-1.5 py-px text-xs w-max rounded-full bg-green-500 text-gray-50">{{task.status}}</div>
                                </td>
                                <td class="py-3 px-5">
                                    <div v-if="task.dueDate">{{getDate(task.dueDate)}}</div>
                                    <div v-else>--</div>
                                </td>
                                <td class="py-3 px-5">
                                    <div v-if="task.assignee">{{task.assignee.name}}</div>
                                    <div v-else>--</div>
                                </td>
                                <td class="py-3 px-5">
                                    <div class="flex items-center justify-between">
                                        <div>
                                            <div>{{getDate(task.createdAt)}}</div>
                                            <div class="text-gray-400">by {{task.userId.name}}</div>
                                        </div>
                                        <div class="ml-3">
                                            <a v-if="task.status == 'In Progress'" @click.prevent="putTask(task._id)" href="#" class="text-green-500"><i class="icon-checkmark mr-2"></i></a>
                                            <a v-if="task.status == 'In Progress'" @click.prevent="editTask(i)" href="#" class="text-blue-500"><i class="icon-pencil4 mr-2"></i></a>
                                            <a @click.prevent="deletTask(task._id)" href="#" class="text-red-500"><i class="icon-cancel-circle2"></i></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- /end task -->
        </div>
        <task-note :task="taskEdit" :show="modalTask" :documentId="overview._id" :documentName="overview.name" @closeModal="onCloseModalTask" @getTasks="getTask"></task-note>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../moment'
import Paginate from '../../components/paginate.vue'
import taskNote from '../../components/taskNote.vue'
import Swal from 'sweetalert2/dist/sweetalert2'
export default {
    components: {Paginate, taskNote},
    data () {
        return {
            tab: 'overview',
            tabs: 'a',
            overview: '',
            countAddress: 0,
            countContact: 0,
            countPurchases: 0,
            purchases: [],
            pages: '',
            modalTask: false,
            tasks: [],
            taskEdit: '',
        }
    },
    mounted () {
        this.$refs.active.style.width = '95px'
        this.getData()
    },
    computed: {
        contact () {
            if(this.overview) {
                if(this.overview.contactLists.length > 0) {
                    return this.overview.contactLists.find(obj => obj.isDefault == true)
                }
            }
            return {office: '-', name: '-', mobile: '-', emai: '-'}
        }
    },
    methods: {
        getData (e) {
            axios.get('/suppliers/detail/'+this.$route.params.id, {
                params: {
                    page: e
                }
            })
            .then(res => {
                this.overview = res.data.supplier
                this.countAddress = res.data.supplier.addressLists.length
                this.countContact = res.data.supplier.contactLists.length
                this.pages = res.data.pages
                this.purchases = res.data.purchases
                this.countPurchases = res.data.countPurchases
                this.tasks = res.data.tasks
            })
        },
        onEdit () {
            this.$router.push('/suppliers/edit/'+this.$route.params.id)
        },
        tabsMenu (e, name) {
            this.tab = name
            const offset = e.target.offsetLeft - 20
            const width = e.target.clientWidth+'px'
            this.$refs.active.style.width = width
            this.$refs.active.style.transform="translateX("+offset+"px)"
        },
        getDate (d) {
            return moment (d)
        },
        // TASKS & NOT FUNCTION
        getTask () {
            axios.get('/tasks/read/'+this.overview._id)
            .then(res => {
                this.tasks = res.data
            })
        },
        editTask (i) {
            this.taskEdit = ''
            this.taskEdit = this.tasks[i]
            this.$nextTick(() => {
                this.modalTask = true
            })
        },
        putTask (id) {
            axios.put('/tasks/update/'+id, {
                status: 'Completed'
            })
            .then(() => {
                this.getTask()
            })
        },
        deletTask (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return 
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    axios.delete('/tasks/delete/'+id)
                    .then(() => {
                        app.getTask()
                    })
                }
            })
        },
        onCloseModalTask () {
            this.modalTask = false
            this.taskEdit = ''
        },
    }
}
</script>
<style scoped>
    .text-sm {
        font-size: 10px !important;
    }
</style>