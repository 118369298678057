<template>
    <transition :duration="300">
        <div v-show="show" class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div class="absolute inset-0 overflow-hidden">
                <transition enter-active-class="ease-in-out duration-300" enter-class="opacity-0" enter-to-class="opacity-100" leave-active-class="ease-in-out duration-300" leave-class="opacity-100" leave-to-class="opacity-0">
                    <div v-show="show" class="absolute inset-0 bg-black bg-opacity-50 transition-opacity"></div>
                </transition>
                <transition name="custom-classes-transition" enter-active-class="transform transition ease-in-out duration-300" enter-class="translate-x-full" enter-to-class="translate-x-0" leave-active-class="transform transition ease-in-out duration-300" leave-class="translate-x-0" leave-to-class="translate-x-full">
                    <div v-show="show" class="fixed inset-y-0 max-w-full flex right-0">
                        <slot></slot>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>
<script>

export default {
    props: {
        show: Boolean
    },
    watch: {
        show(val) {
            if(val) {
                document.body.style.overflow="hidden" 
            } else {
                setTimeout(() => {
                    document.body.style.overflow="auto"

                },300)
            }
        }
    }
}
</script>