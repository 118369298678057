<template>
    <div class="relative flex justify-center">
        <div v-show="show" class="absolute bg-white border rounded-md w-32 z-20 p-3 top-12 shadow-lg">
            <div class="flex items-center justify-between mb-3">
                <div class="font-medium">Availability</div>
                <div v-if="isLoading">
                    <svg class="animate-spin -ml-1 mr-3 h-4 w-4 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div>{{Intl.NumberFormat().format(stock)}}</div>
            </div>
            <div class="text-right flex items-center justify-end">
                <a @click.prevent="getInventory" href="#" class="text-blue-500 hover:text-blue-600"><i class="icon-arrow-right8"></i> See details</a>
            </div>
            <div class="w-full flex justify-center">
                <div class="absolute bg-white border border-b-0 border-r-0 h-3.5 w-3.5 -top-2 transform rotate-45"></div>
            </div>
        </div>
        <div>
            <slot></slot>
        </div>

        <modal :show="modalInventory">
            <div class="bg-white border rounded-sm p-5 shadow-xl w-2/5">
                <div class="flex items-center justify-between mb-5">
                    <div class="font-medium text-lg">INVENTORY</div>
                    <div class="border border-teal-500 rounded-sm p-2 w-32 text-right">
                        <div class="text-lg text-teal-500 font-medium leading-none">{{stock}}</div>
                        <div class="leading-none text-gray-400">total stock</div>
                    </div>
                </div>
                <div class="flex items-center mb-5">
                    <div class="mr-5">
                        <img :src="image" width="65">
                    </div>
                    <div>
                        <div class="text-base font-medium">{{name}}</div>
                        <div class="text-gray-400">{{sku}}</div>
                    </div>
                </div>
                <div v-if="inventories" class="mb-5 border-b" style="max-height: 400px; overflow-y: scroll">
                    <div v-for="(inv, i) in inventories" :key="i">
                        <div class="bg-teal-500 py-2 px-3 text-teal-50 rounded-sm flex items-center justify-between">
                            <div>
                                <i class="icon-stack2 mr-2"></i>{{inv._id.name}} {{inv._id.default}}
                            </div>
                            <div class="font-semibold">{{inv.total}}</div>
                        </div>
                        <div v-for="(section, a) in inv.sections" :key="a" class="flex items-center justify-between px-3 py-2 border-b">
                            <div>{{section.name}}</div>
                            <div class="text-right">{{section.qty}}</div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button @click="modalInventory=false" class="border rounded-sm h-9 px-5 hover:bg-gray-50">close</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import modal from '../components/Modal.vue'
export default {
    components: {modal},
    props: ['productId','serial'],
    data () {
        return {
            isLoading: true,
            modalInventory: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            show: false,
            stock: '',
            name: '',
            sku: '',
            image: '',
            isSerialNumber: '',
            inventories: ''
        }
    },
    methods: {
        onFocus() {
            this.show = true
        },
        onFocusOut () {
            setTimeout(() => {
                this.show = false
            }, 50)
        },
        getStock () {
            axios.get('/products/stock/'+this.productId)
            .then(res => {
                this.stock = res.data.stock
                this.name = res.data.name
                this.sku = res.data.sku
                this.image = this.imgURL+'/public/img/products/200/'+res.data.images[0]
                this.isLoading = false
            })
        },
        getInventory () {
            this.inventories = ''
            this.modalInventory = true
            axios.get('/products/inventory/'+this.productId)
            .then(res => {
                this.inventories = res.data
            })
            .catch(() => {
                this.inventories = ''
            })
        }
    },
    watch: {
        show (val) {
            if(val && this.productId) {
                this.getStock()
            }
        }
    }
    
}
</script>