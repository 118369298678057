<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <div class="font-medium text-lg">
                {{documentName}}
            </div>
            <div>
                <button @click="addTask" class=" bg-blue-500 h-7 px-3 rounded-sm text-blue-50">Add Task & Note</button>
            </div>
        </div>
        <table class="w-full border">
            <thead>
                <tr>
                    <th class="py-2 px-2 border text-center" style="width: 7%">Type</th>
                    <th class="py-2 px-2 border text-center" style="width: 50%">Description</th>
                    <th class="py-2 px-2 border text-center" style="width: 8%">Assignee</th>
                    <th class="py-2 px-2 border text-center" style="width: 12%">Status</th>
                    <th class="py-2 px-2 border text-center" style="width: 10%">Due Date</th>
                    <th class="py-2 px-2 border text-center" style="width: 13%">Created</th>
                </tr>
            </thead>
            <tbody ref="body">
                <tr v-for="(task, i) in tasks" :key="task._id" class="cursor-pointer group" @click="editTask(i)">
                    <td class="border h-9 relative">
                        <popoper :place="'center'">
                            <template v-slot:body>
                                <div class="flex flex-col space-y-1.5">
                                    <button @click="setType('Task', i, task._id)" class="bg-cyan-500 text-cyan-50 w-32 h-8 hover:bg-cyan-600" :class="{'border-2 border-cyan-700': task.type == 'Task'}">Task</button>
                                    <button @click="setType('Note', i, task._id)" class="bg-blue-500 text-blue-50 w-32 h-8 hover:bg-blue-600" :class="{'border-2 border-blue-700': task.type == 'Note'}">Note</button>
                                </div>
                            </template>
                            <template>
                                <div class="h-full w-full test text-cyan-50 flex items-center justify-center relative" :class="[task.edited ? 'bg-opacity-80 border':'group-hover:bg-opacity-80', task.type == 'Note' ? 'bg-blue-500':'bg-cyan-500']">
                                    <div class="triangle absolute top-0 right-0"></div>
                                    <span>{{task.type}}</span>
                                </div>
                            </template>
                        </popoper>
                    </td>
                    <td class="h-9 border" :class="[task.edited ? 'bg-blue-500 bg-opacity-30':'group-hover:bg-gray-100']">
                        <div class="p-1">
                            <input :ref="'description'+i" v-model="task.description" @keydown.enter="onEnter(i)" @blur="setDescription(i, task._id)" type="text" class="w-full h-7 focus:outline-none px-2 focus:bg-white placeholder-italic" :class="[task.edited ? 'bg-blue-100 bg-opacity-5':'group-hover:bg-gray-100']" placeholder="New Task & Note">
                        </div>
                    </td>
                    <td class="h-9 border" :class="[task.edited ? 'bg-blue-500 bg-opacity-30':'group-hover:bg-gray-100']">
                        <popoper :place="'center'">
                            <template v-slot:body>
                                <ul class=" w-72">
                                    <li v-for="(user, a) in users" :key="a" @click="setAssignee(user._id, i, task._id)" class="flex items-center py-1 px-2 hover:bg-gray-200 rounded-sm">
                                        <div class="border rounded-full flex items-center justify-center h-7 w-7 bg-teal-500">
                                            <span class="text-teal-50">{{user.name[0]}}</span>
                                        </div>
                                        <div class="ml-3">{{user.name}}</div>
                                    </li>
                                </ul>
                            </template>
                            <template>
                                <div class="w-full h-full flex items-center justify-center">
                                    <div v-if="!task.assignee" class="border rounded-full flex items-end justify-center h-7 w-7 ring-2 ring-white overflow-hidden">
                                        <i class="icon-person top-4 text-gray-200" style="font-size: 40px;"></i>
                                    </div>
                                    <div v-if="task.assignee" class="bg-teal-500 rounded-full flex items-center justify-center h-7 w-7 ring-2 ring-white overflow-hidden">
                                        <span v-if="task.assignee.name" class="text-teal-50">{{task.assignee.name[0]}}</span>
                                    </div>
                                </div>
                            </template>
                        </popoper>
                    </td>
                    <td class="h-9 border" :class="[task.edited ? 'bg-blue-500 bg-opacity-30':'group-hover:bg-gray-100']">
                        <popoper :place="'center'">
                            <template v-slot:body>
                                <div class="flex flex-col space-y-1.5">
                                    <button @click="setStatus('In Progress', i, task._id)" class="bg-yellow-500 text-cyan-50 w-40 h-8 hover:bg-yellow-600" :class="{'border-2 border-yellow-700': task.status == 'In Progress'}">In Progress</button>
                                    <button @click="setStatus('Done', i, task._id)" class="bg-green-500 text-green-50 w-40 h-8 hover:bg-green-600" :class="{'border-2 border-green-700': task.status == 'Done'}">Done</button>
                                    <button @click="setStatus('Stuck', i, task._id)" class="bg-red-500 text-red-50 w-40 h-8 hover:bg-red-600" :class="{'border-2 border-red-700': task.status == 'Stuck'}">Stuck</button>
                                </div>
                            </template>
                            <template>
                                <div v-if="task.status == 'In Progress'" class="h-full w-full bg-yellow-500 text-cyan-50 flex items-center justify-center relative" :class="[task.edited ? 'bg-opacity-80': 'group-hover:bg-opacity-80']">
                                    <div class="triangle absolute top-0 right-0"></div>
                                    <span>In Progress</span>
                                </div>
                                <div v-if="task.status == 'Done'" class="h-full w-full bg-green-500 text-cyan-50 flex items-center justify-center relative" :class="[task.edited ? 'bg-opacity-80': 'group-hover:bg-opacity-80']">
                                    <div class="triangle absolute top-0 right-0"></div>
                                    <span>Done</span>
                                </div>
                                <div v-if="task.status == 'Stuck'" class="h-full w-full bg-red-500 text-cyan-50 flex items-center justify-center relative" :class="[task.edited ? 'bg-opacity-80': 'group-hover:bg-opacity-80']">
                                    <div class="triangle absolute top-0 right-0"></div>
                                    <span>Stuck</span>
                                </div>
                            </template>
                        </popoper>
                    </td>
                    <td class="h-9 border text-center" :class="[task.edited ? 'bg-blue-500 bg-opacity-30':'group-hover:bg-gray-100']">
                        <date-picker v-model="task.dueDate" @input="updateTask(i, task._id)" :popover="{placement: 'bottom'}">
                            <template v-slot="{togglePopover}">
                                <div class="px-1 flex items-center w-full">
                                    <input :value="moment(task.dueDate)" type="button" @click="togglePopover()" class="w-full h-7 text-center cursor-text bg-white focus:bg-white" :class="[task.edited ? 'bg-blue-100 bg-opacity-5':'group-hover:bg-gray-100']">
                                </div>
                            </template>
                        </date-picker>
                    </td>
                    <td class="h-9 border text-center" :class="[task.edited ? 'bg-blue-500 bg-opacity-30':'group-hover:bg-gray-100']">
                        <div class="relative flex items-center justify-center w-full">
                            <div class="flex items-center">
                                <div class="border rounded-full flex items-center bg-violet-400 justify-center h-7 w-7 ring-2 ring-white overflow-hidden">
                                    <span class="text-violet-50">{{task.userId.name[0]}}</span>
                                </div>
                                <div class="ml-3">{{moment(task.createdAt)}}</div>
                            </div>
                            <div class="absolute -right-2 text-transparent group-hover:text-red-500">
                                <a @click.prevent="deleteTask(task._id)" href="#" class="hover:text-red-600 focus:outline-none"><i class="icon-cancel-circle2"></i></a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Popoper from './popover.vue'
import axios from 'axios'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    props: ['documentName'],
    components: {Popoper, DatePicker},
    data () {
        return {
            tasks: [],
            users: [],
            onSetDescription: ''
        }
    },
    mounted () {
        this.getData()
        window.addEventListener('click', this.unEdit)
    },
    destroyed () {
        window.removeEventListener('click', this.unEdit)
    },
    methods: {
        getData () {
            axios.get('/tasks/read/'+this.$route.params.id)
            .then(res => {
                this.tasks = res.data.tasks
                this.users = res.data.users
            })
        },
        addTask () {
            let task = {
                type: 'Note',
                documentId: this.$route.params.id,
                documentName: this.documentName,
                route: this.$route.path,
                descriptions: '',
                dueDate: new Date(),
                assignee: '',
                status: '',
                edited: false
            }
            axios.post('/tasks/create', task)
            .then (res => {
                this.tasks = res.data
                this.$nextTick(() => {
                    for(let i in this.tasks) {
                        if(!this.tasks[i].description) {
                            this.tasks[i].edited = true
                            this.$refs['description'+i][0].focus()
                            break
                        }
                    }
                })
            })
        },
        editTask (i) {
            for(let a in this.tasks) {
                this.tasks[a].edited = false
            }
            this.tasks[i].edited = true
        },
        setType(val, i, id) {
            this.tasks[i].type = val
            this.updateTask(i, id)
        },
        setDescription (i, id) {
            this.onSetDescription = true
            this.updateTask(i, id)
        },
        setAssignee (val, i, id) {
            this.tasks[i].assignee = val
            this.updateTask(i, id)
        },
        setStatus (val, i, id) {
            this.tasks[i].status = val
            this.updateTask(i, id)
        },
        onEnter (i) {
            this.$refs['description'+i][0].blur()
        },
        updateTask (i, id) {
            axios.put('/tasks/update/'+id, this.tasks[i])
            .then(res => {
                if(!this.onSetDescription) {
                    this.tasks = res.data
                }
                this.onSetDescription = false
            })
        },
        deleteTask (id) {
            axios.delete('/tasks/delete/'+id)
            .then(() => {
                this.getData()
            })
        },
        unEdit (e) {
            if(!e || !this.$refs.body.contains(e.target)) {
                for(let i in this.tasks) {
                    this.tasks[i].edited = false
                }
            }
        },
        moment (d) {
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
            let date = new Date (d)
            let D = date.getDate()
            let M = date.getMonth()
            D = checkTime(D)
            let Y = date.getFullYear().toString().substr(-2)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return`${D} ${month[M]} ${Y}`
        }
    },
    watch: {
        tasks () {
            this.$emit('input', this.tasks.length)
        }
    }
}
</script>

<style>
    td {
        padding: 0;
        margin: 0;
    }
    .triangle {
        width: 0px;
        height: 0px;
        border-top: 1px solid #fff;
        border-left: 1px solid rgb(31,41,55, 0.2);
        -webkit-transition: border .2s ease-in;
        -moz-transition: border .2s ease-in;
        -o-transition: border .2s ease-in;
        transition: border .2s ease-in;
    }
    td:hover .triangle {
        border-top: 10px solid #fff;
        border-left: 10px solid rgb(31,41,55, 0.2);
    }
</style>