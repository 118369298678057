<template>
    <div style="font-size: 12px">
        <div class="p-5 bg-white text-black">

            <div v-if="company" class="grid grid-cols-12 mb-5">
                <div class="col-span-2 py-2 border-gray-400 -mr-px border-2 rounded-2xl flex items-center">
                    <img :src="imgURL+'/public/img/company-logo.png'" alt="">
                </div>
                <div class="col-span-6 p-2 border-gray-400 border-2  rounded-2xl -ml-px -mr-px">
                    <div class="text-xl text-red-600 font-semibold">{{company.name}}</div>
                    <div class="text-base leading-none mb-px font-semibold text-blue-500"><i>{{company.tagline}}</i></div>
                    <div>
                        <div class=" leading-4 text-blue-600">{{company.companyAddress.address}} {{company.companyAddress.city.name}} - {{company.companyAddress.postal_code}} <span class="uppercase">{{company.companyAddress.country}}</span></div>
                        <div class=" leading-4 text-blue-600">Phone. <span class="font-semibold">{{company.phone}} (Hunting)</span></div>
                        <div class=" leading-4 text-blue-600">e-mail: <span class="font-semibold">{{company.email}}</span></div>
                        <div class=" leading-4 text-blue-600"><span class="font-semibold">{{company.website}}</span></div>
                    </div>
                </div>
                <div class="col-span-4 p-2 border-gray-400 border-2 rounded-2xl -ml-px flex items-center justify-center">
                    <div class="text-4xl text-gray-700 font-extrabold drop-shadow-lg italic">
                        <div class="">PURCHASE</div>
                        <div class="text-right">ORDER</div>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-5 mb-5">
                <div class="col-span-6 border border-gray-400 rounded-3xl p-2 relative">
                    <table class="w-full">
                        <tr>
                            <td class="text-right w-20 italic align-top">Company:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.company}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic align-top">Address:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.address}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">City:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.city}} {{to.postalCode}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Country:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.country}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Phone:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.phone}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Fax:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.fax}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">E-mail:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.email}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Website:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.website}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 font-semibold italic">Attn:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{to.attn}}</td>
                        </tr>
                    </table>
                    <div class="absolute -top-2.5 bg-white px-1 left-9 font-semibold">To :</div>
                </div>
                <div class="col-span-6 border border-gray-400 rounded-3xl p-2 relative">
                    <table class="w-full">
                        <tr>
                            <td class="text-right w-20 italic">Company:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.company}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic align-top">Address:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.address}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">City:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.city}} {{shipTo.zip}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Country:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.country}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Phone:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.phone}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Fax:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.fax}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">E-mail:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.email}}</td>
                        </tr>
                        <tr>
                            <td class="text-right italic whitespace-nowrap">Shipping Mark:</td>
                            <td class="pl-2"></td>
                            <td class="border-b font-semibold">{{shipTo.shippingMark}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 font-semibold italic">Attn:</td>
                            <td class="pl-2"></td>
                            <td class="border-b w-full font-semibold">{{shipTo.name}}</td>
                        </tr>
                    </table>
                    <div class="absolute -top-2.5 bg-white px-1 left-9 font-semibold">Ship To :</div>
                </div>
                
            </div>

            <div>
                <div class="mb-5">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">P.O. DATE</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">REQUISITIONER</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">P.O. NUMBER</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">SHIP VIA</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">F.O.B POINT</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-center">CURRENCY</th>
                            </tr>
                        </thead>
                        <tbody class="border-b">
                            <tr>
                                <td class="px-2 py-1 text-center">{{getDate(info.poDate)}}</td>
                                <td class="px-2 py-1 text-center">{{info.user}}</td>
                                <td class="px-2 py-1 text-center">{{info.poNumber}}</td>
                                <td class="px-2 py-1 text-center">{{info.shipVia}}</td>
                                <td class="px-2 py-1 text-center">{{info.fobPoint}}</td>
                                <td class="px-2 py-1 text-center">{{info.currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-5">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="bg-red-600 px-2 py-1 text-red-50">No.</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50">PRODUCT DESCRIPTION</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-right">QTY</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-right">UNIT PRICE</th>
                                <th class="bg-red-600 px-2 py-1 text-red-50 text-right">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y border-b">
                            <tr v-for="(item, i) in items" :key="item.idx">
                                <td class="px-2 py-1">{{i+1}}</td>
                                <td class="px-2 py-1">{{item.name}}</td>
                                <td class="px-2 py-1 text-right">{{item.qty}}</td>
                                <td class="px-2 py-1 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                                <td class="px-2 py-1 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                            </tr>
                            <tr v-for="a in addRow" :key="a">
                                <td class="px-2 py-3"></td>
                                <td class="px-2 py-3"></td>
                                <td class="px-2 py-3"></td>
                                <td class="px-2 py-3"></td>
                                <td class="px-2 py-3"></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <!-- SUB TOTAL -->
                            <tr>
                                <td colspan="3"></td>
                                <td class="px-2 text-right">Sub Total</td>
                                <td class="px-2 flex items-center justify-between">
                                    <div></div>
                                    {{currencySymbol}}{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(subTotal)}}
                                </td>
                            </tr>
                            <!-- <tr v-if="discountOnTotalPrice">
                                <td colspan="2"></td>
                                <td colspan="2" class="px-2 py-1 text-right">Discount on total price</td>
                                <td class="px-2 py-1 flex items-center justify-between">
                                    <div>Rp</div>
                                    {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(discountOnTotalPrice)}}
                                </td>
                            </tr> -->
                            <tr>
                                <td colspan="3"></td>
                                <td class="px-2 text-right">Tax {{tax}}%</td>
                                <td class="px-2 flex items-center justify-between">
                                    <div></div>
                                    {{currencySymbol}}{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(taxValue)}}
                                </td>
                            </tr>
                            <tr v-if="totalAdditionalCharges">
                                <td colspan="2"></td>
                                <td colspan="2" class="px-2 text-right">ADDITIONAL CHARGERS</td>
                                <td class="px-2 flex items-center justify-between">
                                    <div></div>
                                    {{currencySymbol}}{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(totalAdditionalCharges)}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td colspan="2" class="px-2 text-right">Shipping Cost</td>
                                <td class="px-2 flex items-center justify-between">
                                    <div></div>
                                    {{currencySymbol}}{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(shippingConst)}}
                                </td>
                            </tr>
                            <tr class="font-semibold border border-l-0 border-r-0 border-red-600">
                                <td colspan="3"></td>
                                <td class="px-2 py-1 text-right">Total</td>
                                <td class="px-2 py-1 flex items-center justify-between">
                                    <div></div>
                                    <div>
                                        {{currencySymbol}}{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(total)}}
                                    </div>
                                </td>
                            </tr>
                            <tr class="border border-red-600 border-l-0 border-r-0" style="font-size: 10px">
                                <td colspan="4"></td>
                                <td colspan="1" class="flex items-center justify-end py-1.5">
                                    <div class="flex items-center border border-gray-400 justify-between px-2 h-7 rounded-md space-x-5">
                                        <div>Rates:</div>
                                        <div>{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(rates)}}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="w-full border-b-4 border-gray-500"></div>
                    <div class="w-full border-b mt-px border-gray-500"></div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-5 mb-5">
                <div class="col-span-5 h-24 border rounded-2xl border-gray-600 relative">
                    <div class="absolute bg-white px-1 -top-2.5 left-9 font-semibold">Approval</div>
                </div>
                <div class="col-span-7 h-24 border rounded-2xl border-gray-600 relative">
                    <div class="absolute bg-white px-1 -top-2.5 right-9 font-semibold">Special Notes</div>
                </div>
            </div>
            <div class="border rounded-2xl border-gray-600 relative p-5 font-bold">
                <div>Please note!</div>
                <div>Very important, please put PO# and shipping mark on all boxes and all documents.</div>
                <div class="absolute bg-white px-1 -top-2.5 right-9 font-semibold">Notes/Remarks</div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            to: {
                company: '',
                address: '',
                city: '',
                postalCode: '',
                country: '',
                phone: '',
                fax: '',
                email: '',
                website: '',
                attn: ''
            },
            shipTo: {
                company: '',
                Address: '',
                city: '',
                country: '',
                phone: '',
                fax: '',
                email: '',
                shippingMark: '',
                attn: ''
            },
            info: {
                poDate: '',
                user: '',
                poNumber: '',
                shipVia: '',
                fobPoint: 'Bandung',
                currency: '',
            },
            items: [],
            currencySymbol: '',
            subTotal: '',
            tax: '',
            taxValue: '',
            shippingConst: '',
            totalAdditionalCharges: '',
            total: '',
            rates: '',
        }
    },
    computed: {
        ...mapGetters({
            company: 'auth/company'
        }),
        addRow () {
            let row = this.items.length
            let t = 0
            if(row < 14) {
                t = 14 - row
                return t
            } else {
                return t
            }
        }
    },
    methods: {
        getDate (i) {
            let month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            let date = new Date(i)
            let M = month[date.getMonth()+1]
            let d = date.getDate();
            let Y = date.getFullYear()
            return `${M} ${d}, ${Y}`
        }
    },
    watch: {
        data (val) {
            if(val) {
                let contact = val.contacts.find(obj => {
                    obj.isDefault == true
                    return obj
                })
                this.to.company = val.supplier
                this.to.address = val.address.address
                this.to.city = val.address.city
                this.to.postalCode = val.address.postalCode
                this.to.country = val.address.country
                this.to.phone = contact.office
                this.to.website = val.website
                if(contact.designation) {
                    this.to.attn = contact.designation+' '+contact.name
                } else {
                    this.to.attn = contact.name
                }
                this.info.poDate = val.createdAt
                this.info.poNumber = val.no
                this.info.user = val.user.name
                this.info.shipVia = val.shipmentMethod
                this.info.currency = val.currency
                this.items = val.items
                this.currencySymbol = val.currencySymbol
                this.subTotal = val.total
                this.tax = val.tax.amount
                this.taxValue = val.tax.value
                this.total = val.grandTotal
                this.rates = val.exchangeRate
                if(val.shipTo) {
                    this.shipTo = val.shipTo
                }
            }
        }
    }
}
</script>
