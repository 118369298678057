import store from '../store'
import axios from 'axios'

let breadcrumbs = localStorage.getItem('breadcrumbs')
store.commit('breadcrumbs/set', JSON.parse(breadcrumbs))

store.subscribe((mutations, state) => {
    switch(mutations.type) {
        case 'auth/SET_TOKEN': 
            if(mutations.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutations.payload}`
                localStorage.setItem('token', mutations.payload)
                store.dispatch('auth/company')
                store.dispatch('auth/user')
            } else {
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token');
            }
            break;
        case 'auth/SET_REFRESH_TOKEN' : 
            if(mutations.payload) {
                localStorage.setItem('refreshToken', mutations.payload);
            } else {
                localStorage.removeItem('refreshToken');
            }
            break;
        case 'auth/SET_USER':
            if(mutations.payload) {
                localStorage.setItem('userId', mutations.payload.id)
            } else {
                localStorage.removeItem('userId')
            }
            break;
    }
    localStorage.setItem('breadcrumbs', JSON.stringify(state.breadcrumbs.breadcrumbs))
})
