<template>
    <div class="p-5 border border-t-0">
        <div class="mb-5 flex items-center justify-between">
            <div class="font-semibold text-lg">{{product.name}}</div>
            <div class="flex items-center gap-5">
                <div class="text-right">
                    <div class="text-xs text-gray-400">CREATED AT</div>
                    <div class="text-xs">{{getDate(product.createdAt)}}</div>
                </div>
                <div class="text-right">
                    <div class="text-xs text-gray-400">UPDATED AT</div>
                    <div class="text-xs">{{getDate(product.updatedAt)}}</div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-5 mb-5">
            <div class="col-span-3 bg-cyan-600 p-5 text-cyan-50 rounded-sm flex items-center justify-between">
                <div class="text-cyan-300">
                    <i class="icon-stack icon-3x"></i>
                </div>
                <div class="text-right">
                    <div v-if="purchases" class="text-3xl font-medium">{{purchases.total}}</div>
                    <div v-else class="text-3xl font-medium">0</div>
                    <div>PURCHASE ORDERS</div>
                </div>
            </div>
            <div class="col-span-3 bg-green-600 p-5 text-green-50 rounded-sm flex items-center justify-between">
                <div class="text-green-300">
                    <i class="icon-price-tag3 icon-3x"></i>
                </div>
                <div class="text-right">
                    <div class="text-3xl font-medium">{{Intl.NumberFormat().format(totalSales)}}</div>
                    <div>SALES ORDERS</div>
                </div>
            </div>
            <div class="col-span-3 bg-red-600 p-5 text-red-50 rounded-sm flex items-center justify-between">
                <div class="text-red-300">
                    <i class="icon-spinner11 icon-3x"></i>
                </div>
                <div class="text-right">
                    <div class="text-3xl font-medium">0</div>
                    <div>RETURN</div>
                </div>
            </div>
            <div class="col-span-3 bg-orange-600 p-5 text-orange-50 rounded-sm flex items-center justify-between">
                <div class="text-orange-300">
                    <i class="icon-stack2 icon-3x"></i>
                </div>
                <div class="text-right">
                    <div class="text-3xl font-medium">{{product.stock}}</div>
                    <div>INVENTORY STOCK</div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-5 mb-5">
            <div class="col-span-1 p-5 border rounded-sm">
                PURCHASE ORDERS
            </div>
            <div class="col-span-1 p-5 border rounded-sm">
                SALES ORDERS
            </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
            <div class="col-span-1 p-5 border rounded-sm">
                ORDERS BY CUSTOMER GROUP
            </div>
            <div class="col-span-1 p-5 border rounded-sm">
                ORDERS BY CUSTOMER CITY
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../moment'
import BreadcrumbsManager from '../mixins/breadcrumbsManager.vue'
export default {
    mixins: [BreadcrumbsManager],
    data () {
        return {
            purchases: '',
            sales: 0,
            pos: 0,
            product: '',
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        totalSales () {
            let t = 0
            t = this.sales + this.pos
            return t
        }
    },
    methods: {
        getData () {
            axios.get('/products/overview/'+this.$route.params.productId)
            .then(res => {
                this.setBreadcrumbs([
                    {text: 'Products', path: '/products'},
                    {text: res.data.product.name}
                ])
                this.$emit('input', res.data.product.name)
                this.purchases = res.data.purchases
                if(res.data.sales) {
                    this.sales = res.data.sales.total
                }
                if(res.data.pos) {
                    this.pos = res.data.pos.total
                }
                this.product = res.data.product
            })
        },
        getDate (d) {
            return moment(d)
        }
    }
}
</script>