<template>
    <div>
        <div class="bg-white border rounded-sm rounded-b-none p-5">
            <div class="flex items-center">
                <div class="w-2/5">
                    <div class="w-full">
                        <div class="form-feedback form-feedback-left">
                            <input v-model="search"  type="text" class="form-control" placeholder="search no transfers or warehouse name">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-search4 text-gray-400"></i>
                                </span>
                            </div>
                            <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                                <a @click.prevent="search = ''" href="#">
                                    <i class="icon-cancel-circle2 text-red-500"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <button @click="newStockTransfer" type="button" class="bg-orange-500 relative rounded-sm h-9 text-white focus:outline-none">
                        <div class="flex items-center">
                            <div class="h-9 w-9 bg-orange-600 rounded-l-sm flex justify-center items-center">
                                <i class="icon-plus-circle2"></i>
                            </div>
                            <div class="px-3">
                                New Stock Transfer
                            </div>
                        </div>
                        <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
                    </button>
                </div>
            </div>
        </div>
        <table class="w-full divide-y">
            <thead>
                <tr class="bg-orange-500">
                    <th class="py-3 px-5 text-left text-blue-50">STOCK TRANSFER</th>
                    <th class="py-3 px-5 text-left text-blue-50">FROM LOCATION</th>
                    <th class="py-3 px-5 text-left text-blue-50">TO LOCATION</th>
                    <th class="py-3 px-5 text-left text-blue-50">USER CREATED</th>
                    <th class="py-3 px-5 text-blue-50 text-center w-28">REMARKS</th>
                    <th class="py-3 px-5 text-blue-50">STATUS</th>
                    <th class="py-3 px-5 text-blue-50">DATE CREATED</th>
                </tr>
            </thead>
            <tbody v-if="isLoading" class="divide-y bg-white border border-t-0">
                <tr v-for="i in 20" :key="i">
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                    <td class="px-5 py-3 ph-item">
                        <div class="w-full">
                            <div class="h-3 bg-gray-200"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="!isLoading" class="divide-y bg-white border border-t-0">
                <tr v-for="(trf, i) in transfers" :key="i">
                    <td class="px-5 py-3 text-orange-500">
                        <router-link :to="'/stock-transfers/detail/'+trf._id">
                            {{trf.no}}
                        </router-link>
                    </td>
                    <td class="px-5 py-3">
                        {{trf.fromWarehouseId.name}}
                    </td>
                    <td class="px-5 py-3">
                        {{trf.toWarehouseId.name}}
                    </td>
                    <td class="px-5 py-3">{{trf.userId.name}}</td>
                    <td class="px-5 py-3 text-center">
                        <popover :text="trf.remarks" :place="'center'">
                            <div v-if="trf.remarks" class="cursor-pointer">
                                <i class="icon-file-text2 text-orange-500"></i>
                            </div>
                        </popover>
                    </td>
                    <td class="px-5 py-3">
                        <div class="flex items-center">
                            <div class="h-3 w-3 rounded-full mr-2" :class="[trf.status == 'New' ? 'bg-green-500':'bg-blue-500']"></div>
                            <div>{{trf.status}}</div>
                        </div>
                        </td>
                    <td class="px-5 py-3">{{getDate(trf.createdAt)}}</td>
                </tr>
            </tbody>
        </table>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import popover from '../../../components/popoverText.vue'
import paginate from '../../../components/paginate.vue'
import debounce from 'lodash.debounce'
import {moment} from '../../../moment'
export default {
    components: {popover, paginate},
    data () {
        return {
            isLoading: true,
            search: '',
            transfers: [],
            pages: '',
            searchData: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData (e) {
            window.scrollTo(0, 0)
            this.isLoading = true
            axios.get('/transfers', {
                params: {
                    page: e,
                    search: this.search
                }
            })
            .then(res => {
                this.isLoading = false
                this.transfers = res.data.data
                this.pages = res.data.pages
            })
        },
        newStockTransfer () {
            this.$router.push('/stock-transfers/new')
        },
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        search() {
            this.searchData()
        }
    }
}
</script>