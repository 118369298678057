<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" ref="modal" class="fixed inset-0 z-40 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class="w-4/6 bg-white rounded-sm relative">
                                <div class="pl-5 pr-3 py-3 border-b">
                                    <div class="flex items-center justify-between">
                                        <h5 class="font-semibold">{{title}}</h5>
                                        <a @click.prevent="onClose" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500" style="font-size: 19px"></i></a>
                                    </div>
                                </div>
                                <form @submit.prevent="onSubmit">
                                    <div ref="modalBody"  class="overflow-scroll">
                                        <div class="p-5">
                                            <div class="float-right">
                                                <div class="h-24 w-24 border rounded relative group overflow-hidden">
                                                    <div class="absolute z-10 bg-teal-700 transition duration-200 text-teal-50 px-2 py-1 w-full flex justify-between opacity-0 group-hover:opacity-75">
                                                        <div>
                                                            <a @click.prevent="modal=true" tabindex="-1" href="#"><i class="icon-pencil"></i></a>
                                                        </div>
                                                        <div>
                                                            <a @click.prevent="removeImage" tabindex="-1" href="#"><i class="icon-trash"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="h-full w-full flex items-center justify-center">
                                                        <i v-if="!logo" class="icon-camera icon-4x text-gray-300"></i>
                                                        <img :src="logo" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="max-w-2xl">
                                                <div class="flex space-x-5 mb-5">
                                                    <div class="form-radio">
                                                        <input v-model="form.customerGroup"  :value="'Personal'" type="radio" id="radioinline1" name="radioinline" checked>
                                                        <label for="radioinline1">Personal</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.customerGroup" :value="'University'" type="radio" id="radioinline2" name="radioinline">
                                                        <label for="radioinline2">University</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.customerGroup" :value="'Corporate'" type="radio" id="radioinline3" name="radioinline">
                                                        <label for="radioinline3">Corporate</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.customerGroup" :value="'Goverment'" type="radio" id="radioinline4" name="radioinline">
                                                        <label for="radioinline4">Government</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <input ref="name" v-model="form.name" @keydown="error.name = ''" class="text-2xl border-b border-gray-500 w-full focus:outline-none focus:border-teal-500" :class="{'border-red-500': error.name}"  placeholder="e.g. John Doe">
                                                </div>
                                                <!-- PARENTS -->
                                                <div v-if="form.parents.length > 0" class="mt-2 flex justify-between">
                                                    <div class="border-b w-full">
                                                        <ul class="flex items-center">
                                                            <li v-for="(parent, i) in form.parents" :key="i" class="w-auto flex items-center border-b">
                                                                <div v-if="i+1 < form.parents.length" class="flex items-center">{{parent.name}} <i class="icon-arrow-right22"></i></div>
                                                                <div v-if="i+1 == form.parents.length" class="flex items-center justify-between w-36">
                                                                    <div>{{parent.name}}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <button tabindex="-1" type="button" @click="$emit('onEditParent',{id: form.parents[form.parents.length -1]._id, i: form.parents.length -1})" class="text-gray-400 hover:text-teal-500 focus:outline-none"><i class="icon-pencil7 ml-2 mb-1"></i></button>
                                                </div>
                                            </div> 
                                
                                            <div class="grid grid-cols-2 mt-12">
                                                <div class="col-span-1 pr-10">
                                                    <div class="grid grid-cols-7 mb-2">
                                                        <div class="col-span-2 font-medium">Address</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.address.street" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500" placeholder="Street...">
                                                            <input v-model="form.address.street2" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500" placeholder="Street 2...">
                                                            <div class="grid grid-cols-2 mb-2 gap-2">
                                                                <div class="col-span-1">
                                                                    <autocomplete v-model="subdistrict" @input="onSelectSubdistrict($event)" :url="'indonesia/subdistricts/?cityId='+city._id" :placeholder="'Subdistrict'">
                                                                        <template #default="item">
                                                                            {{item.item.name}}
                                                                        </template>
                                                                    </autocomplete>
                                                                </div>
                                                                <div class="col-span-1">
                                                                    <autocomplete v-model="city" @input="onSelectCity($event)" :url="'indonesia/cities/?provinceId='+province._id" :placeholder="'City'">
                                                                        <template #default="item">
                                                                            {{item.item.name}}
                                                                        </template>
                                                                    </autocomplete>
                                                                </div>
                                                            </div>
                                                            <div class="grid grid-cols-3 gap-2">
                                                                <div class="col-span-2">
                                                                    <autocomplete v-model="province" :placeholder="'Province'" @input="onSelectProvince($event)" :url="'indonesia/provinces'">
                                                                        <template #default="item">
                                                                            {{item.item.name}}
                                                                        </template>
                                                                    </autocomplete>
                                                                </div>
                                                                <div class="col-span-1">
                                                                    <input v-model="form.address.zip" type="text" class="w-full border-b focus:outline-none focus:border-teal-500" placeholder="ZIP">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7 mb-2">
                                                        <div class="col-span-2 font-medium">Customer Code</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.customerCode" type="text" class="w-full border-b focus:outline-none focus:border-teal-500" placeholder="DPI-CS-001">
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">NPWP</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.npwp" type="text" class="w-full border-b focus:outline-none focus:border-teal-500" placeholder="e.g. 4539202334320839">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-span-1 pr-10">
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">Phone</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.contact.phone" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500">
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">Mobile</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.contact.mobile" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500">
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">Email</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.contact.email" type="email" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500">
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">Website</div>
                                                        <div class="col-span-5">
                                                            <input v-model="form.contact.website" type="text" class="w-full border-b focus:outline-none mb-1 focus:border-teal-500" placeholder="e.g. https://dutapersada.co.id">
                                                        </div>
                                                    </div>
                                                    <div class="grid grid-cols-7">
                                                        <div class="col-span-2 font-medium">Tags</div>
                                                        <div class="col-span-5">
                                                            <tags-input v-model="form.tags"></tags-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- TABS MENU -->
                                        <div class="flex whitespace-nowrap cursor-pointer border-b relative">
                                            <div @click="onTab('contact')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='contact'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">CONTACT & ADDRESS</div>
                                            <div @click="onTab('sales')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='sales'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">SALES & PURCHASE</div>
                                            <div @click="onTab('remarks')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='remarks'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">REMARKS</div>
                                        </div>

                                        <!-- CONATACT & ADDRESS -->
                                        <div v-show="tab == 'contact'" class="p-5">
                                            <button  type="button" @click="onShowModalContact" class="px-3 h-8 border mb-2 border-transparent rounded-sm hover:border-teal-500 hover:bg-teal-50 font-medium text-teal-500">ADD</button>
                                            <ul class="grid grid-cols-2 gap-2">
                                                <li v-for="(child, i) in form.children" :key="i" @click="onEditContact(i)" class="border rounded-sm p-2 cursor-pointer hover:border-teal-500">
                                                    <div class="flex items-center">
                                                        <div>
                                                            <i v-if="child.type == 'invoice'" class="icon-cash4 icon-4x text-red-500"></i>
                                                            <i v-else-if="child.type == 'delivery'" class="icon-truck icon-4x text-red-500"></i>
                                                            <i v-else class="icon-user icon-4x text-gray-300"></i>
                                                        </div>
                                                        <div class="ml-3">
                                                            <div class="font-medium">{{child.name}}</div>
                                                            <div>{{child.address.zip}} {{child.address.subdistrictName}}</div>
                                                            <div>{{child.address.cityName}} {{child.address.provinceName}}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- SALES & PURCHASE -->
                                        <div v-show="tab == 'sales'" class="p-5">
                                            sales
                                        </div>
                                        
                                        <!-- REMARKS -->
                                        <div v-show="tab == 'remarks'" class="p-5">
                                            <textarea v-model="form.remarks"  class="w-full focus:outline-none resize-none" placeholder="Remarks..." cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="px-5 py-3 border-t text-right">
                                        <button type="button" @click="onClose" class="h-9 px-5 bg-gray-100 hover:bg-gray-200 border mr-1.5 rounded-sm hover:border-gray-300">DISCARD</button>
                                        <button :disabled="isDisabled" class="h-9 px-5 bg-teal-500 text-teal-50 hover:bg-teal-600 rounded-sm">SAVE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
        <customer-contact :show="modalContact" :title="modalContactTitle" :data="modalContactData" @input="onAddContact($event)" @onClose="modalContact=false"></customer-contact>
        <!-- Modal upload logo -->
        <modal :show="modal">
            <div class="w-1/2 bg-white border rounded-sm">
                <div class="flex p-5 items-center">
                    <div class="text-lg">Customer Logo</div>
                </div>
                <div v-show="image">
                    <div ref="croppie"></div>
                </div>
                <div class="pb-5 flex justify-center">
                    <div class="relative text-white flex items-center bg-teal-600 h-9 w-auto rounded-sm">
                        <i class="icon-file-plus mx-3"></i>
                        <div class="mr-3">Browse file image</div>
                        <input ref="imageInput" @change="onFileChange" type="file" class="absolute border w-full h-9 opacity-0 cursor-pointer" accept=".jpg, .png">
                    </div>
                </div>
                <div class="text-right py-3 px-5 bg-gray-100">
                    <button type="button" @click="closeModal" class="bg-red-500 h-9 text-white px-5 rounded-sm focus:outline-none mr-2 hover:bg-red-600">Close</button>
                    <button type="button" @click="uploadImage" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!image ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!image">Save</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import Autocomplete from '../components/Autocomplete.vue'
import TagsInput from '../components/Tags.vue'
import CustomerContact from '../components/customerContact.vue'
import Modal from '../components/Modal.vue'
import Croppie from 'croppie'
import 'croppie/croppie.css'
export default {
    props: ['show', 'dataCustomer', 'title'],
    components: {Autocomplete, TagsInput, CustomerContact, Modal},
    data () {
        return {
            isDisabled: false,
            tab: 'contact',
            logo: false,
            modal: false,
            image: '',
            croppie: '',
            modalContact: false,
            modalContactData: '',
            modalContactTitle: '',
            childrenIndex : '',
            subdistrict: '',
            city: '',
            province: '',
            form: {
                name: '',
                image: '',
                customerGroup: 'Personal',
                customerCode: '',
                npwp: '',
                address: {
                    street: '',
                    street2: '',
                    subdistrictId: '',
                    subdistrictName: '',
                    cityId: '',
                    cityName: '',
                    provinceId: '',
                    provinceName: '',
                    zip: '',
                },
                contact: {
                    phone: '',
                    mobile: '',
                    email: '',
                    website: ''
                },
                tags: [],
                remarks: '',
                children: [],
                parents: []
            },
            error: {
                name: ''
            }
        }
    },
    mounted () {
        this.initCroppie()
        this.$refs.name.focus()
        this.onResize()
        window.addEventListener('resize', this.onResize)
    },
    destroyed () {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize () {
            this.$refs.modalBody.style.maxHeight = window.innerHeight - 180+'px'
        },
        onTab (t) {
            this.tab = t
        },
        onEditContact(i) {
            this.childrenIndex = i
            let child = this.form.children[i]
            this.modalContactData = {
                subdistrict: {_id: child.address.subdistrictId, name: child.address.subdistrictName},
                city: {_id: child.address.cityId, name: child.address.cityName},
                province: {_id: child.address.provinceId, name: child.address.provinceName},
                form: JSON.parse(JSON.stringify(child))
            }
            this.modalContactTitle = 'EDIT CONTACT'
            this.modalContact = true
        },
        onShowModalContact() {
            this.modalContactData = {
                subdistrict: JSON.parse(JSON.stringify(this.subdistrict)),
                city: JSON.parse(JSON.stringify(this.city)),
                province: this.province,
                address: JSON.parse(JSON.stringify(this.form.address))
            }
            this.modalContactTitle = 'CREATE CONTACT'
            this.modalContact = true
        },
        onSubmit () {
            
            if(!this.form.name) {
                this.error.name = true
            }
            if(this.form.name) {
                this.isDisabled = true
                this.$emit('setDisabled', true)
                let formData = new FormData()
                formData.append('name', this.form.name)
                formData.append('image', this.form.image)
                formData.append('customerGroup', this.form.customerGroup)
                formData.append('customerCode', this.form.customerCode)
                formData.append('npwp', this.form.npwp)
                formData.append('address', JSON.stringify(this.form.address))
                formData.append('contact', JSON.stringify(this.form.contact))
                formData.append('remarks', this.form.remarks)
                formData.append('children', JSON.stringify(this.form.children))
                formData.append('tags', JSON.stringify(this.form.tags))
                if(this.title == 'CREATE CUSTOMER') {
                    axios.post('/customers/create', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        this.$emit('input', res.data)
                        this.onClose()
                        this.isDisabled = false
                    })
                    .catch(err => {
                        this.isDisabled = false
                        console.log(err)
                    })
                }
                if(this.title == 'EDIT CUSTOMER') {
                    axios.put('/customers/update/'+this.form._id, formData, {
                         headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.$emit('input', res.data)
                        this.onClose()
                        this.isDisabled = false
                    })
                    .catch(err => {
                        this.isDisabled = false
                        console.log(err);
                    }) 
                }
            }

        },
        onSelectSubdistrict (e) {
            if(e) {
                this.form.address.subdistrictId = e._id;
                this.form.address.subdistrictName = e.name
                axios.get('/indonesia/cities/', {
                    params: {
                        cityId: e.city_id
                    }
                })
                .then(cities => {
                    this.city = cities.data[0]
                    this.form.address.cityId = this.city._id
                    this.form.address.cityName = this.city.name
                    axios.get('indonesia/provinces')
                    .then(provinces => {
                        this.province = provinces.data.find(obj => obj._id == cities.data[0].province_id)
                        this.form.address.provinceId = this.province._id
                        this.form.address.provinceName = this.province.name
                    })
                })
            } else {
                this.form.address.subdistrictId = '';
                this.form.address.subdistrictName = ''
            }
        },
        onSelectCity (e) {
            this.subdistrict = ''
            if(e) {
                this.form.address.cityId = e._id;
                this.form.address.cityName = e.name
                axios.get('indonesia/provinces')
                .then(provinces => {
                    this.province = provinces.data.find(obj => obj._id == e.province_id)
                    this.form.address.provinceId = this.province._id
                    this.form.address.provinceName = this.province.name
                })
            } else {
                this.form.address.cityId = '';
                this.form.address.cityName = ''
            }
        },
        onSelectProvince (e) {
            this.city = ''
            if(e) {
                this.form.address.provinceId = e._id
                this.form.address.provinceName = e.name
            } else {
                this.form.address.provinceId = ''
                this.form.address.provinceName = ''
            }
        },
        onAddContact (e) {
            let child = JSON.parse(JSON.stringify(e))
            if(this.modalContactTitle == 'CREATE CONTACT') {
                this.form.children.push(child)
            } else {
                this.form.children[this.childrenIndex] = child
            }
        },

        // IMAGE LOGO FUNCTION
        removeImage () {
            this.logo = ''
            this.form.image = ''
        },
        initCroppie () {
            let el = this.$refs.croppie
            let opt = {
                viewport: {
                    width: 250,
                    height: 250,
                },
                boundary: {
                    width: 300,
                    height: 300
                },
            }
            this.croppie = new Croppie(el, opt)
        },
        onFileChange (e) {
            const file = e.target.files[0]
            if(file) {
                this.image = URL.createObjectURL(file)
            }
            this.croppie.bind(this.image)
        },
        uploadImage() {
            let app = this
            this.uploadLoading = true
            this.croppie.result('blob', {format: 'png'})
            .then(output => {
                this.form.image = output;
                let reader = new FileReader();
                reader.readAsDataURL(output); 
                reader.onloadend = function() {
                    app.logo = reader.result;   
                    app.modal = false
                }
            })
        },
        closeModal () {
            this.$refs.imageInput.value = ''
            this.modal = false
            setTimeout(()=> {
                this.image = ''
            }, 100)
        },
        onClose () {
            this.$emit('onClose')
            this.logo = ''
            this.subdistrict = '',
            this.city = '',
            this.province = '',
            this.form = {
                name: '',
                image: '',
                customerGroup: 'Personal',
                customerCode: '',
                npwp: '',
                address: {
                    street: '',
                    street2: '',
                    subdistrictId: '',
                    subdistrictName: '',
                    cityId: '',
                    cityName: '',
                    provinceId: '',
                    provinceName: '',
                    zip: '',
                },
                contact: {
                    phone: '',
                    mobile: '',
                    email: '',
                    website: ''
                },
                tags: [],
                remarks: '',
                children: [],
                parents: []
            }
        }
    },
    watch: {
        'form.customerGroup': function (val) {
            if(val) {
                this.$refs.name.focus()
            }
        },
        show (val) {
            if(val) {
                if(this.title == 'CREATE CUSTOMER') {
                    this.form.name = this.dataCustomer
                }
                if(this.title == 'EDIT CUSTOMER') {
                    let val = this.dataCustomer
                    this.subdistrict = {_id: val.address.subdistrictId, name: val.address.subdistrictName}
                    this.city = {_id: val.address.cityId, name: val.address.cityName}
                    this.province  = {_id: val.address.provinceId, name: val.address.provinceName}
                    this.form = val
                    if(val.image) {
                        this.logo = process.env.VUE_APP_ROOT_API+'/'+val.image
                        this.form.image = ''

                    }
                }
            }
        },
    }
}
</script>