<template>
    <div ref="popover" class="relative flex justify-center h-full w-full">
        <div ref="body" v-show="show" :style="[body ? {bottom: bottom }: {top:bottom}]" :class="places" class="absolute shadow-lg text-left w-max max-w-sm animated faster fadeIn z-20 bg-gray-50 border flex inset-x-auto text-gray-600 p-3 rounded-sm">
            <slot name="body"></slot>
            <div class="absolute transform rotate-45 bg-gray-50 h-3.5 w-3.5" :class="[body ? '-bottom-2 border-r border-b': '-top-2 border-t border-l']"></div>
        </div>
        <div @click="onShow" class="w-full h-full">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        place: String
    },
    data () {
        return {
            show: false,
            bottom: '',
            body: ''
        }
    },
    mounted () {
        window.addEventListener('click', this.close)
        window.addEventListener('scroll', this.position)
    },
    destroyed () {
        window.removeEventListener('click', this.close)  
        window.removeEventListener('scroll', this.position)
    },
    computed: {
        places () {
            let p = ''
            if(this.place == 'left') {
                p ='left-0 justify-start'
            }
            if(this.place == 'center') {
                p = 'justify-center'
            }
            if(this.place == 'right') {
                p = 'right-0 justify-end'
            }
            return p
        }
    },
    methods: {
        onShow () {
            this.show = true
            this.$nextTick(() => {
                this.position()
            })
        },
        close (e) {
            if(!e || !this.$refs.popover.contains(e.target) || this.$refs.body.contains(e.target)) {
                this.show = false
            }
        },
        position () {
            let window_height = (window.innerHeight || document.documentElement.clientHeight)
            let rect = this.$refs.popover.getBoundingClientRect()
            this.bottom = this.$refs.popover.clientHeight+10+'px'
            this.body = (rect.bottom > window_height - 200) && (rect.top >= rect.height)
            if(rect.bottom > window_height + 33 || rect.top <= rect.height) {
                this.show = false
            }
        }
    }
}
</script>