<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-50 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class="w-2/3 bg-white border rounded-sm cursor-default">
                                <div class="pl-5 pr-3 py-3 border-b flex items-center justify-between">
                                    <div class="font-semibold">CREATE PRODUCT</div>
                                    <a @click.prevent="$emit('onClose')" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500" style="font-size: 19px"></i></a>
                                </div>
                                <form @submit.prevent="onSubmit">
                                    <div class="p-5">
                                        <div class="mb-5">
                                            <label for="">Product Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                            <input v-model="form.name" @keydown.tab="$refs.brand.open"  type="text" :class="{'border-red-500': error.name}" @keydown="error.name=''" class="form-control mt-2" placeholder="Product name">
                                            <span class="text-red-500">{{error.name}}</span>
                                        </div>
                                        <div class="mb-5 grid grid-cols-3 gap-5">
                                            <div :class="{'err': error.brandId}" class="flex flex-col">
                                                <div class="mb-2">
                                                    <label for="">Brand <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                                </div>
                                                <select-2 ref="brand" v-model="form.brandId" @input="error.brandId=''" :options="brandLists" :sort="'asc'" @onAdd="onShowModalBrand" :button="true" :buttonText="'+ add new brand'" class="form-control err" :placeholder="'Select brand'">
                                                    <option value=""></option>
                                                </select-2>
                                                <span class="text-red-500">{{error.brandId}}</span>
                                            </div>
                                            <div>
                                                <div class="mb-2">
                                                    <label for="">Model / Type</label>
                                                </div>
                                                <input v-model="form.model" type="text" class="form-control" placeholder="Model / Type">
                                            </div>
                                            <div :class="{'err': error.categoriesId}" class="flex flex-col">
                                                <div class="mb-2">
                                                    <label for="">Category <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                                </div>
                                                <select-2 v-model="form.categoriesId" @input="error.categoriesId=''" :options="categoryLists" :sort="'asc'" @onAdd="onShowModalCategory" :button="true" :buttonText="'+ add new category'" class="form-control" :placeholder="'Select category'" :multiple="'multiple'">
                                                    <option value=""></option>
                                                </select-2>
                                                <span class="text-red-500">{{error.categoriesId}}</span>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-12 mb-5">
                                            <div class="col-span-1">
                                                Description
                                            </div>
                                            <div class="col-span-11">
                                                <textarea v-model="form.description" cols="30" rows="5" class="w-full border rounded-sm focus:outline-none p-2"></textarea>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-3 gap-5 mb-5">
                                            <div class="col-span-2 relative">
                                                <label for="">SKU / Barcode <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                                <div class="flex mt-2">
                                                    <span :class="{'border-red-500': error.sku}" class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-barcode2"></i></span>
                                                    <input v-model="form.sku" type="text" :class="{'border-red-500': error.sku}" class="text-center block w-full h-9 border border-l-0 border-r-0 px-3 focus:outline-none" placeholder="SKU or Barcode">
                                                    <button @click.prevent="onGenerate" type="button" :class="[error.sku ? 'border-red-500':'border-teal-600']" class="h-9 border rounded-r-sm px-3 bg-teal-600 text-white focus:outline-none hover:bg-teal-700">Generated</button>
                                                </div>
                                            </div>
                                            <div class="col-span-1 mx-auto">
                                                <div class="mb-3">Serial Number <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></div>
                                                <div class="form-radio flex justify-center">
                                                    <input v-model="form.isSerialNumber" id="serialnumber" type="checkbox">
                                                    <label for="serialnumber">Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-3 gap-5 mb-5">
                                            <div class="col-span-1 relative">
                                                <label for="">Selling Price <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                                <div class="mt-2">
                                                    <autonumeric v-model="form.sellingPrice" :options="{modifyValueOnWheel : false}" @input="error.sellingPrice=''" :class="{'border-red-500': error.sellingPrice}" class="form-control text-right"></autonumeric>
                                                </div>
                                                <span class="text-red-500">{{error.sellingPrice}}</span>
                                            </div>
                                            <div class="col-span-1">
                                                <label for="">Net Price</label>
                                                <div class="mt-2">
                                                    <autonumeric v-model="form.netPrice" :options="{modifyValueOnWheel : false}" class="form-control text-right"></autonumeric>
                                                </div>
                                            </div>
                                            <div class="col-span-1">
                                                <label for=""><span class="capitalize">Weight</span> <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                                                <div class="flex mt-2">
                                                    <autonumeric v-model="form.measurements.weight.value" :options="{modifyValueOnWheel : false}" @input="error.weight=''" :class="{'border-red-500': error.weight}" class="h-9 border w-full border-r-0 rounded-l-sm text-right focus:outline-none px-3"></autonumeric>
                                                    <dropdown 
                                                        :items="weights"
                                                        class="w-28"
                                                        v-model="form.measurements.weight.unit"
                                                        >
                                                        <template #default="data">
                                                            <div class="cursor-pointer border rounded-sm rounded-l-none bg-gray-100 h-9 flex items-center" :class="[data.show ? 'rounded-b-none':'', {'border-red-500': error.weight}]">
                                                                <button class="w-full flex h-full items-center px-3 focus:outline-none" tabindex="-1">
                                                                    <span class="mr-auto flex items-center">{{form.measurements.weight.unit}}</span>
                                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                                </button>
                                                            </div>
                                                        </template>
                                                        <template #items="items">
                                                            <div>{{items.item.name}}</div>
                                                        </template>
                                                    </dropdown>
                                                </div>
                                                <span class="text-red-500">{{error.weight}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right py-3 px-5 bg-gray-100">
                                        <button type="button" @click="$emit('onClose')" class="bg-red-500 h-9 text-white px-5 rounded-sm focus:outline-none mr-2 hover:bg-red-600">Close</button>
                                        <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none bg-teal-500" :disabled="isDisabled">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
        <modal :show="modalNewBrand">
            <form @submit.prevent="onSubmitBrand" class="bg-white border rounded-sm p-5 w-1/2 vld-parent">
                <div class="mb-5">
                    <h6 class="text-lg uppercase">Add New Brand</h6>
                </div>
                <div>
                    <div class="mb-5">
                        <label for="">Brand Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <input v-model="formBrand.name" ref="brandname" type="text" class="form-control my-2" :class="{'border-red-500': errorBrand.name}" @keydown="errorBrand.name = ''" placeholder="Brand name">
                        <span class="text-red-500">{{errorBrand.name}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Description <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></label>
                        <input v-model="formBrand.description" type="text" class="form-control mt-2" placeholder="Description">
                    </div>
                    <div class="mb-5">
                        <div class="mb-2">LOGO</div>
                        <div class="group relative flex items-stretch cursor-pointer">
                            <input ref="fileImage" @change="onLogoBrandChange" type="file" class="w-full absolute z-10 opacity-0 cursor-pointer">
                            <span class="flex w-full items-center bg-white px-3 border h-9 rounded-l-sm cursor-pointer whitespace-nowrap overflow-hidden">{{logBrandName}}</span>
                            <span class="bg-blue-500 h-9 whitespace-nowrap px-3 flex items-center rounded-r-sm text-white group-hover:bg-blue-600 cursor-pointer">Choose image</span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <button @click="onCloseModalBrand" type="button" class="h-9 px-5 bg-red-600 mr-2 text-white rounded-sm focus:outline-none hover:bg-red-700">Cancel</button>
                    <button class="h-9 px-5 bg-teal-600 text-white rounded-sm focus:outline-none hover:bg-teal-700" :disabled="isDisabled">Save</button>
                </div>
            </form>
        </modal>

        <modal :show="modalCategory">
            <form @submit.prevent="onSubmitCategory" class="bg-white border rounded-sm p-5 w-1/2 vld-parent">
                <div class="mb-5">
                    <h6 class="text-lg uppercase">Add New Category</h6>
                </div>
                <div>
                    <div class="mb-5">
                        <label for="">Category Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <input v-model="formCategory.name" ref="categoryname" type="text" class="form-control my-2" :class="{'border-red-500': errorCategory.name}" @keydown="errorCategory.name = ''" placeholder="Category name">
                        <span class="text-red-500">{{errorCategory.name}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Description <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></label>
                        <input v-model="formCategory.description" type="text" class="form-control mt-2" placeholder="Description">
                    </div>
                </div>
                <div class="flex justify-end">
                    <button @click="onCloseModalCategory" type="button" class="h-9 px-5 bg-red-600 mr-2 text-white rounded-sm focus:outline-none hover:bg-red-700">Cancel</button>
                    <button class="h-9 px-5 bg-teal-600 text-white rounded-sm focus:outline-none hover:bg-teal-700" :disabled="isDisabled">Save</button>
                </div>
            </form>
        </modal>
    </div>
</template>
<script>
import Select2 from '../components/Select2.vue'
import Autonumeric from '../components/Autonumeric.vue'
import Dropdown from '../components/Dropdown.vue'
import axios from 'axios'
import Modal from '../components/Modal.vue'
export default {
    props: {
        show: Boolean
    },
    components: {Select2, Autonumeric, Dropdown, Modal},
    data () {
        return {
            isDisabled: false,
            warehouses: [],
            brands: [],
            modalNewBrand: false,
            logBrandName: '',
            formBrand: {
                name: '',
                description: '',
                logo: ''
            },
            errorBrand: {
                name: ''
            },
            categories: [],
            modalCategory: false,
            formCategory: {
                name: '',
                description: ''
            },
            errorCategory: {
                name: ''
            },
            weights: [{name: 'gr'},{name: 'kg'},{name: 'oz'},{name: 'lb'}],
            accessories: {
                status: false,
                accessories: []
            },
            form: {
                name: '',
                brandId: '',
                model: '',
                categoriesId: [],
                condition: 'NEW',
                description: '',
                videos: [],
                sku: '',
                isSerialNumber: false,
                currency: '',
                currencySymbol: '',
                purchasePrice: 0,
                sellingPrice: '',
                netPrice: 0,
                wholesale: [],
                measurements: {
                    width: {value: '', unit: 'mm'},
                    height: {value: '',unit: 'mm'},
                    depth: {value: '',unit: 'mm'},
                    weight: {value: '',unit: 'gr'},
                },
                notes: {
                    status: false,
                    internal: '',
                    external: '',
                },
                accessories: [],
                preorder: {
                    isActive: false,
                    duration: '',
                    timeUnit: 'DAY'
                },
                warehouseId: '',
                sectionId: '',
                qtyStock: '',
            },
            error: {
                images: '',
                name: '',
                brandId: '',
                categoriesId: '',
                description: '',
                sku: '',
                warehouseId: '',
                sellingPrice: '',
                weight: '',
            }
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        brandLists () {
            return this.brands.map(obj => {
                obj.id = obj._id
                obj.text = obj.name.replace(/\b\w/g, (c) => c.toUpperCase())
                return obj
            })
        },
        categoryLists () {
            return this.categories.map(obj => {
                obj.id = obj._id
                obj.text = obj.name.replace(/\b\w/g, (c) => c.toUpperCase())
                return obj
            })
        },
        productAccessories () {
            return this.accessories.accessories.map(obj => {
                return obj._id
            })
        }
    },
    methods: {
        getData () {
            axios.get('/products/create')
            .then(res => {
                this.brands = res.data.brands
                this.categories = res.data.categories
                this.currencies = res.data.currencies,
                this.warehouses = res.data.warehouses
                this.$nextTick(() => {
                    const isDefault = this.warehouses.find(wr => wr.isDefault == true)
                    this.form.sectionId = isDefault.sections[0]._id
                    this.form.warehouseId = isDefault._id
                })
            })
        },
        async onSubmit () {
            if(!this.form.measurements.weight.value) {
                this.error.weight = 'Weight is required'
            }
            if(!this.form.sellingPrice) {
                this.error.sellingPrice = 'Selling price is required'
            }
            if(!this.form.sku) {
                this.error.sku = 'SKU is required'
            }
            if(!this.form.warehouseId) {
                this.error.warehouseId = 'Warehause is required'
            }
            if(this.form.categoriesId.length < 1) {
                this.error.categoriesId = 'Category is required'
            }
            if(!this.form.brandId) {
                this.error.brandId = 'Brand is required'
            }
            if(!this.form.name) {
                this.error.name = 'Product name is required'
            }
            if(this.form.measurements.weight.value && this.form.sellingPrice && this.form.sku && this.form.categoriesId.length > 0 && this.form.brandId && this.form.name) {
                this.isDisabled = true
                const formData = new FormData()
                const categories = JSON.stringify(this.form.categoriesId)
                const videos = JSON.stringify(this.form.videos)
                const wholesale = JSON.stringify(this.form.wholesale)
                const measurements = JSON.stringify(this.form.measurements)
                const notes = JSON.stringify(this.form.notes)
                const accessories = JSON.stringify(this.productAccessories)
                const preorder = JSON.stringify(this.form.preorder)
                formData.append('name', this.form.name)
                formData.append('brandId', this.form.brandId)
                formData.append('model', this.form.model)
                formData.append('categoriesId', categories)
                formData.append('condition', this.form.condition)
                formData.append('description', this.form.description)
                formData.append('videos', videos)
                formData.append('sku', this.form.sku)
                formData.append('isSerialNumber', this.form.isSerialNumber)
                formData.append('currency', this.form.currency)
                formData.append('currencySymbol', this.form.currencySymbol)
                formData.append('purchasePrice', this.form.purchasePrice)
                formData.append('sellingPrice', this.form.sellingPrice)
                formData.append('netPrice', this.form.netPrice)
                formData.append('wholesale', wholesale)
                formData.append('measurements', measurements)
                formData.append('notes', notes)
                formData.append('accessories', accessories)
                formData.append('preorder', preorder)
                formData.append('warehouseId', this.form.warehouseId)
                formData.append('sectionId', this.form.sectionId)
                formData.append('qtyStock', this.form.qtyStock)
                axios.post('/products/create', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.isDisabled = false
                    this.$emit('onSet', res.data)
                })
                .catch((err) => {
                    console.log(err);
                    this.isDisabled = false
                    this.error.sku = 'SKU is already exists'
                })
            }
        },
        // BRAND FUNCTION
        onShowModalBrand () {

            this.modalNewBrand = true
            this.$nextTick(() => {
                this.$refs.brandname.focus()
            })
        },
        onCloseModalBrand () {
            this.modalNewBrand = false
            this.formBrand.name = ''
            this.formBrand.description = ''
            this.errorBrand.name = ''
        },
        onLogoBrandChange (e) {
            const file = e.target.files[0]
            this.logBrandName = file.name
            this.formBrand.logo = file
        },
        onSubmitBrand () {
            if(!this.formBrand.name) {
                this.errorBrand.name = 'Brand name is required'
            }
            if(this.formBrand.name) {
                this.isDisabled = true
                const formData = new FormData()
                formData.append('name', this.formBrand.name)
                formData.append('description', this.formBrand.description)
                formData.append('logo', this.formBrand.logo)

                axios.post('/brands/create', formData, {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.modalNewBrand = false
                    this.isDisabled = false
                    this.brands.push(res.data)
                    this.$nextTick(() => {
                        this.form.brandId = res.data._id
                    })
                    this.formBrand.name = ''
                    this.formBrand.description = ''
                    this.errorBrand.name = ''
                })
                .catch(() => {
                    this.isDisabled = false
                    this.errorBrand.name = 'Brand name is already exists'
                })
            }
        },

        // CATEGORY FUNCTION
        onShowModalCategory () {
            this.modalCategory = true
            this.$nextTick(() => {
                this.$refs.categoryname.focus()
            })
        },
        onCloseModalCategory () {
            this.modalCategory = false
            this.formCategory.name = ''
            this.formCategory.description  = ''
            this.errorCategory.name = ''
        },
        onSubmitCategory () {
            if(!this.formCategory.name) {
                this.errorCategory.name = 'Category name is required'
            }
            if(this.formCategory.name) {
                this.isDisabled = true
                axios.post('/categories/create', this.formCategory)
                .then(res => {
                    this.isDisabled = false
                    this.modalCategory = false
                    this.categories.push(res.data)
                    this.$nextTick(() => {
                        this.form.categoriesId.push(res.data._id)
                    })
                    this.formCategory.name = ''
                    this.formCategory.description = ''
                    this.errorCategory.name = ''
                })
                .catch(() => {
                    this.isDisabled = false
                    this.errorCategory.name = 'Category name is already exists'
                })
            }
        },

        // SKU GENERATE FUNCTION
        onGenerate () {
            this.form.sku = 'DPI-'+new Date().getTime()
            this.error.sku = ''
        },
    }
}
</script>

<style>
    .err .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
        border-color: red !important;
    }
    .err .select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
        border-color: red !important;
    }
</style>