<template>
    <div class="m-5">
        <div class="flex items-center justify-between mb-5">
            <div class="text-lg font-medium">User Management</div>
            <button @click="onCreate" class="bg-blue-500 h-9 px-5 rounded-sm hover:bg-blue-600 text-blue-50">NEW USER</button>
        </div>
        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="bg-gray-500 text-gray-50 px-5 py-3">USER NAME</th>
                        <th class="bg-gray-500 text-gray-50 px-5 py-3">EMAIL</th>
                        <th class="bg-gray-500 text-gray-50 px-5 py-3">ROLE</th>
                        <th class="bg-gray-500 text-gray-50 px-5 py-3">DATE CREATED</th>
                        <th class="bg-gray-500 text-gray-50 px-5 py-3 text-center" style="width: 5%">ACTION</th>
                    </tr>
                </thead>
                <tbody class="border">
                    <tr v-for="(user, i) in users" :key="i" class="border-b">
                        <td class="py-2 px-5">{{user.name}}</td>
                        <td class="py-2 px-5">{{user.email}}</td>
                        <td class="py-2 px-5">{{user.role}}</td>
                        <td class="py-2 px-5">{{user.createdAt}}</td>
                        <td class="py-2 px-5 flex items-center justify-center">
                            <button @click="onEdit(user)" class="rounded-sm h-6 w-6 border-2 border-gray-600 flex items-center justify-center"><i class="icon-key"></i></button>
                            <button @click="onEdit(user)" class="rounded-sm h-6 w-6 border-2 border-gray-600 flex items-center justify-center ml-1"><i class="icon-pencil"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <slider :show="slider">
            <form @submit.prevent="onSubmit" class="relative w-screen max-w-md">
                <div class="h-full flex flex-col bg-white">
                    <div class="px-5 py-3 bg-gray-200 text-sm font-semibold flex items-center">
                        <div class="uppercase">{{sliderTitle}}</div>
                    </div>

                    <div class="p-5">
                        <div class="mb-5">
                            <label for="">User Name</label>
                            <input v-model="form.name" :class="{'border-red-500': err.name}" @keydown="err.name = ''" type="text" class="form-control mt-2">
                        </div>
                        <div class="mb-5">
                            <label for="">Email</label>
                            <input v-model="form.email" :class="{'border-red-500': err.email}" @keydown="err.email = ''" type="email" class="form-control mt-2">
                        </div>
                        <div v-if="sliderTitle == 'NEW USER'" class="mb-5">
                            <label for="">Password</label>
                            <input v-model="form.password" :class="{'border-red-500': err.password}" @keydown="err.password =''" type="password" class="form-control mt-2">
                        </div>
                        <div class="mb-5">
                            <label for="">Role</label>
                            <div class="flex flex-col mt-2" :class="{'err': err.role}">
                                <select-2 v-model="form.role" class="form-control" :placeholder="'Select Role'">
                                    <option value=""></option>
                                    <option value="Admin">Admin</option>
                                    <option value="Staff">Staff</option>
                                </select-2>
                            </div>
                        </div>
                    </div>

                    <div class="fixed bg-gray-200 bottom-0 max-w-md w-full px-5 py-3 text-right z-20">
                        <button type="button" @click="slider = false" class="bg-red-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-red-600 mr-2">Close</button>
                        <button class="relative bg-teal-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-teal-600" :disabled="isDisabled">
                            <span>Save</span>
                            <div v-show="isDisabled" class="absolute bg-teal-400 w-full h-full left-0 top-0 rounded-sm flex items-center justify-center">
                                <div>
                                    <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </slider>
    </div>
</template>

<script>
import axios from 'axios'
import slider from '../../components/slider.vue'
import Select2 from '../../components/Select2.vue'
export default {
    components: {slider, Select2},
    data () {
        return {
            isDisabled: false,
            slider: false,
            sliderTitle: 'NEW USER',
            form: {
                name: '',
                email: '',
                password: '',
                role: '',
            },
            err: {
                name: '',
                email: '',
                password: '',
                role: ''
            },
            users: []
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            axios.get('/auth')
            .then(res => {
                this.users = res.data
            })
        },
        onCreate () {
            this.slider = true
            this.sliderTitle = 'NEW USER'
        },
        onEdit (user) {
            this.sliderTitle = 'EDIT USER'
            this.form = JSON.parse(JSON.stringify(user))
            this.slider = true
        },
        onSubmit () {
            if(!this.form.name) {
                this.err.name = true
            }
            if(!this.form.email) {
                this.err.email = true
            }
            if(!this.form.password) {
                this.err.password = true
            }
            if(!this.form.role) {
                this.err.role = true
            }
            if(this.sliderTitle == 'NEW USER') {
                if(this.form.name && this.form.email && this.form.password && this.form.role) {
                    this.isDisabled = true
                    axios.post('/auth/register', this.form)
                    .then(() => {
                        this.isDisabled = false
                        this.getData()
                        this.slider = false
                    })
                }
            } else {
                if(this.form.name && this.form.email && this.form.role) {
                    this.isDisabled = true
                    axios.put('/auth/update/'+this.form._id, this.form)
                    .then(() => {
                        this.isDisabled = false
                        this.getData()
                        this.slider = false
                    })
                }
            }
        }
    },
    watch: {
        'form.role': function() {
            this.err.role = ''
        },
        slider (val) {
            if(!val) {
                this.err = {
                    name: '',
                    email: '',
                    password: '',
                    role: ''
                }
                this.form = {
                    name: '',
                    email: '',
                    password: '',
                    role: ''
                }
            }
        }
    }
}
</script>