import { render, staticRenderFns } from "./productOverview.vue?vue&type=template&id=4c1300d3&"
import script from "./productOverview.vue?vue&type=script&lang=js&"
export * from "./productOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports