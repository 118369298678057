<template>
    <div>
        <div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
             <div>
                <stepper>
                    <li><button :class="{'active': purchase.status == 'Draft'}">Draft</button></li>
                    <li><button :class="{'active': purchase.status == 'PO Sent'}">PO SENT</button></li>
                    <li><button :class="{'active': purchase.status == 'PO Firm'}">PO FIRM</button></li>
                    <li v-if="purchase.status == 'Cancelled'"><button :class="{'active': purchase.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="purchase.status == 'Draft'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button @click="onConfirm" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CONFIRM</button>
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">SEND BY EMAIL</button>
            </div>
            <div v-if="purchase.status == 'PO Firm' && purchase.receiveStatus == 'Nothing to Receive' && purchase.billingStatus == 'Nothing to Bill'">
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CREATE BILL</button>
                <button @click="onReceiveProducts" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">RECEIVE PRODUCTS</button>
            </div>
            <div v-if="purchase.status == 'PO Firm' && purchase.billingStatus == 'Waiting Bills'">
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CREATE BILL</button>
            </div>
            <div v-if="purchase.status == 'Cancelled'">
                <button @click="onSetPurchase" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">SET TO DRAFT</button>
            </div>
        </div>

        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                        <div @click="onTab('history')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='history'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">HISTORY LOG <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{activities.length}}</span></div>
                    </div>
                    <div class="flex divide-x">
                        <div @click="onReceived" v-show="received.length > 0" class="py-2 px-5 border-l flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-truck text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-3">
                                <div>{{received.length}}</div>
                                <div>Received</div>
                            </div>
                        </div>
                        <div v-show="billed.length > 0" class="py-2 px-5 flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-calculator text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-2">
                                <div class="text-right">1</div>
                                <div>Billed</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                <div class="grid grid-cols-9 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{purchase.no}}</div>
                                <div class="text-gray-400 text-xs">PURCHASE ORDER #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{getDate(purchase.createdAt)}}</div>
                                <div class="text-gray-400 text-xs">DATE CREATED</div>
                            </div>
                        </div>

                        <div class="mb-3" v-if="purchase">
                            <label for="">SUPLLIER</label>
                            <div class="text-base font-medium">{{purchase.supplier}}</div>
                            <div v-if="purchase.address">{{purchase.address.address}}</div>
                            <div v-if="purchase.address">{{purchase.address.city}} {{purchase.address.state}} {{purchase.address.country}} {{purchase.address.postalCode}}</div>
                        </div>

                        <div class="mb-3">
                            <label for="">REFFERENCE NUMBER</label>
                            <div v-if="purchase.referenceNumber">{{purchase.referenceNumber}}</div>
                            <div v-else>--</div>
                        </div>

                        <div class="mb-3">
                            <label for="">REMARKS</label>
                            <div v-if="purchase.remarks">{{purchase.remarks}}</div>
                            <div v-else>--</div>
                        </div>

                        <div v-if="purchase.tags">
                            <label for="" class="font-semibold">TAGS</label>
                            <div v-if="purchase.tags.length > 0" class="flex items-center space-x-0.5">
                                <div v-for="tag in purchase.tags" :key="tag" class="bg-gray-500 text-gray-50 text-xs px-1 rounded-sm py-px">{{tag}}</div>
                            </div>
                            <div v-else>--</div>
                        </div>
                    </div>

                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">ESTIMATED DELIVERY DATE</label>
                                <div>{{getDate(purchase.estimatedReceiveDate)}}</div>
                            </div>
                            <div class="col-span-1">
                                <label class="font-medium" for="">INVOICE DATE</label>
                                <div v-if="purchase.invoiceDate">{{getDate(purchase.invoiceDate)}}</div>
                                <div v-else>--</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="font-medium" for="">SHIP TO</label>
                            <div v-if="purchase.shipTo" class="text-base font-medium">{{purchase.shipTo.company}}</div>
                            <div v-else>--</div>
                        </div>
                        <div class="mb-3">
                            <label class="font-medium" for="">PAYMENT TERM</label>
                            <div v-if="purchase.paymentTerm">{{purchase.paymentTerm}}</div>
                            <div v-else>--</div>
                        </div>
                        <div class="grid grid-cols-2 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">CURRENCY</label>
                                <div>{{purchase.currency}}</div>
                            </div>
                            <div class="col-span-1">
                                <label class="font-medium" for="">EXCHANGE RATE</label>
                                <div>Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.exchangeRate)}}</div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">SHIPMENT TERM</label>
                                <div v-if="purchase.shipmentTerm">{{purchase.shipmentTerm}}</div>
                                <div v-else>--</div>
                            </div>
                            <div class="col-span-1">
                                <label class="font-medium" for="">SHIPMENT METHOD</label>
                                <div v-if="purchase.shipmentMethod">{{purchase.shipmentMethod}}</div>
                                <div v-else>--</div>
                            </div>
                        </div>
                        <div v-if="purchase.additionalCharges">
                            <label for="" class="font-semibold">ADDITIONAL CHARGES</label>
                            <table v-if="purchase.additionalCharges.length > 0" class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr v-for="(adc, i) in purchase.additionalCharges" :key="i">
                                    <td class="py-2 pl-3">{{adc.name}}</td>
                                    <td v-if="adc.isFixed" class="py-2 pr-3 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(adc.unitPrice)}}</td>
                                    <td v-else class="py-2 pr-3 text-right">{{adc.unitPrice}}%</td>
                                </tr>
                            </table>
                            <table v-else class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr>
                                    <td class="py-2 pl-3">--</td>
                                    <td class="py-2 pr-3 text-right">--</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <table class="w-full">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  sticky z-20 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 bg-blue-500" style="width: 40%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">UNIT PRICE</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">QTY</th>
                            <th v-show="purchase.status == 'Purchase Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">RECEIVED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                            <th v-show="purchase.status == 'Purchase Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">BILLED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 11%; top: 93px">DISCOUNT</th>
                            <th class="py-2.5 pl-2 pr-5 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody class="border border-b-0">
                        <tr v-for="(item, i) in purchase.items" :key="i" class="border-b">
                            <td class="pl-5 h-9 border-r">{{i+1}}</td>
                            <td class="px-2 h-9 truncate">{{item.name}}</td>
                            <td class="px-2 h-9 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                            <td class="px-2 h-9 text-right">{{item.qty}}</td>
                            <td v-show="purchase.status == 'Purchase Order'" class="px-2 h-9 text-right">{{item.received}}</td>
                            <td class="h-9 px-1 text-center">
                                <a href="#" :class="[item.qty > item.stock ? 'text-red-500':'text-green-500']"><i class="icon-chart"></i></a>
                            </td>
                            <td v-show="purchase.status == 'Purchase Order'" class="px-2 h-9 text-right">{{item.billed}}</td>
                            <td v-if="item.discount.amount" class="h-9 px-2 text-right text-red-500">
                                <div v-if="item.discount.discountType == 'Fixed'">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.discount.amount)}}</div>
                                <div v-else>{{item.discount.amount}}%</div>
                            </td>
                            <td v-else class="h-9 px-2 text-right">--</td>
                            <td class="h-9 pl-2 pr-5 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                        </tr>
                        <!-- TOTOAL -->
                        <tr>
                            <td v-if="purchase.status == 'Purchase Order'" colspan="7"></td>
                            <td v-else colspan="5"></td>
                            <td class="py-1 px-2 text-right">Subtotal</td>
                            <td class="py-1 pl-2 pr-5 text-right font-medium">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.total)}}</td>
                        </tr>
                        <!-- DISCOUNT -->
                        <tr v-if="purchase.discount.value" class="font-medium text-red-500">
                            <td v-if="purchase.status == 'Purchase Order'" colspan="8" class="px-2 py-1 text-right">Discount on total price</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">Discount on total price</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.discount.value)}}</td>
                        </tr>
                        <!-- TAX -->
                        <tr v-if="purchase.tax.value" class="font-medium text-blue-500">
                            <td v-if="purchase.status == 'Purchase Order'" colspan="8" class="px-2 text-right">
                                {{purchase.tax.code}} ({{purchase.tax.amount}}%)
                            </td>
                            <td v-else colspan="6" class="px-2 text-right">
                                {{purchase.tax.code}} ({{purchase.tax.amount}}%)
                            </td>
                            <td class="py-1 pl-2 pr-5 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.tax.value)}}</td>
                        </tr>
                         <!-- ADDITIONAL CHARGES -->
                        <tr v-if="purchase.totalAdditionalCharges" class="font-medium text-green-600">
                            <td v-if="purchase.status == 'Purchase Order'" colspan="8" class="px-2 py-1 text-right">ADDITIONAL CHARGERS</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">ADDITIONAL CHARGERS	</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.totalAdditionalCharges)}}</td>
                        </tr>
                        <!-- SHIPPING -->
                        <tr v-if="purchase.shipping" class="font-medium">
                            <td v-if="purchase.status == 'Purchase Order'" colspan="8" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(purchase.shipping.shipmentCost)}}</td>
                        </tr>
                        <!-- TOTAL -->
                        <tr class="bg-orange-100">
                            <td></td>
                            <td></td>
                            <td v-if="purchase.status == 'Purchase Order'" colspan="5"></td>
                            <td v-else colspan="3" class="py-1 pl-10 pr-2.5"></td>
                            <td class="text-right text-base font-semibold h-9">TOTAL</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">{{purchase.currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(purchase.grandTotal)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="purchase.no"></task-manager>   
            </div>
        </div>
        <div class="hidden">
            <purchase-pdf id="printJS-form" :data="purchase"></purchase-pdf>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import stepper from '../../components/stepper.vue'
import {moment} from '../../moment'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
import TaskManager from '../../components/taskManager.vue'
import {EventBus} from '../../event-bus'
import PurchasePdf from '../../components/purchasePdf.vue'
import printJS from 'print-js'
export default {
    components: {stepper, TaskManager, PurchasePdf},
    mixins: [BreadcrumbsManager],
    data () {
        return {
            tab: 'overview',
            received: [],
            billed: [],
            purchase: {
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                },
            },
            task: 0,
            activities: []
        }
    },
    mounted () {
        this.setButtonText('EDIT')
        this.setDiscard(true)
        this.setDiscardText('CREATE')
        this.setAction(true)
        this.getData()
        EventBus.$on('onClick', this.onEdit)
        EventBus.$on('onCancel', this.onCreate)
        EventBus.$on('onPrint', this.onPrint);
    },
    destroyed () {
        this.setAction(false)
        EventBus.$off('onClick', this.onEdit)
        EventBus.$off('onCancel', this.onCreate)
        EventBus.$off('onPrint', this.onPrint)
    },
    methods: {
        onTab (i) {
            this.tab = i
        },
        getData () {
            axios.get('/purchases/detail/'+this.$route.params.id)
            .then(res => {
                this.purchase = res.data
                this.setBreadcrumbs([
                    {text: 'Purchase Orders', path: '/purchases'},
                    {text: res.data.no}
                ])
            })
        },
        getDate (d) {
            return moment(d)
        },
        onEdit () {
            this.$router.push('/purchases/edit/'+this.$route.params.id)
        },
        onCreate () {
            this.$router.push('/purchases/new')
        },  
        onConfirm () {
            axios.put('/purchases/confirm/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onCancelled () {
            axios.put('/purchases/cancelled/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onSetPurchase () {
            axios.put('/purchases/settorfq/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onReceiveProducts () {
            axios.put('/purchases/receive/'+this.$route.params.id)
            .then(() => {
                this.getData()
            })
        },
        onPrint () {
            let title = this.purchase.no.replace(/\//g, ".") +" ("+ this.purchase.supplier+")"
            document.title = title
            this.$nextTick(() => {
                printJS({
                    printable: 'printJS-form', 
                    type: 'html',
                    css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                    scanStyles: false,
                    documentTitle: title,
                    onPrintDialogClose: () => this.closePrint()
                })
            })
        },
        closePrint () {
            document.title ='APP DUTAPERSADA'
        },
        onReceived () {

        }
    }
}
</script>