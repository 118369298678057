<template>
    <div class="relative">
        <button :disabled="isDisabled" @click="show = !show" class="w-full leading-none bg-white flex items-center justify-end hover:bg-gray-100 p-1 rounded-sm relative" :class="{'rounded-b-none': show}"> 
            <span v-if="text" class="mr-6">{{text}}</span>
            <span v-else class="text-gray-400 mr-6">Select Tax</span>
            <span v-show="!text" class="absolute right-0.5"><i class="icon-arrow-down22"></i></span>
            <a v-show="text" @click.prevent="onClear" href="#" class="absolute inline-block right-0.5 leading-none hover:text-red-500"><i class="icon-cross3"></i></a>
        </button>
        <ul v-show="show" class="absolute bg-white w-full border rounded-b-sm left-0 -mt-0.5 top-6 z-20 shadow-lg pb-1 pt-2">
            <li v-for="(item, i) in options" :key="i" @click="onSelect(item)" class="py-1.5 px-3 text-left hover:bg-gray-100 cursor-pointer" :class="{'bg-blue-500 text-blue-50 hover:bg-blue-500': item._id == id }">{{item.code}} ({{item.amount}}%)</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['value', 'options', 'isDisabled'],
    data () {
        return {
            show: false,
            text: '',
            id: ''
        }
    },
    mounted () {
        window.addEventListener('click', this.onClose)
    },
    destroyed () {
        window.addEventListener('click', this.onClose)
    },
    methods: {
        onSelect (v) {
            this.text = `${v.code} (${v.amount}%)`
            this.id = v._id
            this.$emit('input', v)
            this.show = false
        },
        onClear () {
            this.text = ''
            this.id = ''
            this.$emit('input', {code: '', amount: ''})
        },
        onClose (e) {
            if(!e || !this.$el.contains(e.target)) {
                this.show = false
            }
        }
    },
    watch: {
        value (v) {
            if(v.amount) {
                this.text = `${v.code} (${v.amount}%)`
                this.id = v._id
            }
        }
    }
}
</script>