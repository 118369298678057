<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-50 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class="bg-white w-2/3 border rounded-sm">
                                <div class="flex items-center justify-between py-2 px-5 border-b">
                                    <div class="font-medium">SHIPPING</div>
                                    <a @click.prevent="$emit('closeModal')" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500 -mr-3" style="font-size: 19px"></i></a>
                                </div>
                                <form @submit.prevent="onSubmit" >
                                    <div class="p-5">
                                        <div class="grid grid-cols-3 space-x-5 mb-5">
                                            <div class="col-span-1">
                                                <label for="">KURIR / EXPEDISI</label>
                                                <div class="flex flex-col mt-1.5" :class="{'err': err.shipmentMethodId}">
                                                    <select-2 v-model="form.shipmentMethodId" @input="onSelectShippmentMethodId" :placeholder="'Select kurir'" :options="shippings">
                                                        <option value=""></option>
                                                    </select-2>
                                                </div>
                                            </div>
                                            <div class="col-span-1">
                                                <label for="">SHIPMENT SERVICE</label>
                                                <div class="flex flex-col mt-1.5" :class="{'err': err.shipmentService}">
                                                    <select-2 v-model="form.shipmentService" ref="services" :options="shipmentServices" :placeholder="'Select service'">
                                                        <option value=""></option>
                                                    </select-2>
                                                </div>
                                            </div>
                                            <div class="col-span-1">
                                                <label for="">SHIPMENT COST</label>
                                                <autonumeric ref="shipmentCost" class="form-control mt-1.5 text-right" 
                                                    :class="{'border-red-500': err.shipmentCost}"
                                                    @keydown.native="err.shipmentCost = false"
                                                    v-model="form.shipmentCost"
                                                    :options="{modifyValueOnWheel : false}"
                                                >
                                                </autonumeric>
                                            </div>
                                        </div>
                                        <div>
                                            <label for="">BOOKING CODE</label>
                                            <input v-model="form.bookingCode" type="text" class="form-control mt-2">
                                        </div>
                                    </div>
                                    <div class="py-2 px-5 text-right">
                                        <button @click="$emit('closeModal')" type="button" class="bg-whit border h-9 rounded-sm px-5 hover:bg-gray-100 mr-2">CANCEL</button>
                                        <button type="submit" class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50 hover:bg-teal-600">{{buttonText}}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import Select2 from './Select2.vue'
import Autonumeric from './Autonumeric.vue'
import axios from 'axios'
export default {
  components: { Select2, Autonumeric },
    props: {
        show: Boolean,
        value: Object
    },
    data () {
        return {
            shippings: [],
            form: {
                shipmentMethodId: '',
                shipmentService: '',
                shipmentCost: '',
                bookingCode: '',
                trackingNumber: ''
            },
            buttonText: 'ADD',
            err : {
                shipmentMethodId: '',
                shipmentService: '',
                shipmentCost: ''
            }
        }
    },
    computed: {
        shipmentServices () {
            let services =  this.shippings.find(obj => obj.id == this.form.shipmentMethodId)
            if(services) {
                return services.services.map(obj => {
                    obj.id = obj.name,
                    obj.text = obj.name
                    return obj
                })
            }
            return []
        },
    },
    methods: {
        getData () {
            this.form.shipmentMethodId = ''
            this.form.shipmentService = ''
            axios.get('/setting/method')
            .then(res => {
                this.shippings = res.data
                this.$nextTick(() => {
                    let a =  JSON.parse(JSON.stringify(this.value))
                    this.form.shipmentMethodId = a.shipmentMethodId
                    this.form.shipmentService = a.shipmentService
                    this.form.shipmentCost = a.shipmentCost
                    this.form.bookingCode = a.bookingCode
                    if(a.shipmentMethodId) {
                        this.buttonText = 'UPDATE'
                    } else {
                        this.buttonText = 'ADD'
                    }
                    this.$nextTick(() => {
                        this.$refs.services.update()
                    })
                })
            })
        },
        onSelectShippmentMethodId () {
            this.$nextTick (() => {
                this.$refs.services.open()
            })
        },
        onSubmit () {
            if(!this.form.shipmentMethodId) {
                this.err.shipmentMethodId = true
            }
            if(!this.form.shipmentService) {
                this.err.shipmentService = true
            }
            if(!this.form.shipmentCost) {
                this.err.shipmentCost = true
            }
            if(this.form.shipmentMethodId && this.form.shipmentService && this.form.shipmentCost) {
                let data = JSON.parse(JSON.stringify(this.form))
                this.$emit('input', data)
                this.$emit('closeModal')
            }
        }
    },
    watch: {
        show (v) {
            if(v) {
                this.err = {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: ''
                }
                this.getData()
            }
        },
        'form.shipmentMethodId': function () {
            this.err.shipmentMethodId = false
        },
        'form.shipmentService': function () {
            this.err.shipmentService = false
            this.$refs.shipmentCost.$el.focus()
        }
    }
}
</script>