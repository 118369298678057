<template>
    <div class="sidebar leading-none">
        <div class="sidebar-content">
            <ul class="nav-sidebar" data-nav-type="accordion">
                <li class="px-5 mt-3"> 
                    <div class="text-xs uppercase text-gray-500 py-3">main</div>
                </li>
                <li class="nav-item">
                    <router-link @click.native="onBreadcrumb('Dashboard')" to="/" class="nav-link" exact>
                        <i class="icon-grid5 mr-5"></i>
                        <span>Dashboard</span>
                    </router-link>
                </li>
                <!-- Customers -->
                <li class="nav-item">
                    <router-link @click.native="onBreadcrumb('Customers')" to="/customers" class="nav-link">
                        <i class="icon-users2 mr-4.5"></i>
                        <span>Customers</span>
                    </router-link>
                </li>
                <!-- /components -->
                <!-- Suppliers -->
                <li id="suppliers" class="nav-item">
                    <router-link @click.native="onBreadcrumb('Suppliers')" to="/suppliers" class="nav-link">
                        <i class="icon-users2 mr-4.5"></i>
                        <span>Suppliers</span>
                    </router-link>
                </li>
                <!-- /suppliers -->

                <!-- Products -->
                <li id="products" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">
                        <i class="icon-stack2 mr-4.5"></i>
                        <span>Products</span>
                    </a>
                    <ul class="nav-group-sub">
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Products')" to="/products" class="nav-link">
                                <div class="bg-gray-800 rounded h-6 w-6 mr-5 flex items-center justify-center">PD</div>
                                <span>Products</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Services')" to="/services" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">SV</span>
                                <span>Services</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Barcode Printing')" to="/barcode" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">BP</span>
                                <span>Barcode Printing</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Categories')" to="/categories" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">CA</span>
                                <span>Categories</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Brands')" to="/brands" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">BR</span>
                                <span>Brands</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- /products -->

                <li class="px-5"> 
                    <div class="text-xs uppercase text-gray-500 py-3">operations</div>
                </li>

                <!-- POINT OF SALES -->
                <li class="nav-item">
                    <router-link @click.native="onBreadcrumb('POS')" to="/pos" class="nav-link">
                        <i class="icon-store mr-5"></i>
                        <span>Point Of Sales</span>
                    </router-link>
                </li>
                <!-- Sales -->
                <li id="sales" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">
                        <i class="icon-barcode2 mr-5"></i>
                        <span>Sales</span>
                    </a>
                    <ul class="nav-group-sub">
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Quotations')" to="/quotations" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">QO</span>
                                <span>Quotations</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Sales Orders')" to="/sales" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">SO</span>
                                <span>Sales Orders</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- /sales -->
                <!-- Purchases -->
                <li id="purchases" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">
                        <i class="icon-stack mr-5"></i>
                        <span>Purchases</span>
                    </a>
                    <ul class="nav-group-sub">
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Purchase Orders')" to="/purchases" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">PO</span>
                                <span>Purchase Orders</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">SO</span>
                                <span>Receive Notes</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Purchases -->
                <!-- Inventory -->
                <li id="inventory" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">
                        <i class="icon-stack2 mr-5"></i>
                        <span>Inventory</span>
                    </a>
                    <ul class="nav-group-sub">
                        <!-- <li class="nav-item">
                            <router-link to="/adjustments" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">AD</span>
                                <span>Adjustments</span>
                            </router-link>
                        </li> -->
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Receipts')" to="/receipts" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">RC</span>
                                <span>Receipts</span>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Transfers')" to="/stock-transfers" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">TR</span>
                                <span>Transfers</span>
                            </router-link>
                        </li> -->
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Delivery Orders')" to="/delivery-orders" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">DO</span>
                                <span>Delivery Orders</span>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Returns')" to="/stock-transfers" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">RN</span>
                                <span>Returns</span>
                            </router-link>
                        </li> -->
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Stock Opname')" to="/stock-opname" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">SO</span>
                                <span>Stock Opname</span>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link to="/stock-takes" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">ST</span>
                                <span>Stock Takes</span>
                            </router-link>
                        </li> -->
                    </ul>
                </li>
                <!-- /inventory -->
                <!-- Accounting -->
                <li id="accounting" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">
                        <i class="icon-calculator3 mr-5"></i>
                        <span>Receivables</span>
                    </a>
                    <ul class="nav-group-sub">
                        <li class="nav-item">
                            <router-link @click.native="onBreadcrumb('Invoices')" to="/invoices" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">IN</span>
                                <span>Customer Invoices</span>
                            </router-link>
                        </li>
                        <li id="bank" class="nav-item">
                            <router-link @click.native="onBreadcrumb('Bank Reconcilitation')" to="/bank" class="nav-link">
                                <span class="bg-gray-800 rounded h-6 w-6 flex items-center justify-center mr-5">BN</span>
                                <span>Bank Reconciliation</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <router-link @click.native="onBreadcrumb('Serial Numbers')" to="/serial-numbers" class="nav-link">
                        <i class="icon-chip mr-5"></i>
                        <span>Serial Numbers</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import BreadcrumbsManager from '../mixins/breadcrumbsManager.vue'
import {mapGetters} from 'vuex'

export default {
    mixins: [BreadcrumbsManager],
    mounted () {
            this.navigasi()
            this.activeNav()
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        route () {
            return this.$route.path
        },
        products () {
            return /\/products.*/.test(this.route)
        },
        services () {
            return /\/services.*/.test(this.route)
        },
        barcode () {
            return /\/barcode.*/.test(this.route)
        },

        categories () {
            return /\/categories.*/.test(this.route)
        },
        brands () {
            return /\/brands.*/.test(this.route)   
        },
        quotations () {
            return /\/quotations.*/.test(this.route)
        },
        sales () {
            return /\/sales.*/.test(this.route)
        },
        purchases () {
            return /\/purchases.*/.test(this.route)
        },
        receipts () {
            return /\/receipts.*/.test(this.route)
        },
        delivery () {
            return /\/delivery-orders.*/.test(this.route)
        },
        adjustments () {
            return /\/adjustments.*/.test(this.route)
        },
        stockTransfers () {
            return /\/stock-transfers.*/.test(this.route)
        },
        stockTakes () {
            return /\/stock-takes.*/.test(this.route)
        },
        stockOpname () {
            return /\/stock-opname.*/.test(this.route)
        },
        invoices () {
            return /\/invoices.*/.test(this.route)
        },
        bank () {
            return /\/bank.*/.test(this.route)
        }
    },
    methods: {
        onBreadcrumb (val) {
            this.setBreadcrumbs([
                {text: val}
            ])
        },
        navigasi() {
            $('.nav-sidebar').each(function () {
                $(this).find('.nav-item').has('.nav-group-sub').children('.nav-item > .nav-link').on('click', function(e) {
                    e.preventDefault()
                    var $target = $(this)
                    if($target.parent('.nav-item').hasClass('nav-item-open')) {
                        $target.parent('.nav-item').removeClass('nav-item-open').children('.nav-group-sub').slideUp(250)
                    } else {
                        $target.parent('.nav-item').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                    }
                })
                $(this).find('.nav-item').children('.nav-item > .nav-link').on('click', function(e) {
                    e.preventDefault()
                    // accordion
                    if($(this).parent('.nav-item')) {
                        $(this).parent('.nav-item').siblings(':has(.nav-group-sub)').removeClass('nav-item-open').children('.nav-group-sub').slideUp(250)
                    }
                })
            }) 
        },
        closMenu (id) {
            $('.nav-sidebar').each(function() {
                $(this).find('.nav-item').not(id).removeClass('nav-item-open').children('.nav-group-sub').slideUp(250)
            })
        },
        activeNav () {
            if(!this.products && !this.barcode && !this.services 
            && !this.categories && !this.brands && !this.customerPricelist && !this.supplierPricelist
            && !this.quotations && !this.sales && !this.purchases && !this.delivery && !this.receipts
            && !this.adjustments && !this.stockTransfers && !this.stockTakes && !this.stockOpname 
            && !this.invoices && !this.bank) {
                $('.nav-sidebar').each(function() {
                    $(this).find('.nav-item').removeClass('nav-item-open').children('.nav-group-sub').slideUp(250)
                })
            }
            if(this.products) {
                $('#products').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#products')
            } 
            if(this.services) {
                $('#products').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#products')
            } 
            if(this.barcode) {
                $('#products').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#products')
            } 
            if(this.categories) {
                $('#products').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#products')
            } 
            if(this.brands) {
                $('#products').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#products')
            } 
            if(this.quotations) {
                $('#sales').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#sales')
            }
            if(this.sales) {
                $('#sales').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#sales')
            }
            if(this.purchases) {
                $('#purchases').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#purchases')
            } 
            if(this.delivery) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            } 
            if(this.receipts) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            } 
            if(this.adjustments) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            } 
            if(this.stockTransfers) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            }  
            if(this.stockTakes) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            }  
            if(this.stockOpname) {
                $('#inventory').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#inventory')
            }  
            if(this.invoices) {
                $('#accounting').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#accounting')
            }  
            if(this.bank) {
                $('#accounting').addClass('nav-item-open').children('.nav-group-sub').slideDown(250)
                this.closMenu('#accounting')
            }  
        }
    },
    watch: {
        user(val) {
            if(val) {
                if(val.role != 'Admin') {
                    $('#purchases').addClass('hidden')
                    $('#suppliers').addClass('hidden')
                    $('#bank').addClass('hidden')
                } else {
                    $('#purchases').removeClass('hidden')
                    $('#suppliers').removeClass('hidden')
                    $('#bank').removeClass('hidden')
                }
            }
        },
        $route() {
            this.activeNav()
        }
    },
}
</script>