<template>
    <div class="bg-white rounded-sm">
        <div class="bg-white border rounded-t-sm">
            <div class="flex justify-between items-center">
                <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                    <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                    <div @click="onTab('info')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='info'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">INFO</div>
                    <div @click="onTab('inventory')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='inventory'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">INVENTORY</div>
                    <div @click="onTab('history')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='history'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">HISTORY</div>
                </div>
            </div>
        </div>
        <div v-show="tab == 'overview'">
            <div class="animated fadeIn faster">
                <product-overview v-model="productName"></product-overview>
            </div>
        </div>
        <div v-show="tab == 'info'">
            <div class="animated fadeIn faster">
                <product-info :id="productId"></product-info>
            </div>
        </div>
        <div v-show="tab == 'inventory'">
            <div class="animated fadeIn faster">
                <prdouct-inventory></prdouct-inventory>
            </div>
        </div>
        <div v-show="tab == 'history'">
            <div class="animated fadeIn faster">
                <product-history></product-history>
            </div>
        </div>
    </div>
</template>

<script>
import ProductOverview from '../../components/productOverview.vue'
import ProductInfo from '../../components/productInfo.vue'
import PrdouctInventory from '../../components/productInventory.vue'
import ProductHistory from '../../components/productHistory.vue'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    components: {ProductOverview, ProductInfo, PrdouctInventory, ProductHistory},
    mixins: [BreadcrumbsManager],
    data () {
        return {
            tab: 'overview',
            productId: '',
            productName: ''
        }
    },
    created () {
        this.productId = this.$route.params.productId
    },
    mounted () {
        this.setButtonText('EDIT')
        EventBus.$on('onClick', this.onEdit)
    },
    destroyed () {
        EventBus.$off('onClick', this.onEdit)
    },
    methods: {
        onTab (i) {
           this.tab = i
        },
        onEdit () {
            this.$router.push('/products/edit/'+this.productId)
        }
    },
    beforeRouteLeave (to, from, next) {
        console.log(to)
        if(to.name == 'Invoice Detail') {
            this.replaceBreadcrumb({
                find: this.productName,
                replace: {text: this.productName, path: this.$route.fullPath}
            })
        }
        next()
    }
}
</script>