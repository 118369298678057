<template>
    <div style="font-size: 12px">
        <div class="bg-white text-black">

            <div v-if="company" class="grid grid-cols-12 mb-5">
                <div class="col-span-2 py-2 border-gray-800 -mr-px border-2 rounded-2xl flex items-center">
                    <img :src="imgURL+'/public/img/company-logo.png'" alt="">
                </div>
                <div class="col-span-6 p-2 border-gray-800 border-2  rounded-2xl -ml-px -mr-px">
                    <div class="text-xl text-red-600 font-bold">{{company.name}}</div>
                    <div class="text-base leading-none mb-px font-semibold" style="color: blue"><i>{{company.tagline}}</i></div>
                    <div>
                        <div class=" leading-4" style="color: blue">{{company.companyAddress.address}} {{company.companyAddress.city.name}} - {{company.companyAddress.postal_code}} <span class="uppercase">{{company.companyAddress.country}}</span></div>
                        <div class=" leading-4" style="color: blue">Phone. <span class="font-semibold">{{company.phone}} (Hunting)</span></div>
                        <div class=" leading-4" style="color: blue">e-mail: <span class="font-semibold">{{company.email}}</span></div>
                        <div class=" leading-4" style="color: blue"><span class="font-semibold">{{company.website}}</span></div>
                    </div>
                </div>
                <div class="col-span-4 p-2 border-gray-800 border-2 rounded-2xl -ml-px flex items-center justify-center">
                    <div class="text-3xl text-gray-600 font-semibold">QUOTATION</div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-5 mb-5">
                <div class="col-span-7 border border-gray-800 rounded-2xl p-2 relative">
                    <table class="w-full">
                        <tr>
                            <td class="text-right w-20 italic">Company:</td>
                            <td class="pl-1 font-semibold">{{toCompany}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic align-top">Address:</td>
                            <td class="pl-1">{{address}} {{address2}} {{subdistrict}} {{province}} {{postalCode}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">City/Country:</td>
                            <td class="pl-1">{{city}} {{country}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Phone:</td>
                            <td class="pl-1">{{phone}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Fax:</td>
                            <td class="pl-1">{{fax}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">Cell Phone:</td>
                            <td class="pl-1">{{cellPhone}}</td>
                        </tr>
                        <tr>
                            <td class="text-right w-20 italic">E-mail:</td>
                            <td class="pl-1">{{email}}</td>
                        </tr>
                        <tr v-if="attn">
                            <td class="text-right w-20 font-semibold italic">Attn:</td>
                            <td class="pl-1">{{attn}}</td>
                        </tr>
                    </table>
                    <div class="absolute -top-2.5 bg-white px-1 left-9 font-semibold">To :</div>
                </div>
                <div class="col-span-5 flex items-center">
                    <div class="w-full">
                        <div class="border border-gray-800 rounded-2xl pl-5 pr-2 py-2 mb-5">
                            <table class="w-full">
                                <tr>
                                    <td class="w-20 italic">Date:</td>
                                    <td class="border-b">{{getDate(date)}}</td>
                                </tr>
                            </table>
                            <table class="w-full">
                                <tr>
                                    <td class="italic w-60">Number of pages including cover sheet:</td>
                                    <td class="border-b"></td>
                                </tr>
                            </table>
                        </div>
                        <div class="border border-gray-800 rounded-2xl pl-5 pr-2 py-2">
                            <table class="w-full">
                                <tr>
                                    <td class="w-20 italic">Our Reff:</td>
                                    <td class="border-b text-red-500 font-semibold">{{ourReff}}</td>
                                </tr>
                                <tr>
                                    <td class="w-20 italic">Your Reff:</td>
                                    <td class="border-b">{{yourReff}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5 flex items-center justify-between border-gray-800 border-t-4 border-l border-r px-5 py-px">
                <div class="font-semibold">REMARKS:</div>
                <div class="form-radio flex justify-center">
                    <input class="mr-1" type="checkbox" v-model="remarks.urgent" readonly>
                    <label for="">Urgent</label>
                </div>
                <div class="form-radio flex justify-center">
                    <input class="mr-1" type="checkbox" v-model="remarks.forYourRevew" readonly>
                    <label for="">For Your Review</label>
                </div>
                <div class="form-radio flex justify-center">
                    <input class="mr-1" type="checkbox" v-model="remarks.replyAsap" readonly>
                    <label for="">Reply ASAP</label>
                </div>
                <div class="form-radio flex justify-center">
                    <input class="mr-1" type="checkbox" v-model="remarks.pleaseComment" readonly>
                    <label for="">Please Comment</label>
                </div>
            </div>

            <div class="mb-5">
                <div>Dengan hormat, {{this.attn}}</div>
                <div>Bersama surat ini kami ajukan penawaran harga untuk barang-barang seperti tertera dibawah ini:</div>
            </div>

            <div class="mb-5">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="bg-red-500 px-2 py-1 text-red-50">No.</th>
                            <th class="bg-red-500 px-2 py-1 text-red-50">PRODUCT DESCRIPTION</th>
                            <th class="bg-red-500 px-2 py-1 text-red-50 text-right">QTY</th>
                            <th class="bg-red-500 px-2 py-1 text-red-50 text-right">UNIT PRICE</th>
                            <th class="bg-red-500 px-2 py-1 text-red-50 text-right">DISCOUNT</th>
                            <th class="bg-red-500 px-2 py-1 text-red-50 text-right">AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y border-b">
                        <tr v-for="(item, i) in items" :key="i">
                            <td class="px-2 py-1">{{i+1}}</td>
                            <td class="px-2 py-1">{{item.name}}</td>
                            <td class="px-2 py-1 text-right">{{item.qty}}</td>
                            <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                            <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.discount.value)}}</td>
                            <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                        </tr>
                        <!-- SUB TOTAL -->
                        <tr>
                            <td colspan="4"></td>
                            <td class="px-2 py-1 text-right">Sub Total</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(subTotal)}}
                            </td>
                        </tr>
                        <tr v-if="discountOnTotalPrice">
                            <td colspan="3"></td>
                            <td colspan="2" class="px-2 py-1 text-right">Discount on total price</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(discountOnTotalPrice)}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"></td>
                            <td class="px-2 py-1 text-right">Tax {{tax}}%</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(taxValue)}}
                            </td>
                        </tr>
                        <tr v-if="totalAdditionalCharges">
                            <td colspan="3"></td>
                            <td colspan="2" class="px-2 py-1 text-right">ADDITIONAL CHARGERS</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(totalAdditionalCharges)}}
                            </td>
                        </tr>
                        <tr v-if="shipmentCost">
                            <td colspan="3"></td>
                            <td colspan="2" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(shipmentCost)}}
                            </td>
                        </tr>
                        <tr class="font-semibold">
                            <td colspan="4"></td>
                            <td class="px-2 py-1 text-right">Total</td>
                            <td class="px-2 py-1 flex items-center justify-between">
                                <div>Rp</div>
                                <div>
                                    {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(total)}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mb-5">
                <div class="font-semibold underline">KONDISI PENAWARAN:</div>
                <div class="pl-7">
                    <table v-if="offerConditions" class="w-full leading-4 mb-5">
                        <tr v-for="(condition, i) in offerConditions" :key="i">
                            <td>{{condition.name}}</td>
                            <td class="pl-5">{{condition.description}}</td>
                        </tr>
                    </table>
                    <ul class="text-xs list-inside pl-3">
                        <ol class="list-decimal">
                            <li>Barang yang sudah dibeli, tidak dapat dikembalikan</li>
                            <li>Kerusakan yang diakibatkan oleh kesalahan pabrik, harus segera dilaporkan dalam jangka waktu tujuh hari sejak barang diterima</li>
                            <li>Semua barang yang tertera di atas, masih menjadi milik PT. DUTA PERSADA INSTRUMENT, sampai barang tersebut dilunasi</li>
                            <li>Biaya pembatalan akan kami kenakan sebesar 50% dari jumlah harga penawaran apabila pesanan di batalkan setelah kami menerima PO atauuang muka</li>
                            <li>Barang-barang yang di jual, mengacu kepada "Kondisi Penawaran" yang di ajukan oleh PT. DUTA PERSADA INSTRUMENT</li>
                            <li>Semua biaya dalam proses transfer ditanggung oleh pengirim, sehingga jumlah yang kami terima Full amount tanpa potongan biaya apapun sesuai dengan jumlah tagihan</li>
                            <li>Kami berhak menarik penawaran kami, sebelum kami menerima uang muka atau Purchase Order</li>
                        </ol>
                    </ul>
                </div>
            </div>
            <div>
                Demikian surat penawaran harga ini kami ajukan dan sebelumnya kami ucapkan terima kasih.
            </div>
            <div class="mt-5">
                Hormat Kami,
                <div class="mt-10">{{user}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {moment} from '../moment'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            toCompany: '',
            address: '',
            address2: '',
            subdistrict: '',
            city: '',
            province: '',
            postalCode: '',
            country: '',
            phone: '',
            cellPhone: '',
            fax: '',
            email: '',
            attn: '',
            date: '',
            ourReff: '',
            yourReff: '',
            remarks: {
                comment: '',
                urgent: false,
                forYourReview: false,
                replyAsap: false,
                pleaseComment: false,
            },
            items: [],
            subTotal: '',
            discountOnTotalPrice: '',
            tax: '',
            taxValue: '',
            totalAdditionalCharges: '',
            shipmentCost: '',
            total: '',
            offerConditions: '',
            user: ''
        }
    },
    computed: {
        ...mapGetters({
            company: 'auth/company'
        })
    },
    methods: {
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        data (val) {
            if(val) {

                if(val.customer) {
                    if(val.customer.parent) {
                        this.toCompany = val.customer.parent.name
                        this.address = val.customer.parent.address.street
                        this.address2 = val.customer.parent.address.street2
                        this.subdistrict = val.customer.parent.address.subdistrictName
                        this.province = val.customer.parent.address.provinceName
                        this.city = val.customer.parent.address.cityName
                        this.postalCode = val.customer.parent.address.zip
                        this.cellPhone = val.customer.parent.contact.mobile
                        this.email = val.customer.parent.contact.email
                        this.phone = val.customer.parent.contact.phone
                        this.attn = val.customer.attn.name
                    } else {
                        this.toCompany = val.customer.attn.name
                        this.address = val.customer.attn.address.street
                        this.address2 = val.customer.attn.address.street2
                        this.subdistrict = val.customer.attn.address.subdistrictName
                        this.province = val.customer.attn.address.provinceName
                        this.city = val.customer.attn.address.cityName
                        this.postalCode = val.customer.attn.address.zip
                        this.cellPhone = val.customer.attn.contact.mobile
                        this.email = val.customer.attn.contact.email
                        this.phone = val.customer.attn.contact.phone
                        this.attn = ''
                    }
                }
                this.date = val.createdAt
                this.ourReff = val.quotationNo
                this.yourReff = val.customerPO
                this.remarks = val.remarks
                this.items = val.items
                this.subTotal = val.total
                this.total = val.grandTotal
                this.discountOnTotalPrice = val.discount.value 
                this.tax = val.tax.amount
                this.totalAdditionalCharges = val.totalAdditionalCharges
                this.taxValue = val.tax.value
                if(val.shipping) {
                    this.shipmentCost = val.shipping.shipmentCost
                }
                this.offerConditions = val.offerConditions
                this.user = val.user.name
            }
        }
    }
}
</script>