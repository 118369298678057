<template>
    <div class="fixed top-12 px-5 flex items-center justify-between z-30" style="height: 46px; background-color: #3a3f45">
        <div>
            <ul class="flex text-gray-400 text-base space-x-1.5">
                <li v-for="(route, i) in breadcrumbs" :key="i">
                    <div v-if="route.path && breadcrumbs.length > 0" class="flex items-center">
                        <router-link @click.native="onRemove(i)" :to="route.path" class="text-teal-500 truncate inline-block" style="max-width: 300px">{{route.text}}</router-link>
                        <span class="ml-1.5">/</span>
                    </div>
                    <div v-if="!route.path" class=" truncate" style="max-width: 300px"> {{route.text}}</div>
                </li>
            </ul>
        </div>
        <div class="flex items-center">
            <div v-show="action" class="flex border-gray-500">
                <button @click.prevent="onPrint" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-100 hover:border-gray-400 hover:bg-opacity-25 hover:bg-gray-100 flex items-center"><i class="icon-printer2 mr-1" style="font-size: 12px"></i> Print</button>
                <button @click.prevent="onDuplicate" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100 flex items-center"><i class="icon-copy3 mr-1" style="font-size: 12px"></i> Duplicate</button>
            </div>
            <div v-if="pages" class="ml-2 mr-1 flex items-center text-gray-50">
                <div>{{pages.currPage}}/{{pages.totalItems}}</div>
                <div class="flex items-center space-x-1 ml-1">
                    <button @click="onPreviousPage" class="text-gray-50"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                    <button @click="onNextPage" class="text-gray-50"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                </div>
            </div>
            <button @click.prevent="onCancel" v-show="discard" class="h-8 text-teal-500 px-5 rounded-sm hover:bg-gray-100 border border-transparent hover:text-gray-600 hover:border-gray-300">{{discardText}}</button>
            <button @click.prevent="onClick" v-show="button" :disabled="isDisabled" class="h-8 bg-teal-500 relative focus:outline-none text-teal-50 px-5 rounded-sm hover:bg-teal-600 ml-1.5">
                <div v-if="isDisabled" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                    <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                {{button}}
            </button>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {EventBus} from '../event-bus'
import BreadcrumbManager from '../mixins/breadcrumbsManager.vue'
export default {
    mixins: [BreadcrumbManager],
    computed: {
        ...mapGetters({
            breadcrumbs: 'breadcrumbs/breadcrumbs',
            button: 'breadcrumbs/button',
            discard: 'breadcrumbs/discard',
            discardText: 'breadcrumbs/discardText',
            isDisabled: 'breadcrumbs/disabled',
            action: 'breadcrumbs/action',
            pages: 'breadcrumbs/pages'
        })
    },
    methods: {
        onClick () {
            EventBus.$emit('onClick')
        },
        onCancel () {
            EventBus.$emit('onCancel')
        },
        onPrint () {
            EventBus.$emit('onPrint')
        },
        onDuplicate () {
            EventBus.$emit('onDuplicate')
        },
        onNextPage () {
            EventBus.$emit('onNextPage')
        },
        onPreviousPage () {
            EventBus.$emit('onPreviousPage')
        },
        onRemove (i) {
            this.spliceBreadcrumb({
                path: this.breadcrumbs[i+1].path
            })
            this.replaceBreadcrumb({
                find: this.breadcrumbs[i].text,
                replace: {text: this.breadcrumbs[i].text}
            })
        }
    },
}
</script>
