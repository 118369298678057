<template>
    <div class="border border-t-0">
        <div class="p-5">
            <div class="form-feedback form-feedback-right w-1/4">
                <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search customer name">
                <div v-show="!search" class="form-control-feedback">
                    <span>
                        <i class="icon-search4 text-gray-400"></i>
                    </span>
                </div>
                <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                    <a @click.prevent="search = ''" href="#">
                        <i class="icon-cancel-circle2 text-red-500"></i>
                    </a>
                </div>
            </div>
        </div>
        <table class="w-full">
            <thead class="border border-blue-500">
                <tr>
                    <th class="py-2 pl-5 pr-2 bg-blue-500 text-blue-50">DATE</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50">#INVOCE</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50">CUSTOMER NAME</th>
                    <th class="py-2 pl-2 pr-5 bg-blue-500 text-blue-50 text-right">QTY</th>
                </tr>
            </thead>
            <tbody class="divide-y">
                <tr v-for="(inv , i) in histories" :key="i">
                    <td class="py-2 pl-5 pr-2">{{getDate(inv.createdAt)}}</td>
                    <td class="py-2 px-2 text-blue-500">
                        <router-link :to="'/invoices/detail/'+inv._id">{{inv.invoiceNo}}</router-link>
                    </td>
                    <td class="py-2 px-2">{{inv.customer}}</td>
                    <td class="py-2 pl-2 pr-5 text-right">
                        <ul v-for="qty in inv.items" :key="qty.product">
                            <li v-if="qty.productId == productId">{{qty.qty}}</li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="m-5 text-right">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import paginate from '../components/paginate.vue'
import { moment } from '../moment'
import debounce from 'lodash.debounce'
export default {
    components: {paginate},
    data () {
        return {
            search: '',
            histories: [],
            pages: '',
            productId: '',
            searchData: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.productId = this.$route.params.productId
        this.getData()
    },
    methods: {
        getData (e) {
            axios.get('/products/history/'+this.$route.params.productId, {
                params: {
                    page: e,
                    search: this.search
                }
            })
            .then(res => {
                console.log(res);
                this.histories = res.data.data
                this.pages = res.data.pages
            })
        },
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        search () {
            this.searchData()
        },
    },
}
</script>