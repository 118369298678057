<template>
    <div class="border-b relative bg-white pt-0.5 pb-0" :class="{'border-teal-500':focus}" @click="onFocus">
        <span v-for="(tg, i) in tags" :key="i" :class="duplicate && duplicateText == tags[i] ? 'bg-green-400 text-white':'bg-gray-200'" class="hover:bg-red-400 hover:text-red-50 inline-flex items-center px-1 mr-0.5 mb-0.5">
            <span class="mr-1 cursor-default">{{tg}}</span>
            <a @click.prevent="removeTag(i)" href="#" class="flex items-center">
                <i class="icon-cross3"></i>
            </a>
        </span>
        <input v-model="tag" @keydown="onAddTags" @focus="focus=true" @blur="focus=false" type="text" class="focus:outline-none mb-0.5" placeholder="Add tag" ref="newtag">
    </div>
</template>

<script>
export default {
    props: {
        value: Array
    },
    data () {
        return {
            tag: '',
            tags: this.value,
            duplicate: false,
            duplicateText: '',
            focus: false,
        }
    },
    methods: {
        onFocus () {
            this.$refs.newtag.focus()
        },
        onAddTags(e) {
            if(e.keyCode === 13 || e.keyCode === 9 || e.keyCode === 188) {
                e.preventDefault()
                this.duplicate = this.tags.includes(this.tag)
                if(this.tags.includes(this.tag)) {
                    this.duplicateText = this.tag
                    this.tag = ''
                }
                if(this.tag) {
                    this.tags.push(this.tag)
                    this.tag = ''
                    this.duplicateText = ''
                    this.$refs.newtag.focus()
                }
            }
            if(e.keyCode === 8) {
                if(!this.tag) {
                    this.tags.splice(this.tags.length-1,1)
                }
            }
        },
        removeTag(i) {
            this.tags.splice(i,1)
        }
    },
    watch: {
        value (val) {
            this.tags = val
        }
    }
}
</script> 