export default {
    namespaced: true,
    state: {
        breadcrumbs: [],
        button: '',
        discard: '',
        discardText: '',
        disabled: false,
        action: false,
        pages: ''
    },
    getters: {
        breadcrumbs (state) {
            return state.breadcrumbs
        },
        button (state) {
            return state.button
        },
        discard (state) {
            return state.discard
        },
        discardText (state) {
            return state.discardText
        },
        disabled (state) {
            return state.disabled
        },
        action (state) {
            return state.action
        },
        pages (state) {
            return state.pages
        }
    },
    mutations: {
        set(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        push(state, breadcrumb) {
            const index = state.breadcrumbs.find(obj => {
                return obj.text === breadcrumb.text
            })
            if(!index) {
                state.breadcrumbs.push(breadcrumb)
            }
        },
        pop(state) {
            state.breadcrumbs.pop();
        },
        splice(state, payload) {
            const index = state.breadcrumbs.findIndex((breadcrumb) => {
                return breadcrumb.path === payload.path
            })
            
            if(index > 0) {
                const length = state.breadcrumbs.length - index
                state.breadcrumbs.splice(index, length)
            }
        },
        replace(state, payload) {
            const index = state.breadcrumbs.findIndex((breadcrumb) => {
                return breadcrumb.text === payload.find;
            });
            state.breadcrumbs.splice(index, 1, payload.replace);
        },

        empty(state) {
            state.breadcrumbs = [];
        },
        text (state, button) {
            state.button = button
        },
        discard (state, discard) {
            state.discard = discard
        },
        discardText (state, discardText) {
            state.discardText = discardText
        },
        disabled (state, disabled) {
            state.disabled = disabled
        },
        action (state, action) {
            state.action = action
        },
        pages (state, pages) {
            state.pages = pages
        }
    }

}