<template>
    <div class="m-5">
        <div class="flex items-center">
            <div class="text-lg"><i class="icon-truck mr-3"></i>Shippings</div>
            <div class="ml-auto w-80">
                <div class="form-feedback form-feedback-left">
                    <input ref="search" v-model="search"  type="text" class="form-control" placeholder="search courier">
                    <div class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search" class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search=''" href="#">
                            <i class="icon-cancel-circle2 text-red-600"></i>
                        </a>
                    </div>
                </div>
            </div>
            <button @click="openSlider" type="button" class="bg-teal-500 ml-2 relative rounded-sm h-9 text-white focus:outline-none">
                <div class="flex items-center">
                    <div class="h-9 w-9 bg-teal-600 rounded-l-sm flex justify-center items-center">
                        <i class="icon-plus-circle2"></i>
                    </div>
                    <div class="px-3"> Add Shipping </div>
                </div>
                <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
            </button>
        </div>

        <!-- Shipping loader -->
        <div v-if="onload" class="ph-item mt-5 grid grid-cols-3 gap-5">
            <div v-for="i in 6" :key="i" class="border rounded-sm p-5" style="height: 94px">
                <div class="grid grid-cols-12 items-center">
                    <div class="col-span-1">
                        <div class="h-5 w-5 bg-gray-200 rounded-sm"></div>
                    </div>
                    <div class="col-span-4 px-2">
                        <div class="h-12 w-full bg-gray-200 rounded-sm"></div>
                    </div>
                    <div class="col-span-5 pl-2">
                        <div class="flex items-center">
                            <div>
                                <div class="h-4 bg-gray-200 w-10 mb-1.5"></div>
                                <div class="h-3 bg-gray-200 w-16"></div>
                            </div>
                            <div class="ml-auto">
                                <i class="icon-arrow-down12 text-gray-200"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 text-right">
                        <i class="icon-cog3 text-gray-200"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- /end shipping loader -->

        <!-- Shipping lists-->
        <div class="mt-5 grid grid-cols-3 gap-5">
            <div v-for="(shipping, i) in shippings" :key="i" class="border rounded-sm p-5">
                <div class="grid grid-cols-12 items-center">
                    <div class="col-sapn-1">
                        <input @change="onChange($event, i)" :checked="shipping.status" type="checkbox">
                    </div>
                    <div class="col-span-4 px-2">
                        <img :src="imgUrl+'/'+shipping.logo" alt="">
                    </div>
                    <div class="relative col-span-5 pl-2">
                        <dropdown :ref="shipping._id">
                            <div class="flex items-center cursor-pointer">
                                <div>
                                    <div class="text-sm font-semibold uppercase">{{shipping.name}}</div>
                                    <div class="text-gray-400">Select Service</div>
                                </div>
                                <div class="ml-auto">
                                    <i class="icon-arrow-down12"></i>
                                </div>
                            </div>
                            <template #dropdown>
                                <div  class="w-44 bg-white shadow-mg px-3 space-y-2" @click.stop="">
                                    <div v-for="(service, a) in shipping.services" :key="a" class="form-radio">
                                        <input @change="onService($event, i, a)" :checked="service.active" :id="shipping.id+service.name" type="checkbox">
                                        <label :for="shipping.id+service.name" class="w-full">{{service.name}}</label>
                                    </div>
                                </div>
                            </template>
                        </dropdown>
                    </div>
                    <div class="col-span-2 text-right">
                        <a @click.prevent="onEdit(i)" href="#" class="hover:text-blue-500"><i class="icon-cog3"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /end shipping lists -->

        <!-- Slider form -->
        <slider :show="slider">
            <form @submit.prevent="onSubmit" class="relative w-screen max-w-md">
                <div class="h-full flex flex-col bg-white">
                    <div class="px-5 py-3 bg-gray-200 text-sm font-semibold flex items-center">
                        <div class="uppercase">{{sliderTitle}}</div>
                    </div>
                    <div class="p-5 overflow-x-auto">
                        <div class="mb-5">
                            <label for="">Name</label>
                            <input v-model="form.name" type="text" @keydown="error.name=''" :class="{'border-red-500': error.name}" class="form-control mt-2" placeholder="Name (example: JNE)">
                            <div v-if="error.name" class="text-red-500 mt-1">{{error.name}}</div>
                        </div>
                        <div class="mb-5">
                            <label for="">Services</label>
                            <div v-for="(service, i) in form.services" :key="i" class="mt-2">
                                <div class="flex">
                                    <input v-model="service.name" type="text" @keydown="error.services[i].name=''" :class="{'border-red-500': error.services[i].name}" class="block w-full h-9 border border-r-0 rounded-l-sm px-3 focus:outline-none" placeholder="Service (example: Reg)">
                                    <!-- <button v-if="i == 0" @click.prevent="addService" tabindex="-1" :class="{'border-red-500': error.services[i].name}"  type="button" class="h-9 border rounded-r-sm px-3 bg-gray-100 hover:text-blue-500 focus:outline-none"><i class="icon-plus-circle2"></i></button> -->
                                    <button @click.prevent="removeService(i)" tabindex="-1" type="button" :class="{'border-red-500': error.services[i].name}"  class="h-9 border rounded-r-sm px-3 bg-gray-100 focus:outline-none"><i class="icon-minus-circle2 text-red-500"></i></button>
                                </div>
                                <div v-if="error.services[i].name" class="text-red-500 mt-1">{{error.services[i].name}}</div>
                            </div>
                            <div class="flex mt-2">
                                <div class="w-full border border-r-0 rounded-l-sm bg-gray-100"></div>
                                <button @click.prevent="addService" tabindex="-1"  type="button" class="h-9 border rounded-r-sm px-3 bg-gray-100 hover:text-blue-500 focus:outline-none"><i class="icon-plus-circle2"></i></button>
                            </div>
                        </div>
                        <div class="mb-16">
                            <label for="">Logo</label>
                            <div class="mt-2 relative flex items-stretch cursor-pointer">
                                <input ref="fileImage" @change="onFileChange" type="file" class="w-full absolute z-10 opacity-0 cursor-pointer">
                                <span :class="error.logo ? 'border-red-500 border-r-0':''" class="flex w-full items-center bg-white px-3 border h-9 rounded-l-sm cursor-pointer whitespace-nowrap overflow-hidden">{{filename}}</span>
                                <span :class="error.logo ? 'border border-red-500 border-l-0':''" class="bg-blue-500 h-9 whitespace-nowrap px-3 flex items-center rounded-r-sm text-white group-hover:bg-blue-600 cursor-pointer">Choose image</span>
                            </div>
                        </div>
                    </div>
                    <div class="fixed bg-gray-200 bottom-0 max-w-md w-full px-5 py-3 text-right z-20">
                        <button type="button" @click="slider = false" class="bg-red-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-red-600 mr-2">Close</button>
                        <button class="relative bg-teal-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-teal-600" :disabled="isLoading">
                            <span>Save</span>
                            <div v-show="isLoading" class="absolute bg-teal-400 w-full h-full left-0 top-0 rounded-sm flex items-center justify-center">
                                <div>
                                    <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </slider>
        <!-- /end slider form -->
    </div>
</template>

<script>
import Dropdown from '../../components/DropdownMenu.vue'
import Slider from '../../components/slider.vue'
import axios from 'axios'
export default {
    components: {
        Dropdown,
        Slider
    },
    data () {
        return {
            onload: '',
            imgUrl: process.env.VUE_APP_ROOT_API,
            isLoading: false,
            shippingData: [],
            slider: false,
            sliderMode: '',
            sliderTitle: '',
            filename: '',
            search: '',
            form: {
                name: '',
                services: [
                    {name: '', active: true}
                ],
                logo: '',
                status: true
            },
            error: {
                name: '',
                services: [
                    {name: '', active: true}
                ],
                logo: ''
            },
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        shippings () {
            if(this.search) {
                return this.shippingData.filter(ship => {
                    return ship.name.toLowerCase().includes(this.search.toLowerCase())
                })
            } else {
                return this.shippingData
            }
        }
    },
    methods: {
        getData () {
            this.onload = true
            axios.get('/setting/shipping')
            .then(res => {
                this.shippingData = res.data
                this.onload = false
            })
        },
        openSlider () {
            this.slider = true,
            this.sliderMode = 'new'
            this.sliderTitle = 'ADD NEW SHIPPING'
            this.filename = ''
            this.$refs.fileImage.value = ''
            this.form = {
                name: '',
                services: [
                    {name: '', active: true}
                ],
                logo: '',
                status: true
            }
            this.error= {
                name: '',
                services: [
                    {name: '', active: true}
                ],
                logo: ''
            }
        },
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Name is required'
            }
            for (let i = 0; i < this.form.services.length; i++) {
                if(!this.form.services[i].name) {
                    this.error.services[i].name = 'Service is required'
                }
            }
            if(!this.form.logo && this.sliderMode == 'new') {
                this.error.logo = 'Logo is required'
            }
            if(this.form.name) {
                for (let i = 0; i < this.form.services.length; i++) {
                    if(!this.form.services[i].name) {return}
                }
                if(this.form.logo && this.sliderMode == 'new') {
                    this.$nextTick(() => {
                        this.formSubmit()
                    })
                }  
                
                if(this.sliderMode == 'edit'){
                    this.$nextTick(() => {
                        this.formUpdate()
                    })
                }
            }
        },
        formSubmit () {
            this.isLoading = true
            const formData = new FormData()
            const services = JSON.stringify(this.form.services)
            formData.append('name', this.form.name)
            formData.append('services', services)
            formData.append('logo', this.form.logo)
            formData.append('status', this.form.status)
            axios.post('/setting/shipping', formData, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                this.shippingData = res.data
                this.isLoading = false
                this.slider = false
            })
            .catch(() => {
                this.isLoading = false
                this.error.name = 'Shipping name is already exists'
            })
        },
        formUpdate () {
            this.isLoading = true
            const formData = new FormData()
            const services = JSON.stringify(this.form.services)
            formData.append('name', this.form.name)
            formData.append('services', services)
            formData.append('status', this.form.status)
            if(this.form.logo) {
                formData.append('logo', this.form.logo)
            }
            axios.put('/setting/shipping/'+this.form.id, formData, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            })
            .then(() => {
                this.getData()
                this.isLoading = false
                this.slider = false;
            })
            .catch(() => {
                this.$refs.fileImage.value = ''
                this.isLoading = false
                this.error.name = 'Shipping name is already exists'
            })
        },
        onEdit (i) {
            this.form = {
                id: '',
                name: '',
                service: '',
                status: '',
                logo: '',
            }
            this.sliderMode = 'edit'
            this.sliderTitle = 'EDIT SHIPPING'
            let service = this.shippings[i].services
            this.error.services = []
            for (let i = 0; i < service.length; i++) {
                this.error.services.push({name: '', active: true})
            }
            this.slider = true
            this.form.id = this.shippings[i]._id
            this.form.name = this.shippings[i].name
            this.form.services = this.shippings[i].services
            this.form.status = this.shippings[i].status
        },
        onChange (e ,i) {
            let service = this.shippings[i].services
            let id = this.shippings[i]._id
            this.shippings[i].status = e.target.checked
            for(let i = 0; i < service.length; i++) {
                service[i].active = e.target.checked
            }
            if(e.target.checked == true) {
                this.$refs[id][0].open()
            }
            this.$nextTick(() => {
                axios.put('/setting/shipping/service/'+id, this.shippings[i])
            })
        },
        onService (e, i, a) {
            let id = this.shippings[i]._id
            this.shippings[i].services[a].active = e.target.checked
            if(e.target.checked == true) {
                this.shippings[i].status = true
            }
            if(this.shippings[i].services.every(x => x.active == false) == true) {
                this.shippings[i].status = false
            }
            this.$nextTick(() => {
                axios.put('/setting/shipping/service/'+id, this.shippings[i])
            })
        },
        onFileChange (e) {
            this.error.logo = ''
            const file = e.target.files[0]
            this.filename = file.name
            this.form.logo = file
        },
        addService () {
            if(this.sliderMode == 'new') {
                this.form.services.push({name: '', active: true})
                this.error.services.push({name: '', active: true})
            } else {
                if(this.form.status == true) {
                    this.form.services.push({name: '', active: true})
                    this.error.services.push({name: '', active: true})   
                } else {
                    this.form.services.push({name: '', active: false})
                    this.error.services.push({name: '', active: false})
                }
            }

        },
        removeService (index) {
            this.form.services.splice(index, 1)
            this.error.services.splice(index, 1)
        }
    }
}
</script>