<template>
    <div class="m-5">
        <div class="bg-white border rounded-sm rounded-b-none p-5">
            <div class="flex items-center">
                <div class="w-2/5">
                    <div class="w-full">
                        <div class="form-feedback form-feedback-left">
                            <input v-model="search"  type="text" class="form-control" placeholder="search brand name">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-search4 text-gray-400"></i>
                                </span>
                            </div>
                            <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                                <a @click="search = ''" href="#">
                                    <i class="icon-cancel-circle2 text-red-500"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <button @click="addNewBrand('NEW BRAND')" type="button" class="bg-teal-500 relative rounded-sm h-9 text-white focus:outline-none">
                        <div class="flex items-center">
                            <div class="h-9 w-9 bg-teal-600 rounded-l-sm flex justify-center items-center">
                                <i class="icon-plus-circle2"></i>
                            </div>
                            <div class="px-3">
                                Add new brand
                            </div>
                        </div>
                        <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-white border-t-0 border rounded-sm rounded-t-none">
            <table class="w-full divide-y">
                <thead>
                    <tr class="bg-orange-500">
                        <th class="py-3 px-5 text-left text-blue-50 w-40">BRAND LOGO</th>
                        <th class="py-3 px-5 text-left text-blue-50">BRAND NAME</th>
                        <th class="py-3 px-5 text-left text-blue-50">DESCRIPTION</th>
                        <th class="py-3 px-5 text-blue-50 text-center w-28">ACTION</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="divide-y">
                    <tr v-for="i in 10" :key="i">
                        <td class="px-5 py-3 ph-item">
                            <div class="w-full">
                                <div class="h-8 bg-gray-200"></div>
                            </div>
                        </td>
                        <td class="px-5 py-3 ph-item">
                            <div class="w-full">
                                <div class="h-3 bg-gray-200"></div>
                            </div>
                        </td>
                        <td class="px-5 py-3 ph-item">
                            <div class="w-full">
                                <div class="h-3 bg-gray-200"></div>
                            </div>
                        </td>
                        <td class="px-5 py-4 ph-item">
                            <div class="flex justify-center space-x-3">
                                <div class="h-5 w-5 bg-teal-200"></div>
                                <div class="h-5 w-5 bg-gray-200"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!isLoading" class="divide-y">
                    <tr v-for="(brand, i) in brands" :key="i">
                        <td class="py-3 px-5">
                            <div v-if="brand.logo">
                                <img :src="imgUrl+'/'+brand.logo" alt="" width="80">
                            </div>
                            <div v-else class="text-xl text-gray-300">
                                NO LOGO
                            </div>
                        </td>
                        <td class="py-3 px-5">{{brand.name.replace(/\b\w/g, (c) => c.toUpperCase())}}</td>
                        <td class="py-3 px-5">{{brand.description}}</td>
                        <td class="py-3 px-5 flex justify-center">
                            <div class="flex items-center">
                                <tooltip :text="'view stats'" :place="'center'">
                                    <a @click="onShowModalDetail(i)" href="#" class="flex items-center justify-center h-8 w-8 text-teal-500">
                                        <i class="icon-stack-text"></i>
                                    </a>
                                </tooltip>
                                <tooltip :text="'Edit Brand'" :place="'right'">
                                    <a @click.prevent="onEditBrand('EDIT BRAND', i)" href="#" class="flex items-center justify-center h-8 w-8">
                                        <i class="icon-pencil5"></i>
                                    </a>
                                </tooltip>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <modal :show="modal">
            <form @submit.prevent="onSubmit" class="w-2/4 bg-white rounded-sm p-5 border vld-parent">
                <div class="flex items-center justify-between mb-5">
                    <div class="font-semibold">{{modalTitle}}</div>
                    <a @click.prevent="modal= false" href="#">
                        <i class="icon-cancel-circle2"></i>
                    </a>
                </div>
                <div class="mb-5">
                    <label for="">BRAND NAME <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                    <input v-model="form.name" @keydown="error.name=''" :class="{'border-red-500':error.name}" ref="name" type="text" class="form-control my-2" placeholder="name">
                    <span class="text-red-500">{{error.name}}</span>
                </div>
                <div class="mb-5">
                    <label for="">DESCRIPTION</label>
                    <input v-model="form.description" type="text" class="form-control mt-2" placeholder="description">
                </div>
                <div class="mb-5">
                    <div class="mb-2">LOGO</div>
                    <div class="group relative flex items-stretch cursor-pointer">
                        <input ref="fileImage" @change="onFileChange" type="file" class="w-full absolute z-10 opacity-0 cursor-pointer">
                        <span class="flex w-full items-center bg-white px-3 border h-9 rounded-l-sm cursor-pointer whitespace-nowrap overflow-hidden">{{filename}}</span>
                        <span class="bg-blue-500 h-9 whitespace-nowrap px-3 flex items-center rounded-r-sm text-white group-hover:bg-blue-600 cursor-pointer">Choose image</span>
                    </div>
                </div>
                <div class="text-right">
                    <button @click="modal=false" type="button" class="bg-white h-9 border px-5 rounded-sm mr-1 focus:outline-none hover:bg-gray-50">CANCEL</button>
                    <button v-if="modalTitle == 'NEW BRAND'" type="submit" class="bg-teal-600 hover:bg-teal-700 h-9 px-5 text-white rounded-sm focus:outline-none" :disabled="isDisabled">SAVE</button>
                    <button v-else type="submit" class="bg-teal-600 hover:bg-teal-700 h-9 px-5 text-white rounded-sm focus:outline-none">UPDATE</button>
                </div>
            </form>
        </modal>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Modal from '../../components/Modal.vue'
import tooltip from '../../components/Tooltip.vue'
import paginate from '../../components/paginate.vue'
import debounce from 'lodash.debounce'
export default {
    components: {
        Modal, tooltip, paginate
    },
    data () {
        return {
            search: '',
            imgUrl: process.env.VUE_APP_ROOT_API,
            isLoading: false,
            isDisabled: false,
            modal: false,
            modalTitle: '',
            brands: '',
            pages: '',
            brandId: '',
            index: '',
            filename: '',
            form: {
                name: '',
                description: '',
                logo: '',
            },
            error: {
                name: ''
            },
            searchData: '',
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData (i) {
            window.scrollTo(0,0)
            this.isLoading = true
            axios.get('/brands', {
                params: {
                    page: i,
                    search: this.search
                }
            })
            .then(res => {
                this.isLoading = false
                this.brands = res.data.data
                this.pages = res.data.pages
            })
        },
        addNewBrand (title) {
            this.modal = true
            this.isDisabled = false
            this.modalTitle = title 
            this.filename = ''
            this.$refs.fileImage.value = ''
            this.form = {
                name: '',
                description: '',
                logo: ''
            }
            this.error = {
                name: ''
            }
            this.$nextTick(() => {
                this.$refs.name.focus()
            })
        },
        onEditBrand(title, i) {
            this.isDisabled = true
            this.modal = true
            this.modalTitle = title
            this.filename = ''
            this.$refs.fileImage.value = ''
            this.index = i
            this.form = {
                name: '',
                description: '',
                logo: ''
            }
            this.error = {
                name: ''
            }
            this.brandId = this.brands[i]._id
            this.form.name = this.brands[i].name
            this.form.description = this.brands[i].description
        },
        onFileChange (e) {
            const file = e.target.files[0]
            this.filename = file.name
            this.form.logo = file
        },
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Brand name is required'
            }
            if(this.form.name) {
                this.isDisabled = true
                const formData = new FormData()
                formData.append('name', this.form.name)
                formData.append('description', this.form.description)
                formData.append('logo', this.form.logo)
                if(this.modalTitle == 'NEW BRAND') {
                    axios.post('/brands/create', formData, {
                        headers: {
                            'Content-type':'multipart/form-data'
                        }
                    })
                    .then(() => {
                        this.modal = false
                        this.getData()
                    })
                    .catch(() => {
                        this.error.name = 'Brand name is already exists'
                    })
                } else {
                    axios.put('/brands/update/'+this.brandId, formData, {
                        headers: {
                            'Content-type':'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.modal = false
                        this.brands[this.index].name = res.data.name
                        this.brands[this.index].description = res.data.description
                        this.brands[this.index].logo = res.data.logo
                    })
                    .catch(() => {
                        this.error.name = 'Brand name is already exists'
                    })
                }
            }
        },
    },
    watch: {
        search() {
            this.searchData()
        }
    }
}
</script>