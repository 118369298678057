<template>
    <div class="px-5 pt-5 pb-10">
        <div v-if="onLoad" class="pt-4 px-5 ph-item">
            <div class="grid grid-cols-4 gap-10">
                <div v-for="i in 8" :key="i" class="col-span-1 flex items-center gap-10">
                    <div class="relative w-full border rounded-sm inline-flex items-center px-5 py-4">
                        <div class="absolute -top-4 -right-4 border-4 border-gray-100 h-10 w-10 bg-gray-200 rounded-full">
                            
                        </div>
                        <div class="bg-gray-200 rounded-lg h-10 w-10 my-0.5">
                            
                        </div>
                        <div class="ml-auto w-20">
                            <div class="bg-gray-200 w-full h-3 mb-1"></div>
                            <div class="bg-gray-200 w-10 h-3 m-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!onLoad" class="mt-4 px-5">
            <div class="grid grid-cols-4 text-gray-500 gap-10">
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalCurrency = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-green-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-green-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{currencyList.length}}</div>
                        </div>
                        <div class="mb-px">
                            <i class="icon-coin-dollar mb-0.5 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Currency</div>
                        </div>
                    </a>
                    <modal :show="modalCurrency">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Currency Setting</div>
                                <a @click.prevent="modalCurrency = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List of Currencies</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2 sticky top-0 z-20 bg-white" style="width: 10%">Currency</th>
                                                        <th class="p-2 sticky top-0 z-20 bg-white" style="width: 50%">Name</th>
                                                        <th class="p-2 sticky top-0 z-20 bg-white text-center" style="width: 10%">Symbol</th>
                                                        <th class="p-2 sticky top-0 z-20 bg-white text-center" style="width: 30%">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(curr, i) in currencyList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{curr.code}}</td>
                                                        <td class="p-2">{{curr.name}}</td>
                                                        <td class="p-2 text-center">{{curr.symbolNative}}</td>
                                                        <td class="p-2 text-center">
                                                            <div>
                                                                <toggler v-model="curr.status" @input="onUpdateCurrency(i)"></toggler>
                                                                <div v-if="curr.status" class="text-xs text-gray-500">active</div>
                                                                <div v-else class="text-xs text-gray-300">inactive</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitCurrency">
                                            <div class="font-semibold mb-3">Add New Currency</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Currency Code</div>
                                                <select-2 ref="selectcurrency" v-model="currency" @input="selectCurrency(currency)" :placeholder="'please select currency code'">
                                                    <option v-for="(curr, i) in currencies" :key="i" :data-foo="curr.name" :value="curr.code">{{curr.code}}</option>
                                                </select-2>
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Name</div>
                                                <input v-model="formCurrency.name"  type="text" class="form-control bg-gray-50" disabled>
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Symbol</div>
                                                <input v-model="formCurrency.symbolNative" type="text" class="form-control bg-gray-50" disabled>
                                            </div>
                                            <div class="text-right">
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!currency ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!currency">Create New Currency</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalCreditTerm = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-orange-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-orange-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{creditTermList.length}}</div>
                        </div>
                        <div class="my-px">
                            <i class="icon-cash icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Credit Terms</div>
                        </div>
                    </a>
                    <modal :show="modalCreditTerm">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Credit Term</div>
                                <a @click.prevent="modalCreditTerm = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List of Credit Terms</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full relative">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="widt: 20%">Code</th>
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="width: 15%">Days</th>
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="width: 45%">Description</th>
                                                        <th class="p-2 sticky top-0 bg-white text-center z-20" style="width: 20%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(credit, i) in creditTermList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{credit.code}}</td>
                                                        <td class="p-2">{{credit.duration}}</td>
                                                        <td class="p-2">{{credit.description}}</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditCreditTerm(i)" href="#" :class="i == formCreditTerm.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeleteCreditTerm(credit._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitCreditTerm">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Credit Term</div>
                                            <div v-else class="font-semibold mb-4">Edit Credit Term</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Credit Term Code <span class="text-red-500">*</span></div>
                                                <input ref="creditterm" v-model="formCreditTerm.code" type="text" class="form-control" placeholder="Code">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Duration <span class="text-red-500">*</span></div>
                                                <input v-model="formCreditTerm.duration" type="number" class="form-control" placeholder="duration day(s)">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Description</div>
                                                <textarea v-model="formCreditTerm.description" rows="3" class="w-full border focus:outline-none rounded-sm px-3 py-2"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="formCreditTerm.code && formCreditTerm.duration ? 'bg-teal-600 hover:bg-teal-700':'bg-teal-300 cursor-not-allowed'" :disabled="formCreditTerm.code && !formCreditTerm.duration">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div> 
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalPaymentTerm = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-cyan-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-cyan-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{paymentTermList.length}}</div>
                        </div>
                        <div class="mb-px">
                            <i class="icon-cash mb-0.5 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Payment Term</div>
                        </div>
                    </a>
                    <modal :show="modalPaymentTerm">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Payment Term</div>
                                <a @click.prevent="modalPaymentTerm = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List Of Payment Terms</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2" style="width: 20%">Code</th>
                                                        <th class="p-2" style="width: 20%">Duration</th>
                                                        <th class="p-2" style="width: 50%">description</th>
                                                        <th class="p-2 text-center" style="width: 10%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(pay, i) in paymentTermList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{pay.code}}</td>
                                                        <td class="p-2">{{pay.duration}}</td>
                                                        <td class="p-2">{{pay.description}}</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditPaymentTerm(i)" href="#" :class="i == formPaymentTerm.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeletePaymentTerm(pay._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitPaymentTerm">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Payment Term</div>
                                            <div v-else class="font-semibold mb-4">Edit Payment Term</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Payment Term Code<span class="text-red-500">*</span></div>
                                                <input ref="paymenttermname" v-model="formPaymentTerm.code" type="text" class="form-control" placeholder="Name or code">
                                            </div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Duration<span class="text-red-500">*</span></div>
                                                <input v-model="formPaymentTerm.duration" type="number" class="form-control" placeholder="duration day(s)">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Description</div>
                                                <textarea v-model="formPaymentTerm.description" rows="3" class="w-full border focus:outline-none rounded-sm px-3 py-2"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!formPaymentTerm.code || !formPaymentTerm.duration ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!formPaymentTerm.code">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalShipmentTerm = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-blue-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-blue-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{shipmentTermList.length}}</div>
                        </div>
                        <div class="mb-px">
                            <i class="icon-certificate mb-0.5 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center">
                            <div class="font-semibold text-sm w-20">Shipment Term</div>
                        </div>
                    </a>
                    <modal :show="modalShipmentTerm">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Shipment Term</div>
                                <a @click.prevent="modalShipmentTerm = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List Of Shipment Terms</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2" style="width: 30%">Code</th>
                                                        <th class="p-2" style="width: 50%">description</th>
                                                        <th class="p-2 text-center" style="width: 20%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(ship, i) in shipmentTermList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{ship.code}}</td>
                                                        <td class="p-2">{{ship.description}}</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditShipmentTerm(i)" href="#" :class="i == formShipmentTerm.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeleteShipmentTerm(ship._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitShipmentTerm">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Shipment Term</div>
                                            <div v-else class="font-semibold mb-4">Edit Shipment Term</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Shipment Term Code<span class="text-red-500">*</span></div>
                                                <input ref="shipmenttermname" v-model="formShipmentTerm.code" type="text" class="form-control" placeholder="Name or code">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Description</div>
                                                <textarea v-model="formShipmentTerm.description" rows="3" class="w-full border focus:outline-none rounded-sm px-3 py-2"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!formShipmentTerm.code ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!formShipmentTerm.code">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalShipmentMethod = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-yellow-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-yellow-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{shipmentMethodList.length}}</div>
                        </div>
                        <div class="mb-px">
                            <i class="icon-dropbox mb-0.5 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Shipment Method</div>
                        </div>
                    </a>
                    <modal :show="modalShipmentMethod">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Shipment Method</div>
                                <a @click.prevent="modalShipmentMethod = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List of Shipment Methods</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2" style="width: 30%">Code</th>
                                                        <th class="p-2" style="width: 50%">description</th>
                                                        <th class="p-2 text-center" style="width: 20%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(ship, i) in shipmentMethodList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{ship.code}}</td>
                                                        <td class="p-2">{{ship.description}}</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditShipmentMethod(i)" href="#" :class="i == formShipmentMethod.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeleteShipmentMethod(ship._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitShipmentMethod">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Shipment Method</div>
                                            <div v-else class="font-semibold mb-4">Edit Shipment Method</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Shipment Method Code<span class="text-red-500">*</span></div>
                                                <input ref="shipmentmethodname" v-model="formShipmentMethod.code" type="text" class="form-control" placeholder="Name or code">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Description</div>
                                                <textarea v-model="formShipmentMethod.description" rows="3" class="w-full border focus:outline-none rounded-sm px-3 py-2"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!formShipmentMethod.code ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!formShipmentMethod.code">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalAdditionalCharge = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-rose-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-rose-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{additionalChargeList.length}}</div>
                        </div>
                        <div class="my-px">
                            <i class="icon-credit-card icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Additional Charge</div>
                        </div>
                    </a>
                    <modal :show="modalAdditionalCharge">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Additional Charge</div>
                                <a @click.prevent="modalAdditionalCharge = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List of Additional Charges</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2" style="width: 25%">Name</th>
                                                        <th class="p-2" style="width: 15%">Unit Price</th>
                                                        <th class="p-2" style="width: 35%">description</th>
                                                        <th class="p-2" style="width: 10%">Status</th>
                                                        <th class="p-2 text-center" style="width: 15%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(additional, i) in additionalChargeList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{additional.name}}</td>
                                                        <td class="p-2"><span v-if="additional.isFixed">Rp</span><span v-else>%</span> {{Intl.NumberFormat().format(additional.unitPrice)}}</td>
                                                        <td class="p-2">{{additional.description}}</td>
                                                        <td class="p-2">
                                                            <toggler v-model="additional.status" @input="onStatusAdditionalCharge(i)"></toggler>
                                                        </td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditAdditionalCharge(i)" href="#" :class="i == formAdditionalCharge.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeleteAdditionalCharge(additional._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitAdditionalCharge">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Additional Charge</div>
                                            <div v-else class="font-semibold mb-4">Edit Additional Charge</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Name <span class="text-red-500">*</span></div>
                                                <input ref="additionalchargename" v-model="formAdditionalCharge.name" type="text" class="form-control" placeholder="Additional charge name">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Unit Price <span class="text-red-500">*</span></div>
                                                <div class="flex items-center mb-2">
                                                    <div class="flex items-center mr-5">
                                                        <input v-model="formAdditionalCharge.isFixed" :value="true" type="radio" name="unit" id="fixed" class="mr-2">
                                                        <label for="fixed" class="cursor-pointer">Fixed</label>
                                                    </div>
                                                    <div class="flex items-center">
                                                        <input v-model="formAdditionalCharge.isFixed" :value="false" type="radio" name="unit" id="percentage" class="mr-2">
                                                        <label for="percentage" class="cursor-pointer">Percentage</label>
                                                    </div>
                                                </div>
                                                <div class="flex items-center">
                                                    <span v-if="formAdditionalCharge.isFixed" class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm">Rp</span>
                                                    <span v-else class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm">%</span>
                                                    <autonumeric 
                                                        v-model="formAdditionalCharge.unitPrice"
                                                        :options="{
                                                            decimalPlaces: 0,
                                                            modifyValueOnWheel: false,
                                                            emptyInputBehavior: 'null'
                                                        }"
                                                        :placeholder="'Unit Price'"
                                                        class="block text-right w-full h-9 border border-l-0 rounded-r-sm px-3 focus:outline-none">
                                                    </autonumeric>
                                                </div>
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Description</div>
                                                <textarea v-model="formAdditionalCharge.description" rows="2" class="w-full border focus:outline-none rounded-sm px-3 py-2"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="formAdditionalCharge.name && formAdditionalCharge.unitPrice ? 'bg-teal-600 hover:bg-teal-700':'bg-teal-300 cursor-not-allowed'" :disabled="formAdditionalCharge.name && !formAdditionalCharge.unitPrice">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalTax = true" href="#" class="relative w-full border rounded-sm inline-flex items-center transition duration-300 px-5 py-3 hover:text-teal-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-teal-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{taxList.length}}</div>
                        </div>
                        <div class="mb-px">
                            <i class="icon-percent mb-0.5 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center">
                            <div class="font-semibold text-sm w-20">Tax Code</div>
                        </div>
                    </a>
                    <modal :show="modalTax">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 460px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Tax code</div>
                                <a @click.prevent="modalTax = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List of Tax Codes</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 330px">
                                            <table class="w-full">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2" style="width: 30%">Code</th>
                                                        <th class="p-2" style="width: 50%">Amount</th>
                                                        <th class="p-2 text-center" style="width: 20%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(tax, i) in taxList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{tax.code}}</td>
                                                        <td class="p-2">{{tax.amount}}%</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditTax(i)" href="#" :class="i == formTax.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a @click.prevent="onDeleteTax(tax._id)" href="#" class="text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 px-5 pb-5">
                                        <form @submit.prevent="onSubmitTax">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Tax Code</div>
                                            <div v-else class="font-semibold mb-4">Edit Tax Code</div>
                                            <div class="flex-col flex mb-5">
                                                <div class="mb-1">Tax Code<span class="text-red-500">*</span></div>
                                                <input ref="taxcodename" v-model="formTax.code" type="text" class="form-control" placeholder="Tax code">
                                            </div>
                                            <div class="mb-5">
                                                <div class="mb-1">Amount<span class="text-red-500">*</span></div>
                                                <div class="flex items-center">
                                                    <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm">%</span>
                                                    <autonumeric 
                                                        v-model="formTax.amount"
                                                        :options="{
                                                            maximumValue: '100',
                                                            decimalPlaces: 0,
                                                            modifyValueOnWheel: false,
                                                            emptyInputBehavior: 'null'
                                                        }"
                                                        class="block w-full h-9 border border-l-0 rounded-r-sm px-3 focus:outline-none">
                                                    </autonumeric>
                                                </div>
                                            </div>
                                            <div class="text-right">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="formTax.code && formTax.amount ? 'bg-teal-600 hover:bg-teal-700':'bg-teal-300 cursor-not-allowed'" :disabled="formTax.code && !formTax.amount">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalWarehouse = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-violet-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-violet-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{warehouseList.length}}</div>
                        </div>
                        <div class="my-px">
                            <i class="icon-location4 icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Location Warehouse</div>
                        </div>
                    </a>
                    <modal :show="modalWarehouse">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 590px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Warehouse</div>
                                <a @click.prevent="modalWarehouse = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div>
                                <div class="grid grid-cols-6 divide-x">
                                    <div class="col-span-4 px-5">
                                        <div class="font-semibold mb-3">List Of Warehouse</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 460px">
                                            <table class="w-full relative">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="widt: 40%">Name</th>
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="widt: 20%">Code</th>
                                                        <th class="p-2 sticky top-0 bg-white text-center z-20" style="width: 20%">action</th>
                                                        <th class="p-2 sticky top-0 bg-white text-center z-20" style="width: 20%">status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(warehouse, i) in warehouseSort" :key="i" class="border-b" :class="i == warehouseList.length -1 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">
                                                            {{warehouse.name}}
                                                        </td>
                                                        <td class="p-2">
                                                            {{warehouse.code}}
                                                        </td>
                                                        <td class="p-2 text-center flex items-center">
                                                            <a @click.prevent="onOrderWarehouse(i)" href="#" class="hover:text-blue-500 mr-3"><i class="icon-move-up2 icon-2x"></i></a>
                                                            <a @click.prevent="onEditWarehouse(i)" href="#" :class="i == formWarehouse.key ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                            <a v-if="warehouse.status" @mousemove="hover= i" @mouseleave="hover='a'" @click.prevent="onSetDefaultWarehouse(i)" href="#" :class="warehouse.isDefault ? 'text-green-500 hover:text-green-500 mr-3':'text-gray-400'" class="hover:text-blue-500 mr-3 relative flex justify-center">
                                                                <i class="icon-checkmark"></i>
                                                                <div v-show="hover == i" class="bg-cyan-500 p-px absolute animated faster fadeIn flex justify-center -top-7 w-28 z-30 text-white rounded-sm">
                                                                    <span class="text-xs z-10">Set as default</span>
                                                                    <div class="absolute bg-cyan-500 h-2.5 w-2.5 -bottom-1 transform rotate-45">

                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a v-else class="text-gray-200 mr-3 cursor-not-allowed"><i class="icon-checkmark"></i></a>
                                                        </td>
                                                        <td class="p-3 text-center">
                                                            <div>
                                                                <toggler v-model="warehouse.status" @input="onUpdateWarehouseStatus(i)"></toggler>
                                                                <div v-if="warehouse.status" class="text-xs text-gray-500">active</div>
                                                                <div v-else class="text-xs text-gray-300">inactive</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-2 pl-5 pb-5">
                                        <form @submit.prevent="onSubmitWarehouse">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Warehouse</div>
                                            <div v-else class="font-semibold mb-4">Edit Warehouse</div>
                                            <div class="flex-col pr-5 flex mb-5">
                                                <div class="mb-1">Locatioan Name<span class="text-red-500">*</span></div>
                                                <input ref="warehousename" v-model="formWarehouse.name" type="text" class="form-control" placeholder="Location name (e.g Store A)">
                                            </div>
                                            <div class="mb-5 pr-5">
                                                <div class="mb-1">Code</div>
                                                <input v-model="formWarehouse.code" type="text" class="form-control" placeholder="Code location">
                                            </div>
                                            <div id="container" class="mb-5 overflow-y-auto" style="height: 250px">
                                                <div class="grid grid-cols-5 gap-3 pr-5">
                                                    <div class="col-sapn-1 pt-1">
                                                        <div class="mb-1">Section</div>
                                                    </div>
                                                    <div class="col-span-4">
                                                        <div v-if="formWarehouse.sections.length == 0" class="mb-1"></div>
                                                        <div v-for="(section, i) in formWarehouse.sections" :key="i" class="relative flex items-center " :class="i == formWarehouse.sections.length-1? 'mb-3':'mb-1'">
                                                            <input :ref="'section'+i" v-model="section.name" type="text" class="border-b h-7 focus:outline-none w-full focus:border-teal-500">
                                                            <a @click.prevent="deleteSection(i)" tabindex="-1" class="absolute right-0 text-red-400 hover:text-red-500" href=""><i class="icon-cancel-circle2"></i></a>
                                                        </div>
                                                        <a @click.prevent="addSection" class="text-xs text-teal-500 hover:text-teal-600" href="#"><i class="add icon-plus-circle2 mr-2"></i>Add more section</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-right pr-5">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!formWarehouse.name ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!formWarehouse.name">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
                <!-- FORWARDING -->
                <div class="col-span-1 flex items-center">
                    <a @click.prevent="modalForwarding = true" href="#" class="relative w-full border rounded-sm inline-flex items-center px-5 py-3 transition duration-300 hover:text-violet-500">
                        <div class="absolute -top-4 -right-4 border-4 h-10 w-10 bg-violet-500 rounded-full leading-none flex items-center justify-center">
                            <div class="text-base font-semibold text-white">{{forwardingList.length}}</div>
                        </div>
                        <div class="my-px">
                            <i class="icon-truck icon-3x"></i>
                        </div>
                        <div class="ml-auto text-center w-20">
                            <div class="font-semibold text-sm">Forwarding Services</div>
                        </div>
                    </a>
                    <modal :show="modalForwarding">
                        <div class="w-3/4 bg-white rounded-sm border overflow-hidden text-black" style="height: 710px">
                            <div class="flex p-5 items-center">
                                <div class="text-lg">Forwarding</div>
                                <a @click.prevent="modalForwarding = false" href="#" class="ml-auto">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                            <div>
                                <div class="grid grid-cols-12 divide-x">
                                    <div class="col-span-6 px-5">
                                        <div class="font-semibold mb-3">List Of Forwarding</div>
                                        <div class="overflow-y-auto overflow-x-hidden" style="height: 460px">
                                            <table class="w-full relative">
                                                <thead>
                                                    <tr class="border-b uppercase text-xs">
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="widt: 50%">Company</th>
                                                        <th class="p-2 sticky top-0 bg-white z-20" style="widt: 30%">Country</th>
                                                        <th class="p-2 sticky top-0 bg-white text-center z-20" style="width: 20%">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   <tr v-for="(fwd, i) in forwardingList" :key="i" class="border-b" :class="i == 0 && animated ? 'animated fadeInRight':''">
                                                        <td class="p-2">{{fwd.company}}</td>
                                                        <td class="p-2">{{fwd.country}}</td>
                                                        <td class="p-2 text-center">
                                                            <a @click.prevent="onEditForwarding(i)" href="#" :class="i == formForwarding.index ? 'text-blue-500':''" class="hover:text-blue-500 mr-3"><i class="icon-pencil4"></i></a>
                                                        </td>
                                                   </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-span-6 pl-5 pb-5">
                                        <form @submit.prevent="onSubmitForwarding">
                                            <div v-if="!onEdit" class="font-semibold mb-4">Create New Company</div>
                                            <div v-else class="font-semibold mb-4">Edit Company</div>
                                            <div class="flex-col pr-5 flex mb-3">
                                                <div class="mb-1">Name <span class="text-red-500"></span></div>
                                                <input ref="warehousename" v-model="formForwarding.name" type="text" class="form-control" placeholder="Name">
                                            </div>
                                            <div class="flex-col pr-5 flex mb-3">
                                                <div class="mb-1">Company <span class="text-red-500">*</span></div>
                                                <input ref="warehousename" v-model="formForwarding.company" type="text" class="form-control" placeholder="Company Name">
                                            </div>
                                            <div class="mb-3 pr-5">
                                                <div class="mb-1">Address</div>
                                                <textarea v-model="formForwarding.address"  rows="2" class="border rounded-sm focus:outline-none w-full p-2" placeholder="Address"></textarea>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="col-span-1 mb-3 pr-5 flex flex-col">
                                                    <div class="mb-1">Country</div>
                                                    <select-2 v-model="formForwarding.country" :options="countries" class="form-control" :placeholder="'Select Country'">
                                                        <option value=""></option>
                                                    </select-2>
                                                </div>
                                                <div class="col-span-1 mb-3 pr-5">
                                                    <div class="mb-1">State</div>
                                                    <input v-model="formForwarding.state" type="text" class="form-control" placeholder="State">
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="col-span-1 mb-3 pr-5">
                                                    <div class="mb-1">City</div>
                                                    <input v-model="formForwarding.city" type="text" class="form-control" placeholder="City">
                                                </div>
                                                <div class="col-span-1 mb-3 pr-5">
                                                    <div class="mb-1">ZIP</div>
                                                    <input v-model="formForwarding.zip" type="text" class="form-control px-2" placeholder="ZIP">
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="mb-3 pr-5">
                                                    <div class="mb-1">Phone</div>
                                                    <input v-model="formForwarding.phone" type="text" class="form-control" placeholder="Phone">
                                                </div>
                                                <div class="mb-3 pr-5">
                                                    <div class="mb-1">Fax</div>
                                                    <input v-model="formForwarding.fax" type="text" class="form-control" placeholder="Fax">
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="mb-3 pr-5">
                                                    <div class="mb-1">Email</div>
                                                    <input v-model="formForwarding.email" type="text" class="form-control" placeholder="email">
                                                </div>
                                                <div class="mb-3 pr-5">
                                                    <div class="mb-1">Shipping Mark</div>
                                                    <input v-model="formForwarding.shippingMark" type="text" class="form-control" placeholder="Shipping Mark">
                                                </div>
                                            </div>
                                            <div class="text-right pr-5 mt-3">
                                                <button v-if="onEdit" @click="onCancelEdit" type="button" class="h-9 bg-white border rounded-sm px-3 mr-2">Cancel</button>
                                                <button type="submit" class="h-9 text-white px-5 rounded-sm focus:outline-none" :class="!formForwarding.company ? 'bg-teal-300 cursor-not-allowed':'bg-teal-600 hover:bg-teal-700'" :disabled="!formForwarding.company">
                                                    <span v-if="!onEdit">Create New</span>
                                                    <span v-else>Update</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select2 from '../../components/Select2.vue'
import Modal from '../../components/Modal.vue'
import Toggler from '../../components/Toggler.vue'
import Currency from '../../currency.json'
import Country from '../../countries.json'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2'
import Autonumeric from '../../components/Autonumeric.vue'
export default {
    components: {
        Modal,
        Select2,
        Toggler,
        Autonumeric
    },
    data () {
        return {
            hover: 'a',
            satu: null,
            imgURL: process.env.VUE_APP_URL,
            onLoad: false,
            isLoading: false,
            animated: false,
            modalCurrency: false,
            currencies: Currency,
            currency: '',
            formCurrency: '',
            currencyList: '',
            modalPaymentTerm: false,
            formPaymentTerm: {
                code: '',
                duration: '',
                description: ''
            },
            paymentTermList: '',
            modalShipmentTerm: false,
            formShipmentTerm: {
                code: '',
                description: '',
            },
            shipmentTermList: '',
            onEdit: false,
            modalShipmentMethod: false,
            formShipmentMethod: {
                code: '',
                description: ''
            },
            shipmentMethodList: '',
            modalAdditionalCharge: false,
            formAdditionalCharge: {
                name: '',
                unitPrice: null,
                isFixed: true,
                description: ''
            },
            additionalChargeList: '',
            modalTax: false,
            formTax: {
                code: '',
                amount: null
            },
            taxList: '',
            modalCreditTerm: false,
            formCreditTerm: {
                code: '',
                duration: '',
                description: ''
            },
            creditTermList: '',
            modalWarehouse: false,
            formWarehouse: {
                name:'',
                code: '',
                order: '',
                sections: []
            },
            warehouseList: '',
            modalForwarding: false,
            formForwarding: {
                name: '',
                company: '',
                address: '',
                country: '',
                state: '',
                city: '',
                zip: '',
                phone: '',
                fax: '',
                email: '',
                shippingMark: ''
            },
            forwardingList: ''
        }
    },
    mounted() {
        this.getData()
        this.$refs.selectcurrency.templateResult()
        window.addEventListener('keydown', this.closeModal)
    },
    unmounted () {
        window.removeEventListener('keydown', this.closeModal)
    },
    computed: {
        warehouseSort () {
           return this.warehouseList
        },
        countries () {
            return Country.map(obj => {
                obj.id = obj.name
                obj.text = obj.name
                return obj
            })
        },
    },
    methods: {
        closeModal (e) {
            if(e.keyCode == 27) {
                this.modalCurrency = false
                this.modalPaymentTerm = false
                this.modalShipmentTerm = false
                this.modalShipmentMethod = false
                this.modalAdditionalCharge = false
                this.modalTax = false
                this.modalCreditTerm = false
                this.modalWarehouse = false
            }
        },
        getData () {
            this.onLoad = true
            axios.get('/setting/general')
            .then((res) => {
                this.currencyList = res.data.currencies
                this.paymentTermList = res.data.paymentterms
                this.shipmentTermList = res.data.shipmentterms
                this.shipmentMethodList = res.data.shipmentmethods
                this.additionalChargeList = res.data.additionalcharges
                this.taxList = res.data.taxcodes
                this.creditTermList = res.data.creditterms
                this.warehouseList = res.data.warehouses
                this.forwardingList = res.data.forwarding
                this.onLoad = false
            })
        },
        selectCurrency (val) {
            this.formCurrency = this.currencies[val]
        },
        onUpdateCurrency(i) {
            axios.put('/setting/general/currency/'+this.currencyList[i]._id, this.currencyList[i])
        },
        onSubmitCurrency () {
            if(this.currencyList.filter(x => {return x.code == this.formCurrency.code}).length > 0) {
                Swal.fire({
                    title: this.formCurrency.code,
                    text: 'Currency already exists',
                    type: 'error',
                    confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                });
            }  else {
                this.animated = false
                this.isLoading = true
                axios.post('/setting/general/currency', this.formCurrency)
                .then((res) => {
                    this.currencyList = res.data
                    this.formCurrency = ''
                    this.currency = ''
                    this.animated = true
                    this.isLoading = false
                    setTimeout(() => {
                        this.animated = false
                    },1000)
                })
            }
        },
        onSubmitPaymentTerm () {
            if(!this.onEdit) {
                if(this.paymentTermList.filter(x => {return x.code == this.formPaymentTerm.code}).length > 0 ) {
                    Swal.fire({
                        title: this.formPaymentTerm.code,
                        text: 'payment term already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/payment-terms', this.formPaymentTerm)
                    .then((res) => {
                        this.paymentTermList = res.data
                        this.formPaymentTerm = {name:'', duration:  '', description: ''}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }

            } else {
                this.isLoading = true
                axios.put('/setting/general/payment-terms/'+this.formPaymentTerm.id, this.formPaymentTerm)
                .then(res => {
                    this.onEdit = false
                    this.paymentTermList = res.data
                    this.formPaymentTerm = {code:'', description: ''}
                    this.isLoading = false
                })  
            }
        },
        onEditPaymentTerm (i) {
            this.onEdit = true,
            this.$refs.paymenttermname.focus()
            this.formPaymentTerm = {
                index: i,
                id: this.paymentTermList[i]._id,
                code: this.paymentTermList[i].code,
                duration: this.paymentTermList[i].duration,
                description: this.paymentTermList[i].description
            } 
        },
        onDeletePaymentTerm (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/payment-terms/'+id)
                    .then((res) => {
                        app.paymentTermList = res.data
                        app.formPaymentTerm.code = ''
                        app.formPaymentTerm.description = ''
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Payment Term code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onSubmitShipmentTerm () {
            if(!this.onEdit) {
                if(this.shipmentTermList.filter(x => {return x.code == this.formShipmentTerm.code}).length > 0 ) {
                    Swal.fire({
                        title: this.formShipmentTerm.code,
                        text: 'Shipment term already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/shipment-terms', this.formShipmentTerm)
                    .then((res) => {
                        this.shipmentTermList = res.data
                        this.formShipmentTerm = {code:'', description: ''}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }

            } else {
                this.isLoading = true
                axios.put('/setting/general/shipment-terms/'+this.formShipmentTerm.id, this.formShipmentTerm)
                .then((res) => {
                    this.onEdit = false
                    this.shipmentTermList = res.data
                    this.formShipmentTerm = {code:'', description: ''}
                    this.isLoading = false
                })  
            }
        },
        onEditShipmentTerm (i) {
            this.onEdit = true,
            this.$refs.shipmenttermname.focus()
            this.formShipmentTerm = {
                index: i,
                id: this.shipmentTermList[i]._id,
                code: this.shipmentTermList[i].code,
                description: this.shipmentTermList[i].description
            } 
        },
        onDeleteShipmentTerm (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/shipment-terms/'+id)
                    .then((res) => {
                        app.shipmentTermList = res.data
                        app.formShipmentTerm.code = ''
                        app.formShipmentTerm.description = ''
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Shipment Term code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onSubmitShipmentMethod () {
            if(!this.onEdit) {
                if(this.shipmentMethodList.filter(x => {return x.name == this.formShipmentMethod.code}).length > 0 ) {
                    Swal.fire({
                        title: this.formShipmentMethod.code,
                        text: 'Shipment method already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/shipment-methods', this.formShipmentMethod)
                    .then((res) => {
                        this.shipmentMethodList = res.data
                        this.formShipmentMethod = {code:'', description: ''}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }

            } else {
                this.isLoading = true
                axios.put('/setting/general/shipment-methods/'+this.formShipmentMethod.id, this.formShipmentMethod)
                .then((res) => {
                    this.onEdit = false
                    this.shipmentMethodList = res.data
                    this.formShipmentMethod = {code:'', description: ''}
                    this.isLoading = false
                })  
            }
        },
        onEditShipmentMethod (i) {
            this.onEdit = true,
            this.$refs.shipmentmethodname.focus()
            this.formShipmentMethod = {
                index: i,
                id: this.shipmentMethodList[i]._id,
                code: this.shipmentMethodList[i].code,
                description: this.shipmentMethodList[i].description
            } 
        },
        onDeleteShipmentMethod (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/shipment-methods/'+id)
                    .then((res) => {
                        app.shipmentMethodList = res.data
                        app.formShipmentMethod.code = ''
                        app.formShipmentMethod.description = ''
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Shipment Term code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onSubmitAdditionalCharge() {
            if(!this.onEdit) {
                if(this.additionalChargeList.filter(x => {return x.name == this.formAdditionalCharge.name}).length > 0 ) {
                    Swal.fire({
                        title: this.formAdditionalCharge.name,
                        text: 'Shipment term already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/additional-charges', this.formAdditionalCharge)
                    .then((res) => {
                        this.additionalChargeList = res.data
                        this.formAdditionalCharge = {name:'',unitPrice: null, isFixed: true, description: ''}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }

            } else {
                this.isLoading = true
                axios.put('/setting/general/additional-charges/'+this.formAdditionalCharge.id, this.formAdditionalCharge)
                .then((res) => {
                    this.onEdit = false
                    this.additionalChargeList = res.data
                    this.formAdditionalCharge = {name: '', unitPrice: null, isFixed: true, description: ''}
                    this.isLoading = false
                })  
            }
        },
        onStatusAdditionalCharge (i) {
            axios.put('/setting/general/additional-charges/'+this.additionalChargeList[i]._id, this.additionalChargeList[i])
        },
        onEditAdditionalCharge (i) {
            this.onEdit = true,
            this.$refs.additionalchargename.focus()
            this.formAdditionalCharge = {
                index: i,
                id: this.additionalChargeList[i]._id,
                name: this.additionalChargeList[i].name,
                unitPrice: this.additionalChargeList[i].unitPrice,
                isFixed: this.additionalChargeList[i].isFixed,
                status: this.additionalChargeList[i].status,
                description: this.additionalChargeList[i].description
            } 
        },
        onDeleteAdditionalCharge (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/additional-charges/'+id)
                    .then((res) => {
                        app.additionalChargeList = res.data
                        app.formAdditionalCharge.name = ''
                        app.formAdditionalCharge.unitPrice = ''
                        app.formAdditionalCharge.isFixed = true
                        app.formAdditionalCharge.description = ''
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Additional charge code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onSubmitTax () {
            if(!this.onEdit) {
                if(this.taxList.filter(x => {return x.code == this.formTax.code}).length > 0 ) {
                    Swal.fire({
                        title: this.formTax.code,
                        text: 'Tax code already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/tax-codes', this.formTax)
                    .then((res) => {
                        this.taxList = res.data
                        this.formTax = {code:'', amount: null}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }

            } else {
                this.isLoading = true
                axios.put('/setting/general/tax-codes/'+this.formTax.id, this.formTax)
                .then((res) => {
                    this.onEdit = false
                    this.taxList = res.data
                    this.formTax = {name:'', amount: null}
                    this.isLoading = false
                })  
            }
        },
        onEditTax (i) {
            this.onEdit = true,
            this.$refs.taxcodename.focus()
            this.formTax = {
                index: i,
                id: this.taxList[i]._id,
                code: this.taxList[i].code,
                amount: this.taxList[i].amount
            } 
        },
        onDeleteTax (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/tax-codes/'+id)
                    .then((res) => {
                        app.taxList = res.data
                        app.formTax.code = ''
                        app.formTax.amount = null
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Tax code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        onSubmitCreditTerm () {
            if(!this.onEdit) {
                if(this.creditTermList.filter(x => {return x.code == this.formCreditTerm.code}).length > 0 ) {
                    Swal.fire({
                        title: this.formCreditTerm.code,
                        text: 'Credit Term code already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    this.animated = false
                    this.isLoading = true
                    axios.post('/setting/general/credit-terms', this.formCreditTerm)
                    .then((res) => {
                        this.creditTermList = res.data
                        this.formCreditTerm = {code:'', duration: '', description: ''}
                        this.animated = true,
                        this.isLoading = false,
                        setTimeout(() => {
                            this.animated = false
                        }, 1000)
                    })  
                }
            } else {
                this.isLoading = true
                axios.put('/setting/general/credit-terms/'+this.formCreditTerm.id, this.formCreditTerm)
                .then((res) => {
                    this.onEdit = false
                    this.creditTermList = res.data
                    this.formCreditTerm = {code:'', duration: '', description: ''}
                    this.isLoading = false
                })  
            }
        },
        onEditCreditTerm (i) {
            this.onEdit = true,
            this.$refs.creditterm.focus()
            this.formCreditTerm = {
                index: i,
                id: this.creditTermList[i]._id,
                code: this.creditTermList[i].code,
                duration: this.creditTermList[i].duration,
                description: this.creditTermList[i].description,
            } 
        },
        onDeleteCreditTerm (id) {
            let app = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return axios.delete('/setting/general/credit-terms/'+id)
                    .then((res) => {
                        app.creditTermList = res.data
                        app.formCreditTerm.code = ''
                        app.formCreditTerm.duration = ''
                        app.formCreditTerm.description = ''
                        app.onEdit = false
                    })
                },
                allowOutsideClick: false
            })
            .then((res) => {
                if(res.value) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Tax code has been deleted.',
                        type: 'success',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                }
            })
        },
        scrollSectionToEnd() {
            let container = this.$el.querySelector('#container')
            let scroll = container.scrollHeight - container.clientHeight
            container.scrollTop = scroll
        },
        addSection () {
            this.formWarehouse.sections.push({name: ''})
            this.$nextTick(() => {
                let ref = this.formWarehouse.sections.length -1
                this.$refs['section'+ref][0].focus()
                this.scrollSectionToEnd()
            })
        },
        deleteSection (i) {
            const section = this.formWarehouse.sections[i]
            if(section._id) {
                axios.delete('/setting/general/warehouse/section/'+section._id)
                .then(() => {
                    this.formWarehouse.sections.splice(i,1)
                })
                .catch(err => {
                    Swal.fire({
                        title: `can't be deleted`,
                        text: err.response.data,
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                })
            } else {
                this.formWarehouse.sections.splice(i,1)
            }
            
        },
        onSubmitWarehouse () {
            if(!this.onEdit) {
                if(this.warehouseList.filter(x => {return x.name == this.formWarehouse.name}).length > 0 ) {
                    Swal.fire({
                        title: this.formWarehouse.name,
                        text: 'Warehouse name already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    if(this.formWarehouse.sections.length < 1) {
                        Swal.fire({
                            text: 'Please create at least 1 section for this location',
                            type: 'warning',
                            confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                        });
                    } else {
                        this.animated = false
                        this.isLoading = true
                        this.formWarehouse.order = this.warehouseList.length
                        axios.post('/setting/general/warehouse', this.formWarehouse)
                        .then((res) => {
                            this.warehouseList = res.data
                            this.formWarehouse = {name:'', code: '', order: '', sections: []}
                            this.animated = true,
                            this.isLoading = false,
                            setTimeout(() => {
                                this.animated = false
                            }, 1000)
                        })  
                    }
                }
            } else {
                this.isLoading = true
                axios.put('/setting/general/warehouse/'+this.formWarehouse.id, this.formWarehouse)
                .then((res) => {
                    this.onEdit = false
                    this.warehouseList = res.data
                    this.formWarehouse = {name:'', code: '', order: '', sections: []}
                    this.isLoading = false
                })  
            }
        },
        onEditWarehouse (i) {
            this.onEdit = true,
            this.$refs.warehousename.focus()
            this.formWarehouse = {
                key: i,
                id: this.warehouseList[i]._id,
                name: this.warehouseList[i].name,
                code: this.warehouseList[i].code,
                sections: this.warehouseList[i].sections,
                index: this.warehouseList[i].index
            } 
        },
        onOrderWarehouse (idx) {
            let lth = idx + 1
            for (let i = 0; i < lth; i++) {
                this.warehouseList[i].order = i +1
                this.warehouseList[idx].order = 0
            }
            this.warehouseList.sort((a, b) => {
                return a.order - b.order
            })
            axios.put('/setting/general/warehouse/order/set', this.warehouseList)
        },
        onUpdateWarehouseStatus (i) {
            if(this.warehouseList[i].isDefault) {
                let app = this
                let title = this.warehouseList[i].name
                Swal.fire({
                    title: title,
                    text: 'this warehouse as default',
                    type: 'warning',
                    confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    preConfirm: function () {
                        app.warehouseList[i].status = true
                    },
                    allowOutsideClick: false
                });
            } else {
                axios.put('/setting/general/warehouse/status/'+this.warehouseList[i]._id, {status: this.warehouseList[i].status})
            }
        },
        onSetDefaultWarehouse (i) {
            axios.put('/setting/general/warehouse/set-default/'+this.warehouseList[i]._id)
            .then(res => {
                this.warehouseList = res.data
            })
        },
        onSubmitForwarding () {
            if(!this.onEdit) {
                if(this.forwardingList.filter(x => {return x.company == this.formForwarding.company}).length > 0) {
                    Swal.fire({
                        title: this.formTax.code,
                        text: 'Company already exists',
                        type: 'error',
                        confirmButtonClass: 'bg-blue-500 focus:outline-none h-9 px-5 text-white hover:bg-blue-600 rounded-sm',
                    });
                } else {
                    axios.post('/setting/general/forwarding', this.formForwarding)
                    .then(res => {
                        this.forwardingList = res.data
                        this.formForwarding = {company: '', address: '', city: '', country: '', zip: '', phone: '', fax: '', shippingMark: ''}
                    })
                }
            } else {
                axios.put('/setting/general/forwarding/'+this.formForwarding.id, this.formForwarding)
                .then(res => {
                    this.onEdit = false
                    this.forwardingList = res.data
                    this.formForwarding = {company: '', address: '', city: '', country: '', zip: '', phone: '', fax: '', shippingMark: ''}
                })
            }
        },
        onEditForwarding (i) {
            this.onEdit = true
            this.formForwarding = {
                index: i,
                id: this.forwardingList[i]._id,
                name: this.forwardingList[i].name,
                company: this.forwardingList[i].company,
                address: this.forwardingList[i].address,
                city: this.forwardingList[i].city,
                country: this.forwardingList[i].country,
                state: this.forwardingList[i].state,
                zip: this.forwardingList[i].zip,
                phone: this.forwardingList[i].phone,
                fax: this.forwardingList[i].fax,
                email: this.forwardingList[i].email,
                shippingMark: this.forwardingList[i].shippingMark
            }
        },
        onCancelEdit () {
            this.onEdit = false,
            this.formCreditTerm = {
                code: '',
                duration: '',
                description: ''
            }
            this.formPaymentTerm = {
                code: '',
                duration: '',
                description: ''
            }
            this.formShipmentTerm = {
                code: '',
                description: ''
            }
            this.formShipmentMethod = {
                code: '',
                description: ''
            }
            this.formAdditionalCharge = {
                name: '',
                unitPrice: null,
                isFixed: true,
                description: ''
            }
            this.formTax = {
                code: '',
                amount: null
            }
            this.formWarehouse = {
                name: '',
                code: '',
                order: '',
                sections: []
            }
            this.formForwarding = {company: '', address: '', city: '', country: '', zip: '', phone: '', fax: '', shippingMark: ''}
        }
    },
    watch: {
        modalCurrency (val) {
            if(!val) {
                this.formCurrency = ''
            }
        },
        modalPaymentTerm (val) {
            if(!val) {
                this.onEdit = false
                this.formPaymentTerm = {
                    code: '',
                    description: ''
                }
            }
        },
        modalShipmentTerm (val) {
            if(!val) {
                this.onEdit = false
                this.formShipmentTerm = {
                    code: '',
                    description: ''
                }
            }
        },
        modalShipmentMethod (val) {
            if(!val) {
                this.onEdit = false
                this.formShipmentMethod = {
                    code: '',
                    description: ''
                }
            }
        },
        modalAdditionalCharge (val) {
            if(!val) {
                this.onEdit = false
                this.formAdditionalCharge = {
                    name: '',
                    unitPrice: null,
                    isFixed: true,
                    description: ''
                }
            }
        },
        modalTax (val) {
            if(!val) {
                this.onEdit = false
                this.formTax = {
                    code: '',
                    amount: null
                }
            }
        },
        modalCreditTerm (val) {
            if(!val) {
                this.onEdit = false
                this.formCreditTerm = {
                    code: '',
                    duration: '',
                    description: ''
                }
            }
        },
        modalWarehouse (val) {
            if(!val) {
                this.onEdit = false
                this.formWarehouse = {
                    name: '',
                    code: '',
                    order: '',
                    sections: []
                }
            }
        },
        modalForwarding (val) {
            if(!val) {
                this.onEdit = false
                this.formForwarding = {company: '', address: '', city: '', country: '', zip: '', phone: '', fax: '', shippingMark: ''}
            }
        }
    }
}
</script>

<style scoped>
    .add.icon-plus-circle2 {
        font-size: 13px;
    }
</style>