<template>
    <div class="m-5">
        <div class="bg-white border rounded-sm rounded-b-none p-5">
            <div class="flex items-center">
                <div class="w-2/5">
                    <div class="w-full">
                        <div class="form-feedback form-feedback-left">
                            <input v-model="search"  type="text" class="form-control" placeholder="search category name">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-search4 text-gray-400"></i>
                                </span>
                            </div>
                            <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                                <a @click="search = ''" href="#">
                                    <i class="icon-cancel-circle2 text-red-500"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <button @click="addNewCategory('NEW CATEGORY')" type="button" class="bg-teal-500 relative rounded-sm h-9 text-white focus:outline-none">
                        <div class="flex items-center">
                            <div class="h-9 w-9 bg-teal-600 rounded-l-sm flex justify-center items-center">
                                <i class="icon-plus-circle2"></i>
                            </div>
                            <div class="px-3">
                                Add new category
                            </div>
                        </div>
                        <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-white border-t-0 border rounded-sm rounded-t-none">
            <table class="w-full divide-y">
                <thead>
                    <tr class="bg-blue-500">
                        <th class="py-3 px-5 text-left text-blue-50">CATEGORY NAME</th>
                        <th class="py-3 px-5 text-left text-blue-50">DESCRIPTION</th>
                        <th class="py-3 px-5 text-blue-50 text-center w-28">ACTION</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="divide-y">
                    <tr v-for="i in 20" :key="i">
                        <td class="px-5 py-3 ph-item">
                            <div class="w-full">
                                <div class="h-3 bg-gray-200"></div>
                            </div>
                        </td>
                        <td class="px-5 py-3 ph-item">
                            <div class="w-full">
                                <div class="h-3 bg-gray-200"></div>
                            </div>
                        </td>
                        <td class="px-5 py-4 ph-item">
                            <div class="flex justify-center space-x-3">
                                <div class="h-5 w-5 bg-teal-200"></div>
                                <div class="h-5 w-5 bg-gray-200"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!isLoading" class="divide-y">
                    <tr v-for="(category, i) in categories" :key="i">
                        <td class="py-3 px-5">{{category.name}}</td>
                        <td class="py-3 px-5">{{category.description}}</td>
                        <td class="py-3 px-5 flex justify-center">
                            <div class="flex items-center">
                                <tooltip :text="'view stats'" :place="'center'">
                                    <a @click="onShowModalDetail(i)" href="#" class="flex items-center justify-center h-8 w-8 text-teal-500">
                                        <i class="icon-stack-text"></i>
                                    </a>
                                </tooltip>
                                <tooltip :text="'Edit Category'" :place="'right'">
                                    <a @click="onEditCategory('EDIT CATEGORY', i)" href="#" class="flex items-center justify-center h-8 w-8">
                                        <i class="icon-pencil5"></i>
                                    </a>
                                </tooltip>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <modal :show="modal">
            <form @submit.prevent="onSubmit" class="w-2/4 bg-white rounded-sm p-5 border">
                <div class="flex items-center justify-between mb-5">
                    <div class="font-semibold">{{modalTitle}}</div>
                    <a @click.prevent="modal= false" href="#">
                        <i class="icon-cancel-circle2"></i>
                    </a>
                </div>
                <div class="mb-5">
                    <label for="">CATEGORY NAME <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                    <input v-model="form.name" @keydown="error.name=''" :class="{'border-red-500':error.name}" ref="name" type="text" class="form-control my-2" placeholder="name">
                    <span class="text-red-500">{{error.name}}</span>
                </div>
                <div class="mb-5">
                    <label for="">DESCRIPTION</label>
                    <input v-model="form.description" type="text" class="form-control mt-2" placeholder="description">
                </div>
                <div class="text-right">
                    <button @click="modal=false" type="button" class="bg-white h-9 border px-5 rounded-sm mr-1 focus:outline-none hover:bg-gray-50">CANCEL</button>
                    <button v-if="modalTitle == 'NEW CATEGORY'" type="submit" class="bg-teal-600 hover:bg-teal-700 h-9 px-5 text-white rounded-sm focus:outline-none" :disabled="isDisabled">SAVE</button>
                    <button v-else type="submit" class="bg-teal-600 hover:bg-teal-700 h-9 px-5 text-white rounded-sm focus:outline-none" :disabled="isDisabled">UPDATE</button>
                </div>
            </form>
        </modal>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import Modal from '../../components/Modal.vue'
import tooltip from '../../components/Tooltip.vue'
import axios from 'axios'
import paginate from '../../components/paginate.vue'
import debounce from 'lodash.debounce'
export default {
    components: {
        Modal, tooltip, paginate
    },
    data () {
        return {
            search: '',
            categories: '',
            pages: '',
            isLoading: false,
            isDisabled: false,
            modal: false,
            modalTitle: '',
            categoryId: '',
            index: '',
            form: {
                name: '',
                description: ''
            },
            error: {
                name: ''
            },
            searchData: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData (i) {
            this.isLoading = true
            axios.get('/categories', {
               params: {
                   page: i,
                   search: this.search
               }
            })
            .then(res => {
                this.categories = res.data.data
                this.pages = res.data.pages
                this.isLoading = false
            })
        },
        addNewCategory (title) {
            this.isDisabled = false
            this.modal = true
            this.modalTitle = title
            this.form = {
                name: '',
                description: ''
            }
            this.error.name = ''
            this.$nextTick(() => {
                this.$refs.name.focus()
            })
        },
        onEditCategory (title, i) {
            this.isDisabled = false
            this.modal = true
            this.modalTitle = title
            this.form = {
                name: '',
                description: ''
            }
            this.error.name = ''
            this.form.name = this.categories[i].name
            this.form.description = this.categories[i].description
            this.categoryId = this.categories[i]._id
            this.index = i
        },
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Category name is required'
            }
            if(this.form.name) {
                this.isDisabled = true
                if(this.modalTitle == 'NEW CATEGORY') {
                    axios.post('/categories/create', this.form)
                    .then(() => {
                        this.modal = false
                        this.getData()
                    })
                    .catch(() => {
                        this.error.name = 'Category name is already exists'
                    })
                } else {
                    axios.put('/categories/update/'+this.categoryId, this.form)
                    .then(res => {
                        this.categories[this.index].name = res.data.name
                        this.categories[this.index].description = res.data.description
                        this.modal = false
                    })
                    .catch(() => {
                        this.error.name = 'Categoty name is already exists'
                    })
                }
            }
        }
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>