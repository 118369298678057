<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-40 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <transition name="slide">
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class=" w-4/6 bg-white rounded-sm relative">
                                <div class="pl-5 pr-3 py-3 border-b">
                                    <div class="flex items-center justify-between">
                                        <h5 class="font-semibold">{{title}}</h5>
                                        <a @click.prevent="$emit('onClose')" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500" style="font-size: 19px"></i></a>
                                    </div>
                                </div>
                                <form>
                                    <div ref="modalBody" class="p-5 overflow-scroll">
                                        <div class="mb-10 border-b">
                                            <div class="w-full mr-28">
                                                <div class="col-span-10 flex space-x-5 mb-5">
                                                    <div class="form-radio">
                                                        <input v-model="form.type"  :value="'contact'" type="radio" id="radioinlinesatu" name="radioinline">
                                                        <label for="radioinlinesatu">Contact</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.type" :value="'invoice'" type="radio" id="radioinlinedua" name="radioinline">
                                                        <label for="radioinlinedua">Invoice Address</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.type" :value="'delivery'" type="radio" id="radioinlinetiga" name="radioinline">
                                                        <label for="radioinlinetiga">Delivery Address</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.type" :value="'other'" type="radio" id="radioinlineempat" name="radioinline">
                                                        <label for="radioinlineempat">Other Address</label>
                                                    </div>
                                                    <div class="form-radio">
                                                        <input v-model="form.type" :value="'private'" type="radio" id="radioinlinelima" name="radioinline">
                                                        <label for="radioinlinelima">Private Address</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-2 mb-5">
                                            <div class="col-span-1 pr-10">
                                                <div class="grid grid-cols-7 mb-2">
                                                    <div class="col-span-2">Contact Name</div>
                                                    <div class="col-span-5">
                                                        <input v-model="form.name" type="text" class="w-full border-b border-gray-500 focus:outline-none focus:border-teal-500">
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-7 mb-2">
                                                    <div class="col-span-2">Address</div>
                                                    <div class="col-span-5">
                                                        <input v-model="form.address.street" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500" placeholder="Street...">
                                                        <input v-model="form.address.street2" type="text" class="w-full border-b focus:outline-none mb-2 focus:border-teal-500" placeholder="Street 2...">
                                                        <div class="grid grid-cols-2 gap-2 mb-2">
                                                            <div class="col-span-1">
                                                                <autocomplete v-model="subdistrict" :placeholder="'District'" @input="onSelectSubdistrict($event)" :url="'indonesia/subdistricts/?cityId='+city._id">
                                                                    <template #default="item">
                                                                        {{item.item.name}}
                                                                    </template>
                                                                </autocomplete>
                                                            </div>
                                                            <div class="col-span-1">
                                                                <autocomplete v-model="city" :placeholder="'City'" @input="onSelectCity($event)" :url="'indonesia/cities/?provinceId='+province._id">
                                                                    <template #default="item">
                                                                        {{item.item.name}}
                                                                    </template>
                                                                </autocomplete>
                                                            </div>
                                                        </div>
                                                        <div class="grid grid-cols-3 gap-2">
                                                            <div class="col-span-2">
                                                                <div class="col-span-1">
                                                                <autocomplete v-model="province" :placeholder="'Province'" @input="onSelectProvince($event)" :url="'indonesia/provinces'">
                                                                    <template #default="item">
                                                                        {{item.item.name}}
                                                                    </template>
                                                                </autocomplete>
                                                            </div>
                                                            </div>
                                                            <div class="col-span-1">
                                                                <input v-model="form.address.zip" type="text" class="w-full border-b focus:outline-none focus:border-teal-500" placeholder="ZIP">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-1 pr-10">
                                                <div class="grid grid-cols-7 mb-2">
                                                    <label for="" class="col-span-2">Mobile</label>
                                                    <div class="col-span-5">
                                                        <input v-model="form.contact.mobile" type="text" class="w-full border-b focus:outline-none focus:border-teal-500">
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-7 mb-2">
                                                    <label for="" class="col-span-2">Eamil</label>
                                                    <div class="col-span-5">
                                                        <input v-model="form.contact.email" type="text" class="w-full border-b focus:outline-none focus:border-teal-500">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-8">
                                            <div class="col-span-1">Remarks</div>
                                            <div class="col-span-7 pr-10">
                                                <textarea v-model="form.remarks" class="w-full focus:outline-none border-b resize-none" placeholder="Remarks..." cols="30" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-5 py-3 border-t text-right">
                                        <button type="button" @click="$emit('onClose')" class="h-9 px-5 bg-gray-100 hover:bg-gray-200 border mr-1.5 hover:border-gray-300 rounded-sm">DISCARD</button>
                                        <button v-if="title == 'CREATE CONTACT'" type="button" @click="onSubmit('new')" class="h-9 px-5 bg-teal-500 text-teal-50 hover:bg-teal-600 mr-1.5 rounded-sm">SAVE & NEW</button>
                                        <button type="button" @click="onSubmit('save')" class="h-9 px-5 bg-teal-500 text-teal-50 hover:bg-teal-600 rounded-sm">
                                            <span v-if="title == 'CREATE CONTACT'">SAVE & CLOSE</span>
                                            <span v-else>SAVE</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'
import autocomplete from './Autocomplete.vue'
export default {
    props: ['show','data', 'title'],
    components: {autocomplete},
    data() {
        return {
            subdistrict:'',
            city: '',
            province: '',
            form: {
                type: 'other',
                name: '',
                address: {
                    street: '',
                    street2: '',
                    subdistrictId: '',
                    subdistrictName: '',
                    cityId: '',
                    cityName: '',
                    provinceId: '',
                    provinceName: '',
                    zip: ''
                },
                contact: {
                    mobile: '',
                    email: '',
                },
                remarks: ''
            }
        }
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize)
    },
    destroyed () {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize () {
            this.$refs.modalBody.style.maxHeight = window.innerHeight - 180+'px'
        },
        onSelectSubdistrict (e) {
            if(e) {
                this.form.address.subdistrictId = e._id;
                this.form.address.subdistrictName = e.name
                axios.get('/indonesia/cities/', {
                    params: {
                        cityId: e.city_id
                    }
                })
                .then(cities => {
                    this.city = cities.data[0]
                    this.form.address.cityId = this.city._id
                    this.form.address.cityName = this.city.name
                    axios.get('indonesia/provinces')
                    .then(provinces => {
                        this.province = provinces.data.find(obj => obj._id == cities.data[0].province_id)
                        this.form.address.provinceId = this.province._id
                        this.form.address.provinceName = this.province.name
                    })
                })
            } else {
                this.form.address.subdistrictId = '';
                this.form.address.subdistrictName = ''
            }
        },
        onSelectCity (e) {
            this.subdistrict = ''
            if(e) {
                this.form.address.cityId = e._id;
                this.form.address.cityName = e._name
                axios.get('indonesia/provinces')
                .then(provinces => {
                    this.province = provinces.data.find(obj => obj._id == e.province_id)
                })
            } else {
                this.form.address.cityId = '';
                this.form.address.cityName = ''
            }
        },
        onSelectProvince (e) {
            this.city = ''
            if(e) {
                this.form.address.provinceId = e._id
                this.form.address.provinceName = e.name
            } else {
                this.form.address.provinceId = ''
                this.form.address.provinceName = ''
            }
        },
        onSubmit (i) {
            this.$emit('input', this.form)
            this.form.name = ''
            this.form.remarks = ''
            this.form.type = 'other'
            this.form.contact.mobile = ''
            this.form.contact.email = ''
            if(i == 'save') {
                this.clearForm()
                this.$emit('onClose')
            }
        },
        clearForm () {
            this.form = {
                type: 'other',
                name: '',
                address: {
                    street: '',
                    street2: '',
                    subdistrictId: '',
                    subdistrictName: '',
                    cityId: '',
                    cityName: '',
                    provinceId: '',
                    provinceName: '',
                    zip: ''
                },
                contact: {
                    mobile: '',
                    email: '',
                },
                remarks: ''
            }
        }
    },
    watch: {
        show (val) {
            this.$refs.modalBody.style.maxHeight = window.innerHeight - 180+'px'
            if(val) {
                if(this.title == 'CREATE CONTACT') {
                    this.city = this.data.city
                    this.subdistrict = this.data.subdistrict
                    this.province = this.data.province
                    this.form.address = this.data.address
                } else {
                    this.city = this.data.city
                    this.subdistrict = this.data.subdistrict
                    this.province = this.data.province
                    this.form = this.data.form   
                }
            }
        }
    }
}
</script>