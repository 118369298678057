<template>
    <div class="m-5">
        <div class="flex items-center justify-between">
            <div class="text-lg"><i class="icon-library2 mr-3"></i>Bank accounts</div>
            <button @click="openSlider" type="button" class="bg-teal-500 ml-2 relative rounded-sm h-9 text-white focus:outline-none">
                <div class="flex items-center">
                    <div class="h-9 w-9 bg-teal-600 rounded-l-sm flex justify-center items-center">
                        <i class="icon-plus-circle2"></i>
                    </div>
                    <div class="px-3">Add Bank Account</div>
                </div>
                <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
            </button>
        </div>
        <!-- Bank list -->
        <div class="mt-5 space-y-5">
            <div v-for="(bank, i) in banks" :key="i" class="grid grid-cols-12 bg-white p-5 border rounded-sm gap-5 items-center">
                <div class="col-span-2">
                    <img :src="imgUrl+'/'+bank.icon" alt="">
                </div>
                <div class="col-span-5">
                    <div>{{bank.name}}</div>
                    <div class="font-semibold">{{bank.accountNumber}}</div>
                    <div>{{bank.accountName}}</div>
                </div>
                <div class="col-span-3">
                    <!-- untuk modul tambahan -->
                </div>
                <div class="col-span-2 text-center">
                    <a @click.prevent="editBank(i)" href="#" class="border-2 border-teal-600 text-teal-600 p-3 rounded-sm">
                        <i class="icon-pencil7"></i>
                    </a>
                    <!-- <a @click.prevent="onDelete(bank.id)" href="#" class="border-2 p-3 border-red-600 text-red-600 rounded-sm">
                        <i class="icon-trash"></i>
                    </a> -->
                </div>
            </div>
        </div>
        <!-- /end bank list -->

        <!-- form slider -->
        <slider :show="slider">
            <form @submit.prevent="onSubmit" class="relative w-screen max-w-md">
                <div class="h-full flex flex-col bg-white">
                    <div class="px-5 py-3 bg-gray-200 text-sm font-semibold flex items-center">
                        <div class="uppercase">{{sliderTitle}}</div>
                    </div>
                    <div class="p-5">
                        <div class="mb-5">
                            <label for="">Bank Name</label>
                            <div class="flex flex-col mt-2">
                                <select-2 v-model="bankId" :options="banksSelect" @input="onSelect(bankId)" :placeholder="'Select bank'">
                                    <option value=""></option>
                                </select-2>
                            </div>
                            <div v-if="error.name" class="text-red-500 mt-1">{{error.name}}</div>
                        </div>
                        <div class="mb-5">
                            <label for="">Account Number</label>
                            <input ref="account_number" v-model="form.accountNumber" @keydown="error.accountNumber=''" type="text" :class="{'border-red-500': error.accountNumber}" class="form-control mt-2" placeholder="Account Number (example: 23990093849)">
                            <div v-if="error.accountNumber" class="text-red-500 mt-1">{{error.accountNumber}}</div>
                        </div>
                        <div class="mb-5">
                            <label for="">Account Name</label>
                            <input type="text" v-model="form.accountName" @keydown="error.accountName=''" :class="{'border-red-500': error.accountName}" class="form-control mt-2" placeholder="Account Name (example: John Doe)">
                            <div v-if="error.accountName" class="text-red-500 mt-1">{{error.accountName}}</div>
                        </div>
                        <div class="mb-5">
                            <label for="">Kantor Cabang Pembantu</label>
                            <input type="text" v-model="form.kcp"  class="form-control mt-2" placeholder="">
                        </div>
                    </div>
                    <div class="fixed bg-gray-200 bottom-0 max-w-md w-full px-5 py-3 text-right z-20">
                        <button type="button" @click="slider = false" class="bg-red-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-red-600 mr-2">Close</button>
                        <button class="relative bg-teal-500 h-9 rounded-sm px-5 focus:outline-none text-white hover:bg-teal-600" :disabled="isLoading">
                            <span>Save</span>
                            <div v-show="isLoading" class="absolute bg-teal-400 w-full h-full left-0 top-0 rounded-sm flex items-center justify-center">
                                <div>
                                    <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </slider>
        <!-- /form slider -->
    </div>
</template>

<script>
import slider from '../../components/slider.vue'
import Select2 from '../../components/Select2.vue'
import axios from 'axios'
export default {
    components: {
        slider,
        Select2
    },
    data () {
        return {
            imgUrl: process.env.VUE_APP_ROOT_API,
            isLoading: false,
            slider: false,
            sliderTitle: '',
            sliderMode: '',
            banks: '',
            banksSelect: [
                {id: 1, text: 'PT. BCA (BANK CENTRAL ASIA) TBK / BCA', icon: 'public/img/bank/icon-bca.png'},
                {id: 2, text: 'PT. BANK NEGARA INDONESIA (BNI) (PERSERO) / BNI', icon: 'public/img/bank/icon-bni.png'},
                {id: 3, text: 'PT. BANK RAKYAT INDONESIA (BRI) (PERSERO) / BRI', icon: 'public/img/bank/icon-bri.png'},
                {id: 4, text: 'CITIBANK NA / CITIBANK', icon: 'public/img/bank/icon-citibank.png'},
                {id: 5, text: 'PT. BANK DANAMON INDONESIA TBK / BANK DANAMON', icon: 'public/img/bank/icon-danamon.png'},
                {id: 6, text: 'PT. BANK MANDIRI / BANK MANDIRI', icon: 'public/img/bank/icon-mandiri.png'},
            ],
            bankId: '',
            form: {
                name: '',
                accountNumber: '',
                accountName: '',
                kcp: '',
                icon: '',
            },
            error: {
                name: '',
                accountNumber: '',
                accountName: ''
            }

        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData () {
            axios.get('/setting/bank')
            .then(res => {
                this.banks = res.data
            })
        },
        openSlider () {
            this.slider = true
            this.sliderMode = 'new',
            this.sliderTitle = 'ADD NEW BANK ACCOUNT'
            this.bankId= '',
            this.form= {
                name: '',
                accountNumber: '',
                accountName: '',
                icon: '',
            },
            this.error = {
                name: '',
                accountNumber: '',
                accountName: ''
            }
        },
        editBank (i) {
            let bank = this.banksSelect.filter(bank => {
                return bank.text == this.banks[i].name
            })
            this.bankId = bank[0].id
            this.form = this.banks[i]
            this.slider = true
            this.sliderTitle = 'EDIT BANK ACCOUNT'
            this.sliderMode = 'edit'
        },
        onSelect(id) {
            let bank = this.banksSelect.filter(bank => {
                return bank.id == id
            })
            this.form.name = bank[0].text
            this.form.icon = bank[0].icon
            this.$refs.account_number.focus()
            this.error.name = ''
        },
        onSubmit () {
            if(!this.form.name) {
                this.error.name = 'Please select bank name'
            }
            if(!this.form.accountNumber) {
                this.error.accountNumber = 'Account number is required'
            }
            if(!this.form.accountName) {
                this.error.accountName = 'Account name is required'
            }
            if(this.form.name && this.form.accountNumber && this.form.accountName) {
                if(this.sliderMode == 'new') {
                    this.addNewBank()
                } else {
                    this.updateBank()
                }
            }
        },
        addNewBank () {
            this.isLoading = true
            axios.post('/setting/bank', this.form)
            .then(res => {
                this.banks.push(res.data)
                this.slider = false
                this.isLoading = false
            }) 
            .catch(() => {
                this.isLoading = false
            })  
        },
        updateBank () {
            axios.put('/setting/bank/'+this.form._id, this.form) 
            .then(() => {
                this.slider = false
            })
        }
    }
}
</script>