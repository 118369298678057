<template>
    <div class="relative flex justify-center">
        <div v-show="show" :class="places" class="absolute shadow-md text-left w-max max-w-sm animated bottom-7 faster fadeIn z-10 bg-gray-50 border flex inset-x-auto text-gray-600 px-3 py-2 rounded-sm">
            {{text}}
            <div class="absolute -bottom-1.5 border border-t-0 border-l-0 transform rotate-45 bg-gray-50 h-2.5 w-2.5"></div>
        </div>
        <div @mousemove="show=true" @mouseleave="show=false">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        place: String
    },
    data () {
        return {
            show: false,
        }
    },
    computed: {
        places () {
            let p = ''
            if(this.place == 'left') {
                p ='left-0 justify-start'
            }
            if(this.place == 'center') {
                p = 'justify-center'
            }
            if(this.place == 'right') {
                p = 'right-0 justify-end'
            }
            return p
        }
    }
}
</script>