<template>
    <div>
        <div class="flex items-center justify-between bg-white border rounded-t-sm px-4 shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': form.status == 'Draft'}">Draft</button></li>
                    <li><button :class="{'active': form.status == 'PO Sent'}">PO SENT</button></li>
                    <li><button :class="{'active': form.status == 'PO Firm'}">PO FIRMƒ</button></li>
                </stepper>
            </div>
        </div>
        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-red-500 hover:border-gray-200 hover:text-red-600">INFO</div>
                    </div>
                </div>
            </div>
            <div class="border border-t-0 border-b-0 p-5 bg-white">
                <div class="grid grid-cols-9 divide-x">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-10">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{purchaseNo}}</div>
                                <div class="text-gray-400 text-xs">PURCHASE ORDER #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{getDate(createdAt)}}</div>
                                <div class="text-gray-400 text-xs">DATE CREATED</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">SUPPLIER <span class="text-red-500">*</span></label>
                            <div class="mt-2 flex items-center w-full">
                                <autocomplete class="w-full text-lg" ref="autocomplete" @onSearchMore="modalCustomerSearch = true" @onEdit="onEditCreateCustomer($event)" :err="errCustomerId" v-model="supplier" :label="supplier.name" @input="onSelectSupplier($event)" :text="true" :url="'/purchases/supplier'" :placeholder="'Search supplier by name or code'">
                                    <template #default="item">
                                        <div>
                                            <span >{{item.item.name}}</span>
                                            <div class="text-gray-400 text-xs">
                                               
                                            </div>
                                        </div>
                                    </template>
                                </autocomplete>
                            </div>
                            <div v-if="address">
                               <div>{{address.address}}</div>
                               <div>{{address.city}} {{address.state}} {{address.country}} {{address.postalCode}}</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">REFERENCE NUMBER</label>
                            <input v-model="form.referenceNumber" type="text" class="form-control mt-2">
                        </div>
                        <div class="mb-4">
                            <div class="flex items-center justify-between">
                                <div>
                                    <label for="">REMARKS</label>
                                </div>
                            </div>
                            <textarea v-model="form.remarks" placeholder="remarks" rows="1" class="border w-full rounded-sm p-3 mt-2 focus:outline-none"></textarea>
                        </div>
                        <div>
                            <label for="">TAGS</label>
                            <tags-input v-model="form.tags" class="mt-2"></tags-input>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div>
                                <label for="">ESTIMATED DELIVERY DATE</label>
                                <div class="mt-2">
                                    <date-picker v-model="form.estimatedReceiveDate" :masks="masks" class="mt-2">
                                        <template v-slot="{inputValue, togglePopover}">
                                            <div class="flex">
                                                <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                                <input :value="inputValue" @click="togglePopover" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                            <div>
                                <label for="">INVOICE DATE</label>
                                <div class="mt-2">
                                    <date-picker v-model="form.invoiceDate" :masks="masks" class="mt-2">
                                        <template v-slot="{inputValue, togglePopover}">
                                            <div class="flex">
                                                <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                                <input :value="inputValue" @click="togglePopover" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">SHIP TO <span class="text-red-500"></span></label>
                            <div class="mt-2 flex items-center w-full">
                                <autocomplete class="w-full text-lg" @onEdit="onEditCreateCustomer($event)" :err="errCustomerId" v-model="company" :label="company.company" @input="onSelectComapny($event)" :text="true" :url="'/purchases/forwarding'" :placeholder="'Search company by name'">
                                    <template #default="item">
                                        <div>
                                            <span >{{item.item.company}}</span>
                                        </div>
                                    </template>
                                </autocomplete>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">PAYMENT TERM</label>
                            <div class="relative flex items-center mt-2">
                                <select-2 v-model="form.paymentTermId" :options="paymentTerms" class="form-control" :placeholder="'Select payment term'">
                                    <option value=""></option>
                                </select-2>
                                <a v-if="form.paymentTermId" @click.prevent="onDeletePaymentTerm" href="#" class="absolute mb-px right-7 hover:text-red-500"><i class="icon-cross3"></i></a>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div>
                                <label for="">CURRENCY</label>
                                <div class="relative flex items-center mt-2">
                                    <select-2 v-model="form.currencyId" @input="onSelectCurrency($event)" :options="currencies" class="form-control" :placeholder="'Select currency'">
                                        <option value=""></option>
                                    </select-2>
                                    <a v-if="form.currencyId" @click.prevent="onDeletCurrency" href="#" class="absolute mb-px right-7 hover:text-red-500"><i class="icon-cross3"></i></a>
                                </div>
                            </div>
                            <div>
                                <label for=""> EXCHANGE RATE</label>
                                <div class="mt-2 relative flex items-center">
                                    <autonumeric v-model="form.exchangeRate" :options="{currencySymbol: 'Rp', modifyValueOnWheel: false}" class="form-control text-right"></autonumeric>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div>
                                <label for="">SHIPMENT TERM</label>
                                <div class="relative flex items-center mt-2">
                                    <select-2 v-model="form.shipmentTermId" :options="shipmentTerms" class="form-control" :placeholder="'Select shipment term'">
                                        <option value=""></option>
                                    </select-2>
                                    <a v-if="form.shipmentTermId" @click.prevent="onDeleteShipmentTerm" href="#" class="absolute mb-px right-7 hover:text-red-500"><i class="icon-cross3"></i></a>
                                </div>
                            </div>
                            <div>
                                <label for="">SHIPMENT METHOD</label>
                                <div class="relative flex items-center mt-2">
                                    <select-2 v-model="form.shipmentMethodId" :options="shipmentMethods" class="form-control" :placeholder="'Select shipment method'">
                                        <option value=""></option>
                                    </select-2>
                                    <a v-if="form.shipmentMethodId" @click.prevent="onDeleteShipmentMethod" href="#" class="absolute mb-px right-7 hover:text-red-500"><i class="icon-cross3"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="mb-2">
                                <label for="">ADDITIONAL CHARGES</label>
                            </div>
                            <div v-for="(charges, i) in form.additionalCharges" :key="i" class="grid grid-cols-2 gap-5 text-teal-500">
                                <div class="slt charges">
                                    <select-2 :ref="'additionalCharges'+i" v-model="charges.id" @input="onSelectAdditionalCharges($event, i)" :options="additionalChargesList[i]" :placeholder="'Please select addtional charges'" class="form-control bg-gray-200">
                                        <option value=""></option>
                                    </select-2>
                                </div>
                                <div>
                                    <div v-if="charges.isFixed" class="flex items-center">
                                        <autonumeric v-model="charges.unitPrice" :options="{currencySymbol: currencySymbol, modifyValueOnWheel : false}" class="form-control border-t-0 border-l-0 border-r-0 text-right" :placeholder="'unit price'"></autonumeric>
                                        <a @click.prevent="deleteAdditionalCharges(i)" href="#" tabindex="-1" class="ml-2 text-red-400"><i class="icon-cancel-circle2"></i></a>
                                    </div>
                                    <div v-else class="flex items-center">
                                        <autonumeric v-model="charges.unitPrice" :options="['integer',{suffixText: '%', modifyValueOnWheel : false}]" class="form-control border-t-0 border-l-0 border-r-0 text-right" :placeholder="'unit price'"></autonumeric>
                                        <a @click.prevent="deleteAdditionalCharges(i)" href="#" tabindex="-1" class="ml-2 text-red-400"><i class="icon-cancel-circle2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="additionalCharges.length > additionalChargesList.length" class="grid grid-cols-2 gap-5 mt-1">
                                <div class="slt">
                                    <div class="w-full bg-gray-100 border-b h-7 rounded-sm"></div>
                                </div>
                                <div>
                                    <div class="flex items-center">
                                        <div class="w-full bg-gray-100 border-b h-7 rounded-sm"></div>
                                        <a @click.prevent="addAddtionalCharges" tabindex="-1" href="#" class="ml-2 text-green-500"><i class="icon-plus-circle2"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <add-item v-model="form.items" :currencySymbol="currencySymbol" >
                <!-- DISCOUNT -->
                <tr v-if="form.discount.value" class="font-medium text-red-500">
                    <td colspan="6" class="px-2 py-1 text-right">Discount on total price</td>
                    <td class="px-2 py-1 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.discount.value)}}</td>
                    <td></td>
                </tr>
                <!-- TAX -->
                <tr class="font-medium text-blue-500">
                    <td colspan="6" class="px-2 h-7 text-right">
                        <div class="flex items-center justify-end">
                            <select-tax @input="onSelectTax($event)" :options="taxCodes" v-model="form.tax" class="ml-3 w-32 -m-1.5"></select-tax>
                        </div>
                    </td>
                    <td class="px-2 h-5 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(tax)}}</td>
                    <td></td>
                </tr>
                <!-- ADDITIONAL CHARGES -->
                <tr v-if="totalAdditionalCharges" class="font-medium text-green-600">
                    <td colspan="6" class="px-2 h-7 text-right">ADDITIONAL CHARGERS	</td>
                    <td class="px-2 h-7 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(totalAdditionalCharges)}}</td>
                    <td></td>
                </tr>
                <!-- SHIPPING -->
                <tr class="font-medium">
                    <td colspan="6" class="px-2 h-7 text-right">
                        <button @click="modalShipping=true" class="hover:bg-gray-100 px-2 rounded-sm relative -mr-1.5">
                            <span v-if="form.shipping.shipmentCost" class="mr-5">
                                UPDATE SHPMENT COST
                                <a @click.prevent.stop="onClearShpping" href="#" class="absolute right-0.5 hover:text-red-500"><i class="icon-cross3"></i></a>
                            </span>
                            <span v-else>ADD SHIPPING</span>
                        </button>
                    </td>
                    <td class="px-2 h-7 text-right">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.shipping.shipmentCost)}}</td>
                    <td></td>
                </tr>
                <!-- TOTAL -->
                <tr class="bg-orange-100">
                    <td></td>
                    <td></td>
                    <td colspan="3" class="py-1 pl-10 pr-2.5">
                        <vue-discount ref="discount" :idx="'discount'" v-model="form.discount" :total="total" :symbol="currencySymbol" :label="'Discount on Total Price'"></vue-discount>
                    </td>
                    <td class="text-right text-base font-semibold">TOTAL</td>
                    <td class="text-right text-base font-semibold px-2">{{currencySymbol}}{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(grandTotal)}}</td>
                    <td></td>
                </tr>
            </add-item>
        </div>
        
        <shipping v-model="form.shipping" :show="modalShipping" @closeModal="modalShipping = false"></shipping>
        <!-- ALERT -->
        <div v-show="invalids.length > 0" class="fixed bg-red-200 z-50 animated fadeInDown faster top-5 w-72 right-5 text-black p-2 rounded-sm shadow-lg">
            <div class="font-medium flex justify-between items-center">
                <div>Invalid Fields:</div>
                <a @click.prevent="closeAlert" href="#" class="hover:text-red-600"><i class="icon-cross3"></i></a>
            </div>
            <div class="pl-5">
                <ul class="list-disc">
                    <li v-for="(invalid, i) in invalids" :key="i">{{invalid.text}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import stepper from '../../components/stepper.vue'
import autocomplete from '../../components/Autocomplete.vue'
import TagsInput from '../../components/TagsInput.vue' 
import Select2 from '../../components/Select2.vue'
import autonumeric from '../../components/Autonumeric.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueDiscount from '../../components/discount.vue'
import axios from 'axios'
import {EventBus} from '../../event-bus'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import AddItem from '../../components/additemsPurchase.vue'
import SelectTax from '../../components/tax.vue'
import Shipping from '../../components/shipping.vue'
import {moment} from '../../moment'
export default {
    components: {stepper, autocomplete, TagsInput, Select2, autonumeric, DatePicker, VueDiscount, AddItem, SelectTax, Shipping},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isDisabled: false,
            modalShipping: false,
            modalTitle: '',
            dataParentEdit: '',
            imgURL: process.env.VUE_APP_ROOT_API,
            masks: {
                input: 'DD/MM/YYYY'
            },  
            purchaseNo: '',
            createdAt: '',
            supplier: '',
            company: '',
            currencies: [],
            paymentTerms: [],
            shipmentTerms: [],
            shipmentMethods: [],
            additionalCharges: [],
            additionalChargesList: [],
            taxCodes: [],
            showStock: false,
            form: {
                supplierId: '',
                address: '',
                referenceNumber: '',
                remarks: '',
                tags: [],
                estimatedReceiveDate: '',
                dateValidaty: '',
                currencyId: '',
                exchangeRate: '',
                additionalCharges: [],
                status: '',
                items: [],
                total: '',
                totalAdditionalCharges: '',
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                    bookingCode: '',
                    trackingNumber: ''
                },
                grandTotal: '',
            },
            errCustomerId: '',
            invalids: [],
            invalidTimeout: ''
        }
    },
    mounted () {
        EventBus.$on('onClick', this.onSave)
        EventBus.$on('onCancel', this.onCancel)
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setAction(false)
        this.setDiscardText('DISCARD')
        this.$refs.autocomplete.setFocus()
        this.getData()
        this.validaty()
    },
    destroyed () {
        EventBus.$off('onClick', this.onSave)
        EventBus.$off('onCancel', this.onCancel)
    },
    computed: {
        address () {
            let addr = {}
            if(this.supplier) {
                addr = this.supplier.addressLists.find(obj => obj.isDefault == true)
            }
            return addr
        },
        currencySymbol () {
            let symbol = ''
            const currency = this.currencies.find(obj => {
               return obj._id == this.form.currencyId
            })
            if(currency) {
                symbol = currency.symbolNative
            }
            return symbol
        },
        productIds () {
            let id = []
            for (let i = 0; i < this.form.items.length; i++) {
                id.push(this.form.items[i].productId)
            }
            return id
        },
        items () {
            return this.form.items.map(obj => {
                obj.total = obj.unitPrice * obj.qty
                obj.subTotal = obj.total - obj.discount.value
                return obj
            })
        },
        totalAdditionalCharges () {
            let total = 0;
            for (let i = 0; i < this.form.additionalCharges.length; i++) {
                const el = this.form.additionalCharges[i]
                if(el.isFixed) {
                    total += el.unitPrice
                } else {
                    total += this.total * el.unitPrice / 100
                }
            }
            return total
        },
        total () {
            let total = 0
            for (let i = 0; i < this.form.items.length; i++) {
                total += this.form.items[i].subTotal
            }
            return total
        },
        tax () {
            let t = 0
            if(this.form.tax.amount) {
                t = (this.total - this.form.discount.value) * this.form.tax.amount / 100
            }
            return t
        },
        grandTotal () {
            let g = 0
            g = this.total + this.tax + this.totalAdditionalCharges + this.form.shipping.shipmentCost - this.form.discount.value
            return g
        },
        formSubmit () {
            for (let i in this.form) {
                if(this.form[i] === '') {
                    delete this.form[i]
                }
            }
            return this.form
        }
    },
    methods: {
        getData () {
            axios.get('/purchases/edit/'+this.$route.params.id)
            .then(res => {
                this.paymentTerms = res.data.paymentTerms
                this.shipmentTerms = res.data.shipmentTerms
                this.shipmentMethods = res.data.shipmentMethods
                this.additionalCharges = res.data.additionalCharges
                this.taxCodes = res.data.taxCodes
                this.currencies = res.data.currencies
                const purchase = res.data.purchase
                this.supplier = purchase.supplier
                this.company = purchase.shipTo
                const charges = purchase.additionalCharges
                this.purchaseNo = purchase.no
                this.createdAt = purchase.createdAt
                if(this.additionalChargesList.length == 0) {
                    for (let i = 0; i < charges.length; i++) {
                        this.additionalChargesList.push(this.additionalCharges)
                    }
                }
                this.$nextTick(() => {
                    this.form.supplierId = purchase.supplierId
                    this.form.status = purchase.status
                    this.form.referenceNumber = purchase.referenceNumber
                    this.form.remarks = purchase.remarks
                    this.form.tags = purchase.tags
                    this.form.estimatedReceiveDate = purchase.estimatedReceiveDate
                    this.form.dateValidaty = purchase.dateValidaty
                    this.form.invoiceDate = purchase.invoiceDate
                    this.form.shipToId = purchase.shipToId
                    this.form.currencyId = purchase.currencyId
                    this.form.paymentTermId = purchase.paymentTermId
                    this.form.shipmentTermId = purchase.shipmentTermId
                    this.form.shipmentMethodId = purchase.shipmentMethodId
                    this.form.exchangeRate = purchase.exchangeRate
                    this.form.additionalCharges = purchase.additionalCharges
                    this.form.items = purchase.items
                    this.form.discount = purchase.discount
                    this.$nextTick(() => {
                        this.form.tax = purchase.tax
                    })
                    if(purchase.shipping) {
                        this.form.shipping = purchase.shipping
                    }
                })
                this.setBreadcrumbs([
                    {text: 'Purchase Orders', path: '/purchases'},
                    {text: purchase.no}
                ])
            })
        },
        // SUPPLIER FUNCTION
        onSelectSupplier (val) {
            this.form.supplierId = val._id
            this.form.currencyId = val.defaultCurrencyId
            this.form.paymentTermId = val.defaultPaymentTermId
            this.form.shipmentTermId = val.defaultShipmentTermId
            this.form.shipmentMethodId = val.defaultShipmentMethodId
            this.errSupplierId = ''
            const currency = this.currencies.find(obj => {
                return obj._id == val.defaultCurrencyId
            })
            if(currency) {
                this.currencySymbol = currency.symbolNative
            } else {
                this.currencySymbol = ''
            }
            const tax = this.taxCodes.find(obj => {
                return obj._id == val.defaultTaxId
            })
            
            if(tax) {
                let t = {
                    code: tax.code,
                    amount: tax.amount,
                    value: 0
                }
                this.form.tax = t
            } else {
                this.$refs.tax.onClear()
            }
        },
        // company 
        onSelectComapny (val) {
            this.form.shipToId = val._id
        },
        // DATE VALIDATY
        validaty () {
            let date = new Date()
            this.form.dateValidaty = date.setDate(date.getDate() + 30)
            this.form.estimatedReceiveDate = date.setDate(date.getDate())
        },

        // ITEM FUNCTIONS
        onSave () {
            this.form.status = 'RFQ'
            this.onSubmit()
        },
        onSubmit () {
            this.setDisabled(true)
            if(!this.form.supplierId) {
                this.errCustomerId = true
                window.scrollTo(0,0)
                this.invalids.push({text: 'Customer'})
            }
            if(this.form.items.length == 0) {
                this.invalids.push({text: 'Items'})
            }
            if(this.form.items.length > 1) {
                for(let i=0; i < this.form.items.length; i++) {
                    if(!this.form.items[i].productId) {
                        this.form.items.splice(i, 1)
                    }
                }
            }
            for(let i=0; i<this.form.items.length; i++) {
                this.form.items[i].edited = false
                if(!this.form.items[i].productId) {
                    this.invalids.push({text: 'Items'})
                    return
                }
            }
            if(this.form.supplierId && this.form.items.length > 0) {
                this.form.address = this.address
                this.form.totalQty = this.totalQty
                this.form.total = this.total
                this.form.totalAdditionalCharges = this.totalAdditionalCharges
                this.form.tax.value = this.tax
                this.form.grandTotal = this.grandTotal
                axios.put('/purchases/update/'+this.$route.params.id, this.formSubmit)
                .then(res => {
                    const id = res.data._id
                    this.setDisabled(false)
                    this.$router.push('/purchases/detail/'+id)
                })
                .catch(err => {
                    this.setDisabled(false)
                    console.log(err.response);
                })
            } else {
                this.setDisabled(false)
            }
        },

        closeAlert () {
            this.invalids = []
            clearTimeout(this.invalidTimeout)
        },
        // CURRENCY FUNCTION
        onSelectCurrency (val) {
            this.form.currencyId = val
        },
        // CHARGES FUNCTIONS
        addAddtionalCharges () {
            this.form.additionalCharges.push({id: '', isFixed: true, name: '', unitPrice: ''})
            const charges = this.additionalCharges.filter(obj1 => {
                return !this.form.additionalCharges.some(obj2 => {
                    return obj1.id === obj2.id
                })
            })
            this.additionalChargesList.push(charges)
            this.$nextTick(() => {
                const i = this.form.additionalCharges.length -1
                this.$refs['additionalCharges'+i][0].open()
            })
        },
        deleteAdditionalCharges (i) {
            this.form.additionalCharges.splice(i, 1)
            this.additionalChargesList.splice(i, 1)
            this.$nextTick(() => {
                for(let i = 0; i < this.form.additionalCharges.length; i++) {
                    this.$refs['additionalCharges'+i][0].update()
                }
            })
        },
        onSelectAdditionalCharges (e, i) {
            const charges = this.additionalChargesList[i].find(obj => obj.id === e)
            this.form.additionalCharges[i].name = charges.name
            this.form.additionalCharges[i].unitPrice = charges.unitPrice
            this.form.additionalCharges[i].isFixed = charges.isFixed
        },

        // TAX FUNCTION
        onSelectTax (e) {
            this.form.tax.code = e.code
            this.form.tax.amount = e.amount
        },
        onDeleteTax () {
            this.form.tax.code = ''
            this.form.tax.amount = ''
        },
        
        // SHIPPING FUNCTION
        onClearShpping () {
            this.form.shipping = {
                shipmentMethodId: '',
                shipmentService: '',
                shipmentCost: '',
            }
        },
        getDate (d) {
            return moment(d)
        },
        onCancel () {
            this.$router.push('/purchases/detail/'+this.$route.params.id)
        }
        
    },
    watch: {
        invalids () {
            clearTimeout(this.invalidTimeout)
            this.invalidTimeout = setTimeout(this.closeAlert, 3500)
        }
    },
}
</script>

<style >
    .charges .select2-selection__rendered {
        color: #14b8a6 !important;
    }
    
</style>