<template>
    <div>
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search quotation or customer name or">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    
                </div>
                
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>
        <div class="my-5 bg-white rounded-sm overflow-hidden border">
            <table class="w-full">
                <thead>
                    <tr class="bg-teal-500 leading-none">
                        <th class="uppercase font-medium text-teal-100 py-3.5 px-5 tracking-wide">COMANY NAME</th>
                        <th class="uppercase font-medium text-teal-100 py-3.5 px-5 tracking-wide">Code</th>
                        <th class="uppercase font-medium text-center text-teal-100 py-3 px-5 tracking-wide">PURCHASES</th>
                    </tr>
                </thead>
                <tbody class="divide-y">
                    <tr v-for="(supplier, i) in suppliers" :key="i">
                        <td class="py-3 px-5 whitespace-nowrap">
                            <div class="flex items-center">
                                <div class="inline-flex h-9 w-9 items-center justify-center bg-teal-500 rounded-full p-1">
                                    <span class="block text-white text-sm uppercase">{{supplier.name.substring(0,1)}}</span>
                                </div>
                                <div class="ml-3">
                                    <router-link :to="'/suppliers/'+supplier._id" class="font-semibold text-lightblue-500 hover:text-blue-600">{{supplier.name}}</router-link>
                                    <div class="text-gray-400">{{supplier.createdAt}}</div>
                                </div>
                            </div>
                        </td>
                        <td class="py-3 px-5">
                            {{supplier.code}}
                        </td>
                        <td class="py-3 px-5 text-center">
                            0
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    mixins: [BreadcrumbsManager],
    data () {
        return {
            search: '',
            suppliers: [],
            searchData: '',
            pages: '',
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.setButtonText('CREATE')
        this.setDiscard(false)
        EventBus.$on('onClick', this.createSupplier)
        this.getData()
    },
    destroyed () {
        EventBus.$off('onClick', this.createSupplier)
    },
    methods: {
        getData () {
            axios.get('/suppliers', {
                params: {
                    search: this.search
                }
            })
            .then(res => {
                this.suppliers = res.data.data
            })
        },
        createSupplier () {
            this.$router.push('/suppliers/new')
        }
    },
    watch: {
        search () {
            this.searchData()
        }
    }
}
</script>