<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-50 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class=" w-2/3 bg-white rounded-sm">
                                <div class="flex items-center justify-between px-5 py-2 border-b">
                                    <div class="font-medium">Search Customer</div>
                                    <a @click.prevent="$emit('closeModal')" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500 -mr-3" style="font-size: 19px"></i></a>
                                </div>
                                <div ref="modalBody" class="overflow-scroll">
                                    <div class="flex items-center justify-between px-5 py-3">
                                        <div class="form-feedback form-feedback-left w-3/5">
                                            <input ref="search" v-model="search"  type="text" class="form-control" placeholder="search customer name">
                                            <div class="form-control-feedback">
                                                <span>
                                                    <i class="icon-search4 text-gray-400"></i>
                                                </span>
                                            </div>
                                            <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                                                <a @click.prevent="search = ''" href="#">
                                                    <i class="icon-cancel-circle2 text-red-500"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="flex items-center">
                                            <div class="flex items-center">
                                                <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                                                <div class="flex items-center space-x-1">
                                                    <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                                                    <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="w-full">
                                        <thead>
                                            <tr>
                                                <th class="py-2 pl-5 pr-2 bg-teal-500 text-teal-50" style="width: 30%">Name</th>
                                                <th class="px-2 bg-teal-500 text-teal-50" style="widht: 20%">Phone</th>
                                                <th class="px-2 bg-teal-500 text-teal-50" style="width: 50%">Address</th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y">
                                            <tr v-for="(customer, i) in customers" :key="i" @click="onSelect(customer)" class="hover:bg-gray-100 cursor-pointer">
                                                <td class="py-2 pl-5 pr-2">{{customer.displayName}}</td>
                                                <td class="px-2">{{customer.contact.mobile}}</td>
                                                <td class="px-2">{{customer.address.street}} {{customer.address.street2}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
export default {
    props: {
        show: Boolean
    },
    data () {
        return {
            pages: '',
            customers: [],
            search: '',
            searchData: '',
        }
    },
    created () {
        this.searchData = debounce (() => {
            this.getData()
        }, 100)
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize)
    },
    destroyed () {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize () {
            let h = window.innerHeight - 110
            this.$refs.modalBody.style.height = h+'px'
        },
        getData (i) {
            axios.get('/customers', {
                params: {
                    page: i,
                    search: this.search
                }
            })
            .then(res => {
                this.customers = res.data.data
                this.pages = res.data.pages
            })
        },
        onSelect (v) {
            this.$emit('input', v)
        }
    },
    watch: {
        show (val) {
            if(val) {
                this.getData()
                this.$nextTick(() => {
                    this.$refs.search.focus()
                })
            }
        },
        search () {
            this.searchData()
        }
    }
}
</script>
