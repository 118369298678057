<template>
    <div>
        <div class="bg-white border rounded-sm">
            <div class="relative flex items-center border-b h-14 px-5 text-gray-400">
                <a ref="tab1" @click.prevent="getProduct($event, [true, false])" href="#" :class="filterStatus.length == 2 ? 'text-red-500':''" class="inline-flex font-semibold px-5 h-full items-center transition duration-200 hover:text-red-500">All Products ({{allProduct}})</a>
                <a @click.prevent="getProduct($event, [true])" href="#" :class="filterStatus[0] === true && filterStatus.length == 1 ? 'text-red-500':''" class="inline-flex px-5 h-full items-center font-semibold transition duration-200 hover:text-red-500">Active ({{activeProduct}})</a>
                <a @click.prevent="getProduct($event, [false])" href="#" :class="filterStatus[0] === false ? 'text-red-500':''" class="inline-flex px-5 h-full items-center font-semibold transition duration-200 hover:text-red-500">Inactive ({{deactiveProduct}})</a>
                <div class="ml-auto flex items-center">
                    <button @click="migrateData" type="button" class="bg-green-600 relative rounded-sm h-9 text-white focus:outline-none  overflow-hidden">
                        <div class="flex items-center">
                            <div class="h-9 w-9 bg-green-700 rounded-l-sm flex justify-center items-center">
                                <img src="@/assets/tokopedia.png" alt="" width="25">
                            </div>
                            <div class="px-3">
                                async tokopedia
                            </div>
                        </div>
                        <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-black"></div>
                    </button>
                </div>
                <div ref="active" class="absolute transform duration-300 bg-red-500 -bottom-px" style="height: 3px"></div>
            </div>

            <div class="p-5 grid grid-cols-9 gap-5">
                <div class="col-span-3">
                    <div class="w-full">
                        <div class="form-feedback form-feedback-left">
                            <input type="text" v-model="search" class="form-control" placeholder="search product name or SKU">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-search4 text-gray-400"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2">
                    <div ref="category" class="relative z-10 h-9">
                        <button @click="showFilterCategory = !showFilterCategory" type="button" class="bg-gray-50 border relative rounded-sm h-9 w-full focus:outline-none">
                            <div class="relative h-full flex items-center">
                                <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                    <i class="icon-filter3"></i>
                                </div>
                                <div class="pl-3">
                                    <span v-if="filterCategories.length == 0" class="text-gray-400">Filter by Category</span>
                                    <span v-else class="text-gray-400">{{filterCategories.length}} Categories</span>
                                </div>
                                <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                    <i class="icon-arrow-down22"></i>
                                </div>
                            </div>
                        </button>
                        <ul v-if="showFilterCategory" class="absulute w-full max-h-56 overflow-auto mt-1 bg-white border rounded-sm">
                            <li v-for="(category, i) in categories" :key="i" class="hover:bg-gray-100 px-2">
                                <div class="form-radio">
                                    <input :id="i" type="checkbox" @input="isFilter = true" :value="category._id" v-model="filterCategories">
                                    <label :for="i" class="truncate w-full p-2">{{category.name}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-span-2">
                    <div ref="brand" class="relative z-10 h-9">
                        <button @click="showFilterBrand = !showFilterBrand" type="button" class="bg-gray-50 border relative rounded-sm h-9 w-full focus:outline-none">
                            <div class="relative h-full flex items-center">
                                <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                    <i class="icon-filter3"></i>
                                </div>
                                <div class="pl-3">
                                    <span v-if="filterBrands.length ==  0" class="text-gray-400">Filter by Brand</span>
                                    <span v-else class="text-gray-400">{{filterBrands.length}} Brands</span>
                                </div>
                                <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                    <i class="icon-arrow-down22"></i>
                                </div>
                            </div>
                            
                        </button>
                        <ul v-if="showFilterBrand" class="absulute w-full max-h-56 overflow-auto mt-1 bg-white border rounded-sm">
                            <li v-for="(brand, i) in brands" :key="i" class="hover:bg-gray-100 px-2">
                                <div class="form-radio">
                                    <input :id="i" type="checkbox" @input="isFilter = true" :value="brand._id" v-model="filterBrands">
                                    <label :for="i" class="truncate w-full p-2">{{brand.name}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-span-2">
                    <div class="flex items-center justify-end space-x-2">
                        <tooltip :text="'Reload'" :place="'center'">
                            <button @click="getData" :class="{'bg-green-500 text-white': onReload}" class="h-9 w-9 bg-gray-100 border rounded-sm focus:outline-none">
                                <i :class="{'animate-spin':onReload}" class="icon-sync"></i>
                            </button>
                        </tooltip>
                        <button @click="filterEdited" :class="{'bg-red-500 text-white': edited}" class="h-9 w-9 bg-gray-100 border rounded-sm focus:outline-none">
                            <i class="icon-pencil5"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Filter List -->
            <div v-if="labelCategories.length > 0 || labelBrands.length > 0" class="px-5 pb-3 flex items-center">
                <div class="w-28 pb-2 mr-3">
                    <a @click.prevent="filterBrands=[]; filterCategories=[]" href="#" class="text-green-500 hover:text-green-600">Clear all filter</a>
                </div>
                <ul class="flex flex-wrap">
                    <li v-for="(label, i) in labelCategories" :key="i" class="mr-2 mb-2">
                        <div class="px-3 py-2 bg-white border border-blue-500 rounded-sm flex items-center justify-between">
                            <div>
                                {{label.name}}
                            </div>
                            <a @click.prevent="removeFilterCategory(label._id)" href="#" class="block"><i class="icon-cancel-circle2 text-red-500 ml-3 hover:text-red-600"></i></a>
                        </div>
                    </li>
                </ul>
                <ul class="flex flex-wrap">
                    <li v-for="(label, i) in labelBrands" :key="i" class="mr-2 mb-2">
                        <div class="px-3 py-2 bg-white border border-orange-500 rounded-sm flex items-center justify-between">
                            <div>
                                {{label.name}}
                            </div>
                            <a @click.prevent="removeFilterBrand(label._id)" href="#" class="block"><i class="icon-cancel-circle2 text-red-500 ml-3 hover:text-red-600"></i></a>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- /filter list -->

            <table class="w-full">
                <thead>
                    <tr class="border-t border-b text-gray-500 uppercase"> 
                        <th @click="sortBy('name')" :class="{'text-red-500':sortKey == 'name'}" class="px-5 py-3 hover:text-red-500 cursor-pointer" style="width: 40%">
                            Product Info 
                            <i v-if="sortKey == 'name'" :class="[sortOrder === 1 ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('sellingPrice')" :class="{'text-red-500': sortKey == 'sellingPrice'}" class="px-5 py-3 hover:text-red-500 cursor-pointer text-right" style="width: 20%">
                            Selling Price 
                            <i v-if="sortKey == 'sellingPrice'" :class="[sortOrder === 1 ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('stock')" :class="{'text-red-500':sortKey == 'stock'}" class="px-5 py-3 hover:text-red-500 cursor-pointer text-right" style="width: 15%">
                            Stock 
                            <i v-if="sortKey == 'stock'" :class="[sortOrder === 1 ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th class="px-5 py-3 text-center" style="width: 10%">Active</th>
                        <th class="px-5 py-3" style="width: 15%"></th>
                    </tr>
                </thead>
                <tbody v-if="!products">
                    <tr v-for="i in 20" :key="i">
                        <td class="px-5 py-3 ph-item">
                            <div class="flex items-center">
                                <div class="h-20 w-20 rounded-sm bg-gray-200"></div>
                                <div class="ml-3">
                                    <div class="h-4 bg-cyan-200 w-64 mb-2"></div>
                                    <div class="h-4 bg-cyan-200 w-44 mb-2"></div>
                                    <div class="h-4 bg-gray-200 w-36"></div>
                                </div>
                            </div>
                        </td>
                        <td class="px-5 text-right ph-item">
                            <div class="w-full">
                                <div class="h-4 bg-gray-200 w-20 mb-4 ml-auto mr-7"></div>
                                <div class="h-3 bg-gray-200 w-10 ml-auto mr-7"></div>
                            </div>
                        </td>
                        <td class="px-5 text-right ph-item">
                            <div class="w-full">
                                <div class="h-4 bg-gray-200 w-14 mb-4 ml-auto"></div>
                                <div class="h-3 bg-gray-200 w-12 ml-auto"></div>
                            </div>
                        </td>
                        <td class="px-5 ph-item">
                            <div class="flex items-center justify-center">
                                <div class="h-5 w-10 rounded-full bg-gray-200 mr-1"></div>
                            </div>
                        </td>
                        <td class="px-5 ph-item">
                            <div class="flex items-center space-x-2">
                                <div class="h-8 w-8 bg-gray-200 rounded-sm"></div>
                                <div class="h-8 w-8 bg-gray-200 rounded-sm"></div>
                                <div class="h-8 w-8 bg-gray-200 rounded-sm"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(pr, i) in products" :key="i" class="group border-b transition duration-200 hover:bg-gray-50">
                        
                        <td class="px-5 py-3">
                            <div class="flex items-center">
                                <div v-if="pr.images[0]">
                                    <img class="border rounded-sm" :src="imgURL+'/public/img/products/200/'+pr.images[0]" width="80" alt="">
                                </div>
                                <div class="ml-3">
                                    <router-link :to="'/products/detail/'+pr._id"><h5 class="text-base font-medium text-cyan-500 hover:text-cyan-600">{{pr.name}}</h5></router-link>
                                    <div class="text-gray-500">SKU: {{pr.sku}}</div>
                                    <div v-if="pr.brandId" class="text-orange-500 capitalize flex">
                                        {{pr.brandId.name}}
                                        <ul class="flex items-center ml-3 text-teal-500">
                                            <li v-for="(category, i) in pr.categoriesId" :key="i" class="mr-3">{{category.name}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="px-5 text-right">
                            <div>
                                <div v-if="edit_price_id != pr._id" class="relative inline-flex" :class="edit_price == pr._id? 'border border-dashed':'border border-transparent'">
                                    <span class="mr-7 text-sm font-medium">{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2,}).format(pr.sellingPrice)}}</span>
                                    <span @click="onEditPrice(pr._id)" @mouseover="edit_price=pr._id" @mouseleave="edit_price=''" class="hover:bg-gray-200 hover:text-gray-600 text-gray-400 cursor-pointer px-1 ml-2 transition duration-200 opacity-0 group-hover:opacity-100 absolute right-0"><i class="icon-pencil" style="font-size:13px"></i></span>
                                </div>
                                <div v-if="edit_price_id == pr._id" class="mr-7">
                                    <autonumeric :ref="'price'+pr._id" v-model="pr.sellingPrice" @focusout.native="updatePrice(i)" :options="{modifyValueOnWheel : false}"  class="text-right text-sm font-medium border border-dashed w-32 focus:outline-none"></autonumeric>
                                </div>
                            </div>
                            <div class="mt-2 pl-px text-gray-500 mr-7">Nett: {{Intl.NumberFormat('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2,}).format(pr.netPrice)}} </div>
                        </td>
                        <td class="px-5 text-right">
                            <tooltip :text="'Click to view inventory'" :place="'center'">
                                <a @click.prevent="getInventory(pr)" href="#" class="font-semibold text-teal-500">Stock: {{pr.stock}}</a>
                            </tooltip>
                            <div class="mt-2 text-gray-500">Sold: 0</div>
                        </td>
                        <td class="px-7">
                            <div class="flex items-center justify-center pl-3">
                                <toggler v-model="pr.status" @input="onUpdateStatus(i)" class="mr-2"></toggler>
                            </div>
                        </td>
                        <td class="px-5">
                            <div class="flex items-center space-x-2">
                                <tooltip :text="'tokopedia'" :place="'center'">
                                    <a :href="pr.tokopediaUrl" target="_blank" class="flex items-center justify-center border h-8 w-8 rounded-sm">
                                        <div class="hover:text-teal-500">
                                            <img src="@/assets/tokopedia.png" width="20" alt="">
                                        </div>
                                    </a>
                                </tooltip>
                                <a href="#" class="flex items-center justify-center border h-8 w-8 rounded-sm">
                                    <div class="hover:text-teal-500">
                                        <i class="icon-store"></i>
                                    </div>
                                </a>
                                <tooltip :text="'Edit Product'" :place="'right'">
                                    <button @click="onEditProduct(pr._id)" :class="{'border border-red-500 text-red-500': pr.brand===null}" class="flex items-center justify-center border h-8 w-8 rounded-sm hover:text-teal-500">
                                        <div>
                                            <i class="icon-pencil5"></i>
                                        </div>
                                    </button>
                                </tooltip>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getFilter($event)"></paginate>
        </div>
        
        <product-search ref="search"></product-search>
        <alert ref="alert"></alert>
        <modal :show="modalInventory">
            <div class="bg-white border rounded-sm p-5 shadow-xl w-2/5">
                <div class="flex items-center justify-between mb-5">
                    <div class="font-medium text-lg">INVENTORY</div>
                    <div class="border border-teal-500 rounded-sm p-2 w-32 text-right">
                        <div class="text-lg text-teal-500 font-medium leading-none">{{inventory.stock}}</div>
                        <div class="leading-none text-gray-400">total stock</div>
                    </div>
                </div>
                <div class="flex items-center mb-5">
                    <div class="mr-5">
                        <img :src="inventory.image" width="65">
                    </div>
                    <div>
                        <div class="text-base font-medium">{{inventory.name}}</div>
                        <div class="text-gray-400">{{inventory.sku}}</div>
                    </div>
                </div>
                <div v-if="inventory.inventories" class="mb-5 border-b" style="max-height: 400px; overflow-y: scroll">
                    <div v-for="(inv, i) in inventory.inventories" :key="i">
                        <div class="bg-teal-500 py-2 px-3 text-teal-50 rounded-sm flex items-center justify-between">
                            <div>
                                <i class="icon-stack2 mr-2"></i>{{inv._id.name}} {{inv._id.default}}
                            </div>
                            <div class="font-semibold">{{inv.total}}</div>
                        </div>
                        <div v-for="(section, a) in inv.sections" :key="a" class="flex items-center justify-between px-3 py-2 border-b">
                            <div>{{section.name}}</div>
                            <div class="text-right">{{section.qty}}</div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button @click="modalInventory=false" class="border rounded-sm h-9 px-5 hover:bg-gray-50">close</button>
                </div>
            </div>
        </modal>
        <modal-center :show="modalMigrate">
            <div class="w-2/5 bg-white rounded-sm p-10">
                <div class="text-center text-lg font-medium mb-5">TOKOPEDIA SYNCHRONIZE</div>
                <div class="animated fadeInDown infinite mt-10">
                    <i class="icon-file-download icon-2x"></i>
                </div>
                <div class="mt-10">
                    
                </div>
                <div class="mt-10">please wait...</div>
            </div>
        </modal-center>
    </div>
</template>

<script>
import axios from 'axios'
import Autonumeric from '../../components/Autonumeric.vue'
import Toggler from '../../components/Toggler.vue'
import Tooltip from '../../components/Tooltip.vue'
import Paginate from '../../components/paginate.vue'
import ProductSearch from '../../components/ProductSearch.vue'
import Alert from '../../components/alert.vue'
import Modal from '../../components/Modal.vue'
import ModalCenter from '../../components/ModalCenter.vue'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
import Pusher from 'pusher-js'
import debounce from 'lodash.debounce'
export default {
    components: {
        Autonumeric,
        Toggler,
        Tooltip,
        Paginate,
        ProductSearch,
        Alert,
        Modal,
        ModalCenter
    },
    mixins: [BreadcrumbsManager],
    data () {
        return {
            search: '',
            searchData: '',
            onReload: false,
            modalMigrate: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            categories: '',
            labelCategories: [],
            brands: '',
            labelBrands: [],
            products: '',
            pages: '',
            allProduct: 0,
            activeProduct: 0,
            deactiveProduct: 0,
            edited: false,
            migrate: {
                show: false,
                auth: '',
                page: 3,
            },
            isFilter: false,
            filterStatus: [true, false],
            showFilterCategory: false,
            showFilterBrand: false,
            filterCategories: [],
            filterBrands: [],
            edit_price: false,
            edit_price_id: false,
            tokopedia: false,
            productTokopedia: '',
            sortKey: 'id',
            sortOrder: -1,
            modalInventory: false,
            inventory: {
                stock: '',
                name: '',
                sku: '',
                image: '',
                inventories: ''
            },
            pageCount: 0,
            productCount: 0
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getFilter()
        }, 300)
    },
    mounted () {
        this.subscribe()
        this.setButtonText('CREATE')
        this.setDiscard(false)
        this.setBreadcrumbs([
            {text: 'Products'}
        ])
        this.getData()
        document.addEventListener('click', this.closeFilter)
        const edit = localStorage.getItem('edited')
        const editParse = JSON.parse(edit)
        if(editParse) {
            this.filterStatus = editParse.status
            if(this.filterStatus[0] == true && this.filterStatus.length == 1) {
                this.$refs.active.style.width = '97px'
                this.$refs.active.style.transform="translateX(132px)"
            } else if (this.filterStatus[0] == false) {
                this.$refs.active.style.width = '124px'
                this.$refs.active.style.transform="translateX(228px)"
            }
            this.sortKey = editParse.sortKey
            this.sortOrder = editParse.sortOrder
            this.filterBrands = editParse.brands
            this.labelBrands = editParse.labelBrands
            this.filterCategories = editParse.categories
            this.labelCategories = editParse.labelCategories
            this.getFilter(editParse.page)
        }
        EventBus.$on('onClick', this.addNewProduct)
    },
    destroyed () {
        document.removeEventListener('click', this.closeFilter)
        EventBus.$off('onClick', this.addNewProduct)
    },
    methods: {
        closeFilter (event) {
            if(!event || !this.$refs.category.contains(event.target)) {
                this.showFilterCategory = false
            }
            if(!event || !this.$refs.brand.contains(event.target)) {
                this.showFilterBrand = false
            }
        },
        removeFilterCategory (id) {
            this.isFilter = true
            var index = this.filterCategories.indexOf(id)
            if(index >= 0 ) {
                this.filterCategories.splice(index, 1);
            }
        },
        removeFilterBrand (id) {
            this.isFilter = true
            var index = this.filterBrands.indexOf(id)
            if(index >= 0 ) {
                this.filterBrands.splice(index, 1);
            }
        },
        getData () {
            this.onReload = true
            this.products = ''
            this.sortKey = 'createdAt',
            this.sortOrder = -1
            this.filterStatus = [true, false]
            this.filterCategories = []
            this.filterBrands = []
            this.$refs.active.style.width= this.$refs.tab1.offsetWidth+'px'
            this.$refs.active.style.transform="translateX(0px)"
            axios.get('/products')
            .then((res) => {
                this.onReload = false
                this.brands = res.data.brands
                this.categories = res.data.categories 
                this.products = res.data.products
                this.pages = res.data.pages
                this.allProduct = res.data.allProducts
                this.activeProduct = res.data.activeProducts
                this.deactiveProduct = res.data.deactiveProducts
            })
        },
        getFilter (e) {
            this.products = ''
            window.scroll({top: 0, behavior: 'smooth'})
            axios.get('/products/filter', {
                params: {
                    page: e,
                    search: this.search,
                    edited: this.edited,
                    status: this.filterStatus,
                    sortKey: this.sortKey,
                    sortOrder: this.sortOrder,
                    brands: this.filterBrands,
                    categories: this.filterCategories
                }
            })
            .then((res) => {
                this.products = res.data.data
                this.pages = res.data.pages
            })
        },
        sortBy(key) {
            if(key == this.sortKey) {
                this.sortOrder = (this.sortOrder == -1) ? 1: -1
            } else {
                this.sortKey = key
                this.sortOrder = -1
            }
            this.$nextTick(() => {
                if(this.sortKey == 'name' || this.sortKey == 'sellingPrice' || this.sortKey == 'stock') {
                    this.getFilter()
                }
            })
        },
        filterEdited () {
            this.edited = !this.edited
            this.getFilter()
        },
        showSearch () {
            this.$refs.search.showModal()
        },
        showTokopedia(pr) {
            this.tokopedia = true
            axios.get('api/tokopedia/product', {
                params: {
                    id: pr
                }
            })
            .then((res) => {
                this.productTokopedia = res.data[0]
            })
        },
        migrateData () {
            axios.get('/tokopedia/migrate')
            .then(() => {
                this.modalMigrate = true
            })
        },
        addNewProduct () {
           this.$router.push('/products/create')
        },
        getProduct (e, filter) {
            let x = e.target.offsetLeft - 20
            this.$refs.active.style.width = e.target.clientWidth +"px"
            this.$refs.active.style.transform="translateX("+x+"px)"
            this.filterStatus = filter
            this.$nextTick(()=> {
                this.getFilter()
            })
        },
        updatePrice (i) {
            this.edit_price_id = ''
            this.edit_price = ''
            axios.put('/products/update/price/'+this.products[i]._id, {
                sellingPrice: this.products[i].sellingPrice
            })
            .then(() => {
                this.$refs.alert.open('success', 'Selling price has been successfully updated')
            })
        },
        onEditPrice (id) {
            this.edit_price_id = id
            this.$nextTick(() => {
                this.$refs['price'+id][0].$el.focus()
            })
        },
        onUpdateStatus (i) {
            const status = this.products[i].status
            const productId = this.products[i]._id
            axios.put('/products/update/status/'+productId, {
                status: status
            })
            .then(res => {
                if(res.data.status) {
                    this.activeProduct = parseInt(this.activeProduct + 1)
                    this.deactiveProduct = parseInt(this.deactiveProduct - 1)
                } else {
                    this.activeProduct = parseInt(this.activeProduct - 1)
                    this.deactiveProduct = parseInt(this.deactiveProduct + 1)
                }
            })
        },
        onEditProduct (id) {
            this.$router.push('/products/edit/'+id)
            const edit = {
                page: this.pages.current_page,
                status: this.filterStatus,
                sortKey: this.sortKey,
                sortOrder: this.sortOrder,
                brands: this.filterBrands,
                labelBrands: this.labelBrands,
                categories: this.filterCategories,
                labelCategories: this.labelCategories
            }
            localStorage.setItem('edited', JSON.stringify(edit))
        },
        getInventory (p) {
            this.inventory.inventories = ''
            this.modalInventory = true
            this.inventory.stock = 0
            this.inventory.name = p.name
            this.inventory.sku = p.sku
            this.inventory.image = this.imgURL+'/public/img/products/200/'+p.images[0]
            axios.get('/products/inventory/'+p._id)
            .then(res => {
                this.inventory.inventories = res.data
                let stocks = res.data
                for(let i=0; i < stocks.length; i++) {
                    this.inventory.stock += stocks[i].total
                }
            })
            .catch(() => {
                this.inventory.inventories = ''
            })
        },
        subscribe () {
			let pusher = new Pusher('9ed5b6068ed8653fce89', {cluster: 'ap1'})
			let productCount = pusher.subscribe('tokopedia')
			let pageCount = pusher.subscribe('tokopedia')
			productCount.bind('products', (val) => {
				this.productCount = val.message
			})
			pageCount.bind('pages', (val) => {
				this.pageCount = val.message
			})
		},
        onDelete (id) {
            axios.delete('/products/delete/'+id)
            .then(() => {
                this.getData()
            })
        }
    },
    watch: {
        search () {
            this.searchData()
        },
        filterCategories: {
            handler: function (val) {
                if(this.isFilter) {
                    this.labelCategories = []
                    val.forEach(el => {
                        var label = this.categories.find(x => x._id == el)
                        this.labelCategories.push(label)
                    })
                    this.$nextTick(() => {
                        this.getFilter()
                    })
                }
            },
            deep: true
        },
        filterBrands: {
            handler: function(val) {
                if(this.isFilter) {
                    this.labelBrands = []
                    val.forEach(el => {
                        var label = this.brands.find(x => x._id == el)
                        this.labelBrands.push(label)
                    })
                    this.$nextTick(() => {
                        this.getFilter()
                    })
                }
            },
            deep: true
        }
    },
    beforeRouteLeave(to, from , next) {
        this.replaceBreadcrumb({
            find: 'Products',
            replace: {text: 'Products', path: this.$route.fullPath}
        })
        if(to.name == 'Edit product' && this.edited) {
            next()
        } else {
            localStorage.removeItem('edited');
            next()
        }
    }
}
</script>