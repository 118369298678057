export function moment (d) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Des']
    const date = new Date(d)
    let dd = date.getDate()
    let mm = month[date.getMonth()]
    let yy = date.getFullYear()
    let h = date.getHours()
    let m = date.getMinutes()
    dd = checkTime(dd)
    h = checkTime(h)
    m = checkTime(m)
    function checkTime (i) {
        if(i < 10) {
            i = `0${i}`
        }
        return i
    }
    return `${dd} ${mm} ${yy}, ${h}:${m}`
}