<template>
    <div>
        <div class="border border-t-0">
            <div class="p-5 flex items-center justify-between">
                <dropdown
                    :items="warehouses"
                    class="w-56"
                    v-model="warehouse"
                >
                    <template #default="data">
                        <div class="cursor-pointer border rounded-sm bg-white h-9 flex items-center" :class="data.show ? 'rounded-b-none':''">
                            <button class="w-full flex h-full items-center px-3 focus:outline-none">
                                <span v-if="warehouse" class="mr-auto flex items-center">{{warehouse}}</span>
                                <span v-else class="mr-auto text-gray-400">WAREHOUSE</span>
                                <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                            </button>
                        </div>
                    </template>
                    <template #items="items">
                        <div>{{items.item.name}}</div>
                    </template>
                </dropdown>
                <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getInventory(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getInventory(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <div class="text-right">
                        <div class="text-lg text-green-600 leading-none font-medium">{{stock}}</div>
                        <div class="text-xs text-gray-400">ON HAND</div>
                    </div>
                </div>
            </div>

        </div>
        <table class="w-full">
            <thead class="border border-blue-500">
                <tr>
                    <th class="py-2 pl-5 pr-2 bg-blue-500 text-blue-50">DATE</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50">REFERENCE</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50">SOURCE DOCUMENT</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50 text-right">IN</th>
                    <th class="py-2 px-2 bg-blue-500 text-blue-50 text-right">OUT</th>
                    <th class="py-2 pl-2 pr-5 bg-blue-500 text-blue-50 text-right">BALANCE</th>
                </tr>
            </thead>
            <tbody class="border divide-y">
                <tr v-for="(stock, i) in stockCards" :key="i">
                    <td class="py-2 pl-5 pr-2">{{getDate(stock.createdAt)}}</td>
                    <td class="py-2 px-2">{{stock.documentName}}</td>
                    <td class="py-2 px-2">{{stock.document}}</td>
                    <td class="py-2 px-2 text-right">{{stock.stockIn}}</td>
                    <td class="py-2 px-2 text-right">{{stock.stockOut}}</td>
                    <td class="py-2 pl-2 pr-5 text-right">{{stock.balance}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import axios from 'axios'
import Dropdown from './Dropdown.vue'
import {moment} from '../moment'
export default {
    components: {Dropdown},
    data () {
        return {
            warehouse: '',
            warehouseId: '',
            warehouses: [],
            stock: 0,
            pages: '',
            stockCards: []
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            axios.get('/products/warehouses')
            .then(res => {
                this.warehouses = res.data
                let warehouse = this.warehouses.find(obj => obj.isDefault == true)
                this.warehouse = warehouse.name
            })
        },
        getInventory (i) {
            axios.get('/products/inventories/'+this.$route.params.productId, {
                params: {
                    page: i,
                    warehouseId: this.warehouseId
                }
            })
            .then (res => {
                this.stock = 0
                this.pages = res.data.pages
                if (res.data.inventory) {
                    this.stock = res.data.inventory.qty
                }
                this.stockCards = res.data.stockCards
            })
        },
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        warehouse (val) {
            let warehouse = this.warehouses.find(obj => obj.name == val)
            this.warehouseId = warehouse._id
            this.$nextTick(() => {
                this.getInventory()
            })
        },
    }
    
}
</script>