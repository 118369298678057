<template>
    <div class="p-5">
        <table v-for="(activity, i) in activities" :key="i" class="w-full border-b divide-y table-fixed">
            <tr v-for="(history, i) in getDiff(activity.original, activity.updated)" :key="i">
                <td class="py-2" style="width: 11%">{{getDate(activity.createdAt)}}</td>
                <td class="px-2 text-center" style="width: 4%">
                    <div class="flex items-center justify-center h-7 w-7 text-violet-50 bg-violet-500 rounded-full uppercase">{{activity.userId.name[0]}}</div>
                </td>
                <td class="py-2" style="width: 35%">
                    <div class="px-2 truncate overflow-hidden w-full max-w-full">
                        {{history.name}}
                    </div>
                </td>
                <td class="py-2 px-2" style="width: 10%">
                    <div class="flex items-center" v-html="history.type">
                    </div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    {{history.action}}
                </td>
                <td class="text-center" style="width: 2%">
                    <div v-if="history.action !== 'Added' && history.action !== 'Removed'"><i class="icon-arrow-right13"></i></div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    {{history.value}}
                </td>
            </tr>
            
            <tr v-if="activity.event == 'settoquotation'">
                <td class="py-2" style="width: 11%">{{getDate(activity.createdAt)}}</td>
                <td class="px-2 text-center" style="width: 4%">
                    <div class="flex items-center justify-center h-7 w-7 text-violet-50 bg-violet-500 rounded-full uppercase">{{activity.userId.name[0]}}</div>
                </td>
                <td class="py-2" style="width: 35%">
                    <div class="px-2 truncate overflow-hidden w-full max-w-full">
                        {{activity.documentName}}
                    </div>
                </td>
                <td class="py-2 px-2" style="width: 10%">
                    <div class="flex items-center">
                        <i class="icon-reload-alt"></i>
                        <div class="ml-1.5">Set to quotation</div>
                    </div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    Set to quotation
                </td>
                <td class="text-center" style="width: 2%">
                    
                </td>
                <td class="text-center truncate" style="width: 10%">

                </td>
            </tr>

            <tr v-if="activity.event == 'cancelled'">
                <td class="py-2" style="width: 11%">{{getDate(activity.createdAt)}}</td>
                <td class="px-2 text-center" style="width: 4%">
                    <div class="flex items-center justify-center h-7 w-7 text-violet-50 bg-violet-500 rounded-full uppercase">{{activity.userId.name[0]}}</div>
                </td>
                <td class="py-2" style="width: 35%">
                    <div class="px-2 truncate overflow-hidden w-full max-w-full">
                        {{activity.documentName}}
                    </div>
                </td>
                <td class="py-2 px-2" style="width: 10%">
                    <div class="flex items-center">
                        <i class="icon-cancel-circle2"></i>
                        <div class="ml-1.5">Cancel</div>
                    </div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    Cancelled
                </td>
                <td class="text-center" style="width: 2%">
                    
                </td>
                <td class="text-center truncate" style="width: 10%">

                </td>
            </tr>

            <tr v-if="activity.event == 'confirm'">
                <td class="py-2" style="width: 11%">{{getDate(activity.createdAt)}}</td>
                <td class="px-2 text-center" style="width: 4%">
                    <div class="flex items-center justify-center h-7 w-7 text-violet-50 bg-violet-500 rounded-full uppercase">{{activity.userId.name[0]}}</div>
                </td>
                <td class="py-2" style="width: 35%">
                    <div class="px-2 truncate overflow-hidden w-full max-w-full">
                        {{activity.documentName}}
                    </div>
                </td>
                <td class="py-2 px-2" style="width: 10%">
                    <div class="flex items-center">
                        <i class="icon-checkmark-circle"></i>
                        <div class="ml-1.5">Confirm</div>
                    </div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    Sales Order
                </td>
                <td class="text-center" style="width: 2%">
                    
                </td>
                <td class="text-center truncate" style="width: 10%">

                </td>
            </tr>
            <tr v-if="activity.event == 'insert'">
                <td class="py-2" style="width: 11%">{{getDate(activity.createdAt)}}</td>
                <td class="px-2 text-center" style="width: 4%">
                    <div class="flex items-center justify-center h-7 w-7 text-violet-50 bg-violet-500 rounded-full uppercase">{{activity.userId.name[0]}}</div>
                </td>
                <td class="py-2" style="width: 35%">
                    <div class="px-2 truncate overflow-hidden w-full max-w-full">
                        {{activity.documentName}}
                    </div>
                </td>
                <td class="py-2 px-2" style="width: 10%">
                    <div class="flex items-center">
                        <i class="icon-plus-circle2"></i>
                        <div class="ml-1.5">Created</div>
                    </div>
                </td>
                <td class="text-center truncate" style="width: 10%">
                    New Quotation
                </td>
                <td class="text-center" style="width: 2%">
                    
                </td>
                <td class="text-center truncate" style="width: 10%">

                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import {detailedDiff} from 'deep-object-diff'
import {moment} from '../moment'
export default {
    props: ['activities'],
    methods: {
        getDate (d) {
            return moment (d)
        },
        getDiff(ori, upd) {
            const document = []
            const original = ori
            const updated = upd
            delete original['grandTotal']
            delete original['total']
            delete original['totalQty']
            delete original['tax']
            delete original['updatedAt']
            delete original['totalAdditionalCharges']
            delete original['__v']
            delete updated['grandTotal']
            delete updated['total']
            delete updated['totalQty']
            delete updated['tax']
            delete updated['updatedAt']
            delete updated['totalAdditionalCharges']
            delete updated['__v']
            const detDiff = detailedDiff(original, updated)
            console.log(detDiff);
            // added
            if(Object.keys(detDiff.added).length > 0) {
                for (let a in detDiff.added) {
                    if(a == 'payments') {
                        console.log(detDiff.added);
                    }
                    if(a == 'shipping') {
                        let kurir = detDiff.added.shipping.name.name
                        document.push({name: 'Shipping', type: `<i class="icon-truck"></i><div class="ml-1.5">Courier</div>`, action: 'Added', value: kurir })
                    }
                    if(a == 'customerPO') {
                        let po = detDiff.added.customerPO
                        document.push({name: 'Customer PO', type: `<i class="icon-plus-circle2"></i><div class="ml-1.5">Customer PO</div>`, action: 'Added', value: po})
                    }
                    if(a == 'customerReference') {
                        document.push({name: 'Customer Reference', type: `<i class="icon-user"></i><div class="ml-1.5">Ref</div>`, action: 'Added', value: detDiff.added.customerReference})
                    }
                    if(a == 'additionalCharges') {
                        let charges = {...detDiff.added.additionalCharges[0]}
                        document.push({name: 'Additional Charges', type: `<i class="icon-cash3"></i><div class="ml-1.5">${charges.name}</div>`, action: 'Added', value: Intl.NumberFormat('en-US').format(charges.unitPrice)})
                    }
                    if(a == 'tags') {
                        document.push({name: 'Tags', type: `<i class="icon-price-tag2"></i><div class="ml-1.5">Tag</div>`, action: 'Added', value: a})
                    }
                }
            }

            // IITEM ADDED
            let addDiff = updated.items.filter(x => {
                return !original.items.some(y => {
                    return x.productId === y.productId
                })
            })
            for(let i in addDiff) {
                const p = addDiff[i]
                document.push({name: p.name, type: `<i class="icon-stack2"></i><div class="ml-1.5">Item</div>`, action: 'Added', value: p.name})
            }

            // ITEM REMOVED
            let delDiff = original.items.filter(x => {
                return !updated.items.some(y => {
                    return x.productId === y.productId
                })
            })
            for(let i in delDiff) {
                const p = delDiff[i]
                document.push({name: p.name, type: `<i class="icon-stack2"></i><div class="ml-1.5">Item</div>`, action: 'Removed', value: `${p.name}`})
            }

            if(Object.keys(detDiff.updated).length > 0) {
                for(let a in detDiff.updated) {
                    console.log(a, 'updated');
                    if(a == 'invoiceStatus') {
                        console.log(detDiff.updated);
                    }
                    if(a == 'estimatedDeliveryTime') {
                        const ori = original.estimatedDeliveryTime
                        const upd = updated.estimatedDeliveryTime
                        document.push({name: 'ESTIMATED DELIVERY TIME', type: `<i class="icon-alarm"></i><div class="ml-1.5">Estimated</div>`, action:ori, value: upd})
                    }
                    if(a == 'customerId') {
                        document.push({name: 'Customer', type: `<i class="icon-user"></i><div class="ml-1.5">Customer</div>`})
                    }
                    if(a == 'remarks') {
                        for(let i in detDiff.updated.remarks) {
                            const ori = original.remarks[i]
                            const upd = updated.remarks[i]
                            if(i == 'replyAsap') {
                                document.push({name: 'Remarks', type: `<i class="icon-bubble-lines4"></i><div class="ml-1.5">Reply ASAP</div>`, action: ori ? '✓':'-', value: upd ? '✓':'-'})
                            }
                            if(i == 'forYourReview') {
                                document.push({name: 'Remarks', type: `<i class="icon-bubble-lines4"></i><div class="ml-1.5">For Your Review</div>`, action: ori ? '✓':'-', value: upd ? '✓':'-'})
                            }
                            if(i == 'urgent') {
                                document.push({name: 'Remarks', type: `<i class="icon-bubble-lines4"></i><div class="ml-1.5">Urgent</div>`, action: ori ? '✓':'-', value: upd ? '✓':'-'})
                            }
                            if(i == 'pleaseComment') {
                                document.push({name: 'Remarks', type: `<i class="icon-bubble-lines4"></i><div class="ml-1.5">Please Comment</div>`, action: ori ? '✓':'-', value: upd ? '✓':'-'})
                            }
                            if(i == 'comment') {
                                document.push({name: 'Remarks', type: `<i class="icon-bubble-lines4"></i><div class="ml-1.5">Comment</div>`, action: ori, value: upd})
                            }
                        }
                    }
                    if(a == 'items') {
                        for(let i in detDiff.updated.items) {
                            const p = detDiff.updated.items[i]
                            if(p.qty && !p.productId) {
                                const item = original.items[i]
                                document.push({name: item.name, type: `<i class="icon-cart2"></i><div class="ml-1.5">Qty</div>`, action: item.qty, value: `${p.qty}`})
                            }
                            if(p.unitPrice && !p.productId) {
                                const item = original.items[i]
                                document.push({name: item.name, type: `<i class="icon-coin-dollar"></i><div class="ml-1.5">Unit Price</div>`, action: Intl.NumberFormat('en-US').format(item.unitPrice), value: Intl.NumberFormat('en-US').format(p.unitPrice)})
                            }
                            if(p.discount && !p.productId) {
                                const item = original.items[i]
                                if(p.discount.discountType == 'Percentage') {
                                    document.push({name: item.name, type: `<i class="icon-percent"></i><div class="ml-1.5">Discount</div>`, action: `${Intl.NumberFormat('en-US').format(item.discount.amount)}%`, value: `${Intl.NumberFormat('en-US').format(p.discount.amount)}%`})
                                } else {
                                    document.push({name: item.name, type: `<i class="icon-percent"></i><div class="ml-1.5">Discount</div>`, action: Intl.NumberFormat('en-US').format(item.discount.amount), value: Intl.NumberFormat('en-US').format(p.discount.amount)})
                                }
                            }
                        }
                    }
                }
            }
            return document
        }
    },
}
</script>