<template>
	<div class="text-gray-600">
		<Navbar />
		<div class="flex flex-grow">
			<!-- Sidebar -->
			<Sidebar />
			<!-- /sidebar -->
			<div ref="content" class="flex flex-col flex-grow flex-shrink min-h-screen" style="padding-top: 2.8rem;">
				<div class="" style="padding-top: 0.5px">
                    <div v-show="path !== 'POS'">
                        <breadcrumbs ref="breadcrumb"></breadcrumbs>
                    </div>
                    <div v-show="path == 'POS'">
                        <pos-navbar ref="posNavbar"></pos-navbar>
                    </div>
                    <div style="padding-top: 47px">
                        <transition name="fade" mode="out-in">
                            <router-view />
                        </transition>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Navbar from '@/components/navbar.vue'
import Sidebar from '@/components/sidebar.vue'
import breadcrumbs from './components/breadcrumbs.vue'
import posNavbar from './components/posNavbar.vue'
export default {
	components: {
		Navbar,
		Sidebar,
        breadcrumbs,
        posNavbar
	},
    data () {
        return {
            path: ''
        }
    },
    mounted () {
        let w = this.$refs.content.clientWidth
        this.$refs.breadcrumb.$el.style.width = w+'px'
        this.$refs.posNavbar.$el.style.width = w+'px'
    },
    watch: {
        "$route" (val) {
            this.path = val.name
        }
    }
}
</script>

<style>
    .border-active {
        border-bottom: 3px solid #ef4444 ;
    }
    .slt .select2-selection--single {
        border: 1px solid transparent;
        border-top-color: transparent;
        border-top-style: none !important;
        border-top-width: 1px;
        border-right-color: transparent;
        border-right-style: none !important;
        border-right-width: 1px;
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: transparent;
        border-left-style: none !important;
        border-left-width: 1px;
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
    }
    .err .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
        border-color: #ef4444 !important;
    }
    .hide .select2-search {
        display: none !important;
    }

</style>
